var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-box",class:[
    { 'has-no-top-border': _vm.$route.name.includes('viewer') },
    { 'has-dark-theme-background': _vm.has_dark_theme }
  ]},[_c('div',{staticClass:"search-box-searchicon"},[_c('m-icon',{staticClass:"is-16x16",attrs:{"name":'search',"file":'tickets-sprite'}})],1),_c('div',{staticClass:"search-box-input"},[_c('input',{class:[
        { 'has-no-top-border': _vm.$route.name.includes('viewer') },
        { 'has-dark-theme-background': _vm.has_dark_theme }
      ],attrs:{"autocomplete":"off","type":"text","placeholder":_vm.localization(
          ("app-" + (_vm.placeholder_value
            .split(' ')
            .join('-')
            .toLowerCase())),
          _vm.placeholder_value
        )},on:{"input":_vm.find_tickets}})]),(
      !_vm.$store.state.is_offline &&
        !_vm.$route.name.includes('viewer') &&
        _vm.filter_option
    )?_c('div',{staticClass:"search-box-filter",class:{ 'is-hidden': !_vm.has_filter },on:{"click":function($event){return _vm.$emit('filter_option')}}},[_c('m-icon',{staticClass:"is-16x16",attrs:{"name":_vm.is_filters_selected
          ? 'ticket-search-filter-selected'
          : 'ticket-search-filter',"file":'tickets-sprite'},nativeOn:{"click":function($event){return _vm.$emit('filter')}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }