<template>
  <div class="tooltip-annot" style="display:none;">
    <div :ref="`tooltip_unknown`" style="display:flex;align-items:center;">
      <user-avatar
        :user="'unknown'"
        :pxsize="20"
        :avatarfontsize="12"
        class="mr-5"
        style="width:24px;height:24px;margin-right:5px;"
        :custom_class="'tiny-user-avatar'"
      ></user-avatar>
      <p
        style="margin:0;"
        class="is-medium-13-500-16 has-text-blue-grey truncate-text is-width-10"
      >
        unknown
      </p>
    </div>
    <div
      :ref="`tooltip_${annotUser.uid}`"
      v-for="annotUser in annotOwners"
      :key="annotUser.uid"
      style="display:flex;align-items:center;"
    >
      <user-avatar
        :user="annotUser"
        :pxsize="20"
        :avatarfontsize="12"
        class="mr-5"
        style="width:24px;height:24px;margin-right:5px;"
        :custom_class="'tiny-user-avatar'"
      ></user-avatar>
      <p
        style="margin:0;"
        class="is-medium-13-500-16 has-text-white  truncate-text is-width-10"
        v-if="$store.getters.state_users[annotUser.uid]"
        v-username="$store.getters.state_users[annotUser.uid]"
      >
        {{ $store.state.internal_users_uids.includes(annotUser.uid) }}
      </p>
      <p
        style="margin:0;"
        class="is-medium-13-500-16 has-text-white truncate-text is-width-10"
        v-else-if="$store.state.internal_users_uids.includes(annotUser.uid)"
      >
        Sensehawk
      </p>
      <p
        style="margin:0;"
        class="is-medium-13-500-16 has-text-white truncate-text is-width-10"
        v-else
      >
        Unknown
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['instance', 'idMap', 'annotOwners'],
    mounted() {
      this.setTooltip()
    },
    methods: {
      setTooltip() {
        this.instance.setAnnotationContentOverlayHandler(annotation => {
          try {
            let owner = this.$refs[`tooltip_${this.idMap[annotation.Id].owner}`]
            let targetElement = owner ? owner[0] : this.$refs[`tooltip_unknown`]
            const div = targetElement.cloneNode(true)
            var styledChildren = targetElement.getElementsByTagName('*')
            var children = div.getElementsByTagName('*')
            for (var i = 0; i < children.length; i++) {
              var child = children[i]
              var styles = document.defaultView.getComputedStyle(
                styledChildren[i]
              )
              for (var prop = 0; prop < styles.length; prop++) {
                child.style[styles[prop]] = styles.getPropertyValue(
                  styles[prop]
                )
              }
              child.style['font-family'] = 'inherit'
            }
            return div
          } catch (e) {
            console.log(e)
          }
        })
      }
    }
  }
</script>

<style></style>
