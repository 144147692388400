<template>
  <div class="therm-sidebar-details">
    <div class="therm-popup" :style="custom_styles_for_projects">
      <div class="is-flex has-space-between is-vcenter therm-popup__header">
        <p class="is-medium-14-500-17">
          {{ localization("app-inverter", "Inverter") }}
        </p>
        <p class="is-flex is-vcenter is-pointer" @click="show = !show">
          <span class="is-regular-12-500-14"
            >{{ selected.length }}
            {{ localization("app-selected", "selected") }}</span
          >
          <span class="is-regular-12-500-14 ml-5">
            <i
              class="fas"
              :class="show ? 'fa-chevron-up' : 'fa-chevron-down'"
            ></i>
          </span>
        </p>
      </div>
      <hr class="divider is-marginless has-opacity-4" />
      <div v-if="show" class="therm-popup__body">
        <p class="is-flex is-vcenter mb-10 ml-5">
          <input
            @change="bulk_select_projects"
            v-model="select_all_projects"
            type="checkbox"
            class="sh-checkbox"
            name="all"
            id="all"
          />
          <label for="all" class="is-medium-14-500-17 ml-5">{{
            localization("app-select-all", "Select All")
          }}</label>
        </p>
        <div
          class="sh-buttons therm-popup__body__published-projects has-slimscroll-xs"
        >
          <div
            v-for="project in unique_projects"
            :key="project"
            v-tooltip="{
              content:
                project.length > project_name_max_length ? project : null,
              classes: ['tooltip'],
              placement: 'bottom-end'
            }"
            @click="update_selection(project)"
            class="button is-regular-12-00-14 pb-5"
            :class="[
              [is_project_name_active(project) ? 'is-active' : 'is-disabled'],
              [selected.includes(project) ? 'is-active' : 'is-inactive']
            ]"
          >
            {{ project | truncate(project_name_max_length) }}
          </div>
        </div>
      </div>
    </div>
    <div class="therm-projects" :style="custom_styles_for_groups">
      <div
        v-click-outside="
          () => {
            show_active_groups = false;
          }
        "
        v-if="show_active_groups"
        class="therm-projects__list has-slimscroll-xs"
        :style="[
          $route.name == 'therm-v2-reports' || $route.name == 'therm-v2-tickets'
            ? { order: '2' }
            : null
        ]"
        :class="{ 'mt-5': $route.name == 'therm-v2-reports' }"
      >
        <!-- List all the groups in the view -->
        <div
          class="is-medium-14-500-17 is-pointer py-10 pl-5"
          :class="{ 'is-group-active': active_group == group.groupUid }"
          v-for="group in groups"
          :key="group.groupUid"
          @click="set_active_groups(group.groupUid)"
          v-tooltip="{
            content:
              group.display_name.length > scan_date_max_length
                ? group.display_name
                : null,
            classes: ['tooltip']
          }"
        >
          {{ group.display_name | truncate(scan_date_max_length) }}
        </div>
      </div>
      <div
        @click="show_active_groups = true"
        class="therm-projects__details mt-5 is-pointer"
      >
        <p class="is-regular-12-00-14 mb-4">
          {{ localization("app-scan-date", "Scan Date") }}
        </p>
        <p
          v-tooltip="{
            content:
              active_group_name &&
              active_group_name.length > scan_date_max_length
                ? active_group_name
                : null,
            classes: ['tooltip']
          }"
          class="is-medium-14-500-17 is-flex is-vcenter has-space-between"
        >
          <span :key="groups[active_group]">
            {{
              active_group_name ||
                localization("app-scan-date", "Scan Date")
                  | truncate(
                    active_group_name &&
                      active_group_name.length > scan_date_max_length
                      ? scan_date_max_length - 2
                      : scan_date_max_length
                  )
            }}
          </span>
          <sh-icon :name="'calendar'" class="is-16x16 ml-10" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";

  const PROJECTS_UNSELECTED_EVENT = "projects-unselected";
  const PROJECTS_SELECTED_EVENT = "projects-selected";

  export default {
    props: {
      project_name_max_length: {
        default: 7,
        required: false
      },
      custom_styles_for_projects: {
        type: String,
        required: false,
        default: ""
      },
      custom_styles_for_groups: {
        type: String,
        required: false,
        default: ""
      },
      set_groups: {
        type: Array,
        required: true,
        default: []
      },
      scan_date_max_length: {
        type: Number,
        required: false,
        default: 12
      }
    },
    data() {
      return {
        show: true,
        selected: [],
        active_group: null,
        show_active_groups: false,
        select_all_projects: false,
        projects_by_names: {},
        currently_set_active_groups: [],
        previously_set_active_groups: [],
        unwatch_unique_projects: null
      };
    },
    created() {
      this.unwatch_unique_projects = this.$watch("unique_projects", val => {
        if (this.unique_projects.length) {
          let valid_projects = this.unique_projects.filter(project =>
            this.groups_by_project_name[project].some(
              group => this.currently_active_groups.indexOf(group) !== -1
            )
          );
          if (valid_projects.length && !this.selected.length) {
            this.update_selection(valid_projects[0]);
            this.unwatch_unique_projects();
          }
        }
      });
    },
    computed: {
      ...mapState("thermv2", ["container", "active_layer_types"]),
      active_group_name() {
        return this.groups[this.active_group]
          ? this.groups[this.active_group].display_name
          : null;
      },
      // Only display projects that were published
      published_projects() {
        return this.container
          ? this.container.filter(project => project.published)
          : [];
      },
      currently_active_groups() {
        return [this.active_group, ...this.currently_set_active_groups];
      },
      unique_projects() {
        return Object.keys(this.projects_by_names).sort();
      },
      groups_by_project_name() {
        const grouped_projects = _.cloneDeep(this.projects_by_names);
        for (let project in grouped_projects) {
          grouped_projects[project] = grouped_projects[project].map(
            item => item.groupUid
          );
        }
        return grouped_projects;
      },
      groups() {
        let groups = {};
        const groups_with_scan_date = [];
        const groups_without_scan_date = [];
        if (this.container && this.published_projects.length) {
          const projects_by_names = {};
          for (let project of this.published_projects) {
            const project_details = {
              uid: project.projectUid,
              groupUid: project.groupUid
            };
            // Store the details for the projects against the name
            if (projects_by_names[project.projectName]) {
              projects_by_names[project.projectName].push(project_details);
            } else {
              projects_by_names[project.projectName] = [project_details];
            }
            const group_uid = project.groupUid;
            // List groups in the view by iterating over the published projects
            if (!groups[group_uid]) {
              if (project.groupProperties.date)
                groups_with_scan_date.push({
                  ..._.pick(project, ["groupUid", "groupName"]),
                  display_name: this.pretty_date(project.groupProperties.date)
                });
              else
                groups_without_scan_date.push({
                  ..._.pick(project, ["groupUid", "groupName"]),
                  display_name: project.groupName
                });
            }
          }
          // Sort groups by date
          const groups_by_date = groups_with_scan_date.sort(
            (group1, group2) =>
              new Date(group2.display_name) - new Date(group1.display_name)
          );
          // Sort groups without scan date by their name
          const groups_by_name = _.sortBy(
            groups_without_scan_date,
            "display_name"
          );
          groups = _.keyBy([...groups_by_date, ...groups_by_name], "groupUid");
          if (!this.active_group)
            this.set_active_groups(
              (groups_by_date[0] || groups_by_name[0]).groupUid
            );
          this.projects_by_names = projects_by_names;
        }
        return groups;
      }
    },
    watch: {
      // Explicitly pass groups to be enabled (used in comparison)
      set_groups: {
        deep: true,
        handler(currently_set_active_groups, previously_set_active_groups) {
          this.currently_set_active_groups = currently_set_active_groups;
          this.previously_set_active_groups = previously_set_active_groups;
          this.set_active_groups();
        }
      }
    },
    methods: {
      is_project_name_active(project_name) {
        return this.groups_by_project_name[project_name].some(
          group => this.currently_active_groups.indexOf(group) !== -1
        );
      },
      pretty_date(scan_date) {
        return moment(new Date(scan_date)).format("MMM DD, YYYY");
      },
      // Emit projects that were selected/unselected
      set_active_groups(group_uid = null) {
        if (
          this.$route.name !== "therm-v2-compare" &&
          this.active_group == group_uid
        ) {
          this.show_active_groups = false;
          return;
        }

        // this.selected = [];
        // this.$emit(PROJECTS_SELECTED_EVENT, []);
        const previously_active_groups = group_uid
          ? [this.active_group]
          : this.previously_set_active_groups;
        const currently_active_groups = group_uid
          ? [group_uid]
          : this.currently_set_active_groups;
        if (group_uid) {
          this.show_active_groups = false;
          this.select_all_projects = false;
          this.active_group = group_uid;
          this.$emit("group-selected", group_uid);
        }
        this.selected.forEach(project => {
          this.$emit(
            PROJECTS_UNSELECTED_EVENT,
            this.projects_by_names[project]
              .filter(project =>
                previously_active_groups.includes(project.groupUid)
              )
              .map(project => project.uid),
            previously_active_groups
          );
        });
        this.selected.forEach(project => {
          this.$emit(
            PROJECTS_SELECTED_EVENT,
            this.projects_by_names[project]
              .filter(project =>
                currently_active_groups.includes(project.groupUid)
              )
              .map(project => project.uid),
            currently_active_groups
          );
        });
      },
      bulk_select_projects() {
        if (this.select_all_projects) {
          const projects_to_emit = [];
          for (let project_name of this.unique_projects) {
            if (this.is_project_name_active(project_name)) {
              if (!this.selected.includes(project_name)) {
                this.selected.push(project_name);
                projects_to_emit.push(
                  ...this.projects_by_names[project_name]
                    .filter(project =>
                      this.currently_active_groups.includes(project.groupUid)
                    )
                    .map(project => project.uid)
                );
              }
            }
          }
          this.$emit(PROJECTS_SELECTED_EVENT, projects_to_emit);
        } else {
          const projects_to_emit = [];
          this.selected.forEach(project_name =>
            projects_to_emit.push(
              ...this.projects_by_names[project_name]
                .filter(project =>
                  this.currently_active_groups.includes(project.groupUid)
                )
                .map(project => project.uid)
            )
          );
          this.$emit(PROJECTS_UNSELECTED_EVENT, projects_to_emit);
          this.selected = [];
        }
      },
      async update_selection(project_name) {
        const project_ids = this.projects_by_names[project_name]
          .filter(project =>
            this.currently_active_groups.includes(project.groupUid)
          )
          .map(project => project.uid);
        if (this.selected.includes(project_name)) {
          this.selected = this.selected.filter(b => b != project_name);
          this.select_all_projects = false;
          this.$emit(PROJECTS_UNSELECTED_EVENT, project_ids);
        } else {
          this.selected.push(project_name);
          if (this.selected.length === this.unique_projects.length)
            this.select_all_projects = true;
          this.$emit(PROJECTS_SELECTED_EVENT, project_ids);
        }
      }
    }
  };
</script>
<style lang="scss">
  .therm-sidebar-details {
    position: absolute;
  }
  .therm-popup {
    width: 21rem;
    position: absolute;
    transition: all 0.2s;
    box-shadow: 0px 2px 5px #00000033;
    &__header,
    &__body {
      background: $white;
      // color: $light-black;
      padding: 1rem 0.5rem;
      &__published-projects {
        min-height: 6.5rem;
        max-height: 20rem;
        overflow: auto;
      }
    }
    .button {
      margin: 0 0 5px 5px;
      width: 5.9rem;
      height: 2.8rem;
    }
    &__header {
      padding: 1rem;
      border-radius: 2px;
    }
  }
  .therm-projects {
    position: absolute;
    position: fixed;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;

    // right: -185px;
    bottom: 1rem;
    // left: 2rem;
    &__list,
    &__details {
      box-shadow: 0px 2px 5px #00000033;
      background-color: $white;
      border-radius: 2px;
      padding: 1rem 1.5rem;

      svg {
        margin-top: -2px;
      }
    }
    &__list {
      height: 14rem;
      overflow: auto;
      p {
        padding: 0 0.5rem 1.6rem;
        cursor: pointer;
      }
    }
    .has-slimscroll-xs::-webkit-scrollbar-thumb {
      border-radius: 1rem !important;
      background: rgb(197, 188, 188);
      margin-right: 1rem;
    }
  }

  .button.is-active {
    border: 2px solid $primary;
    color: $primary;

    &:hover {
      background-color: rgba($primary, 0.1);
    }
  }
  .is-group-active {
    color: $primary;
  }

  .button.is-inactive {
    color: #575757;
    border: 2px solid #c5c5c5;

    &:hover {
      background-color: rgba($primary, 0.1);
    }
  }

  .button.is-disabled {
    color: #afafaf;
    background-color: #e6e6e6;
    border: none;
  }
</style>
