<template>
  <div>
    <div class="therm-ticket-details">
      <p
        @click="$store.commit('thermv2/SET_SELECTED_FEATURE_ID', null)"
        class="padding-20"
      >
        <i class="fas is-24x24 fa-chevron-left is-pointer"></i>
        {{ localization("app-anomaly-details", "Anomaly details") }}
      </p>

      <div class="tabs">
        <ul class="is-flex has-space-around">
          <li
            class=""
            :class="{ 'is-active': active_tab == 'details' }"
            @click="switch_tabs('details')"
          >
            <a class="is-medium-14-500-17">{{
              localization("app-details", "Details")
            }}</a>
          </li>
          <li
            :class="{ 'is-active': active_tab == 'attachments' }"
            @click="switch_tabs('attachments')"
          >
            <a class="is-medium-14-500-17">{{
              localization("app-attachments", "Attachments")
            }}</a>
          </li>
        </ul>
      </div>

      <div
        v-if="active_tab == 'details' && ticket"
        class="therm-ticket-details__content mt-20 padding-20"
      >
        <p>{{ ticket.name || "" }}</p>
        <div class="divider has-background-light-grey has-opacity-1"></div>
        <div class="is-flex is-vcenter has-space-between pb-15">
          <p>{{ localization("app-assignees", "Assignees") }}</p>
          <div
            class="is-flex is-vcenter is-pointer"
            :class="{ 'is-active': member_team_bulk_dropdown_active }"
            @click="
              member_team_bulk_dropdown_active = !member_team_bulk_dropdown_active
            "
          >
            <span class="has-text-link-blue roboto-medium-12">{{
              localization("app-add-or-edit", "Add/edit")
            }}</span>
          </div>
          <div class="is-absolute right-0">
            <member-team-bulk-dropdown
              v-if="member_team_bulk_dropdown_active"
              v-click-outside="() => (member_team_bulk_dropdown_active = false)"
              :members="assignee_users"
              :teams="assignee_teams"
              :asset_id="asset_id"
              @close="member_team_bulk_dropdown_active = false"
              @save="
                update_ticket({
                  assignees: [...$event.teams, ...$event.members]
                });
                member_team_bulk_dropdown_active = false;
              "
            />
          </div>
        </div>
        <div>
          <!-- --------------------------------- Members -------------------------------- -->
          <div
            v-if="assignee_users && assignee_users.length"
            class="has-disabled-text roboto-regular-12 mb-5 mx-20"
          >
            {{ localization("app-members", "Members") }}
          </div>
          <div v-if="assignee_users && assignee_users.length" class="mb-20">
            <div
              class="py-5 px-20 is-flex is-vcenter has-hover-bg is-hover-area"
              v-for="user in assignee_users"
              :key="user"
            >
              <user-avatar
                :user="{ uid: user }"
                :pxsize="24"
                :custom_class="'tiny-user-avatar'"
                :show_tooltip="true"
              ></user-avatar>
              <span
                class="ml-6 username-truncate"
                style="width:13rem"
                v-username="{ uid: user }"
              ></span>
            </div>
          </div>

          <!-- ---------------------------------- TEAMS --------------------------------- -->
          <div
            v-if="assignee_teams && assignee_teams.length"
            class="has-disabled-text roboto-regular-12 mb-10 mx-20"
          >
            {{ localization("app-teams", "Teams") }}
          </div>
          <div class="mb-15">
            <div
              class="py-5 px-20 is-flex-between has-hover-bg is-hover-area"
              v-for="team in assignee_teams"
              :key="team"
            >
              <div class="is-flex">
                <sh-icon
                  style="flex-shrink:0;"
                  :name="'team-icon'"
                  class="is-24x24 mr-5"
                ></sh-icon>
                <p>
                  {{
                    $store.getters.state_teams[team] &&
                      $store.getters.state_teams[team].name
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="divider has-background-light-grey has-opacity-1"></div>
        <div class="is-flex is-vcenter has-space-between py-10">
          <p>{{ localization("app-anomaly-type", "Anomaly type") }}</p>
          <p>{{ defect_type }}</p>
        </div>
        <div class="divider has-background-light-grey has-opacity-1"></div>
        <div class="is-flex is-vcenter has-space-between">
          <p>{{ localization("app-status", "Status") }}</p>

          <status-dropdown
            :has_dark_theme="false"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            :current_status="ticket.status"
            :ticket="ticket"
            v-if="ticket"
            @update-ticket-status="
              update_ticket({ status: parseInt($event.value) })
            "
          ></status-dropdown>
        </div>
        <div class="divider has-background-light-grey has-opacity-1"></div>
        <div class="is-flex is-vcenter has-space-between py-10">
          <p>{{ localization("app-location", "Location") }}</p>
          <p>
            {{ (ticket.properties && ticket.properties.stringName) || "NA" }}
          </p>
        </div>
        <div class="divider has-background-light-grey has-opacity-1"></div>
        <div class="is-flex is-vcenter has-space-between pt-10 pb-20">
          <p>Δ {{ localization("app-temperature", "Temperature") }}</p>
          <p>
            {{
              ticket.properties && ticket.properties.temperatureDifference >= 0
                ? `${ticket.properties.temperatureDifference.toFixed(2)} °C`
                : "NA"
            }}
          </p>
        </div>
        <div class="divider has-background-light-grey has-opacity-1"></div>
        <div
          class="is-flex is-vcenter has-space-between pt-10 pb-20"
          v-if="
            ticket.properties &&
              (ticket.properties.serial_number ||
                ticket.properties.serialNumber)
          "
        >
          <p>{{ localization("app-serial-number", "Serial Number") }}</p>
          <p>
            {{
              ticket.properties.serial_number ||
                ticket.properties.serialNumber ||
                "NA"
            }}
          </p>
        </div>
        <div style="width: 80%" class="margin-auto py-20">
          <div
            class="button is-outlined is-info is-large is-fullwidth"
            @click="open_ticket_details(ticket.uid)"
          >
            {{ localization("app-view-details", "View details") }}
          </div>
        </div>
      </div>
      <div v-else-if="active_tab == 'attachments'">
        <p
          class="is-pointer ml-20 mb-10 has-text-info"
          @click="open_raw_image_modal()"
        >
          {{ localization("app-check-markers", "Check markers") }}
        </p>
        <div>
          <div class="is-flex is-vcenter has-space-between px-20">
            <p>
              {{ localization("app-total-images", "Total images") }} ({{
                raw_images && raw_images.length
              }})
            </p>
            <p
              v-if="
                $store.getters.is_internal_user &&
                  raw_images &&
                  raw_images.length
              "
              class="button is-medium is-info"
              :class="{ 'is-loading': downloading_multiple }"
              @click.stop="download_images(raw_images)"
            >
              {{ localization("app-download", "Download") }}
            </p>
          </div>
          <div class="py-20">
            <div
              v-if="raw_images && raw_images.length"
              class="is-flex is-flex-wrap"
              style="justify-content: space-evenly"
            >
              <div
                class="is-inline is-relative  attachment_block mb-20"
                v-for="(image, i) in raw_images"
                :key="i"
                @click="download_image(image)"
              >
                <sh-icon
                  :name="'download'"
                  class="is-36x36 download_icon is-pointer"
                ></sh-icon>
                <img
                  :src="
                    image.src ||
                      require('@/assets/illustrations/working-illustration.png')
                  "
                  alt=""
                  class="image is-90x120"
                />
              </div>
            </div>
            <div v-else class="has-text-centered">
              {{ localization("app-no-attachments", "NO ATTACHMENTS") }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="details-actions  is-flex align-center has-space-around is-absolute"
        style="background: #f3f3f5"
      >
        <div
          :class="{ 'is-disabled': index == 0 }"
          class="is-pointer"
          @click="previous_ticket"
        >
          <i class="fas fa-chevron-left has-text-dark pr-10"></i>
          <a class="is-medium-14-500-17 has-text-dark">{{
            localization("app-previous", "Previous")
          }}</a>
        </div>
        <div
          :class="{ 'is-disabled': index == filtered_defects.length - 1 }"
          class="is-pointer"
          @click="next_ticket"
        >
          <a class="is-medium-14-500-17 has-text-dark">{{
            localization("app-next", "Next")
          }}</a>
          <i class="fas fa-chevron-right has-text-dark pl-10"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import RawImageMapModal from "@/app/old/desktop/therm-v2/components/popup-modal.component.vue";
  import MemberTeamBulkDropdown from "@/app/old/desktop/shared/components/member-team/member-team-bulk-dropdown.vue";

  import { mapMutations, mapState, mapGetters } from "vuex";
  import { apiService } from "@/app/old/desktop/shared/services/";

  export default {
    mixins: [TicketDetailsMixin],
    data() {
      return {
        is_popup_active: false,
        member_team_bulk_dropdown_active: false,
        active_tab: "details",
        index: -1,
        downloading_multiple: false,
        raw_images: [],
        status_bg_classes: {
          1: "is-bg-yellow",
          2: "is-bg-green",
          3: "is-bg-blue",
          4: "is-bg-light-grey",
          5: "is-bg-red",
          6: "is-bg-blue"
        },
        status_options: [
          {
            name: "pending",
            value: 1
          },
          {
            name: "in progress",
            value: 2
          },
          {
            name: "resolved",
            value: 3
          },
          {
            name: "closed",
            value: 4
          },
          {
            name: "rejected",
            value: 5
          }
        ]
      };
    },
    computed: {
      ...mapState("thermv2", [
        "projects",
        "vector_types",
        "map",
        "selected_feature_id"
      ]),
      ...mapGetters("thermv2", ["filtered_defects"]),
      asset_id() {
        return this?.ticket?.targetElement?.asset;
      },
      asset_name() {
        return this?.ticket?.targetElement?.asset
          ? _.find(
              this.$store.state.assets,
              a => a.uid == this.ticket.targetElement.asset
            ).name
          : null;
      },
      ticket() {
        return this.index >= 0 && this.filtered_defects[this.index]
          ? this.filtered_defects[this.index]
          : {};
      },
      attachments() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.projectUid &&
          this.ticket.properties.issueUid
        ) {
          return this.projects[this.ticket.properties.projectUid].features[
            this.ticket.properties.issueUid
          ].properties.raw_images;
        }
        return [];
      },
      ticket_project() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.projectUid
        ) {
          let projectid = this.ticket.properties.projectUid;
          return this.projects[projectid];
        } else {
          return null;
        }
      },
      defect_type() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.classId
        ) {
          let vector = this.vector_types.find(
            v => v.class_id == this.ticket.properties.classId
          );
          if (vector && vector.description) {
            return vector.description;
          } else {
            return "";
          }
          // return vector;
        } else {
          return "";
        }
      },
      assignee_users() {
        if (
          this.ticket &&
          this.ticket.assignees &&
          Array.isArray(this.ticket.assignees) &&
          this.ticket.assignees.length
        ) {
          return _.intersection(
            Object.keys(this.$store.getters.state_users),
            this.ticket.assignees
          );
        } else if (
          !this.ticket.assignees ||
          (this.ticket.assignees && this.ticket.assignees.uid == null)
        ) {
          return [];
        } else {
          return _.intersection(Object.keys(this.$store.getters.state_users), [
            this.ticket.assignees.uid
          ]);
        }
      },
      assignee_teams() {
        if (
          this.ticket &&
          this.ticket.assignees &&
          Array.isArray(this.ticket.assignees) &&
          this.ticket.assignees.length
        ) {
          return _.intersection(
            Object.keys(this.$store.getters.state_teams),
            this.ticket.assignees
          );
        } else if (
          !this.ticket.assignees ||
          (this.ticket.assignees && this.ticket.assignees.uid == null)
        ) {
          return [];
        } else {
          return _.intersection(Object.keys(this.$store.getters.state_teams), [
            this.ticket.assignees.uid
          ]);
        }
      }
    },
    components: {
      StatusDropdown,
      PrimaryTags,
      RawImageMapModal,
      MemberTeamBulkDropdown
    },
    watch: {
      selected_feature_id: {
        handler(val) {
          this.index = this.filtered_defects.findIndex(
            t => t.properties && t.properties.issueUid === val
          );

          this.fly_to();
        },
        immediate: true
      },
      "ticket.uid": {
        handler(newVal, oldVal) {
          if (!newVal) {
            this.$store.commit("thermv2/SET_SELECTED_FEATURE_ID", null);
          } else if (newVal !== oldVal) {
            this.get_raw_images();
          }
        }
      }
    },
    methods: {
      ...mapMutations("thermv2", ["SET_SELECTED_FEATURE_ID"]),
      open_raw_image_modal() {
        this.$buefy.modal.open({
          props: {
            raw_images: this.raw_images
          },
          width: "800px",
          parent: this.$root,
          component: RawImageMapModal
        });
      },
      switch_tabs(tab) {
        this.active_tab = tab;
        if (tab == "attachments") {
          this.get_raw_images();
        }
      },
      fly_to(ticket) {
        if (this.ticket.location && this.ticket.location.coordinates)
          this.map.flyTo({
            zoom: 20,
            center: this.ticket.location.coordinates,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });
      },
      next_ticket() {
        if (!this.filtered_defects[this.index + 1]) return;
        this.SET_SELECTED_FEATURE_ID(
          this.filtered_defects[this.index + 1].properties.issueUid
        );
      },
      previous_ticket() {
        if (!this.filtered_defects[this.index - 1]) return;
        this.SET_SELECTED_FEATURE_ID(
          this.filtered_defects[this.index - 1].properties.issueUid
        );
      },
      async get_raw_images() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.projectUid &&
          this.ticket.properties.issueUid
        ) {
          let raw_images = this.projects[this.ticket.properties.projectUid]
            .features[this.ticket.properties.issueUid].properties.raw_images;
          console.log({ raw_images });
          if (raw_images && typeof raw_images == "string") {
            let res = await apiService.get({ url: raw_images });
            this.raw_images = res.data;
          }
        }
        return [];
      },
      download_image(image) {
        window.open(image.src, "_blank");
        // alert("downloading");
      },
      download_images(images) {
        console.log(images);
        this.downloading_multiple = true;
        let data = {
          name: this.ticket.name,
          attachments: images.map((image, index) => {
            return {
              url: image.src,
              name: `image_${index}.jpg`
            };
          }),
          type: "ticket"
        };

        this.$eventHub.$emit("download_zip", data);
        setTimeout(() => {
          this.downloading_multiple = false;
        }, 1000);
      },

      update_ticket(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
      }
    }
  };
</script>

<style lang="scss">
  .therm-ticket-details {
    width: 30rem;
    height: 100vh;
    font-family: Roboto-Medium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    .details-actions {
      bottom: 0;
      width: 100%;
      height: 4rem;
    }

    &__content {
      overflow-y: auto;
      height: calc(100% - 157px);
    }
  }

  .has-background-light-grey {
    background: #00000029;
  }

  .is-90x120 {
    height: 9rem;
    width: 12rem;
  }
  .add_attachment_button {
    position: fixed;
    bottom: 4rem;
    left: 7rem;
    width: 55%;
  }
  .download_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    visibility: hidden;
  }
  .attachment_block {
    &:hover {
      .download_icon {
        visibility: visible;
      }
    }
  }

  .image_viewer {
    .modal {
      width: 50vw;
      left: -80vw;
      position: fixed;
      font-size: 1.6rem;
    }
  }
</style>
