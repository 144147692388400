<template>
  <div class="ticket-list-item">
    <div class="ticket-header">
      <div
        class="columns is-mobile has-text-centered is-regular-14-500-17 has-text-theme-grey"
      >
        <div
          class="column is-1  is-flex is-between is-align-flex-start no-padding-right"
        >
          <div class="field is-flex is-relative is-vcenter is-pointer">
            <input
              type="checkbox"
              name="checkbox"
              :checked="type || selected.length"
              class="sh-custom-checkbox is-thinner"
              :class="{
                'is-square': selected && selected.length < 25 && !type.length
              }"
              @click="selected.length ? clear_selection() : select('displayed')"
            />
            <span
              class="icon is-small"
              @click="selection_dropdown = true"
              v-if="$route.name !== 'dashboard'"
            >
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
            <div
              class="is-absolute"
              style="top:3rem;left:2rem"
              v-if="selection_dropdown"
              v-click-outside="() => (selection_dropdown = false)"
            >
              <ul class="card has-text-left selection-dropdown py-10">
                <li
                  :class="{ 'has-text-blue': type == 'all' }"
                  class="is-medium-14-500-17 padding-10 no-whitespace-wrap is-pointer hover-background "
                  @click="select('all')"
                >
                  {{ localization("app-select-all", "Select all") }}
                </li>
                <li
                  :class="{
                    'has-text-blue': type == 'displayed',
                    'bb-1': type || selected.length
                  }"
                  class="is-medium-14-500-17 padding-10 no-whitespace-wrap is-pointer hover-background "
                  @click="select('displayed')"
                >
                  {{ localization("app-select-displayed", "Select displayed") }}
                </li>
                <li
                  class="is-medium-14-500-17 padding-10 no-whitespace-wrap is-pointer hover-background bt-1 "
                  @click="select(null)"
                  v-if="type || selected.length"
                >
                  {{ localization("app-clear-selection", "Clear Selection") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          @click="update_sort_key('name')"
          class=" column is-3  is-flex is-vcenter"
        >
          <span class="is-pointer">
            {{ localization("app-ticket-name", "Ticket name") }}
          </span>

          <span class="sort-indicators">
            <i
              class="fas fa-chevron-up fa-xs header-sort-icon"
              :class="[
                sorting_key === 'name' ? 'has-text-blue' : 'has-text-theme-grey'
              ]"
            ></i>
            <i
              class="fas fa-chevron-down fa-xs header-sort-icon"
              :class="[
                sorting_key === '-name'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
          </span>
        </div>
        <div
          @click="update_sort_key('takenUp')"
          class="column is-one-fifth is-flex is-vcenter"
        >
          <span class="is-pointer">
            {{ localization("app-assignee", "Assignee") }}
          </span>

          <span class="sort-indicators">
            <i
              class="fas fa-chevron-up fa-xs header-sort-icon"
              :class="[
                sorting_key === 'takenUp'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
            <i
              class="fas fa-chevron-down fa-xs header-sort-icon"
              :class="[
                sorting_key === '-takenUp'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
          </span>
        </div>
        <div
          @click="update_sort_key('dueDate')"
          class=" column is-flex is-vcenter "
          :class="[small_viewer ? 'is-4' : 'is-2']"
        >
          <span class="is-pointer">{{
            localization("app-due-date", "Due Date")
          }}</span>
          <span class="sort-indicators">
            <i
              class="fas fa-chevron-up fa-xs header-sort-icon"
              :class="[
                sorting_key == 'dueDate'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
            <i
              class="fas fa-chevron-down fa-xs header-sort-icon"
              :class="[
                sorting_key == '-dueDate'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
          </span>
        </div>

        <div
          @click="update_sort_key('priority')"
          class=" column is-flex is-vcenter is-one-sixth"
          :class="[small_viewer ? 'ml-20' : null]"
        >
          <span class="is-pointer">{{
            localization("app-priority", "Priority")
          }}</span>
          <span class="sort-indicators">
            <i
              class="fas fa-chevron-up fa-xs header-sort-icon"
              :class="[
                sorting_key === 'priority'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
            <i
              class="fas fa-chevron-down fa-xs header-sort-icon"
              :class="[
                sorting_key === '-priority'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
          </span>
        </div>

        <div
          @click="update_sort_key('progress')"
          class=" column is-flex is-vcenter is-2"
          v-if="!small_viewer"
        >
          <span class="is-pointer">{{
            localization("app-progress", "Progress")
          }}</span>
          <span class="sort-indicators">
            <i
              class="fas fa-chevron-up fa-xs header-sort-icon"
              :class="[
                sorting_key === 'progress'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
            <i
              class="fas fa-chevron-down fa-xs header-sort-icon"
              :class="[
                sorting_key === '-progress'
                  ? 'has-text-blue'
                  : 'has-text-theme-grey'
              ]"
            ></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";

  export default {
    props: ["small_viewer"],
    data() {
      return {
        selection_dropdown: false,
        type: ""
      };
    },
    computed: {
      ...mapState("tickets", ["selected", "allFiltersData", "tickets"]),
      sorting_key: {
        get() {
          return this.$store.state.tickets.sort_key;
        },
        set(value) {
          this.$store.commit("tickets/SET_SORT_KEY", value);
        }
      }
    },
    watch: {
      selected(ne) {
        this.$store.commit("tickets/SELECT_ALL_TICKETS", "");
        this.$emit("select", "");
        this.type = "";
      }
    },
    methods: {
      update_sort_key(value) {
        let new_value;
        this.sorting_key == value
          ? (new_value = "-" + value)
          : (new_value = value);
        this.sorting_key = new_value;
        // this.$emit("update_sort_key", new_value);
      },
      select(type) {
        if (!type) {
          this.$store.commit("tickets/SELECT_ALL_TICKETS", false);
          this.$emit("select", false);
          this.type = "";
          this.clear_selection();
        }
        if (this.type == type) {
          this.$store.commit("tickets/SELECT_ALL_TICKETS", false);
          this.$emit("select", false);
          this.type = "";
        } else {
          this.type = type;
          if (type == "displayed") {
            this.$store.commit("tickets/SELECT_ALL_TICKETS", false);
            this.$emit("select", false);
            this.clear_selection();
            this.tickets.map(ticket => {
              if (ticket.writeAccess) {
                this.$store.dispatch("tickets/select_ticket", ticket.uid);
              }
            });
          } else {
            this.$store.commit("tickets/SELECT_ALL_TICKETS", true);
            this.$emit("select", true);
          }
        }
        this.selection_dropdown = false;
      },
      clear_selection() {
        this.$store.dispatch("tickets/select_ticket", null);
      }
    }
  };
</script>
<style lang="scss">
  .header-sort-icon {
    cursor: pointer;
    height: 4px;
    width: 12px;
    margin: -2px 3px;
    display: table;
    font-weight: 600;
    font-size: 8px;
  }
  .selection-dropdown {
    z-index: 10000;
    width: 15rem;
  }
</style>
