<template>
  <div
    v-click-outside="
      () => ((tags = null), selected_action == '' ? (active = false) : null)
    "
  >
    <div class="defect-bulk-dropdown">
      <div
        class="is-flex align-center"
        @click="active = !active"
        v-if="selected_defects.length || select_all_defects"
      >
        <p class="is-flex is-vcenter sh-button sh-button--small">
          <span class="heading-2 has-text-white pr-5">{{
            localization("app-actions", "Actions")
          }}</span>
          <span class="icon is-small pr-10">
            <i v-if="!active" class="fas fa-angle-down" aria-hidden="true"></i>
            <i v-else class="fas fa-angle-up" aria-hidden="true"></i>
          </span>
        </p>
      </div>
      <div
        class="therm-bulk-dropdown"
        v-if="active"
        v-click-outside="() => (active = false)"
      >
        <div class="defect-bulk-dropdown-content has-slimscroll mb-5">
          <div
            @click="
              selected_action = selected_action == 'Status' ? '' : 'Status'
            "
            class="defect-bulk-dropdown-content__item is-flex is-relative is-medium-14-500-17 align-center px-15 py-10 is-pointer"
          >
            <sh-icon
              :file="'thermv2_sprite'"
              :name="'change-status'"
              class="is-24x24 mr-10"
            />
            <p>
              {{ localization("app-status", "Status") }}
            </p>

            <div
              class="is-sh-dropdown is-absolute "
              style="top: -3.5rem;left: -15rem;"
            >
              <ul
                v-if="selected_action == 'Status'"
                style="width:15rem"
                class="is-options-list is-status has-background-white"
              >
                <li
                  class="item is-flex is-between align-center"
                  v-for="state in status_options"
                  :key="state.value"
                  @click.stop="
                    bulk_update_tickets({ status: parseInt(state.value) });
                    reset();
                  "
                >
                  <div class="is-flex">
                    <div
                      class="status-pointer is-inline-block mr-10"
                      :class="[
                        status_bg_classes[state.value],
                        { invert_colors: has_dark_theme }
                      ]"
                    ></div>

                    <div class="item--text  is-capitalized">
                      {{
                        localization(
                          `app-${state.name
                            .split(" ")
                            .join("-")
                            .toLowerCase()}`,
                          state.name
                        )
                      }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            @click="
              selected_action = selected_action == 'Priority' ? '' : 'Priority'
            "
            class="defect-bulk-dropdown-content__item is-flex  is-relative  align-center px-15 py-10 is-pointer"
          >
            <sh-icon
              :file="'thermv2_sprite'"
              :name="'add-priority'"
              class="is-24x24 mr-10"
            />
            <p class="is-medium-14-500-17">
              {{ localization("app-priority", "Priority") }}
            </p>
            <div
              v-if="selected_action == 'Priority'"
              class="is-sh-dropdown is-absolute "
              style="top: -3.8rem;left: -14rem;"
            >
              <ul
                class="is-options-list"
                style="width:14rem"
                v-if="selected_action == 'Priority'"
              >
                <li
                  class="item is-flex is-between align-center"
                  @click.stop="
                    bulk_update_tickets({ priority: parseInt(i) });
                    reset();
                  "
                  v-for="(priority_option, i) in priorities"
                  :key="priority_option"
                >
                  <p class="item--text is-capitalized is-flex">
                    <sh-icon
                      :name="'priority-' + i"
                      class="is-20x20 item--icon"
                    ></sh-icon>
                    <span>{{
                      localization(
                        `app-${priority_option
                          .split(" ")
                          .join("-")
                          .toLowerCase()}`,
                        priority_option
                      )
                    }}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div
            class="defect-bulk-dropdown-content__item  px-15 py-10 is-pointer"
          >
            <div
              class="is-medium-14-500-17 is-flex align-center"
              @click="member_team_bulk_dropdown_active = true"
            >
              <sh-icon
                :name="'vault-add-users'"
                class=" is-24x24 is-pointer mr-10"
                v-tooltip="{
                  content: localization('app-add-assignees', 'Add Assignees')
                }"
              />
              {{ localization("app-add-assignees", "Add Assignees") }}
            </div>
            <div class="is-absolute right-0">
              <member-team-bulk-dropdown
                v-if="member_team_bulk_dropdown_active"
                v-click-outside="
                  () => (member_team_bulk_dropdown_active = false)
                "
                @close="member_team_bulk_dropdown_active = false"
                @save="
                  bulk_update_assignees([...$event.teams, ...$event.members]);
                  member_team_bulk_dropdown_active = false;
                  active = false;
                "
              />
            </div>
          </div>

          <div
            @click="
              selected_action = selected_action == 'dueDate' ? '' : 'dueDate'
            "
            class="defect-bulk-dropdown-content__item is-flex is-relative  align-center px-15 py-10 is-pointer"
          >
            <p class="is-medium-14-500-17">
              <duedate-dropdown
                class="has-text-dark"
                :icon_class="'is-24x24 mr-10'"
                @change-duedate="
                  bulk_update_tickets({ dueDate: $event });
                  reset();
                "
                :custom_text="'End Date'"
                :is_bulk="true"
              >
                ></duedate-dropdown
              >
            </p>
          </div>
          <div
            class="defect-bulk-dropdown-content__item is-flex is-between is-relative  align-center px-15 py-10 is-pointer"
          >
            <p class="is-medium-14-500-17">
              <span class="is-flex align-center">
                <type-tag
                  :display_on_hover="false"
                  :full_opacity_text="true"
                  :complete="
                    tag => {
                      bulk_update_tickets({ tags: { add: [tag] } });
                      reset();
                    }
                  "
                >
                  <template>
                    <sh-icon
                      :name="'add-tags'"
                      :file="'thermv2_sprite'"
                      class="is-24x24 is-pointer mr-10"
                      v-tooltip="{
                        content: localization('app-add-tags', 'Add tags'),
                        html: false
                      }"
                    />
                    <!-- <p class="is-medium-14-500-17">{{ localization("app-add-tags", "Add Tags") }}</p> -->
                  </template>
                </type-tag>
              </span>
            </p>
          </div>
          <div
            class="defect-bulk-dropdown-content__item is-flex is-between is-relative  align-center px-15 py-10 is-pointer"
          >
            <p class="is-medium-14-500-17">
              <span class="is-flex align-center">
                <type-tag
                  :display_on_hover="false"
                  :full_opacity_text="true"
                  :complete="
                    tag => {
                      bulk_update_tickets({ tags: { remove: [tag] } });
                      reset();
                    }
                  "
                >
                  <template v-slot:toggle-dropdown>
                    <sh-icon
                      :name="'add-tags'"
                      :file="'thermv2_sprite'"
                      class="is-24x24 is-pointer mr-10"
                      v-tooltip="{
                        content: localization('app-remove-tags', 'remove tags'),
                        html: false
                      }"
                    />
                    <p class="is-medium-14-500-17">
                      {{ localization("app-remove-tags", "Remove Tags") }}
                    </p>
                  </template>
                </type-tag>
              </span>
            </p>
          </div>
          <div
            @click="
              selected_action =
                selected_action == 'save_template' ? '' : 'save_template'
            "
            class="defect-bulk-dropdown-content__item is-flex  is-relative  align-center px-15 py-10 is-pointer"
          >
            <sh-icon
              :file="'thermv2_sprite'"
              :name="'add-task-templates'"
              class="is-24x24 mr-10"
            />
            <p class="is-medium-14-500-17">
              {{ localization("app-task-templates", "Task Templates") }}
            </p>
            <div
              class="is-absolute has-background-white"
              style="left:-28rem;top:4rem;"
            >
              <save-template
                :type="'load'"
                @close-load-template="reset()"
                :tickets="selected_defects"
                v-if="selected_action == 'save_template'"
              ></save-template>
            </div>
          </div>
          <div
            class="defect-bulk-dropdown-content__item  px-15 py-10 is-pointer"
          >
            <p
              class="is-medium-14-500-17 is-flex align-center"
              @click="bulk_update_tickets({ watch: true })"
            >
              <sh-icon
                :name="'watch'"
                :file="'tickets-2'"
                class=" is-20x20 is-pointer mr-12 ml-3"
                v-tooltip="{
                  content: localization('app-follow-tickets', 'Follow tickets'),
                  html: false
                }"
              ></sh-icon>
              {{ localization("app-follow-tickets", "Follow tickets") }}
            </p>
          </div>
          <div
            v-if="$store.getters.is_internal_user"
            class="defect-bulk-dropdown-content__item  px-15 py-10 is-pointer"
          >
            <p
              class="is-medium-14-500-17 is-flex align-center"
              @click="delete_tickets"
            >
              <sh-icon :name="'delete-ticket'" class="is-20x20 mr-12 ml-3" />
              {{ localization("app-delete", "Delete") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-dropdown";
  import SaveTemplate from "@/app/old/desktop/tickets/shared/components/save-template";
  import { mapState, mapActions } from "vuex";
  import TypeTag from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import InviteUser from "@/app/old/desktop/shared/components/add-teams-modal-user-list-invite/add-teams-modal-user-list-invite.vue";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import AddUsers from "@/app/old/desktop/shared/components/add-users-modal";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import MemberTeamBulkDropdown from "@/app/old/desktop/shared/components/member-team/member-team-bulk-dropdown.vue";

  export default {
    props: ["active_projects"],
    components: {
      DuedateDropdown,
      SaveTemplate,
      TypeTag,
      InviteUser,
      MemberTeamBulkDropdown
    },
    data() {
      return {
        save_template_popup: false,
        tags: null,
        userSearchQuery: "",
        active: false,
        member_team_bulk_dropdown_active: false,
        actions: [
          { name: "Status", icon_name: "" },
          { name: "Assignee", icon_name: "" },
          { name: "Priority", icon_name: "" },
          { name: "End Date", icon_name: "" },
          { name: "Tags", icon_name: "" },
          { name: "Task templates", icon_name: "" }
        ],
        selected_action: "",
        selected: this.current_status || [],
        priorities: {
          1: "Critical",
          2: "High",
          3: "Medium",
          4: "Low"
        },
        status_bg_classes: {
          "1": "is-bg-yellow",
          "2": "is-bg-green",
          "3": "is-bg-blue",
          "4": "is-bg-light-grey",
          "5": "is-bg-red",
          "6": "is-bg-blue"
        },
        status_options: [
          {
            name: "pending",
            value: 1,
            key: "pending"
          },
          {
            name: "in progress",
            value: 2,
            key: "inprogress"
          },
          {
            name: "resolved",
            value: 3,
            key: "resolved"
          },
          {
            name: "closed",
            value: 4,
            key: "closed"
          },
          {
            name: "rejected",
            value: 5,
            key: "rejected"
          }
        ]
      };
    },
    computed: {
      ...mapState("thermv2", [
        "selected_defects",
        "select_all_defects",
        "active_filters",
        "active_class_ids"
      ]),

      searched_users() {
        if (this.userSearchQuery.length) {
          return Object.values(this.$store.getters.state_users).filter(user =>
            _.includes(
              (user.first_name
                ? user.first_name + " " + user.last_name + user.email
                : user.email
              ).toLowerCase(),
              this.userSearchQuery.toLowerCase()
            )
          );
        } else {
          return Object.values(this.$store.getters.state_users);
        }
      }
    },
    methods: {
      ...mapActions("thermv2", [
        "update_multiple_defects",
        "delete_multiple_tickets"
      ]),
      reset() {
        this.selected_action = "";
        // this.active=false;
      },
      add_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [],
            complete: async e => {
              this.bulk_update_tickets({ labels: { add: e } });
              this.active = false;
            }
          },
          { height: "80%" }
        );
      },
      add_users() {
        this.$emit("overlay_popup", true);
        this.$modal.show(
          AddUsers,
          {
            users: [],

            complete: async e => {
              this.bulk_update_tickets({ users: { add: e } });
              this.active = false;
            }
          },
          { height: "80%" },
          { name: "add-ticket-users" }
        );
      },
      async bulk_update_assignees(assignees) {
        let body = { tickets: {} };
        for (let defect of this.selected_defects) {
          body.tickets[defect] = {
            users: {
              add: assignees.map(assignee => {
                return { access: "assignees", uid: assignee };
              })
            }
          };
        }
        await ticketService
          .bulk_update({ body })
          .then(res => {
            this.$toast.show(
              "Anomalies updated successfully",
              "",
              this.$store.state.izitoast_options.appSuccess
            );
          })
          .catch(err => {
            this.$toast.show(
              "Failed to update anomalies",
              "",
              this.$store.state.izitoast_options.appError
            );
          });
        if (this.select_all_defects) {
          this.$emit("refresh-tickets");
        }
      },
      async bulk_update_tickets(value) {
        if (this.select_all_defects) {
          this.update_all_tickets(value);
        } else {
          this.update_multiple_defects({
            tickets: this.selected_defects,
            value: value
          })
            .then(res => {
              this.$toast.show(
                "Anomalies updated successfully",
                "",
                this.$store.state.izitoast_options.appSuccess
              );
            })
            .catch(err => {
              this.$toast.show(
                "Failed to update anomalies",
                "",
                this.$store.state.izitoast_options.appError
              );
            });
        }
        this.active = false;
      },
      async update_all_tickets(value) {
        let attributes = value;
        let filters = {
          organization: this.$store.state.current_organization.uid
        };
        filters = { ...filters, ...this.extra_filters };
        if (this.active_filters) {
          let filtersData = {};
          filtersData["assignees"] = this.active_filters.assignee.map(
            o => o.uid
          );

          filtersData["priority"] = this.active_filters.priority;
          filtersData["status"] = this.active_filters.status;
          filtersData["tags"] = this.active_filters.tags;

          if (
            this.active_filters &&
            this.active_filters.dueDate &&
            this.active_filters.dueDate.length
          ) {
            filtersData["dueDateStart"] = this.active_filters.dueDate[0];
            filtersData["dueDateEnd"] = this.active_filters.dueDate[1];
          }
          if (
            this.active_filters &&
            this.active_filters.options &&
            this.active_filters.options.length
          ) {
            filtersData["bookmarked"] = this.active_filters.options.includes(
              "bookmarked"
            );
            filtersData["archived"] = this.active_filters.options.includes(
              "archived"
            );
          }
          filters = { ...filters, ...filtersData };
        }
        if (this.$route.name == "favorites") {
          filters = { ...filters, ...{ bookmarked: true } };
        }

        let properties = `projectUid=${this.active_projects.join(",")}`;

        if (this.active_class_ids?.length) {
          properties += `&classId=${this.active_class_ids.join(",")}`;
        }

        if (this.active_filters?.["temperature-difference"]?.length === 2) {
          const range = this.active_filters["temperature-difference"];
          properties += `&temperatureDifference>=${range[0]}&temperatureDifference<=${range[1]}`;
        }

        filters["properties"] = btoa(properties);

        await ticketService
          .bulk_update_all({
            body: {
              filters: filters,
              attributes
            }
          })
          .then(res => {
            this.$toast.show(
              "Anomalies updated successfully",
              "",
              this.$store.state.izitoast_options.appSuccess
            );
          });
        this.$emit("refresh-tickets");
      },
      async delete_tickets() {
        let text = `You want to delete ${this.selected_defects.length} anomalies ?`;
        if (confirm(text) == true) {
          this.delete_multiple_tickets(this.selected_defects);
          this.active = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .bulk_action_bar {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    position: relative;
    height: 4rem;
    width: auto;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(92, 126, 247, 0.3);
    border: solid 1px $primary;
    justify-content: space-around;
    span {
      width: 3rem;
    }
  }
</style>
<style lang="scss">
  .defect-bulk-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    color: $primary-text;
    .is-options-list {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    // z-index:15;
  }
  .therm-bulk-dropdown {
    box-shadow: 0px 5px 10px #00000029;
    border: solid 1px rgba(23, 30, 72, 0.1);
    background-color: #ffffff;
    position: absolute;
    width: 17.5rem;
    z-index: 15;
    border-radius: 0.4rem;
    top: 4.2rem;
    right: 0;
    display: block;
  }

  .defect-bulk-dropdown-content {
    &__item {
      &:hover {
        background-color: #f7f8ff;
      }
      &:last-child:hover {
        border-radius: 0 0 0.4rem 0.4rem;
        background-color: #f7f8ff;
      }
    }
    .cardfilter-item-name {
      color: #171e48;
    }
  }
</style>
