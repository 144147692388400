<template>
  <div
    class="dropdown is-medium-14-500-17"
    v-click-outside="() => (dropdown = false)"
    :class="{ 'is-active': dropdown }"
  >
    <div class="dropdown-trigger">
      <div
        aria-haspopup="true"
        @click="dropdown = !dropdown"
        :aria-controls="'dropdown-menu' + data.uid"
      >
        <span>{{
          selected[data.uid]
            ? access[selected[data.uid].access]
            : data.access
            ? access[data.access]
            : "NA"
        }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down ml-7" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div class="dropdown-menu" :id="'dropdown-menu' + data.uid" role="menu">
      <div class="dropdown-content">
        <a
          @click="
            $emit('update-access', 'assignee');
            dropdown = null;
          "
          href="#"
          class="dropdown-item is-medium-14-500-17 py-10"
        >
          {{ localization("app-submit", "Submit") }}
        </a>
        <a
          @click="
            $emit('update-access', 'read');
            dropdown = null;
          "
          class="dropdown-item is-medium-14-500-17 py-10"
        >
          {{
            localization("app-submit/view-sibmission", "Submit/View Submission")
          }}
        </a>
        <a
          @click="
            $emit('update-access', 'write');
            dropdown = null;
          "
          class="dropdown-item is-medium-14-500-17 py-10"
        >
          {{ localization("app-admin", "Admin") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["data", "selected"],
    data() {
      return {
        dropdown: false,
        access: {
          read: "Submit/View Submission",
          write: "Admin",
          assignee: "Submit"
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .dropdown {
    justify-self: flex-end;
    cursor: pointer;
    &-menu {
      left: unset;
      right: 0;
    }
  }
</style>
