var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active = false); }),expression:"() => (active = false)"}],staticClass:"is-sh-dropdown is-priority"},[_c('div',{staticClass:"is-pointer",on:{"click":function($event){_vm.active = !_vm.active}}},[(_vm.is_multi_select)?_c('div',{staticClass:"is-medium-14-500-17 has-primary-text is-flex is-vcenter"},[(_vm.priority && _vm.priority.length)?_c('div',{staticClass:"is-flex is-vcenter"},[_vm._l((_vm.priority),function(priority_value){return _c('sh-icon',{key:priority_value,staticClass:"is-pointer is-18x18 mr-5",attrs:{"name":'priority-' + "" + (priority_value ? priority_value : 1)}})}),_c('sh-icon',{staticClass:"is-20x20 ml-5",attrs:{"file":'terra_sprite',"name":'terra-filter-add-priority'}})],2):_c('p',{staticClass:"is-flex is-vcenter"},[_c('sh-icon',{staticClass:"is-20x20 mr-5",attrs:{"file":'terra_sprite',"name":'terra-filter-add-priority'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-select-priority", "Select priority")))])],1)]):(!_vm.priority)?_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization('app-add-priority', 'Add Priority')
      }),expression:"{\n        content: localization('app-add-priority', 'Add Priority')\n      }"}],staticClass:"is-20x20",attrs:{"name":'priority'}}):_c('div',{staticClass:"is-selector ",class:[
        [_vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access' : null],
        [_vm.active ? 'is-open' : 'is-close']
      ]},[_c('p',{staticClass:"is-flex align-center"},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.priority
              ? _vm.localization(
                  ("app-" + (_vm.priorities[_vm.priority]
                    .split(' ')
                    .join('-')
                    .toLowerCase())),
                  _vm.priorities[_vm.priority]
                )
              : _vm.localization('app-add-priority', 'Add Priority')
          }),expression:"{\n            content: priority\n              ? localization(\n                  `app-${priorities[priority]\n                    .split(' ')\n                    .join('-')\n                    .toLowerCase()}`,\n                  priorities[priority]\n                )\n              : localization('app-add-priority', 'Add Priority')\n          }"}],staticClass:"is-20x20 mr-5",attrs:{"name":("priority-" + (_vm.priority ? _vm.priority : 4))}}),_c('span',[_vm._v(_vm._s(_vm.localization( ("app-" + (_vm.priorities[_vm.priority] .split(" ") .join("-") .toLowerCase())), _vm.priorities[_vm.priority] )))])],1)])],1),(_vm.active)?_c('div',{class:{ 'mobile-background-new': _vm.isMobile },on:{"click":function($event){_vm.active = !_vm.active}}}):_vm._e(),(_vm.active)?_c('ul',{staticClass:"is-options-list",class:{ 'mobile-view-option': _vm.isMobile }},[_c('li',{staticClass:"item mobile-header"},[_c('p',{staticClass:"is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-priority", "Priority"))+" ")]),_c('p',{on:{"click":function($event){_vm.active = false}}},[(_vm.active)?_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}}):_vm._e()],1)]),_vm._l((_vm.priorities),function(priority_option,i){return _c('li',{key:priority_option,staticClass:"item is-flex is-between ",on:{"click":function($event){$event.stopPropagation();return _vm.change_priority(i)}}},[_c('p',{staticClass:"item--text is-capitalized is-flex"},[_c('sh-icon',{staticClass:"is-20x20 item--icon",attrs:{"name":'priority-' + i}}),_c('span',[_vm._v(_vm._s(_vm.localization( ("app-" + (priority_option .split(" ") .join("-") .toLowerCase())), priority_option )))])],1),_c('span',{staticClass:"ml-10"},[(!_vm.is_multi_select ? i == _vm.priority : _vm.selected.includes(i))?_c('sh-icon',{staticClass:"is-12x12 item--icon",attrs:{"name":'checkmark'}}):_vm._e()],1)])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }