<template>
  <div
    class="is-sh-dropdown columns is-marginless is-relative is-duedate"
    v-click-outside="() => (active = false)"
  >
    <div
      class="is-close is-pointer column is-narrow is-relative padding-10"
      v-tooltip="{
        content: localization(
          'app-select-due-date-range',
          'Select duedate range'
        ),
        html: false
      }"
      :class="[$route.name == 'reports' ? 'is-paddingless' : 'is-selector']"
      @click="
        active = !active;
        create_cal();
      "
    >
      <p class="is-flex align-center">
        <span @click.stop.prevent="change_duedate([])">
          <sh-icon
            :name="
              dueText == 'Select Date' || $route.name == 'reports'
                ? 'select-date'
                : 'ticket-priority-close'
            "
            :class="[
              dueText == 'Select Date' || $route.name == 'reports'
                ? 'is-24x24 mr-7'
                : 'is-12x12 mr-5'
            ]"
          />
        </span>
        <span>{{
          localization(
            `app-${dueText
              .split(" ")
              .join("-")
              .toLowerCase()}`,
            dueText
          )
        }}</span>
      </p>
    </div>
    <div
      class="mobile-background"
      v-if="active"
      @click="active = !active"
    ></div>
    <div
      class="dueDate-calendar"
      :class="{
        'is-hidden': !active,
        'report-duedate': $route.name == 'reports'
      }"
    >
      <div id="filter_calendar_container"></div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    props: ["ticket", "icon_class", "custom_text", "custom_text_class", "due"],
    data() {
      return {
        active: false,
        calendar: null
      };
    },

    beforeDestroy() {
      this.calendar = null;
    },

    computed: {
      ...mapState(["language"]),
      dueText() {
        if (this.due.length == 2) {
          return (
            moment(new Date(this.due[0])).format(" Do MMM") +
            " - " +
            moment(new Date(this.due[1])).format(" Do MMM")
          );
        } else if (this.due.length == 1 && this.$route.name == "reports") {
          return moment(new Date(this.due[0])).format(" Do MMM");
        } else {
          return this.$route.name == "reports" ? "Custom" : "Select Date";
        }
      }
    },
    methods: {
      create_cal() {
        let es = {
          // short names of months
          monthsShort: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
          ],

          // full names of months
          months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ],
          // short names of days
          daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
          // full names of days
          days: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
          ]
        };

        let ja = {
          // short names of months
          monthsShort: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          // full names of months
          months: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          // short names of days
          daysShort: ["日", "月", "火", "水", "木", "金", "土"],
          // full names of days
          days: [
            "日曜日",
            "月曜日",
            "火曜日",
            "水曜日",
            "木曜日",
            "金曜日",
            "土曜日"
          ]
        };

        if (!this.calendar) {
          if (this.language == "ja") {
            dhx.i18n.setLocale("calendar", ja);
          } else if (this.language == "es") {
            dhx.i18n.setLocale("calendar", es);
          }
          this.calendar = new dhx.Calendar("filter_calendar_container", {
            css: `${this.isMobile ? "for-mobile" : "tickets-cal"}`,
            range: true,
            value: this.due.length > 1 ? this.due : new Date()
          });
        }
        this.calendar.events.on("Change", date => {
          this.change_duedate(this.calendar.getValue(true));
        });
      },
      change_duedate(date) {
        this.$emit("change-duedate", date);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .report-duedate {
    bottom: -24px;
  }
  .dueDate-calendar {
    right: -1.6rem;
  }
</style>
