var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active = false); }),expression:"() => (active = false)"}],staticClass:"is-sh-dropdown is-status"},[_c('div',{on:{"click":function($event){_vm.active = !_vm.active}}},[(_vm.is_multi_select)?_c('div',{staticClass:" has-primary-text is-flex is-vcenter"},[(_vm.current_status && _vm.current_status.length)?_c('div',{staticClass:"is-flex is-vcenter"},[_vm._l((_vm.current_status),function(status){return _c('sh-icon',{key:status,staticClass:"is-pointer",class:[("is-" + _vm.size + "x" + _vm.size)],attrs:{"name":("status-" + (status ? status : 1))}})}),_c('sh-icon',{staticClass:"is-20x20 ml-5",attrs:{"file":'terra_sprite',"name":'terra-filter-add-status'}})],2):_c('p',{staticClass:"is-flex is-vcenter "},[_c('sh-icon',{staticClass:"is-20x20 mr-5",attrs:{"file":'terra_sprite',"name":'terra-filter-add-status'}}),_c('span',[_vm._v(_vm._s(_vm.localization("app-select-status", "Select status")))])],1)]):(_vm.hide_names)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization('app-update-status', 'Update Status'),
        html: false
      }),expression:"{\n        content: localization('app-update-status', 'Update Status'),\n        html: false\n      }"}]},[_c('div',{staticClass:"status-pointer  is-pointer",class:[_vm.status_bg_classes[_vm.current_status ? _vm.current_status : 1]]})]):_c('li',{staticClass:"is-selector",class:[
        [_vm.active ? 'is-open' : 'is-close '],
        [
          _vm.has_dark_theme && _vm.active
            ? 'black-background'
            : _vm.has_dark_theme
            ? 'dark-theme-colors'
            : null
        ]
      ]},[_c('div',{staticClass:"is-flex align-center is-pointer"},[_c('div',{staticClass:"status-pointer is-inline-block mr-10",class:[
            [
              _vm.current_status
                ? _vm.status_bg_classes[_vm.current_status]
                : _vm.status_bg_classes[1]
            ]
          ]}),_c('div',{staticClass:"   is-capitalized",class:[
            _vm.has_dark_theme ? 'has-text-light' : 'has-text-theme-black'
          ]},[_vm._v(" "+_vm._s(_vm.localization( ("app-" + (_vm.status_options[_vm.current_status - 1].name .split(" ") .join("-") .toLowerCase())), _vm.status_options[_vm.current_status - 1].name ))+" ")])]),_c('div',{staticClass:"is-pointer"},[_c('sh-icon',{staticClass:"is-16x16 ml-5",attrs:{"name":_vm.active ? 'expand' : 'collapse'}})],1)])]),(_vm.active)?_c('div',{class:{ 'mobile-background-new': _vm.isMobile },on:{"click":function($event){_vm.active = !_vm.active}}}):_vm._e(),(_vm.active)?_c('ul',{staticClass:"is-options-list is-status",class:[
      { 'dark-theme-colors': _vm.has_dark_theme },
      ,
      { 'mobile-view-option': _vm.isMobile }
    ],style:([
      _vm.$route.name == 'tickets-list'
        ? null
        : _vm.hide_names
        ? { left: '-1rem' }
        : null
    ])},[_c('li',{staticClass:"item mobile-header"},[_c('p',{},[_vm._v(" "+_vm._s(_vm.localization("app-status", "Status"))+" ")]),_c('p',{on:{"click":function($event){_vm.active = false}}},[(_vm.active)?_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}}):_vm._e()],1)]),_vm._l((_vm.status_options),function(state){return _c('li',{key:state.value,staticClass:"item is-flex is-between",class:[
        { 'dark-theme-colors': _vm.has_dark_theme },
        {
          'is-disabled is-not-allowed':
            _vm.disabled_status && _vm.disabled_status.includes(parseInt(state.value))
        }
      ],on:{"click":function($event){state.value == 6 ? _vm.update_and_close() : _vm.change_status(state)}}},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"status-pointer is-inline-block mr-10",class:[_vm.status_bg_classes[state.value]]}),_c('div',{staticClass:"item--text  is-capitalized"},[_vm._v(" "+_vm._s(_vm.localization( ("app-" + (state.name .split(" ") .join("-") .toLowerCase())), state.name ))+" ")])]),_c('p',{staticClass:"is-pulled-right"},[(
            !_vm.is_multi_select
              ? state.value == _vm.current_status
              : _vm.selected.includes(state.value)
          )?_c('sh-icon',{staticClass:"is-12x12 item--icon",attrs:{"name":'checkmark'}}):_vm._e()],1)])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }