<script>
  import {
    viewService,
    apiService,
    projectService
  } from "@/app/old/desktop/shared/services/";
  import { mapMutations, mapState } from "vuex";

  export default {
    data() {
      return {
        vector_filters: []
      };
    },
    computed: {
      ...mapState("thermv2", [
        "container",
        "vector_types",
        "projects",
        "defects"
      ]),
      // Represents the issues that've been enabled by the user
      active_class_ids: {
        get() {
          return this.$store.state.thermv2.active_class_ids;
        },
        set(val) {
          this.$store.commit("thermv2/SET_ACTIVE_CLASSES", val);
        }
      }
    },
    methods: {
      ...mapMutations("thermv2", ["SET_PROJECT_DETAILS"]),
      // Updates features that are displayed on the map
      set_active_vectors() {
        const active_vectors = [];
        if (!this.map) return;
        const features_source = this.map.getSource("project-features");
        if (!features_source) return;
        // Wait until details for all the active projects have been fetched
        for (let project of this.active_projects) {
          if (!this.projects[project]) {
            return;
          }
        }
        for (let defect of this.defects) {
          const project_details = this.projects[defect.properties.projectUid];
          const project_vectors = project_details.features;
          const feature_for_defect =
            project_vectors[defect.properties.issueUid];
          if (!feature_for_defect) {
            console.warn(
              `Feature for the defect with the UID ${defect.uid} not found...`
            );
            continue;
          }
          // Filter features based on the enabled classes
          if (
            this.active_class_ids.includes(
              feature_for_defect.properties.class_id
            )
          )
            active_vectors.push(feature_for_defect);
        }
        features_source.setData({
          type: "FeatureCollection",
          features: active_vectors
        });
      },
      setup_map_with_active_layers_for_projects(project_id) {
        const features_source = this.map.getSource("project-features");
        if (!features_source) {
          this.setup_map_with_features(this.map);
          this.map.on("click", e => {
            this.$store.commit("thermv2/SET_SELECTED_FEATURE_ID", null);
          });
          this.map.on("click", "features-layer-fill", e => {
            if (e.features && e.features.length) {
              this.$store.commit(
                "thermv2/SET_SELECTED_FEATURE_ID",
                e.features[0].properties.uid
              );
            } else {
              this.$store.commit("thermv2/SET_SELECTED_FEATURE_ID", null);
            }
          });
          this.map.on("click", "features-layer-line", e => {
            if (e.features && e.features.length) {
              this.$store.commit(
                "thermv2/SET_SELECTED_FEATURE_ID",
                e.features[0].properties.uid
              );
            } else {
              this.$store.commit("thermv2/SET_SELECTED_FEATURE_ID", null);
            }
          });
          this.map.on("mouseenter", "features-layer-line", e => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = "pointer";
          });
          this.map.on("mouseenter", "features-layer-fill", e => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = "pointer";
          });
          this.map.on("mouseleave", "features-layer-line", e => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = "default";
          });
          this.map.on("mouseenter", "features-layer-fill", e => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = "pointer";
          });
          this.map.on("mouseleave", "features-layer-fill", e => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = "default";
          });
          // this.map.on("mouseenter", "features-layer-fill", e => {
          //   // Change the cursor style as a UI indicator.
          //   this.map.getCanvas().style.cursor = "pointer";
          // });
          // this.map.on("click", e => {

          // });
        }
        this.set_active_layers(
          project_id,
          true,
          this.map,
          this.active_layer_types
        );
      },
      /*
        Setup the raster source and enable/disable layers for
        a particular project
      */
      toggle_layer_visibility(
        tiles_url,
        layer_id,
        source_id,
        visible = true,
        map,
        active_layer_types
      ) {
        const layer_source = map.getSource(source_id);
        if (tiles_url) {
          if (!layer_source) {
            map.addSource(source_id, {
              type: "raster",
              tiles: [`${tiles_url}/{z}/{x}/{y}.png`],
              tileSize: 256
            });
            map.addLayer(
              {
                id: layer_id,
                type: "raster",
                source: source_id,
                layout: {
                  // hide layer by default
                  visibility: "none"
                }
              },
              "features-layer-fill"
            );
          }
          // Enable/disable layers for the project by looking at the currently enabled layers
          if (active_layer_types.includes(source_id.split("-")[1])) {
            map.setLayoutProperty(
              layer_id,
              "visibility",
              visible ? "visible" : "none"
            );
          } else {
            map.setLayoutProperty(layer_id, "visibility", "none");
          }
        }
      },
      // Displays/hides layers for projects depending on the currently enabled layers
      set_active_layers(project_id, visible = true, map, active_layer_types) {
        const container_item = this.container.find(
          item => item.projectUid === project_id
        );
        const visualtiles_url = container_item
          ? container_item.orthoTilesVisual
          : null;
        const thermaltiles_url = container_item
          ? container_item.orthoTiles
          : null;
        const cadtiles_url = container_item
          ? container_item.orthoTilesCAD
          : null;
        const visual_source_id = `MAPSOURCE: ${String(project_id)}-visual`;
        const visual_layer_id = `MAPLAYER: ${String(project_id)}-visual`;
        const thermal_source_id = `MAPSOURCE: ${String(project_id)}-thermal`;
        const thermal_layer_id = `MAPLAYER: ${String(project_id)}-thermal`;
        const cad_source_id = `MAPSOURCE: ${String(project_id)}-cad`;
        const cad_layer_id = `MAPLAYER: ${String(project_id)}-cad`;

        this.toggle_layer_visibility(
          thermaltiles_url,
          thermal_layer_id,
          thermal_source_id,
          visible,
          map,
          active_layer_types
        );
        this.toggle_layer_visibility(
          visualtiles_url,
          visual_layer_id,
          visual_source_id,
          visible,
          map,
          active_layer_types
        );
        this.toggle_layer_visibility(
          cadtiles_url,
          cad_layer_id,
          cad_source_id,
          visible,
          map,
          active_layer_types
        );
      },
      async get_element_for_project(project) {
        try {
          let project_details = await projectService.get({
            group_id: project.group,
            id: project.uid
          });
          // let project_details = this.layer_data[project.uid].project_details;

          if (project_details && _.isString(project_details.element)) {
            let res = await apiService.get({ url: project_details.element });
            project_details.element = res.data;
          }
          return project_details.element;
        } catch (err) {
          console.log(err);
        }
      },
      // Fetch details for the project and push the details to the store
      async fetch_project_details(project_id) {
        const container_item = this.container.find(
          item => item.projectUid === project_id
        );
        const thermaltiles_url = container_item
          ? container_item.orthoTiles
          : null;
        const project = await Promise.all([
          viewService.get_project_features({
            view_id: this.container[0].viewUid,
            project_id: project_id,
            query: "tables=false"
          }),
          thermaltiles_url
            ? apiService.get({
                url: thermaltiles_url
              })
            : null,
          this.get_element_for_project({
            group: container_item.groupUid,
            uid: project_id
          })
        ]);
        const project_features = project[0];
        const project_meta = project[1] ? project[1].data : null;
        const element = project[2] ? project[2] : null;
        project_features.features = project_features.features.map(f => {
          f.properties["feature_type_id"] = f.properties.class_id;
          return f;
        });
        const project_details = {
          ...project_features,
          features: _.keyBy(project_features.features, "properties.uid"),
          uid: project_id,
          meta: project_meta,
          name: container_item.projectName,
          group_name: container_item.groupName,
          group_uid: container_item.groupUid,
          element
        };
        this.SET_PROJECT_DETAILS(project_details);
        return project_details;
      },
      fly_to_project(project_details, map) {
        let center = null;

        if (project_details.meta) {
          center = project_details.meta.center;
        } else {
          const project_features = Object.values(project_details.features);
          if (project_features.length) {
            center = turf.centroid({
              type: "FeatureCollection",
              features: project_features
            }).geometry.coordinates;
          }
        }

        if (center)
          map.flyTo({
            center: [center[0], center[1]],
            speed: 5,
            essential: true,
            zoom: center[2] || 14
          });
      },
      setup_map_with_features(map) {
        const features_source_name = "project-features";
        const geoJson = {
          type: "FeatureCollection",
          features: []
        };
        map.addSource(features_source_name, {
          type: "geojson",
          data: geoJson
        });
        map.addLayer({
          id: "features-layer-fill",
          type: "fill",
          source: features_source_name,
          paint: {
            "fill-opacity": 0
          }
        });
        map.addLayer({
          id: "features-layer-line",
          type: "line",
          source: features_source_name,
          paint: {
            "line-width": 4
          }
        });
        let stops = [];
        this.vector_types.forEach(vector => {
          stops.push([vector.class_id, vector.color || "#222"]);
        });
        stops = stops.sort((a, b) => a[0] - b[0]);
        map.setPaintProperty("features-layer-fill", "fill-color", {
          property: "feature_type_id",
          stops,
          default: "black"
        });
        map.setPaintProperty("features-layer-line", "line-color", {
          property: "feature_type_id",
          stops,
          default: "black"
        });
        map.setPaintProperty("features-layer-fill", "fill-opacity", {
          property: "selected_feature",
          stops: [
            [0, 0],
            [1, 0.5]
          ],
          default: 0
        });
      }
    }
  };
</script>
