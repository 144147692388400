<template>
  <div>
    <!-- START Attachment in Ticket details -->
    <div v-if="component == 'ticketDetails'">
      <div
        @click.shift.stop.exact="handle_attachment_click"
        @click.meta.exact="toggle_selection"
        @click.ctrl.exact="toggle_selection"
        @mouseenter.stop="hover = true"
        @mouseleave.stop="hover = false"
        :id="'attachment' + file.uid"
        class="ticket-attachment is-relative"
        :class="[
          {
            'ticket-attachment-background':
              hover || selected_attachments.includes(file.uid)
          },
          {
            'has-opacity-6':
              index == 2 &&
              !show_more &&
              !hover &&
              !selected_attachments.includes(file.uid) &&
              !open_delete
          },
          {
            'has-opacity-4':
              index == 3 &&
              !show_more &&
              !hover &&
              !selected_attachments.includes(file.uid) &&
              !open_delete
          }
        ]"
      >
        <div class="is-flex align-center is-between px-20 py-10">
          <div class="is-flex align-center">
            <!-- selection -->
            <!-- class="selection is-absolute is-pointer" -->
            <div
              class="mr-15 is-18x18"
              @click.exact.stop="toggle_selection"
              @click.shift.stop.exact="handle_attachment_click"
              :class="[{ 'is-hidden': no_selection }]"
            >
              <sh-icon
                v-if="
                  ((ticket && ticket.writeAccess) ||
                    $store.state.claims.user_id === file.owner.uid) &&
                    hover &&
                    !selected_attachments.includes(file.uid)
                "
                class="is-18x18 is-pointer"
                :file="'tickets_sprite'"
                :name="'new-checklist'"
              ></sh-icon>
              <sh-icon
                v-if="
                  ((ticket && ticket.writeAccess) ||
                    $store.state.claims.user_id === file.owner.uid) &&
                    selected_attachments.includes(file.uid)
                "
                class="is-18x18"
                :file="'tickets_sprite'"
                :name="'new-checklist-selected'"
              ></sh-icon>
            </div>

            <!-- thumbnail -->

            <div class="mr-15 is-relative">
              <div
                v-if="hover"
                class="td-view-eye is-pointer"
                @click.stop="onViewFileClicked(file)"
              >
                <!-- @click.stop="$emit('init-pdftron', file)" -->
                <sh-icon
                  :class="'is-30x30'"
                  :file="'tickets_sprite'"
                  :name="'attachment-eye'"
                ></sh-icon>
              </div>

              <div
                v-if="
                  (file.thumbnails && file.thumbnails.small) ||
                    (file.fileName.match(/.(jpg|jpeg|png|gif)$/i) && file.url)
                "
                :class="{
                  'img-overlay':
                    hover || selected_attachments.includes(file.uid)
                }"
              >
                <img
                  class="is-40x40 ticket-thumbnail-image border-radius-4"
                  @error="
                    $event.target.src = require('@/assets/file-type-icons/' +
                      prettyFileIcons.getIcon(file.fileName) +
                      '.svg')
                  "
                  :src="file.thumbnails ? file.thumbnails.small : file.url"
                  :alt="file.url"
                />
              </div>
              <div v-else>
                <img
                  class="image is-40x40"
                  :src="
                    require('@/assets/file-type-icons/' +
                      prettyFileIcons.getIcon(file.fileName) +
                      '.svg')
                  "
                />
              </div>
            </div>

            <!-- title -->
            <div>
              <div class="is-flex" style="height: 2.2rem">
                <p
                  class="is-medium-14-500-17 filename td-attachment-name"
                  v-tooltip="
                    file.fileName && file.fileName.lastIndexOf('.') > 20
                      ? file.fileName
                      : null
                  "
                >
                  {{
                    file.fileName.substring(0, file.fileName.lastIndexOf("."))
                      | truncate(20)
                  }}{{ file.fileName.substr(file.fileName.lastIndexOf(".")) }}
                </p>
                <div class="ml-8" @click.stop="toggle_favorite(file)">
                  <sh-icon
                    v-if="file.pinned"
                    :class="'is-18x18 is-pointer'"
                    :file="'tickets_sprite'"
                    :name="'bookmarked-highlight'"
                  ></sh-icon>
                  <sh-icon
                    v-if="hover && !file.pinned"
                    :class="'is-18x18 is-pointer'"
                    :file="'tickets_sprite'"
                    :name="'bookmark'"
                  ></sh-icon>
                </div>

                <p
                  v-if="hover"
                  @click.stop="download_file(file)"
                  class="is-pointer"
                >
                  <sh-icon
                    class="is-18x18 ml-15"
                    :file="'tickets_sprite'"
                    :name="'download'"
                  ></sh-icon>
                </p>
              </div>
              <div class="file-details">
                <div
                  class="is-medium-12-500-00 filesize-text"
                  v-if="file.fileSize"
                >
                  {{ $prettysize(file.fileSize || 0) }}
                </div>
                <div v-if="file.fileSize" class="file-details-divider"></div>
                <div
                  :class="[{ 'is-hidden': is_comment }]"
                  class="is-medium-12-500-00 filesize-text is-flex align-center"
                >
                  <sh-icon
                    v-if="file.foreignObjectType == 'checklist'"
                    :name="`ticket-tab-task-list`"
                    class="is-14x14 has-opacity-5 mr-5"
                  />
                  <sh-icon
                    v-if="file.foreignObjectType == 'comment'"
                    :name="`ticket-tab-comments`"
                    class="is-14x14 has-opacity-5 mr-5"
                  />
                  <sh-icon
                    v-if="file.foreignObjectType == 'ticket'"
                    :name="`sidebar-tickets`"
                    class="is-14x14 mr-5"
                  />
                  <span class="is-capitalized">{{
                    file.foreignObjectType
                  }}</span>
                </div>
                <div
                  class="file-details-divider"
                  :class="[{ 'is-hidden': is_comment }]"
                ></div>
                <div class="is-flex" :class="[{ 'is-hidden': is_comment }]">
                  <user-avatar
                    :user="file.owner"
                    :pxsize="16"
                    :custom_class="'tiny-user-avatar'"
                  ></user-avatar>
                  <div
                    class="is-medium-12-500-00 ml-5"
                    v-username="file.owner"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="is-flex align-center">
            <div
              class="mr-25 is-regular-14-00-17 has-text-grey"
              :class="[{ 'is-hidden': is_comment }]"
            >
              {{ file.createdAt | newDateFormat }}
            </div>
            <div
              :class="[
                ((ticket && ticket.writeAccess) ||
                  $store.state.claims.user_id === file.owner.uid) &&
                !is_comment
                  ? hover
                    ? 'is-visible'
                    : 'is-invisible'
                  : 'is-invisible'
              ]"
              @click="
                delete_file = file.uid;
                $modal.show(file.uid);
                open_delete = true;
              "
            >
              <sh-icon
                class="is-pointer is-18x18"
                :file="'tickets_sprite'"
                :name="'delete-2'"
              ></sh-icon>
            </div>
          </div>
        </div>
        <prompt-modal
          v-if="delete_file == file.uid"
          :name="file.uid"
          :heading="file.fileName"
          :iconPath="require('@/assets/icons/prompt/delete.svg')"
          :footer="true"
          :cancelString="'Cancel'"
          :confirmString="'Delete'"
          @cancel="
            $modal.hide(file.uid);
            open_delete = false;
          "
          @confirm="delete_attachment(file)"
          @closed="
            $modal.hide(file.uid);
            open_delete = false;
          "
        >
          <div
            class="prompt-modal__content__text is-capitalized"
            slot="content"
          >
            {{
              localization(
                "app-are-you-sure-you-want-to-delete",
                "Are you sure you want to delete"
              )
            }}
            {{ file.fileName }}?
          </div>
        </prompt-modal>
      </div>
    </div>
    <!-- END Attachment in Ticket details -->

    <div v-else>
      <div
        @click.shift.stop.exact="handle_attachment_click"
        @click.meta.exact="toggle_selection"
        @click.ctrl.exact="toggle_selection"
        @mouseenter.stop="hover = true"
        @mouseleave.stop="hover = false"
        :id="'attachment' + file.uid"
        class="attachment card"
        :style="{
          'border-radius': '6px',
          'box-shadow': 'none',
          border: selected_attachments.includes(file.uid)
            ? '1.5px solid #2585d3'
            : ''
        }"
      >
        <div>
          <!-- favorite -->
          <div
            class="attachment--bookmark is-absolute is-pointer"
            @click.stop="toggle_favorite(file)"
          >
            <sh-icon
              v-if="file.pinned"
              :class="'is-36x36'"
              :file="'tickets_sprite'"
              :name="'bookmark-attachment'"
            ></sh-icon>
            <sh-icon
              v-if="hover && !file.pinned"
              :class="'is-36x36'"
              :file="'tickets_sprite'"
              :name="'unmarked-attachment'"
            ></sh-icon>
          </div>

          <!-- thumbnail -->
          <div
            class="attachment--thumbnail is-flex align-center is-center"
            v-if="file"
            :class="{
              'thumbnail-background':
                hover || selected_attachments.includes(file.uid)
            }"
          >
            <!-- selection -->
            <div
              class="selection is-absolute is-pointer"
              @click.exact.stop="toggle_selection"
              @click.shift.stop.exact="handle_attachment_click"
            >
              <div
                v-if="hover && !selected_attachments.includes(file.uid)"
                class="select-circle"
              ></div>
              <sh-icon
                v-if="selected_attachments.includes(file.uid)"
                :class="'is-22x22'"
                :file="'tickets_sprite'"
                :name="'selection-circle'"
              ></sh-icon>
            </div>

            <!-- eye -->
            <div v-if="hover">
              <div
                class="view-eye is-absolute is-pointer"
                @click.stop="onViewFileClicked(file)"
              >
                <sh-icon
                  :class="'is-30x30'"
                  :file="'tickets_sprite'"
                  :name="'attachment-eye'"
                ></sh-icon>
              </div>
            </div>
            <!-- img -->
            <div
              v-if="file.thumbnails && file.thumbnails.small"
              :class="{
                'has-opacity-8':
                  hover || selected_attachments.includes(file.uid)
              }"
            >
              <img
                class="image thumbnail-image"
                :src="file.thumbnails.small"
                :alt="file.url"
              />
            </div>
            <div v-else>
              <img
                class="image is-64x64"
                :src="
                  require('@/assets/file-type-icons/' +
                    prettyFileIcons.getIcon(file.fileName) +
                    '.svg')
                "
              />
            </div>
          </div>

          <!-- avatar -->
          <figure v-if="hover" class="image is-36x36 is-absolute profile-icon">
            <user-avatar
              :user="file.owner"
              :pxsize="36"
              class="user_avatar_icon"
              :custom_class="'margin-x-auto user-style'"
            ></user-avatar>
          </figure>
        </div>

        <div class="card-content">
          <div class="has-text-centered is-flex is-center align-center mt-10">
            <!-- <sh-icon class="is-14x14 mr-5" file="tickets_sprite" :name="hover?foreign_icon+'#foreign-active-'+file.foreignObjectType:foreign_icon+'#foreign-'+file.foreignObjectType"></sh-icon> -->
            <svg
              v-if="file.foreignObjectType !== 'ticket'"
              viewBox="0 0 16 16"
              class="is-14x14 mr-5"
            >
              <use
                v-if="hover"
                :xlink:href="
                  foreign_icon + '#foreign-active-' + file.foreignObjectType
                "
              />
              <use
                v-else
                :xlink:href="
                  foreign_icon + '#foreign-' + file.foreignObjectType
                "
              />
            </svg>
            <input
              v-focus
              v-if="edit_filename == index"
              type="text"
              class="input is-medium-14-500-17 is-inline-input has-text-centered"
              v-model="file.fileName"
              style="width:25rem"
              :placeholder="
                file.fileName.substring(0, file.fileName.indexOf('.'))
              "
              @keyup.esc.stop="edit_filename = -1"
              @keyup.enter="update_filename(file)"
            />
            <p v-else class="is-medium-14-500-17 filename">
              {{ file.fileName | truncateMiddle(11) }}
            </p>
          </div>

          <div style="height:18px;">
            <!-- date -->
            <div class="date has-text-centered mt-10" v-if="!hover">
              <p class="is-regular-14-00-17 has-text-grey">
                {{ file.createdAt | dateFormat }}
              </p>
            </div>

            <!-- actions -->
            <div
              v-else
              class="actions is-flex has-space-around align-center mt-10"
            >
              <p
                @click.stop="update_filename(file)"
                class="is-pointer"
                v-if="edit_filename == index"
              >
                <sh-icon
                  class="is-16x16"
                  :file="'tickets_sprite'"
                  :name="'tick-set-complete'"
                ></sh-icon>
              </p>
              <p @click.stop="edit_filename = index" class="is-pointer" v-else>
                <sh-icon
                  class="is-16x16"
                  :file="'tickets_sprite'"
                  :name="'edit'"
                ></sh-icon>
              </p>
              <p @click.stop="download_file(file)" class="is-pointer">
                <sh-icon
                  class="is-16x16"
                  :file="'tickets_sprite'"
                  :name="'download'"
                ></sh-icon>
              </p>
              <p
                @click="
                  delete_file = file.uid;
                  $modal.show(file.uid);
                "
                class="is-pointer"
              >
                <sh-icon
                  class="is-16x16"
                  :file="'tickets_sprite'"
                  :name="'remove-red'"
                ></sh-icon>
              </p>
            </div>
          </div>
        </div>
        <prompt-modal
          v-if="delete_file == file.uid"
          :name="file.uid"
          :heading="file.fileName"
          :iconPath="require('@/assets/icons/prompt/delete.svg')"
          :footer="true"
          :cancelString="'Cancel'"
          :confirmString="'Delete'"
          @cancel="$modal.hide(file.uid)"
          @confirm="delete_attachment(file)"
          @closed="$modal.hide(file.uid)"
        >
          <div class="prompt-modal__content__text" slot="content">
            {{
              localization(
                "app-are-you-sure-you-want-to-delete",
                "Are you sure you want to delete"
              )
            }}
            {{
              file.fileName.charAt(0).toUpperCase() + file.fileName.slice(1)
            }}?
          </div>
        </prompt-modal>
      </div>
    </div>
  </div>
</template>

<script>
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";
  import prettyFileIcons from "pretty-file-icons";
  export default {
    props: [
      "file",
      "index",
      "ticket",
      "selected_attachments",
      "template",
      "service",
      "component",
      "no_selection",
      "show_more",
      "attachment_details",
      "is_comment"
    ],
    data() {
      return {
        foreign_icon: require("@/assets/icons/tickets_sprite.svg"),
        prettyFileIcons,
        show_preview: -1,
        active: false,
        show_pinned: false,
        edit_filename: -1,
        delete_file: -1,
        hover: false,
        open_delete: false
      };
    },
    components: {
      PromptModal
    },
    methods: {
      onViewFileClicked(imgFileObj) {
        this.$viewerApi({
          images: [imgFileObj],
          options: {
            url: "url",
            title: "fileName",
            toolbar: false
          }
        });
      },
      handle_attachment_click() {
        this.$emit("attachment_card_click", this.file);
      },
      toggle_selection() {
        this.$emit("toggle_selection", this.file);
      },
      async update_filename(file) {
        if (!this.ticket.uid && this.template.isTemplate) {
          this.update_checklist_template_attachment(file, {
            fileName: this.file.fileName
          });
        } else {
          let res = await this.service
            .update_attachment_data({
              id: this.ticket.uid,
              file_id: file.uid,
              body: {
                fileName: file.fileName
              }
            })
            .catch(err => this.handleErrors(err));
        }
        this.edit_filename = -1;
      },
      download_file(file) {
        window.open(file.url, "_blank");
      },
      async delete_attachment(file) {
        this.$modal.hide(file.uid);
        this.delete_file = -1;
        this.$emit("delete_attachment", file);
      },
      async update_checklist_template_attachment(file, body) {
        let res = await this.service
          .update_checklist_template_attachment({
            checklist_id: this.template.checklist_id,
            item_id: this.template.task_id,
            file_id: file.uid,
            body: body
          })
          .then(() => {
            this.$emit("refresh_attachments");
          })
          .catch(err => this.handleErrors(err));
      },
      async toggle_favorite(file) {
        if (!this.ticket.uid && this.template.isTemplate) {
          this.update_checklist_template_attachment(file, {
            pinned: !this.file.pinned
          });
        } else {
          let res = await this.service
            .update_attachment_data({
              id: this.ticket.uid,
              file_id: file.uid,
              body: {
                pinned: !file.pinned
              }
            })
            .then(() => this.$emit("refresh_attachments"))
            .catch(err => this.handleErrors(err));
        }
      }
    }
  };
</script>

<style lang="scss">
  .ticket-attachment-background {
    background-color: rgba(37, 133, 211, 0.05);
  }
  .border-radius-4 {
    border-radius: 4px;
  }
  .td-view-eye {
    position: absolute;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .attachment {
    width: 200px;
    color: #171e48;
    border: solid 1.5px rgba(116, 120, 145, 0.15);
    box-shadow: none;
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(23, 30, 72, 0.1);
    }
    .profile-icon {
      top: 10.4rem;
      left: 8.2rem;
      z-index: 3;
    }
    .ticket-thumbnail-image {
      object-fit: contain;
    }

    &--thumbnail {
      height: 124px;
      overflow: hidden;
      border-radius: 6px;
      object-fit: contain;
      .thumbnail-image {
        // height:124px;
        // width:200px;
        object-fit: contain;
      }

      img {
        z-index: 1;
      }
      .selection {
        top: 1rem;
        left: 1rem;
        z-index: 2;
      }

      .view-eye {
        top: 4.3rem;
        left: 8.5rem;
        z-index: 3;
      }
    }
    &--bookmark {
      top: -1.8rem;
      right: -1.8rem;
      z-index: 2;
    }
    .card-content {
      .input {
        border: 0px;
        border-bottom: 1px dotted rgba(23, 30, 72, 0.2);
        width: 13rem !important;
      }
      .input:focus {
        border: 0px !important;
        border-bottom: 1px dotted rgba(23, 30, 72, 0.2) !important;
      }
    }
  }
  .thumbnail-background {
    background: rgba(23, 30, 72, 1);
  }
  .google-doc-viewer {
    z-index: 999999;
  }
  .filesize-text {
    color: #747891;
  }
  .select-circle {
    width: 18px;
    height: 18px;
    border: 1.5px solid #a5a9bf;
    background: transparent;
    border-radius: 50%;
  }
  .td-attachment-name {
    height: 1.8rem;
  }
  .file-details {
    display: flex;
    align-items: center;
    &-divider {
      height: 14px;
      width: 1px;
      background-color: #e1e4f1;
      margin: 0 10px;
    }
  }
</style>
