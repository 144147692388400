var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"therm-v2__compare-projects"},[_c('projects-list',{staticClass:"therm-v2__compare-projects__list-of-projects",attrs:{"custom_styles_for_groups":("left: " + (_vm.sidebar_width + 6) + "rem"),"custom_styles_for_projects":"left: 2rem !important; top: 2rem !important;","set_groups":_vm.set_groups},on:{"group-selected":function($event){_vm.map_before_old_group = _vm.map_before_new_group;
      _vm.map_before_new_group = $event;
      _vm.active_map = 'before';},"projects-selected":function (projects, groups) {
        projects.forEach(function (project) {
          _vm.set_features_and_layers_for_map(project, groups);
        });
      },"projects-unselected":function (projects, groups) {
        projects.forEach(function (project) {
          _vm.unset_features_and_layers_for_map(project, groups);
        });
      }}}),_c('projects-list',{staticClass:"therm-v2__compare-projects__list-of-projects",attrs:{"custom_styles_for_groups":"right: 2rem","custom_styles_for_projects":"display: none"},on:{"group-selected":function($event){_vm.map_after_old_group = _vm.map_after_new_group;
      _vm.map_after_new_group = $event;
      _vm.active_map = 'after';}}}),_c('div',{staticClass:"thermv2-map-container__map__layer-select"},_vm._l((_vm.supported_layers),function(layer){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization(("app-" + layer), layer),
        placement: 'left-center',
        classes: ['tooltip']
      }),expression:"{\n        content: localization(`app-${layer}`, layer),\n        placement: 'left-center',\n        classes: ['tooltip']\n      }"}],key:layer,staticClass:"is-pointer  is-semiBold-14-600-17 image my-10 mr-20 has-br-3",class:[
        _vm.active_layers.includes(layer.toLowerCase())
          ? 'has-blue-border'
          : 'has-white-border'
      ],on:{"click":function($event){_vm.update_active_layers(layer.toLowerCase())}}},[_c('img',{staticClass:"is-28x28",attrs:{"src":require(("@/assets/icons/thermv2/" + (layer.toLowerCase()) + ".svg")),"alt":""}}),(_vm.active_layers.includes(layer.toLowerCase()))?_c('div',{staticClass:"layer-check icon"},[_c('i',{staticClass:"fa fa-check has-text-white",attrs:{"aria-hidden":"true"}})]):_vm._e()])}),0),_c('div',{staticClass:"thermv2-map-container__map",attrs:{"id":"before"}}),_c('div',{staticClass:"thermv2-map-container__map",attrs:{"id":"after"}}),_c('div',{staticClass:"therm-sidebar--filters is-fixed has-slimscroll-xs"},_vm._l((_vm.after_vector_filters),function(vector){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content:
          vector.description.length > _vm.vector_description_max_length
            ? vector.description
            : null,
        classes: ['tooltip'],
        placement: 'top'
      }),expression:"{\n        content:\n          vector.description.length > vector_description_max_length\n            ? vector.description\n            : null,\n        classes: ['tooltip'],\n        placement: 'top'\n      }"}],key:vector.class_id,staticClass:"py-10 px-10 is-flex align-center pl-10 is-pointer",on:{"click":function($event){return _vm.update_map_after_active_classes(vector.class_id)}}},[_c('div',{staticClass:"therm-sidebar--filters--box is-relative",style:({
          borderColor: vector.color,
          borderWidth: '1px',
          borderStyle: 'solid',
          width: '15px',
          height: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        })},[_c('span',{staticClass:"is-inner is-absolute",style:({
            background: _vm.map_after_active_class_ids.includes(vector.class_id)
              ? vector.color
              : 'none',
            width: '7px',
            height: '7px'
          })})]),_c('p',{staticClass:"is-regular-14-500-17 therm-sidebar__item--text pl-10"},[_vm._v(" "+_vm._s(_vm._f("truncate")(vector.description,_vm.vector_description_max_length))+" ("+_vm._s(vector.count || 0)+") ")])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }