<template>
  <div>
    <div v-if="tickets" class="ebs-quick-list">
      <div style="padding: 15px" class="is-flex has-space-between pa-10">
        <span class="is-semiBold-16-600-19 is-flex is-vcenter is-capitalized">{{
          localization("app-tickets", "Tickets")
        }}</span>
        <sh-icon
          @click.native="$emit('hide-ticket')"
          name="close"
          class="is-16x16 ml-10 is-pointer"
        ></sh-icon>
      </div>
      <div class="ebs-quick-list__items has-slimscroll">
        <tickets-list
          :webviewer="true"
          :is_vertical_window="true"
          :element="ticketQuery"
          :customclass="'is-relative'"
        ></tickets-list>
      </div>
    </div>
    <div
      class="ebs-quick-list"
      v-if="commentsChannel && ckeditorLoaded"
      v-show="showComments"
    >
      <div
        style="padding: 10px;position:absolute;width:100%;"
        class="is-flex has-space-between pa-10"
      >
        <span class="is-semiBold-16-600-19 is-flex is-vcenter is-capitalized">{{
          localization("app-comments", "Comments")
        }}</span>
        <sh-icon
          @click.native="$emit('hide-comment')"
          name="close"
          class="is-16x16 ml-10 is-pointer"
        ></sh-icon>
      </div>
      <div style="padding-top:40px;height:100%">
        <comments
          id="mobile-comments"
          :small="true"
          :commentsUnread="0"
          :ticket="{ uid: 'pdftron' }"
          :channelName="commentsChannel"
          :dataUid="commentsQuery"
          :is_vertical_window="true"
        ></comments>
      </div>
    </div>
  </div>
</template>
<script>
  import Comments from "@/app/old/mobile/modules/tickets/components/ticket-details/components/comments";
  import TicketsList from "@/app/old/desktop/tickets/components/tickets-list/tickets-list";

  export default {
    components: { Comments, TicketsList },
    props: [
      "commentsQuery",
      "commentsChannel",
      "showComments",
      "ticketQuery",
      "tickets"
    ],
    data() {
      return {
        ckeditorLoaded: false
      };
    },
    created() {
      if (!document.getElementById("ckeditor")) {
        var scriptTag = document.createElement("script");
        scriptTag.src =
          "https://cdn.jsdelivr.net/gh/sensehawk/cdn/ckeditor/ckeditor-classic.js";
        scriptTag.id = "ckeditor";
        scriptTag.onload = () => {
          this.ckeditorLoaded = true;
        };
        document.getElementsByTagName("head")[0].appendChild(scriptTag);
      } else {
        this.ckeditorLoaded = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ebs-quick-list {
    width: 360px;
    height: calc(100% - 100px);
    position: fixed;
    right: 65px;
    top: 75px;
    padding: 0;
    display: flex;
    flex-flow: column;
    border-radius: 4px;
    box-shadow: 2px 2px 8px -2px #333;
  }
</style>
