<template>
  <div class="sh-comment-replies is-flex align-center mt-10">
    <div
      @click="$emit('clicked')"
      class="sh-comment-replies__button is-pointer mr-20 is-flex align-center is-medium-14-500-17"
    >
      {{ comment.reply_count }}
      <span class="ml-5">{{ localization("app-replies", "replies") }}</span>
      <div class="pt-2 ml-5">
        <sh-icon :name="'reply-right-arrow'" class="is-11x11"></sh-icon>
      </div>
    </div>
    <div class="is-medium-14-500-17 has-opacity-6 is-flex"></div>
  </div>
</template>
<script>
  export default {
    props: ["ticket", "comment"]
  };
</script>
