export default {
  bind: function(el, binding, vnode) {
    // console.log(vnode.context)
  },
  // When the bound element is inserted into the DOM...
  inserted: function(el) {
    // Focus the element
    // console.log(el)
  },
  update: function(el) {
    // Focus the element
    // console.log(el)
  }
}
