<template>
  <div>
    <div v-if="is_loading" class="card padding-20 margin-20">
      <b-skeleton width="40%" :animated="animated"></b-skeleton>
      <b-skeleton width="90%" :animated="animated"></b-skeleton>
      <section
        class="is-flex mt-20 align-center has-text-centered has-space-around"
      >
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
        <b-skeleton
          height="200px"
          width="30%"
          :animated="animated"
        ></b-skeleton>
      </section>
    </div>
    <div
      v-else-if="show_no_data"
      class="padding-40 is-flex is-centered"
      style="background: white; box-shadow: none; flex-direction: column"
    >
      <img
        style="height: 25rem"
        src="@/assets/illustrations/assets-illustration.svg"
        alt
      />
      <p class="is-medium-16-500-19 has-text-dark has-text-centered pt-20">
        {{ localization("app-not-enough-data", "Not enough data") }}
      </p>
    </div>

    <div
      class="padding-20"
      :class="{
        'is-vhidden': hide_graph
      }"
    >
      <div class="is-flex is-between pl-40">
        <h2 class="is-semiBold-18-600-22 ">
          {{ localization("app-anomalies-by-inverters", "Anomalies by Inverters") }}
        </h2>
        <div class="is-flex">
          <sh-icon
            @click.native="export_png"
            :name="'ticket-download'"
            class="is-24x24 mr-15 is-pointer"
          />
          <sh-icon
            :name="'ticket-expand-maximize'"
            @click.native="openFullscreen"
            class="is-24x24 is-pointer"
          />
        </div>
      </div>
      <div style="width: 100%">
        <div id="therm-bar-chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { loadScript } from "@/utils/loadScript.js";
  import { viewService } from "@/app/old/desktop/shared/services/";
  import { mapState, mapGetters } from "vuex";

  export default {
    props: ["use_metrics", "view_details"],
    data() {
      return {
        is_loading: false,
        data: null,
        no_data: false,
        metrics_vector_types: null
      };
    },

    created() {
      if (this.use_metrics) {
        this.getVectors();
      }
    },

    mounted() {
      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/plotly.js/1.51.1/plotly.min.js",
        this.getData,
        "plotly-cdn"
      );
      document
        .getElementById("therm-bar-chart")
        .addEventListener("fullscreenchange", e => {
          if (document.fullscreenElement) {
            console.log("fullscreen");
          } else {
            this.getData();
          }
        });
    },

    computed: {
      ...mapState("thermv2", [
        "vector_types",
        "projects",
        "container",
        "active_projects"
      ]),
      ...mapGetters("thermv2", ["filtered_defects"]),
      hide_graph() {
        if (this.use_metrics) {
          return this.no_data || this.is_loading;
        } else {
          return (
            this.no_data || this.is_loading || !this.filtered_defects.length
          );
        }
      },
      show_no_data() {
        if (this.use_metrics) {
          return this.no_data && !this.is_loading;
        } else {
          return (
            !this.filtered_defects.length || (this.no_data && !this.is_loading)
          );
        }
      }
    },
    methods: {
      async export_png(gd) {
        Plotly.downloadImage(document.getElementById("therm-bar-chart"), {
          format: "png",
          height: 500,
          width: 800,
          filename: this.localization(
            "app-anomalies-by-inverters",
            "Anomalies by Inverters"
          )
        });
      },
      openFullscreen(gd) {
        let elem = document.getElementById("therm-bar-chart");
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      },
      async getVectors() {
        let res = await viewService.get_view_issue_types({
          id: this.$route.params.id
        });
        this.metrics_vector_types = _.keyBy(res, "class_name");
      },
      async getData() {
        this.is_loading = true;
        let chart_data = [];
        if (this.use_metrics) {
          if (!this.data || !Object.keys(this.data).length) {
            this.data = await viewService.get_view_metrics({
              id: this.$route.params.id
            });
          }
          console.log("data", this.data);
          console.log("metrics_vector_types", this.metrics_vector_types);

          this.xProjects = Object.keys(this.data);
          this.bars = _.pull(
            _.uniq(
              _.flatten(
                Object.values(this.data)
                  .map(v => v.issues)
                  .map(a => Object.keys(a))
              )
            ),
            "table"
          );
          console.log("view_details", this.view_details);
          console.log("bars", this.data);
          console.log("project", this.xProjects);

          let view_projects = _.keyBy(
            _.uniq(
              _.flatten(this.view_details.groups.map(group => group.projects))
            ),
            "uid"
          );
          console.log({ view_projects });
          this.bars.forEach(bar =>
            chart_data.push({
              x: this.xProjects.map(uid => view_projects[uid].name.toString()),
              y: this.xProjects.map(p =>
                this.data[p].issues[bar] ? this.data[p].issues[bar] : 0
              ),
              meta: this.metrics_vector_types[bar].description,
              hovertemplate:
                "%{y}<br>" + "<span>%{meta}</span>" + "<extra></extra>",
              name: this.metrics_vector_types[bar].description,
              type: "bar",
              width: this.xProjects.map(a => 0.2),
              marker: {
                color: this.metrics_vector_types[bar].color || "#ddd"
              }
            })
          );
        } else {
          if (!this.filtered_defects.length) return;

          for (let vector_type of this.vector_types) {
            let filtered_data = {};
            for (let project of this.active_projects) {
              const number_of_defects = (this.filtered_defects || []).filter(
                defect =>
                  defect.properties.classId === vector_type.class_id &&
                  defect.properties.projectUid === project
              ).length;
              if (number_of_defects)
                filtered_data[this.projects[project].name] = number_of_defects;
            }
            chart_data.push({
              x: Object.keys(filtered_data),
              y: Object.values(filtered_data),
              text: this.localization(
                `app-${vector_type.description
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`,
                vector_type.description
              ),
              hoverinfo: "text",
              name: this.localization(
                `app-${vector_type.description
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`,
                vector_type.description
              ),
              type: "bar",
              width: this.active_projects.map(a => 0.2),
              marker: {
                color: vector_type.color || "#ddd"
              }
            });
          }
        }

        console.log({ chart_data });

        if (
          !chart_data.length ||
          !_.flatten(chart_data.map(a => a.y)).reduce((b, c) => b + c)
        ) {
          this.no_data = true;
          this.is_loading = false;
          return;
        } else {
          this.no_data = false;
        }

        this.is_loading = false;

        var layout = {
          hovermode: "closest",
          height: 270,
          barmode: "stack",
          xaxis: {
            tickmode: Array,
            dtick: 1,
            title: {
              text: this.localization("app-inverters", "Inverters"),
              font: {
                family: "Roboto-Medium",
                size: 15,
                color: "#171e48"
              }
            }
          },
          yaxis: {
            title: {
              text: this.localization("app-no-of-anomalies", "No. of Anomalies"),
              font: {
                family: "Roboto-Medium",
                size: 15,
                color: "#171e48"
              }
            }
          },
          bargap: 5,

          margin: {
            l: 50,
            r: 20,
            b: 40,
            t: 20,
            pad: 10
          }
        };

        Plotly.newPlot(
          document.getElementById("therm-bar-chart"),
          chart_data,
          layout,
          {
            displayModeBar: false,
            responsive: true
          }
        );
        this.is_loading = false;
      }
    },
    watch: {
      filtered_defects: {
        deep: true,
        handler() {
          this.getData();
        }
      }
    }
  };
</script>

<style>
  .nmt-30 {
    margin-top: -10rem;
  }
</style>
