import { render, staticRenderFns } from "./ticket-comment.vue?vue&type=template&id=39602274&scoped=true&"
import script from "./ticket-comment.vue?vue&type=script&lang=js&"
export * from "./ticket-comment.vue?vue&type=script&lang=js&"
import style0 from "./ticket-comment.vue?vue&type=style&index=0&id=39602274&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39602274",
  null
  
)

export default component.exports