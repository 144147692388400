import Vue from "vue";
import "buefy/dist/buefy.css";
// import Buefy from "buefy";
import {
  Toast,
  Dialog,
  Modal,
  Skeleton,
  Upload,
  Checkbox,
  Radio,
  Switch,
  Tabs,
  Loading
} from "buefy";
import "@/assets/style/core.scss";

import vClickOutside from "v-click-outside";
import HelperMixin from "@/app/old/desktop/shared/mixins/helper-mixin.vue";

import NoResults from "@/app/old/desktop/shared/components/no-results.vue";
import DeleteModal from "@/app/old/desktop/shared/components/delete-modal.vue";
import PrettyDate from "@/app/old/desktop/shared/filters/pretty-date.js";
import DateFormat from "@/app/old/desktop/shared/filters/date-format.js";
import NewDateFormat from "@/app/old/desktop/shared/filters/new-date-format.js";
import FullDateFormat from "@/app/old/desktop/shared/filters/full-date-format.js";
import SinceDays from "@/app/old/desktop/shared/filters/since-days.js";
import Currency from "@/app/old/desktop/shared/filters/currency.js";
import {
  Truncate,
  TruncateMiddle
} from "@/app/old/desktop/shared/filters/truncate.js";
import TransferOwner from "@/app/old/desktop/shared/components/transfer-owner/transfer-owner";
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
import VueSkeletonLoading from "vue-skeleton-loading";
import VueCookies from "vue-cookies";
import prettysize from "prettysize";

import ShIcon from "@/app/old/desktop/shared/components/icon.vue";
import MIcon from "@/app/old/mobile/shared/components/mobile-icon.vue";

import UserAvatar from "@/app/old/desktop/shared/components/user-avatar.vue";
import VueClipboard from "vue-clipboard2";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
// import UnknownUser from "@/app/old/desktop/shared/components/unknown-user.vue";

import DashboardAppIllustration from "@/app/old/desktop/shared/components/illustration/dashboard-app.vue";

import Root from "./desktop-root.vue";
import router from "./desktop-router";
import store from "@/app/old/store/";
// import "@/registerServiceWorker";
import VAccess from "@/app/old/desktop/shared/directives/access.directive";
import AdjustPosition from "@/app/old/desktop/shared/directives/adjust-position.directive";
import { Position } from "@/app/old/desktop/shared/directives/position.directive";
import VueSignaturePad from "vue-signature-pad";

// import VueGridLayout from "vue-grid-layout";

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

Vue.directive("access", VAccess);

Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.directive("adjust-position", AdjustPosition);
Vue.component("v-popover", VPopover);

Vue.use(VueSkeletonLoading);
Vue.use(VueCookies);
Vue.use(VueSignaturePad);

Object.defineProperty(Vue.prototype, "$prettysize", { value: prettysize });

// Vue.use(Buefy);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Modal);
Vue.use(Skeleton);
Vue.use(Upload);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(vClickOutside);
Vue.use(VueIziToast);
Vue.use(Loading);
Vue.use(VueViewer);

// components

// Vue.component("GridLayout", VueGridLayout.GridLayout);
// Vue.component("unknown-user", UnknownUser);
Vue.component("sh-icon", ShIcon);
Vue.component("m-icon", MIcon);
Vue.component("user-avatar", UserAvatar);

// Vue.component("vault", Vault);
Vue.component("no-results", NoResults);
Vue.component("delete-modal", DeleteModal);

Vue.component("transfer-owner", TransferOwner);
// no result illustration
Vue.component("no-result-dashboard-app", DashboardAppIllustration);
// mixins
Vue.mixin(HelperMixin);
//filters
Vue.filter("prettyDate", PrettyDate);
Vue.filter("dateFormat", DateFormat);
Vue.filter("newDateFormat", NewDateFormat);
Vue.filter("fullDateFormat", FullDateFormat);
Vue.filter("sinceDays", SinceDays);
Vue.filter("truncate", Truncate);
Vue.filter("currency", Currency);
Vue.filter("truncateMiddle", TruncateMiddle);
Vue.use(VueClipboard);
//Directives
Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  }
});
import UserName from "@/app/old/desktop/shared/directives/user-name.directive";
Vue.directive("username", UserName);

// Vue.directive("position", Position);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.performance = true;

Vue.prototype.$eventHub = new Vue();

Vue.config.silent = true;
// Vue.config.errorHandler = (error) => {
//   console.log(error)
// }

new Vue({
  router,
  store,
  render: h => h(Root)
}).$mount("#app");

window.addEventListener(
  "dragover",
  function(e) {
    e = e || event;
    e.preventDefault();
  },
  false
);
window.addEventListener(
  "drop",
  function(e) {
    e = e || event;
    e.preventDefault();
  },
  false
);

window.spriteCache = {};
