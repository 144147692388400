var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"org_dropdown",class:{
    'open-content-mobile': _vm.active && _vm.organization_list.length > 1 && _vm.isMobile,
    'open-content': _vm.active && _vm.organization_list.length > 1
  }},[_c('div',{staticClass:"header",on:{"click":function($event){!_vm.$store.state.is_offline ? (_vm.active = !_vm.active) : null}}},[_c('div',{staticClass:"is-flex is-vcenter"},[_c('div',{staticClass:"organization-icon is-semiBold-15-600-18 mr-10",style:(("background-color: " + (_vm.current_organization.color &&
            _vm.current_organization.properties.color
              ? _vm.current_organization.properties.color
              : _vm.hashed_color(_vm.current_organization.name, 'projectManagement'))))},[_vm._v(" "+_vm._s(_vm.current_organization.name.charAt(0).toUpperCase())+" ")]),_c('p',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.current_organization.name,_vm.isMobile ? 15 : 22))+" ")])]),(_vm.organization_list && _vm.organization_list.length >= 2)?_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":_vm.active ? 'expand' : 'collapse'}}):_vm._e()],1),(
      _vm.organization_list &&
        _vm.organization_list.length >= 2 &&
        _vm.active &&
        !_vm.$store.state.is_offline
    )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active = false); }),expression:"() => (active = false)"}],class:[_vm.isMobile ? 'org_dropdown--m-content' : 'org_dropdown--content']},[_c('div',{staticClass:"is-flex align-center py-10",class:[_vm.isMobile ? 'm-nav-search' : 'nav-search']},[_vm._m(0),_c('span',[_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.searchOrganization),expression:"searchOrganization"}],staticClass:"search-org is-regular-14-00-17",attrs:{"autocomplete":"off","id":"searchOrg","type":"text","placeholder":_vm.localization('app-search-organization', 'Search Organization')},domProps:{"value":(_vm.searchOrganization)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchOrganization=$event.target.value}}})])]),(_vm.filteredOrg && _vm.filteredOrg.length)?_c('div',{staticClass:"dropdown-height-20 has-slimscroll"},_vm._l((_vm.filteredOrg),function(organization,i){return _c('p',{key:i,staticClass:"is-medium-14-500-17 org_dropdown--content__item has-word-break-all",on:{"click":function($event){return _vm.change_organization(organization)}}},[_c('span',{staticClass:"organization-icon is-semiBold-15-600-18 mr-10",style:(("background-color: " + (organization.color && organization.properties.color
                ? organization.properties.color
                : _vm.hashed_color(organization.name, 'projectManagement'))))},[_vm._v(_vm._s(organization.name.charAt(0).toUpperCase()))]),_vm._v(" "+_vm._s(organization.name)+" ")])}),0):_c('div',{staticClass:"padding-20 is-flex is-center has-opacity-6"},[_vm._v(" "+_vm._s(_vm.localization("app-no-organization-found", "No organization found"))+" ")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"mx-10 nav-search-image"},[_c('img',{attrs:{"src":require("@/assets/icons/nav-search.svg"),"alt":"","srcset":""}})])}]

export { render, staticRenderFns }