<template>
  <div class="coda-comment">
    <div ref="container" class="coda-comment_container">
      <div
        v-for="(pos, index) in annotPos"
        :key="index"
        :style="`top:${pos.y}px;left:${pos.x + 50}px;`"
        :id="pos.id"
        :ref="pos.id"
      >
        <div class="baloon-comment">{{ pos.page }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['annotPos']
  }
</script>

<style lang="scss" scoped>
  .coda-comment {
    position: fixed;
    width: 100%;
    pointer-events: none;
    height: calc(100% - 92px);
    overflow: hidden;
    right: 0;
    top: 72px;
    &_container {
      position: relative;
      > div {
        color: red;
        position: absolute;
        pointer-events: all;
      }
    }
  }
  .mouseon {
    > .baloon-comment {
      background: orange;
    }
  }

  .baloon-comment {
    background: #2d83e8;
    border-radius: 100px;
    color: white;
    padding: 0px 7px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
</style>
