var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checklist-item",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.checklist)?_c('div',[(!_vm.forms)?_c('div',{staticClass:"is-flex is-vcenter",class:[
        {
          'has-opacity-7':
            _vm.index == 3 &&
            _vm.show_more == false &&
            _vm.component == 'ticketDetails' &&
            !_vm.edit_name &&
            !_vm.active &&
            !_vm.open_dropdown &&
            !_vm.loadtemplate &&
            !_vm.savetemplate
        },
        {
          'has-opacity-6':
            _vm.index == 4 &&
            _vm.show_more == false &&
            _vm.component == 'ticketDetails' &&
            !_vm.edit_name &&
            !_vm.active &&
            !_vm.open_dropdown &&
            !_vm.loadtemplate &&
            !_vm.savetemplate
        },
        {
          'has-opacity-4':
            _vm.index == 5 &&
            _vm.show_more == false &&
            _vm.component == 'ticketDetails' &&
            !_vm.edit_name &&
            !_vm.active &&
            !_vm.open_dropdown &&
            !_vm.loadtemplate &&
            !_vm.savetemplate
        }
      ],staticStyle:{"height":"2rem"},on:{"click":function($event){_vm.isMobile ? (_vm.active = !_vm.active) : ''}}},[_c('div',{staticClass:"is-flex is-vcenter checklist-item--header"},[_c('div',{staticClass:"is-narrow no-padding",on:{"click":function($event){$event.stopPropagation();_vm.active = !_vm.active}}},[(_vm.active)?_c('img',{staticClass:"image is-pointer",staticStyle:{"transform":"rotate(90deg)"},attrs:{"src":require("@/assets/icons/expand-right.svg")}}):_c('img',{staticClass:"image is-pointer",attrs:{"src":require("@/assets/icons/expand-right.svg")}})]),(!_vm.edit_name)?_c('span',{class:[
            { 'is-default': !_vm.ticket.writeAccess },
            [
              _vm.component == 'ticketDetails'
                ? 'is-medium-14-500-17'
                : 'is-medium-18-500-22'
            ],
            { 'has-accent-text': _vm.active }
          ],on:{"click":function($event){_vm.ticket.writeAccess && !_vm.isMobile ? (_vm.edit_name = true) : null}}},[_vm._v(_vm._s(_vm.checklist.name))]):_vm._e(),(_vm.edit_name)?_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
            function () { return (_vm.change_checklist_name(_vm.checklist.name), (_vm.edit_name = false)); }
          ),expression:"\n            () => (change_checklist_name(checklist.name), (edit_name = false))\n          "},{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.checklist.name),expression:"checklist.name"}],staticClass:"is-inline-input is-medium-18-500-22",attrs:{"type":"text"},domProps:{"value":(_vm.checklist.name)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.change_checklist_name(_vm.checklist.name);
            _vm.edit_name = false;},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();_vm.edit_name = false}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.checklist, "name", $event.target.value)}}}):_vm._e(),_c('span',{staticClass:"has-text-grey ml-10 is-medium-14-500-17 has-opacity-half"},[_vm._v(" ("+_vm._s((_vm.resolved_tasks && _vm.resolved_tasks.length) || 0)+"/"+_vm._s((_vm.flatten_list && _vm.flatten_list.length) || 0)+") ")])]),(_vm.ticket.writeAccess && _vm.checklist.uid)?_c('div',{staticClass:"dropdown",class:[
          {
            'is-hidden':
              !_vm.hover &&
              (!_vm.$refs ||
                !_vm.$refs[("checklist" + (_vm.checklist.uid))] ||
                (!_vm.$refs[("checklist" + (_vm.checklist.uid))].active &&
                  !_vm.$refs[("checklist" + (_vm.checklist.uid))].load_from_template &&
                  !_vm.$refs[("checklist" + (_vm.checklist.uid))].open_template_modal &&
                  !_vm.$refs[("checklist" + (_vm.checklist.uid))].assign_all))
          }
        ]},[_c('task-list-dropdown',{ref:("checklist" + (_vm.checklist.uid)),staticClass:"is-pointer",attrs:{"ticket":_vm.ticket,"service":_vm.service,"checklist":_vm.checklist},on:{"create_new":function($event){_vm.create_new = true;
            _vm.active = true;
            _vm.keep_open = true;},"load_items":_vm.refresh_details,"open_dropdown":function($event){_vm.open_dropdown = $event},"open_save_modal":function($event){_vm.open_template_modal = true},"edit_checklist":function($event){_vm.edit_name = true},"duplicate_checklist":function($event){return _vm.$emit('duplicate_checklist', _vm.checklist)},"checklist_assignee_update":_vm.refresh_details,"savetemplate":function($event){_vm.$emit('savetemplate', (_vm.savetemplate = $event))},"loadtemplate":function($event){_vm.$emit('loadtemplate', (_vm.loadtemplate = $event))}}})],1):_vm._e()]):_vm._e(),(_vm.active || _vm.forms)?_c('div',{staticClass:"checklist-task-list"},[(_vm.component == 'ticketDetails')?_c('div',{staticClass:"td-checklist-divider"}):_c('hr',{staticClass:"checklist-divider"}),_c('sub-tasks',{attrs:{"create_new":_vm.create_new,"items":_vm.checklist.items,"ticket":_vm.ticket,"checklist":_vm.checklist,"service":_vm.service,"forms":_vm.forms,"depth":0,"keep_open":_vm.keep_open,"component":_vm.component},on:{"add_task_to_checklist":_vm.add_task,"update_checklist_data":_vm.update_checklist,"delete_task_from_checklist":_vm.delete_task,"reorder_tasks":_vm.update_checklist_tasks}}),(!(_vm.forms && !_vm.ticket.writeAccess))?_c('create-subtask',{staticClass:"checklist-subtask--create",attrs:{"keep_open":_vm.keep_open,"ticket":_vm.ticket,"isMobile":_vm.isMobile,"checklist":_vm.checklist,"component":_vm.component,"add_create_new":_vm.create_new,"service":_vm.service},on:{"task_created":_vm.add_task,"close_add_create_task":function($event){_vm.create_new = false}}}):_vm._e()],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }