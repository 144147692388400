<template>
  <div>
    <div class="is-flex is-vcenter has-space-between pb-20 px-20 pt-3 bb-1">
      <div class="is-semiBold-16-600-19 ">
        {{ localization("app-task-attributes", "Task Attributes") }}
      </div>
      <div class="is-pointer" @click="$emit('close')">
        <sh-icon :name="'ticket-detail-close'" class="is-12x12 ml-5" />
      </div>
    </div>
    <ul class="is-medium-14-500-17 pl-20">
      <li
        class="is-flex has-direction-column has-space-between is-center py-10 bb-1"
      >
        <div class="is-flex is-vcenter has-space-between mb-15">
          <p>{{ localization("app-assignees", "Assignees") }}</p>
          <div
            class="is-flex is-vcenter is-pointer"
            :class="{ 'is-active': member_team_bulk_dropdown_active }"
            @click="
              member_team_bulk_dropdown_active = !member_team_bulk_dropdown_active
            "
          >
            <span class="has-text-link-blue roboto-medium-12">{{
              localization("app-add-or-edit", "Add/edit")
            }}</span>
          </div>
          <div class="is-absolute right-0">
            <member-team-bulk-dropdown
              v-if="member_team_bulk_dropdown_active"
              v-click-outside="() => (member_team_bulk_dropdown_active = false)"
              :members="assignee_users"
              :teams="assignee_teams"
              :asset_id="asset_id"
              @close="member_team_bulk_dropdown_active = false"
              @save="
                update_ticket({
                  assignees: [...$event.teams, ...$event.members]
                });
                member_team_bulk_dropdown_active = false;
              "
            />
          </div>
        </div>
        <div>
          <!-- --------------------------------- Members -------------------------------- -->
          <div
            v-if="assignee_users && assignee_users.length"
            class="has-disabled-text roboto-regular-12 mb-5 mx-20"
          >
            {{ localization("app-members", "Members") }}
          </div>
          <div v-if="assignee_users && assignee_users.length" class="mb-20">
            <div
              class="py-5 px-20 is-flex is-vcenter has-hover-bg is-hover-area"
              v-for="user in assignee_users"
              :key="user"
            >
              <user-avatar
                :user="{ uid: user }"
                :pxsize="24"
                :custom_class="'tiny-user-avatar'"
                :show_tooltip="true"
              ></user-avatar>
              <span
                class="ml-6 username-truncate"
                style="width:13rem"
                v-username="{ uid: user }"
              ></span>
            </div>
          </div>

          <!-- ---------------------------------- TEAMS --------------------------------- -->
          <div
            v-if="assignee_teams && assignee_teams.length"
            class="has-disabled-text roboto-regular-12 mb-10 mx-20"
          >
            {{ localization("app-teams", "Teams") }}
          </div>
          <div class="mb-15">
            <div
              class="py-5 px-20 is-flex-between has-hover-bg is-hover-area"
              v-for="team in assignee_teams"
              :key="team"
            >
              <div class="is-flex">
                <sh-icon
                  style="flex-shrink:0;"
                  :name="'team-icon'"
                  class="is-24x24 mr-5"
                ></sh-icon>
                <p>
                  {{
                    $store.getters.state_teams[team] &&
                      $store.getters.state_teams[team].name
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-anomaly-type", "Anomaly") }}
        </p>
        <p
          class="mr-10"
          v-tooltip="{
            content: defect_type.length >= 15 ? defect_type : null,
            html: false
          }"
        >
          {{ defect_type | truncate(15) }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-category", "Category") }}
        </p>
        <p
          class="mr-10"
          v-tooltip="{
            content: category_label || null,
            html: false
          }"
        >
          {{ category_label }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter  bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-priority", "Priority") }}
        </p>
        <p>
          <priority-dropdown
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            class="is-medium-14-500-17"
            @change_ticket_priority="
              update_ticket({ priority: parseInt($event) })
            "
            :priority="ticket.priority"
          ></priority-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-status", "Status") }}
        </p>
        <p>
          <status-dropdown
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            style="left:1rem"
            :width="17"
            @close="$emit('close-popup')"
            :current_status="ticket.status"
            class="is-medium-14-500-17"
            :ticket="ticket"
            v-if="ticket"
            @update-ticket-status="
              update_ticket({ status: parseInt($event.value) })
            "
          ></status-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-ssset", "Asset") }}
        </p>
        <p class="is-medium-14-500-17 mr-10">
          {{ asset_name }}
        </p>
      </li>
      <!-- <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-location", "Location") }}
        </p>
        <div class="is-flex mr-10" v-if="nameArray && nameArray.length">
          <sh-icon
            v-if="
              elementData &&
                ['file', 'folder', 'vault'].includes(elementData.type)
            "
            :file="'vault_sprite'"
            :name="'add-folder'"
            class="image is-pointer mr-5 is-20x20"
          />
          <div class="is-flex is-flex-wrap">
            <div class="is-flex is-vcenter">
              <span
                class="is-medium-14-500-17 no-whitespace-wrap"
                :class="[
                  name == '*Not found'
                    ? 'has-text-light-grey'
                    : 'has-text-theme-black'
                ]"
                >{{ nameArray[nameArray.length - 1] | truncate(15) }}</span
              >
            </div>
          </div>
        </div>
      </li> -->
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-inverter", "Inverter") }}
        </p>
        <p class="mr-10">
          {{
            ticket.properties &&
            ticket.properties.projectUid &&
            projects[ticket.properties.projectUid] &&
            projects[ticket.properties.projectUid].name
              ? projects[ticket.properties.projectUid].name
              : "NA"
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-location", "Location") }}
        </p>
        <p class="mr-10">
          {{ (ticket.properties && ticket.properties.stringName) || "NA" }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-report-date", "Report Date") }}
        </p>
        <p class="mr-10">
          {{ get_report_date() }}
        </p>
      </li>
      <li
        class="is-flex has-space-between is-vcenter py-10 bb-1"
        v-if="
          ticket.properties &&
            (ticket.properties.serial_number || ticket.properties.serialNumber)
        "
      >
        <p class=" has-text-theme-newgrey">
          {{ localization("app-serial-number", "Serial Number") }}
        </p>
        <p
          class="mr-10"
          v-tooltip="
            (ticket.properties.serial_number || ticket.properties.serialNumber)
              .length > 15
              ? ticket.properties.serial_number ||
                ticket.properties.serialNumber
              : ''
          "
        >
          {{
            ticket.properties.serial_number ||
              ticket.properties.serialNumber ||
              "NA" | truncate(15)
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          Δ {{ localization("app-temperature", "Temperature") }}
        </p>
        <p class="mr-10">
          {{
            ticket.properties && ticket.properties.temperatureDifference >= 0
              ? `${ticket.properties.temperatureDifference.toFixed(2)} °C`
              : "NA"
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-yield-loss-estimate", "Yield loss estimate") }}
        </p>
        <p class="mr-10">
          {{
            ticket.properties && ticket.properties.yield_loss_estimate
              ? `${ticket.properties.yield_loss_estimate} kWh`
              : "NA"
          }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-timestamp", "Timestamp") }}
        </p>
        <p class="mr-10">
          {{ (ticket.properties && ticket.properties.issueTimeStamp) || "NA" }}
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-start-date", "Start Date") }}
        </p>
        <p>
          <startdate-dropdown
            :is_therm="true"
            style="left:0"
            class="is-medium-14-500-17"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            :ticket="ticket"
            v-if="ticket"
            @change-startdate="
              update_ticket({
                startDate: $event ? new Date($event).toISOString() : null
              });
              track_start_date($event);
            "
          ></startdate-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter  bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-end-date", "End Date") }}
        </p>
        <p>
          <duedate-dropdown
            style="left:.5rem"
            :is_therm="true"
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            class="is-medium-14-500-17"
            :ticket="ticket"
            v-if="ticket"
            :due="ticket.dueDate || null"
            :in_details="true"
            @change-duedate="
              update_ticket({
                dueDate: $event ? new Date($event).toISOString() : null
              })
            "
          ></duedate-dropdown>
        </p>
      </li>
      <li class="is-flex has-space-between is-vcenter py-10 bb-1">
        <p class=" has-text-theme-newgrey">
          {{ localization("app-tags", "Tags") }}
        </p>
        <p>
          <tags-dropdown
            :position="'left__-5'"
            :service="ticketService"
            :tags="ticket.tags"
            :resourceId="ticket.uid"
            :write_access="ticket.writeAccess"
            :complete="update_ticket"
            :number_of_tags_to_display="2"
            :truncate_length="4"
            :display_on_hover="ticket.tags.length"
          ></tags-dropdown>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
  import PriorityDropdown from "@/app/old/desktop/tickets/shared/components/priority-dropdown";
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import StartdateDropdown from "@/app/old/desktop/tickets/shared/components/startdate-dropdown";
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-dropdown";
  import TagsDropdown from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import { categoryService } from "@/app/old/desktop/core/shared/services";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import MemberTeamBulkDropdown from "@/app/old/desktop/shared/components/member-team/member-team-bulk-dropdown.vue";

  import { mapState } from "vuex";

  export default {
    props: ["ticket"],
    data() {
      return {
        member_team_bulk_dropdown_active: false,
        nameArray: [],
        elementData: null,
        location: null,
        group_name: "",
        project_name: "",
        categories: [],
        ticketService: ticketService
      };
    },
    components: {
      PriorityDropdown,
      StatusDropdown,
      StartdateDropdown,
      DuedateDropdown,
      TagsDropdown,
      PrimaryTags,
      MemberTeamBulkDropdown
    },
    computed: {
      ...mapState("thermv2", ["vector_types", "projects", "container"]),
      category_label() {
        if (!this.ticket.category) return "";
        for (let category of this.categories) {
          if (category.uid === this.ticket.category) return category.name;
        }
        return "";
      },
      defect_type() {
        if (
          this.ticket &&
          this.ticket.properties &&
          this.ticket.properties.classId
        ) {
          let vector = this.vector_types.find(
            v => v.class_id == this.ticket.properties.classId
          );
          if (vector && vector.description) {
            return vector.description;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      asset_id() {
        return this?.ticket?.targetElement?.asset;
      },
      asset_name() {
        return this?.ticket?.targetElement?.asset
          ? _.find(
              this.$store.state.assets,
              a => a.uid == this.ticket.targetElement.asset
            ).name
          : null;
      },
      assignee_users() {
        if (
          this.ticket &&
          this.ticket.assignees &&
          Array.isArray(this.ticket.assignees) &&
          this.ticket.assignees.length
        ) {
          return _.intersection(
            Object.keys(this.$store.getters.state_users),
            this.ticket.assignees
          );
        } else if (
          !this.ticket.assignees ||
          (this.ticket.assignees && this.ticket.assignees.uid == null)
        ) {
          return [];
        } else {
          return _.intersection(Object.keys(this.$store.getters.state_users), [
            this.ticket.assignees.uid
          ]);
        }
      },
      assignee_teams() {
        if (
          this.ticket &&
          this.ticket.assignees &&
          Array.isArray(this.ticket.assignees) &&
          this.ticket.assignees.length
        ) {
          return _.intersection(
            Object.keys(this.$store.getters.state_teams),
            this.ticket.assignees
          );
        } else if (
          !this.ticket.assignees ||
          (this.ticket.assignees && this.ticket.assignees.uid == null)
        ) {
          return [];
        } else {
          return _.intersection(Object.keys(this.$store.getters.state_teams), [
            this.ticket.assignees.uid
          ]);
        }
      }
    },
    async created() {
      try {
        const { data } = await categoryService.getAll();
        this.categories = data.categories;
      } catch (error) {
        this.categories = [];
      }
    },
    methods: {
      update_ticket(value) {
        this.$store.dispatch("thermv2/update_ticket_value", {
          id: this.ticket.uid,
          value: value
        });
      },
      get_report_date() {
        if (this.ticket.properties.projectUid) {
          let project = this.container.find(
            p => p.projectUid == this.ticket.properties.projectUid
          );
          if (
            project &&
            project.groupProperties &&
            project.groupProperties.date
          ) {
            return moment(new Date(project.groupProperties.date)).format(
              "MMM DD, YYYY"
            );
          } else {
            return project.groupName;
          }
        } else {
          return "NA";
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tickets-cal {
    right: 15rem;
  }
</style>
<style lang="scss">
  .left__-5 {
    left: -5rem !important;
  }
</style>
