<template>
  <div>
    <!-- v-if="component == 'ticketDetails'" -->
    <div class="schedule-main">
      <div class="schedule--main--body mt-15">
        <div class="schedule--main--body__top is-flex pb-20">
          <div class="is-flex">
            <p class="is-regular-14-500-17">
              {{ localization("app-frequency", "Frequency") }}:
            </p>
            <div
              class="frequency-dropdown is-pointer"
              v-click-outside="() => (frequency_dropdown = false)"
            >
              <p
                class="frequency-dropdown--selection"
                @click="frequency_dropdown = !frequency_dropdown"
              >
                <span class="pb-3 is-semiBold-14-600-17">
                  {{
                    localization(
                      `app-${recurrence_frequency.toLowerCase()}`,
                      recurrence_frequency
                    )
                  }}
                </span>
                <sh-icon
                  :name="'expand'"
                  class="is-16x16"
                  :class="{ rotate180: !frequency_dropdown }"
                ></sh-icon>
              </p>
              <div
                class="frequency-dropdown--content py-10"
                v-if="frequency_dropdown"
              >
                <div v-for="(option, i) in frequency_options" :key="i">
                  <p
                    class="is-medium-14-500-17 is-pointer"
                    @click.stop="
                      recurrence_frequency = option;
                      frequency_dropdown = false;
                    "
                  >
                    {{ localization(`app-${option.toLowerCase()}`, option) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="is-flex ml-30">
            <p class="is-regular-14-500-17">
              {{ localization("app-every", "Every") }}:
            </p>
            <input
              type="number"
              name="internal"
              id="internal"
              class="small-input has-text-centered is-semiBold-14-600-17"
              v-model="interval"
            />

            <p
              v-if="recurrence_frequency == 'Hourly'"
              class="is-regular-14-500-17 ml-10"
            >
              {{ localization("app-hour", "Hour") }}
            </p>
            <p
              v-if="recurrence_frequency == 'Daily'"
              class="is-regular-14-500-17 ml-10"
            >
              {{ localization("app-day", "Day") }}
            </p>
            <p
              v-if="recurrence_frequency == 'Weekly'"
              class="is-regular-14-500-17 ml-10"
            >
              {{ localization("app-week", "Week") }}
            </p>
            <p
              v-if="recurrence_frequency == 'Monthly'"
              class="is-regular-14-500-17 ml-10"
            >
              {{ localization("app-month", "Month") }}
            </p>
            <p
              v-if="recurrence_frequency == 'Yearly'"
              class="is-regular-14-500-17 ml-10"
            >
              {{ localization("app-year", "Year") }}
            </p>
          </div>
        </div>
        <div class="time-selection px-20">
          <week-picker
            v-if="recurrence_frequency == 'Weekly'"
            ref="week"
            :existing_rule="existingSet"
          ></week-picker>
          <month-picker
            v-if="recurrence_frequency == 'Monthly'"
            ref="month"
            :existing_rule="existingSet"
          ></month-picker>
          <year-picker
            v-if="recurrence_frequency == 'Yearly'"
            ref="year"
            :existing_rule="existingSet"
          ></year-picker>
        </div>
        <div
          v-if="recurrence_frequency != 'Daily'"
          class="new-schedule-divider mt-20"
        ></div>
      </div>
      <repeatation-block
        ref="repeatation"
        :existing_rule="existingSet"
      ></repeatation-block>

      <div class="is-flex is-vcenter px-10 ml-10 skip">
        <input
          type="checkbox"
          name="create"
          class="sleek-checkbox"
          id="create"
          v-model="skip"
        />
        <label for="create" class="is-regular-14-00-00 ml-10">
          {{
            isForms
              ? localization("app-skip-missed-forms", "Skip missed forms")
              : localization("app-skip-missed-task", "Skip missed task")
          }}
          <b-tooltip
            class="is-inline-flex"
            :label="
              `When checked, if a ${
                isForms ? 'form' : 'ticket'
              } is missed because the status of the ${
                isForms ? 'form' : 'ticket'
              } was not updated before due date, that ${
                isForms ? 'form' : 'ticket'
              } will be skipped. This means, only ${
                isForms ? 'form' : 'ticket'
              }s in the future are possible.`
            "
            position="is-left"
            multilined
            animated
            size="is-large"
            type="is-dark"
          >
            <p class="ml-10 info">
              <i class="fas fa-question-circle"></i>
            </p>
          </b-tooltip>
        </label>
      </div>

      <div class="new-schedule-divider mt-25 mb-15"></div>
      <div
        v-if="selectedForm && !selectedForm.writeAccess"
        class="mr-15 is-flex align-center"
      >
        <span
          class="mr-5 is-medium-12-500-14 has-text-theme-newgrey has-opacity-8"
          >{{
            localization(
              "app-form-no-schedule-permission",
              "Sorry, You do not have permission to schedule this form"
            )
          }}</span
        >
        <sh-icon :name="'info-grey'" class="is-16x16"></sh-icon>
      </div>

      <div v-else class="is-flex is-between align-center">
        <div>
          <p
            v-if="schedule"
            class="is-semiBold-16-500-19 has-text-error is-pointer mr-20"
            @click="$emit('remove-recurrence')"
          >
            {{ localization("app-remove-schedule", "Remove schedule") }}
          </p>
        </div>
        <div class="is-flex is-vcenter">
          <p
            class="is-semiBold-16-500-19 has-text-blue is-pointer mr-20"
            @click="$emit('close-schedule')"
          >
            {{ localization("app-cancel", "Cancel") }}
          </p>
          <button
            style="height: 3.6rem; width: 9rem"
            class="sh-button is-semiBold-16-600-19 button has-text-white padding-10"
            :class="[
              is_loading ? 'is-loading' : null,
              { 'is-disabled': !access }
            ]"
            @click="setRecurringRule"
          >
            {{ localization("app-save", "Save") }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div v-else class="schedule-main">
      <div class="schedule--main--body mt-20">
        <div
          class="schedule--main--body__top is-flex has-space-between pl-20 pr-20 pb-20"
        >
          <div class="is-flex mr-20">
            <p class="is-regular-16-500-19 has-text-light-grey">
              {{ localization('app-every', 'Every') }}
            </p>
            <input
              type="number"
              name="internal"
              id="internal"
              class="small-input has-text-centered pb-5 is-semiBold-16-600-19"
              v-model="interval"
            />
          </div>
          <p class="is-regular-16-500-19 has-text-light-grey">
            {{ localization('app-frequency', 'Frequency') }}
          </p>
          <div
            class="frequency-dropdown is-pointer"
            v-click-outside="() => (frequency_dropdown = false)"
          >
            <p
              class="frequency-dropdown--selection"
              @click="frequency_dropdown = !frequency_dropdown"
            >
              <span class="pb-5 is-semiBold-16-600-19">{{
                recurrence_frequency
              }}</span>
              <sh-icon
                :name="'expand'"
                class="is-16x16"
                :class="{ rotate180: !frequency_dropdown }"
              ></sh-icon>
            </p>
            <div class="frequency-dropdown--content" v-if="frequency_dropdown">
              <div v-for="(option, i) in frequency_options" :key="i">
                <p
                  class="is-regular-16-500-19 is-pointer"
                  @click.stop="
                    recurrence_frequency = option
                    frequency_dropdown = false
                  "
                >
                  {{ option }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="time-selection px-20">
          <week-picker
            v-if="recurrence_frequency == 'Weekly'"
            ref="week"
            :existing_rule="existingSet"
          ></week-picker>
          <month-picker
            v-if="recurrence_frequency == 'Monthly'"
            ref="month"
            :existing_rule="existingSet"
          ></month-picker>
          <year-picker
            v-if="recurrence_frequency == 'Yearly'"
            ref="year"
            :existing_rule="existingSet"
          ></year-picker>
        </div>
      </div>
      <repeatation-block
        ref="repeatation"
        :existing_rule="existingSet"
      ></repeatation-block>

      <div class="is-flex is-vcenter padding-10 ml-10 skip">
        <input
          type="checkbox"
          name="create"
          class="sleek-checkbox"
          id="create"
          v-model="skip"
        />
        <label for="create" class="is-regular-16-00-19 ml-10">
          {{ localization('app-skip-missed', 'Skip missed') }}
          {{ isForms ? 'form' : 'ticket' }}
          <b-tooltip
            class="is-inline-flex"
            :label="
              `When checked, if a ${
                isForms ? 'form' : 'ticket'
              } is missed because the status of the ${
                isForms ? 'form' : 'ticket'
              } was not updated before due date, that ${
                isForms ? 'form' : 'ticket'
              } will be skipped. This means, only ${
                isForms ? 'form' : 'ticket'
              }s in the future are possible.`
            "
            position="is-left"
            multilined
            animated
            size="is-large"
            type="is-dark"
          >
            <p class="ml-10 info">
              <i class="fas fa-question-circle"></i>
            </p>
          </b-tooltip>
        </label>
      </div>

      <div
        class="mr-20 mt-40 is-flex is-vcenter"
        :class="{ 'is-disabled': !access }"
      >
        <button
          style="height: 5rem; width: 13rem"
          class="sh-button is-semiBold-16-600-19 mr-20 button has-text-white padding-10"
          :class="[is_loading ? 'is-loading' : null]"
          @click="setRecurringRule"
        >
          {{ localization('app-save', 'Save') }}
        </button>
        <p
          class="is-semiBold-16-500-19 has-text-blue is-pointer"
          @click="resetRule"
        >
          {{ localization('app-clear-all', 'Clear All') }}
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { RRule } from "rrule";
  import RepeatationBlock from "./repeatation-block";
  import YearPicker from "./year-picker";
  import WeekPicker from "./week-picker";
  import MonthPicker from "./month-picker";
  export default {
    props: [
      "is_bulk",
      "schedule",
      "uid",
      "ticket_status",
      "access",
      "component",
      "isForms",
      "selectedForm"
    ],
    data() {
      return {
        frequency_dropdown: false,
        frequency_options: ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"],
        create_new: true,
        skip: false,
        recurrence_frequency: "Weekly",
        interval: 1,
        existingSet: {},
        is_loading: false,
        is_saved: false
      };
    },
    components: {
      RepeatationBlock,
      YearPicker,
      WeekPicker,
      MonthPicker
    },
    created() {
      this.start_recurrence();
    },

    methods: {
      start_recurrence() {
        if (this.schedule) {
          if (this.schedule.rrule) {
            this.setExistingRule(this.schedule.rrule);
          }
        }
      },
      setRecurringRule() {
        this.is_loading = true;
        let recurSet = {};
        let today = new Date();
        if (this.recurrence_frequency == "Hourly") {
          recurSet.dtstart = today;
          // recurSet.dtstart = new Date(
          //   Date.UTC(
          //     today.getFullYear(),
          //     today.getMonth(),
          //     today.getDate(),
          //     today.getHours(),
          //     today.getMinutes(),
          //     0
          //   )
          // );
        } else {
          // setting time to 00:00:00:00
          recurSet.dtstart = new Date(
            Date.UTC(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              0,
              0,
              0
            )
          );
        }
        recurSet.interval = this.interval;
        this.$refs.repeatation.repeat_forever == false
          ? this.$refs.repeatation.until == "date"
            ? (recurSet.until = this.$refs.repeatation.date)
            : (recurSet.count = this.$refs.repeatation.count)
          : null;
        if (this.recurrence_frequency == "Hourly") {
          recurSet.freq = RRule.HOURLY;
        } else if (this.recurrence_frequency == "Weekly") {
          recurSet.freq = RRule.WEEKLY;
          recurSet.byweekday = this.$refs.week.set_week_rule();
        } else if (this.recurrence_frequency == "Monthly") {
          recurSet.freq = RRule.MONTHLY;
          recurSet = { ...recurSet, ...this.$refs.month.set_month_rule() };
        } else if (this.recurrence_frequency == "Yearly") {
          recurSet.freq = RRule.YEARLY;
          recurSet = { ...recurSet, ...this.$refs.year.set_year_rule() };
        } else if (this.recurrence_frequency == "Daily") {
          recurSet.freq = RRule.DAILY;
        }
        let data = {
          schedule: {
            rrule: new RRule(recurSet).toString(),
            createNewTicket: this.create_new,
            recurWhen: this.ticket_status,
            skipOccurrence: this.skip
          }
        };
        if (this.isForms) {
          if (this.is_bulk) {
            this.$parent.$emit("complete", {
              recurring: true,
              schedule: { ...data.schedule }
            });
            setTimeout(() => {
              this.$emit("close-schedule");
            }, 2000);
            return;
          }
          this.$store
            .dispatch("forms/update_form", {
              body: {
                recurring: true,
                schedule: { ...data.schedule, createNewTicket: undefined }
              },
              id: this.uid
            })
            .then(() => {
              this.$emit("close-schedule");
            })
            .catch(err => {
              this.$toast.show(
                "Invalid schedule",
                "",
                this.$store.state.izitoast_options.appError
              );
              console.error(err);
            });
        } else {
          this.$store.dispatch("tickets/update_ticket_value", {
            value: data,
            id: this.uid,
            router: this.$router
          });
        }
        this.setExistingRule(data.schedule.rrule);
        setTimeout(() => {
          this.is_loading = false;
        }, 1000);
      },
      // TODO Remove it
      async remove_recurrence() {
        if (this.isForms) {
          this.$store.dispatch("forms/update_form", {
            body: {
              recurring: false,
              rrule: null
            },
            id: this.uid
          });
        } else {
          this.$store.dispatch("tickets/update_ticket_value", {
            value: {
              rrule: null
            },
            id: this.uid,
            router: this.$router
          });
        }
        this.setExistingRule(res.data.rrule);
      },
      setExistingRule(rule) {
        this.skip = this.schedule ? this.schedule.skipOccurrence : false;
        this.create_new = this.schedule ? this.schedule.createNewTicket : true;
        const { options: existingRules } = new RRule.fromString(rule);

        this.interval = existingRules.interval;
        this.existingSet.count = existingRules.count;
        this.existingSet.date = existingRules.until;
        if (existingRules.freq == 0) {
          // If occurrence is yealy
          this.recurrence_frequency = "Yearly";
          this.existingSet.month = existingRules.bymonth;
        } else if (existingRules.freq == 1) {
          // If occurrence is Monthly
          this.recurrence_frequency = "Monthly";
          existingRules.bymonthday && existingRules.bymonthday.length
            ? (this.existingSet.month_date = existingRules.bymonthday)
            : ((this.existingSet.week_number = existingRules.bysetpos),
              (this.existingSet.weekdays = existingRules.byweekday));
        } else if (existingRules.freq == 2) {
          // If occurrence is weekly
          this.recurrence_frequency = "Weekly";
          this.existingSet.weekdays = existingRules.byweekday;
        } else if (existingRules.freq == 3) {
          // If occurrence is weekly
          this.recurrence_frequency = "Daily";
        } else if (existingRules.freq == 4) {
          // If occurrence is hourly
          this.recurrence_frequency = "Hourly";
        } else {
          this.recurrence_frequency = "Monthly";
          this.existingSet.month_date = existingRules.bymonthday;
        }

        this.existingSet = Object.assign({}, this.existingSet);
      },
      resetRule() {
        this.setExistingRule(this.schedule.rrule);
        if (this.recurrence_frequency == "Monthly") {
          this.$refs.month.create_month_rule();
        } else if (this.recurrence_frequency == "Weekly") {
          this.$refs.week.create_week_rule();
        } else if (this.recurrence_frequency == "Yearly") {
          this.$refs.year.create_year_rule();
        } else {
          this.recurrence_frequency = "Daily";
        }
      }
    }
  };
</script>

<style lang="scss">
  .schedule-main {
    width: 36rem;
    margin: auto;
    &--body {
      &__main {
        margin-top: 3.3rem;
      }
    }
  }
  .small-input {
    border: none;
    border-bottom: 1px solid #ddd;
    width: 5rem;
    margin-left: 10px;
  }
  .frequency-dropdown {
    position: relative;
    width: 8rem;
    margin-left: 1.2rem;
    &--selection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
    }
    &--content {
      position: absolute;
      background: white;
      width: 17rem;
      top: 20px;
      border: 1px solid #ddd;
      border-radius: 1rem;
      box-shadow: 2px 2px 5px 0px #eee;
      z-index: 10;
      // max-height: 20rem;
      overflow: auto;
      &.is-left {
        right: 0;
      }
      p {
        padding: 1rem;
      }
      p:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      p:hover {
        background: $primary-background;
      }
    }
  }
  .rotate180 {
    transform: rotate(180deg);
  }
  .skip {
    .info {
      visibility: hidden;
    }
    &:hover {
      .info {
        visibility: visible;
        cursor: help;
      }
    }
  }
  .recurrence_dropdown {
    z-index: 10;
  }
  .sh-custom-checkbox {
    margin-right: 1rem;
    &.is-thinner {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
  .sleek-checkbox {
    position: relative;
    height: 2rem;
    width: 2rem;
    visibility: hidden;
    margin-right: 0.5rem;
    vertical-align: middle;
    cursor: pointer;
    z-index: 5;
    &::before {
      content: "";
      position: absolute;
      visibility: visible;
      background-color: #fff;
      border: 1px solid #b8bbc8;
      height: 2.3rem;
      width: 2.3rem;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(23, 30, 72, 0.1);
      z-index: 2;
      background-color: $white;
    }
    &:hover::before {
      background-color: $white;
    }
    &::after {
      border-width: 0px 0px 0px 0px;
      visibility: visible;
    }
    &:checked::before {
      background: $primary;
      border: 1px solid #b8bbc8;
      height: 2.3rem;
      width: 2.3rem;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(23, 30, 72, 0.1);
      overflow: hidden;
      visibility: visible;
      backface-visibility: none;
    }
    &:checked::after {
      position: absolute;
      content: "";
      z-index: 3;
      visibility: visible;
      left: 9px;
      top: 5px;
      width: 6px;
      height: 11px;
      border: solid #fff;
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
    }
  }
  .b-radio.radio input[type="radio"]:checked + .check:before {
    -webkit-transform: scale(0.5);
    transform: scale(0.3);
  }
  .b-radio.radio input[type="radio"] + .check.is-info:before {
    background: $primary;
  }
  .b-radio.radio input[type="radio"]:checked + .check.is-info {
    border-color: $primary;
    border-width: 2px;
  }
  .b-radio.radio input[type="radio"] + .check {
    border: 1px solid #7a7a7a;
  }
  .has-text-error {
    color: $schedule-stopped;
  }
</style>
