<template>
  <div
    :class="[
      component == 'ticketDetails' ? 'pr-20 pt-40' : 'sh-tasklist-section'
    ]"
  >
    <div v-if="active">
      <task-list-skeleton></task-list-skeleton>
    </div>
    <div
      class="ml-20 checklist-with-value"
      v-else-if="reverseAllChecklist && reverseAllChecklist.length"
    >
      <div
        v-if="component == 'ticketDetails'"
        class="pb-17 is-flex align-center is-between"
      >
        <div class="is-flex align-center">
          <span class="is-medium-16-500-19 has-primary-text">
            {{ localization("app-task-list", "Task List") }}</span
          >
          <span class="is-medium-16-500-19 has-primary-text ml-5 has-opacity-7"
            >({{ total_items }})</span
          >
          <div class="task-progress">
            <div class="task-progress-bar">
              <p
                class="task-progress-bar--completed"
                :style="`width:${progress}%`"
              ></p>
            </div>
          </div>
          <p class="is-medium-16-500-19 has-opacity-7 ml-20">{{ progress }}%</p>
        </div>
        <div class="is-relative is-pointer">
          <div
            class="add-new-tasklist-btn is-inline-flex align-center"
            :class="[
              ticket && !ticket.writeAccess ? 'has-no-access is-disabled' : null
            ]"
          >
            <div
              class="add-new-tasklist-btn-text is-medium-14-500-17"
              @click="create_new_checklist()"
            >
              {{ localization("app-create-new", "Create New") }}
            </div>
            <div
              @click="is_add_dropdown = true"
              class="is-flex align-center add-new-tasklist-btn-arrow"
            >
              <sh-icon
                :name="`${is_add_dropdown ? 'expand' : 'collapse'}`"
                class="is-16x16 is-pointer ml-5"
              ></sh-icon>
            </div>
          </div>
          <div
            class="add-dropdown is-absolute"
            v-if="is_add_dropdown"
            v-click-outside="() => (is_add_dropdown = false)"
          >
            <div class="card">
              <a
                @click.stop="
                  create_new_checklist();
                  is_add_dropdown = false;
                "
                class="button is-flex is-start is-white is-fullwidth is-medium-16-500-19"
              >
                <i class="fas fa-plus mr-10 ml-3"></i>
                <div class="has-text-black btn-text">
                  {{ localization("app-new-checklist", "New Checklist") }}
                </div>
              </a>
              <a
                @click.stop="
                  import_checklist_items();
                  is_add_dropdown = false;
                "
                class="button is-flex is-start is-white is-fullwidth is-medium-16-500-19"
              >
                <sh-icon
                  name="upload"
                  class="is-pointer is-20x20 no-padding-left mr-7"
                />
                <div class="has-text-black btn-text">
                  {{ localization("app-import", "Import") }}
                </div>
              </a>
              <a
                class="button is-flex is-start is-white is-fullwidth is-medium-16-500-19"
              >
                <load-template
                  :service="service"
                  :ticket="ticket"
                  @update="get_checklist()"
                  isButton="true"
                  @hide-template-modal="load_from_template = false"
                ></load-template>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="progress is-flex mb-10 is-vcenter">
          <p class="progress-percent is-medium-16-500-19 has-opacity-7">
            {{ progress }}%
          </p>
          <div class="progress-bar">
            <p
              class="progress-bar--completed"
              :style="`width:${progress}%`"
            ></p>
          </div>
        </div>
        <div>
          <div @click="create_new_checklist()" class="checklist-actions-mobile">
            <i class="fa fa-plus"></i>
          </div>

          <div
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            class="mb-30 ml-5 checklist-actions--create"
          >
            <span
              @click="create_new_checklist()"
              class="is-medium-16-500-19 is-pointer has-opacity-8 has-text-link-blue"
            >
              <i class="fas fa-plus mr-15"></i>
              {{ localization("app-new-checklist", "New Checklist") }}
            </span>
            <span class="mx-10 sh-medium-header">{{
              localization("app-or", "or")
            }}</span>
            <load-template
              :service="service"
              :ticket="ticket"
              @update="get_checklist()"
              isButton="true"
              @hide-template-modal="load_from_template = false"
            ></load-template>
          </div>
        </div>
      </div>

      <div
        class="checklist-list"
        :class="
          component == 'ticketDetails' ? 'has-slimscroll-xs ticket-task' : ''
        "
        v-if="reverseAllChecklist && reverseAllChecklist.length"
      >
        <div
          :class="component == 'ticketDetails' ? 'mb-20' : 'mb-30'"
          v-for="(checklist, i) in allCheckList"
          :key="checklist.uid"
        >
          <checklists
            :checklist="checklist"
            :is_ticket_without_checklists="is_ticket_without_checklists"
            :ticket="ticket"
            :service="service"
            :index="i"
            :component="component"
            @remove_checklist="delete_checklist"
            @added_first_checklist="get_checklist()"
            @refetch_checklists="get_checklist()"
            @duplicate_checklist="create_duplicate_checklist"
            :keep_open="i == 0 && create_new"
            :show_more="reverseAllChecklist.length > 6 ? show_more : true"
          ></checklists>
        </div>
      </div>
      <div
        v-if="component == 'ticketDetails' && reverseAllChecklist.length > 6"
        class="is-flex is-center is-vcenter"
      >
        <div
          class="show-more-btn is-medium-12-500-14 is-pointer"
          @click="show_more = !show_more"
        >
          {{
            show_more
              ? localization("app-show-less", "Show less")
              : localization("app-show-more", "Show more")
          }}
          <sh-icon
            :name="show_more ? 'expand' : 'collapse'"
            class="is-18x18 ml-5 is-pointer"
          ></sh-icon>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="component == 'ticketDetails'">
        <div class="is-medium-16-500-19 has-primary-text mx-20 pb-5">
          {{ localization("app-task-list", "Task List") }}
        </div>
        <div class="ticket-details-add-checklist">
          <div class="has-text-centered">
            <div class="is-flex is-center">
              <sh-icon
                :name="'no-checklist'"
                :file="'tickets_sprite'"
                class="is-22x22 "
              ></sh-icon>
            </div>
            <div class="is-medium-18-500-22 pt-5">
              {{ localization("app-no-task-list", "No Task List") }}
            </div>
            <div class="is-medium-14-500-17 no-desc pt-10">
              {{
                localization(
                  "app-task-template-suggestion-text",
                  "Create a list of tasks to keep track of changes. You could also create templates for later."
                )
              }}
            </div>
            <div class="is-relative is-pointer pt-10">
              <div class="add-new-tasklist-btn is-inline-flex align-center">
                <div
                  class="add-new-tasklist-btn-text is-medium-14-500-17"
                  @click="create_new_checklist()"
                >
                  {{ localization("app-create-new", "Create New") }}
                </div>
                <div
                  @click="is_add_dropdown = true"
                  class="is-flex align-center add-new-tasklist-btn-arrow"
                >
                  <sh-icon
                    :name="is_add_dropdown ? 'expand' : 'collapse'"
                    class="is-16x16 is-pointer ml-5"
                  ></sh-icon>
                </div>
              </div>
              <div
                class="add-dropdown is-absolute"
                style="right:18.5rem;"
                v-if="is_add_dropdown"
                v-click-outside="() => (is_add_dropdown = false)"
              >
                <div class="card">
                  <a
                    @click.stop="
                      create_new_checklist();
                      is_add_dropdown = false;
                    "
                    class="button is-flex is-start is-white is-fullwidth is-medium-16-500-19"
                  >
                    <i class="fas fa-plus mr-10 ml-3"></i>
                    <div class="has-text-black btn-text">
                      {{ localization("app-new-checklist", "New Checklist") }}
                    </div>
                  </a>
                  <a
                    @click.stop="
                      import_checklist_items();
                      is_add_dropdown = false;
                    "
                    class="button is-flex is-start is-white is-fullwidth is-medium-16-500-19"
                  >
                    <sh-icon
                      name="upload"
                      class="is-pointer is-20x20 no-padding-left mr-7"
                    />
                    <div class="has-text-black btn-text">
                      {{ localization("app-import", "Import") }}
                    </div>
                  </a>
                  <a
                    class="button is-flex is-start is-white is-fullwidth is-medium-16-500-19"
                  >
                    <load-template
                      :service="service"
                      :ticket="ticket"
                      @update="get_checklist()"
                      isButton="true"
                      @hide-template-modal="load_from_template = false"
                    ></load-template>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-result">
        <img src="@/assets/illustrations/no-task-illustration.svg" alt />
        <hr />
        <div style="text-align: center;">
          <div class="mt-10">
            <p class="sh-text-normal-22 mb-10">
              {{ localization("app-no-task-list", "No Task List") }}
            </p>
            <p class="sh-text-normal-22 task-no-result-description">
              {{
                localization(
                  "app-create-a-list-of-tasks-to-keep-track-of-changes-you-could-also-create-templates-for-later",
                  "Create a list of tasks to keep track of changes. You could also create templates for later."
                )
              }}
            </p>
          </div>
          <div class="load-button button ml-auto mr-auto mt-20">
            <p class="is-flex">
              <span
                @click="create_new_checklist()"
                class="is-regular-16-00-20"
                >{{
                  localization("app-create-checklist", "Create Checklist")
                }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import checklists from "./components/checklist-component";
  import LoadTemplate from "./components/load-template";
  import { mapState, mapGetters } from "vuex";
  import UserDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";
  import CreateChecklists from "@/app/old/desktop/shared/components/create-items.vue";
  import TicketsCommonSegmentEvents from "@/app/old/desktop/tickets/components/ticket-details/components/tickets-common-segment-events.mixin.vue";
  import TaskListSkeleton from "@/app/old/desktop/tickets/components/ticket-details/components/task-list/components/task-list-skeleton.vue";

  import { ticketService } from "@/app/old/desktop/shared/services/";
  export default {
    props: ["ticket", "channel", "service", "component"],
    data() {
      return {
        active: false,
        load_from_template: false,
        tasks: [],
        is_ticket_without_checklists: false,
        open_new_task: false,
        edit_name: false,
        new_active: false,
        show_create_new: false,
        new_task_name: "",
        is_add_dropdown: false,
        show_more: false,
        new_task: {
          name: "Checklist",
          isTemplate: false,
          internal: false,
          items: [],
          organization: this.$store.state.current_organization.uid
        }
      };
    },
    components: {
      checklists,
      LoadTemplate,
      UserDropdown,
      TaskListSkeleton
    },
    mixins: [TicketsCommonSegmentEvents],
    computed: {
      ...mapGetters("checklist", ["reverseAllChecklist"]),

      ...mapState("checklist", ["allChecklists"]),

      checked_items() {
        return (
          (this.ticket.checklistsStats &&
            this.ticket.checklistsStats.checkedItems) ||
          0
        );
      },

      allCheckList() {
        if (this.component == "ticketDetails") {
          let all_checklist = this.reverseAllChecklist;
          all_checklist = this.show_more
            ? all_checklist
            : all_checklist.slice(0, 6);
          return all_checklist;
        } else {
          return this.reverseAllChecklist;
        }
      },

      total_items() {
        return (
          (this.ticket.checklistsStats &&
            this.ticket.checklistsStats.totalItems) ||
          0
        );
      },
      progress() {
        return Math.round((this.checked_items / this.total_items) * 100) || 0;
      }
    },
    created() {
      this.$store.dispatch("checklist/initialize_service", this.service);
      this.get_checklist();
      //TODO remove
      this.$eventHub.$on("refresh_tasks_data", this.get_checklist);
    },
    beforeDestroy() {
      this.$eventHub.$off("refresh_tasks_data", this.get_checklist);
    },
    mounted() {
      if (this.channel) {
        this.channel.bind("CHECKLIST_UPDATED", data => {
          this.realTimeChecklistUpdate(data);
        });
        this.channel.bind("CHECKLIST_ADDED", data => {
          this.realTimeChecklistUpdate(data);
        });

        this.channel.bind("CHECKLIST_REMOVED", data => {
          for (const i in data.checklists) {
            this.delete_checklist(data.checklists[i]);
          }
        });
      }
    },

    watch: {
      "ticket.uid": {
        handler(newVal, oldVal) {
          if (this.$route.name.includes("therm-v2") && newVal !== oldVal) {
            this.get_checklist();
          }
        }
      }
    },

    methods: {
      async get_checklist() {
        this.active = true;
        await this.$store.dispatch("checklist/add_checklists", {
          id: this.ticket.uid
        });
        this.active = false;
      },
      import_checklist_items() {
        this.$modal.show(
          CreateChecklists,
          {
            number_of_rows: 20,
            no_items_message: "Please specify names for the tasks!",
            column_title: this.localization(
              "app-checklist-task-name",
              "Checklist task name"
            ),
            table_settings: {
              rowHeaders: true,
              height: 400
            },
            create_items: items => {
              this.create_new_checklist(
                items.map(item => ({
                  name: item,
                  assignee: null,
                  dueDate: null,
                  resolved: false
                }))
              );
            }
          },
          { width: "650px", height: "500px" }
        );
      },
      async create_new_task() {
        if (this.new_task_name.length <= 1) {
          this.$toast.show(
            "Task name can not be single letter",
            "",
            this.$store.state.izitoast_options.appWarning
          );
          return;
        }
        let new_task = {
          name: this.new_task_name,
          assignee: this.$refs.task_assignee.selected_user,
          dueDate: null,
          resolved: false
        };

        await this.create_new_checklist([new_task]);
        this.new_task_name = null;
      },

      async create_new_checklist(tasks = []) {
        if (this.$route.meta.screen !== "mobile") this.create_new = true;
        let checklist = this.new_task;
        checklist.organization = this.$store.state.current_organization.uid;
        checklist.items = tasks;
        this.track_checklist_creation(false);
        this.$store.dispatch("checklist/add_new_checklist", {
          id: this.ticket.uid,
          body: checklist
        });
      },

      async create_duplicate_checklist(checklist) {
        let duplicate_checklist = JSON.parse(JSON.stringify(checklist));
        this.$store.dispatch("checklist/add_new_checklist", {
          id: this.ticket.uid,
          body: duplicate_checklist
        });
        this.$toast.show(
          this.localization("app-checklist-duplicated", "Checklist duplicated"),
          "",
          this.$store.state.izitoast_options.appSuccess
        );
      },
      delete_checklist(checklist_uid) {
        this.$store.dispatch("checklist/delete_checklist", {
          id: this.ticket.uid,
          checklist_id: checklist_uid
        });
      },

      async realTimeChecklistUpdate(data) {
        // if (data.uids && data.uids.includes(this.ticket.uid)) {
        let allcheck = await this.service.get_checklist({
          id: this.ticket.uid,
          query: this.$store.state.current_organization.uid
        });

        let recieved_checklists = allcheck.data;

        for (const i in recieved_checklists) {
          this.$store.dispatch("checklist/update_realtime_checklist", {
            id: this.ticket.uid,
            body: recieved_checklists[i]
          });
          // }
        }
      }
    }
  };
</script>

<style lang="scss">
  .checklist-actions-mobile {
    display: none;
  }
  .progress {
    height: 5rem;
    &-bar {
      margin-left: 1rem;
      width: 100%;
      height: 7px;
      background: #ddd;
      border-radius: 0.5rem;
      position: relative;
      &--completed {
        background-color: #3dd598;
        height: 7px;
        border-radius: 0.5rem;
        position: absolute;
        left: 0;
      }
    }
  }

  .sh-tasklist-section {
    padding: 4rem 5rem;
  }

  .sh-menu-vertical {
    display: none;
  }

  .create_checklist_dropdown {
    position: relative;
    font-size: initial;
    .dropdown_content {
      position: absolute;
      background: white;
      z-index: 1000;
      border: 1px solid #eee;
      border-radius: 1rem;
      font-size: 1.4rem;
      &--item {
        display: flex;
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #eee;
        cursor: pointer;
      }
    }
  }

  .checklist-with-value .sh-dropdown-content {
    padding: 0;
    padding-top: 5px;
  }

  .checklist-input-field--create {
    width: 100%;
    justify-content: space-between;
  }

  .checklist-task-list {
    margin-left: -2.5rem;
    .tasks,
    .subtask-create {
      padding-left: 3rem;
    }
    .checklist-subtask--create {
      padding-left: 6.5rem;
    }
  }
  // .checklist-list > div {
  //   margin-bottom: 30px;
  // }

  .no-result {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    hr {
      border-radius: 10px;
      box-shadow: -10px 20px 50px 0 rgba(23, 30, 72, 0.2);
      position: relative;
      top: -70px;
      width: 80rem;
    }
    img {
      width: 261.2px;
      height: 196.8px;
      z-index: 1;
    }
    .sh-text-normal-22 {
      opacity: 0.8;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      color: #171e48;
    }
    .load-button {
      font-size: 14px;
      font-weight: 600;
      padding: 2rem;
      border-radius: 6px;
      box-shadow: 0 2px 5px 0 rgba(37, 133, 211, 0.3);
      border: solid 1px #2585d3;
      background-color: var(--white);
      line-height: 1.21;
      color: #2585d3;
    }

    .task-no-result-description {
      width: 45rem;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
  .task-progress {
    width: 24rem;
    margin-left: 1.2rem;
    display: flex;
    align-items: center;
    // height: 5rem;
    &-bar {
      margin-left: 1rem;
      width: 100%;
      height: 5px;
      background: #ddd;
      border-radius: 0.5rem;
      position: relative;
      &--completed {
        background-color: #3dd598;
        height: 5px;
        border-radius: 0.5rem;
        position: absolute;
        left: 0;
      }
    }
  }
  // .add-new-tasklist-btn {
  //   color: $primary;
  //   border: 1px solid $primary;
  //   background-color: $white;
  //   border-radius: 6px;
  //   padding: 10px 20px;
  //   cursor: pointer;
  // }
  .add-new-tasklist-btn {
    // border-radius: 6px;
    // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    // border: 1px solid $primary;
    // color: $primary;
    // width: 128px;
    // height: 32px;
    &-text {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid $primary;
      height: 32px;
      border-radius: 6px 0 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
    }
    &-arrow {
      border-radius: 0 6px 6px 0;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid $primary;
      border-left: none;
      height: 32px;
      width: 28px;
    }
  }
  .add-new-tasklist-btn-empty {
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $primary;
    color: $primary;
    width: 100px;
    height: 32px;
  }
  .add-dropdown {
    right: 0rem;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
    .card {
      z-index: 3;
      box-shadow: none;
      border-radius: 10px;
      border: solid 1px rgba(23, 30, 72, 0.1);
      .button {
        padding: 2.2rem 1rem;
      }
    }
  }
  .ticket-task {
    // max-height: 250px;
    // overflow: visible;
    // z-index: 999;
    // overflow-x: auto;
  }
</style>
