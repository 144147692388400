<template>
  <div
    class="member-team-bulk-dropdown"
    :class="{ 'm-member-team-bulk-dropdown': is_mobile }"
    @click.stop
  >
    <div class="field is-marginless">
      <p class="control has-icons-left has-icons-right mx-10 my-10">
        <input
          class="input is-medium-14-500-17"
          type="email"
          :placeholder="localization('app-search', 'Search')"
          v-model="search"
        />
        <span class="icon is-left is-large">
          <sh-icon :name="'search'" />
        </span>
      </p>
    </div>
    <div class="is-h-divider" v-if="!is_mobile"></div>

    <div v-if="is_mobile" class="member-team-bulk-dropdown__header">
      <span class="roboto-medium-14 has-secondary-text">{{ title }}</span>
      <div @click="$emit('close')" class="is-24x24 is-place-center">
        <sh-icon :name="'close-grey'" :app="'work'" class="is-12x12"></sh-icon>
      </div>
    </div>
    <div
      class="member-team-bulk-dropdown__content"
      :class="{ 'm-content': is_mobile }"
    >
      <!-- -------------------------------------------------------------------------- */
      /*                                Members list                                */
      /* -------------------------------------------------------------------------- -->
      <div v-if="filtered_members && filtered_members.length">
        <p
          :class="[
            is_mobile
              ? 'roboto-medium-14 has-secondary-text is-flex px-50 mb-10 mt-10'
              : 'roboto-regular-12 has-disabled-text is-flex px-10 mt-10'
          ]"
        >
          {{ localization("app-members", "Members") }}
        </p>
        <p
          v-for="user in filtered_members"
          :key="user.uid"
          class="sh-checkbox is-flex is-vcenter member-team-bulk-dropdown__content-item"
          :class="{ 'is-small': !is_mobile }"
        >
          <input
            style="flex-shrink:0"
            type="checkbox"
            :name="user.uid"
            :id="user.uid"
            :value="user.uid"
            v-model="_members"
          />
          <label :for="user.uid" class="pl-10 is-full-width is-flex is-vcenter">
            <user-avatar
              :user="user"
              :pxsize="24"
              :custom_class="'tiny-user-avatar'"
              :show_tooltip="true"
            ></user-avatar>
            <span
              class="ml-6 username-truncate"
              style="width:13rem"
              v-username="user"
            ></span>
          </label>
        </p>
      </div>
      <!-- -------------------------------------------------------------------------- */
      /*                                 Teams list                                 */
      /* -------------------------------------------------------------------------- -->
      <div v-if="filtered_teams && filtered_teams.length">
        <p
          :class="[
            is_mobile
              ? 'roboto-medium-14 has-secondary-text is-flex px-50 mb-10 mt-30'
              : 'roboto-regular-12 has-disabled-text is-flex px-10 mt-10'
          ]"
        >
          {{ localization("app-teams", "Teams") }}
        </p>
        <p
          v-for="team in filtered_teams"
          :key="team.uid"
          class="sh-checkbox is-flex is-vcenter member-team-bulk-dropdown__content-item"
          :class="{ 'is-small': !is_mobile }"
        >
          <!-- {{ team }} -->
          <input
            style="flex-shrink:0"
            type="checkbox"
            :name="team.uid"
            :id="team.uid"
            :value="team.uid"
            v-model="_teams"
          />
          <label :for="team.uid" class="pl-10 is-full-width is-flex is-vcenter">
            <sh-icon
              style="flex-shrink:0;"
              :name="'team-icon'"
              :app="'work'"
              class="is-24x24 mr-5"
            ></sh-icon>
            <span
              :class="is_mobile ? 'roboto-regular-14' : 'roboto-regular-13'"
            >
              {{
                $store.getters.state_teams[team.uid]
                  ? $store.getters.state_teams[team.uid].name
                  : team.uid
              }}
            </span>
          </label>
        </p>
      </div>
    </div>
    <div
      v-if="is_mobile"
      class="member-team-bulk-dropdown__footer roboto-medium-14"
    >
      <div @click="$emit('close')" class="mr-8 is-cancel">
        {{ localization("app-cancel", "Cancel") }}
      </div>
      <div @click="save" class="is-success">
        {{ localization("app-apply", "Apply") }}
      </div>
    </div>
    <div v-else class="footer bt-1 is-full-width is-flex is-start py-10">
      <div class="mr-10 button is-large is-medium-14-500-17" @click="$emit('close')">
        {{ localization("app-cancel", "Cancel") }}
      </div>
      <div class="mr-10 button is-info is-large is-medium-14-500-17" @click="save">
        {{ localization("app-save", "Save") }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MemberTeamBulkDropdown",
    props: {
      title: {
        type: String,
        default: "Assignees"
      },
      members: {
        type: Array,
        default: []
      },
      teams: {
        type: Array,
        default: []
      },
      is_teams_list: {
        type: Boolean,
        default: false
      },
      asset_id: {}
    },
    data() {
      return {
        search: null,
        _members: [],
        _teams: []
      };
    },
    computed: {
      filtered_members() {
        if (this.search && this.search.length) {
          return Object.values(this.$store.getters.state_users).filter(user =>
            _.includes(
              (user.first_name
                ? user.first_name + " " + user.last_name + user.email
                : user.email
              ).toLowerCase(),
              this.search.toLowerCase()
            )
          );
        } else {
          return Object.values(this.$store.getters.state_users);
        }
      },
      filtered_teams() {
        if (this.search && this.search.length) {
          let data = _.filter(this.$store.state.teams || [], f =>
            _.includes(f.name.toLowerCase(), this.search.toLowerCase())
          );
          return data;
        } else {
          return Object.values(this.$store.state.teams);
        }
      }
    },
    watch: {
      members: {
        handler(val) {
          this._members = _.cloneDeep(val);
        },
        immediate: true
      },
      teams: {
        handler(val) {
          this._teams = _.cloneDeep(val);
        },
        immediate: true
      }
    },
    methods: {
      save() {
        this._members = [...new Set(this._members)];
        this._teams = [...new Set(this._teams)];
        this.$emit("save", {
          members: this._members,
          teams: this._teams
        });
        if (this.is_mobile) {
          this.$emit("close");
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .member-team-bulk-dropdown {
    width: 25rem;
    position: relative;
    border: 1px solid #23282d1a;
    border-radius: 2px;
    z-index: 10;
    background: $white;
    box-shadow: 0px 4px 8px #00000029;
    &.m-member-team-bulk-dropdown {
      width: 35rem;
      border-radius: 1rem;
      margin: auto;
      max-width: 100vw;
    }
    @include for-size(small) {
      width: unset;
      margin: 0 4rem;
      border-radius: 1rem;
    }

    &__header {
      background-color: #fafafa;
      border-radius: 1rem 1rem 0 0;
      padding: 2rem 2.5rem 1.5rem 2rem;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__content {
      height: 25rem;
      overflow: auto;
      &.m-content {
        height: 35rem;
      }
      @include for-size(small) {
        height: 35rem;
      }
    }
    &__content-item {
      padding: 0.6rem 1rem;
      @include for-size(small) {
        height: 4.2rem;
        &:not(:last-child) {
          margin-bottom: 0.6rem;
        }
      }

      &:hover {
        background-color: rgba(#1676ee, 0.1);
        @include for-size(small) {
          background-color: rgba(#1676ee, 0);
        }
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2rem;
      div {
        height: 3.6rem;
        padding: 0 1.4rem;
        display: grid;
        place-items: center;
      }
      .is-cancel {
        color: #737891;
      }
      .is-success {
        color: #1676ee;
      }
    }
  }
  .username-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .small-button {
    height: 20px;
  }
</style>
