var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex is-between",class:[
    _vm.ticket && !_vm.ticket.writeAccess ? 'is-not-allowed' : null,
    _vm.component == 'ticketDetails' ? '' : 'pl-35 py-20'
  ]},[(!_vm.create_new)?_c('p',{staticClass:"has-text-theme-grey is-pointer has-opacity-8",class:[
      _vm.ticket && !_vm.ticket.writeAccess ? 'has-no-access' : null,
      _vm.component == 'ticketDetails'
        ? 'is-medium-14-500-17'
        : 'is-medium-16-500-19'
    ],on:{"click":function($event){_vm.create_new = true}}},[_c('i',{staticClass:"fas fa-plus mr-5"}),_vm._v(" "+_vm._s(_vm.localization("app-new-task", "New Task"))+" ")]):_vm._e(),(_vm.create_new)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.create_new = false); }),expression:"() => (create_new = false)"}],staticClass:"is-flex is-vcenter checklist-input-field--create"},[_c('div',{staticClass:"is-flex is-vcenter"},[_c('sh-icon',{staticClass:"is-16x16 is-pointer",attrs:{"name":'close'},nativeOn:{"click":function($event){_vm.create_new = false}}}),_c('div',{staticClass:"ml-20 input-div"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_task.name),expression:"new_task.name"},{name:"focus",rawName:"v-focus"}],staticClass:"input custom-input",class:{ 'is-medium-14-500-17': _vm.component == 'ticketDetails' },attrs:{"type":"text ","id":_vm.component == 'ticketDetails'
              ? 'custom-task-input-ticket-details'
              : 'custom-task-input',"placeholder":_vm.localization('app-create-new-task', 'Create New Task')},domProps:{"value":(_vm.new_task.name)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.create_new_task($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();_vm.create_new = false}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.new_task, "name", $event.target.value)}}})]),_c('p',{staticClass:"ml-10",class:{ 'is-hidden': !_vm.service }},[_c('user-dropdown',{staticClass:"is-medium-16-500-19",attrs:{"existing_user":_vm.new_task.assignee,"pxsize":24},on:{"update-user":function($event){_vm.new_task.assignee = $event},"unassigne_assignee":function($event){_vm.new_task.assignee = null}}})],1)],1),_c('div',{staticClass:"is-pointer is-flex align-center",class:[
        _vm.component == 'ticketDetails' ? 'td-save-button' : 'save-button'
      ],on:{"click":_vm.create_new_task}},[_c('sh-icon',{staticClass:"is-14x14 mr-5 is-pointer",attrs:{"name":'ticket-show-tick'}}),_vm._v(" "+_vm._s(_vm.localization("app-save", "Save"))+" ")],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }