<template>
  <div>
    <sh-menu
      v-bind="menuData"
      @clicked-outside="active = false"
      :active="active"
      :width="assign_all ? '20rem;' : '25.6rem;'"
      @icon-clicked="iconAction"
      @item-clicked="listAction"
    >
      <sh-icon
        @click.native="active = !active"
        :name="'3dots-menu-horizontal'"
        class="sh-menu-horizontal is-24x24 ml-20 is-pointer"
      />
      <sh-icon
        @click.native="active = !active"
        :name="'3dots-menu'"
        class="sh-menu-vertical is-24x24 ml-20 is-pointer"
      />
      <template v-if="assign_all" slot="prepend">
        <div class="field px-10">
          <p class="control has-icons-left has-icons-right">
            <input
              class="input"
              type="email"
              placeholder="Search Users"
              v-model="searchQuery"
            />
            <span class="icon is-left is-large">
              <sh-icon :name="'search'" />
            </span>
          </p>
        </div>
      </template>
      <template v-if="assign_all" slot="item" slot-scope="user">
        <user-avatar
          :user="user.item"
          :pxsize="20"
          :custom_class="'tiny-user-avatar'"
          :show_tooltip="true"
        ></user-avatar>
        <span
          class="ml-6 is-medium-14-500-17"
          style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;"
          v-if="!is_name_hidden"
          v-username="user.item"
        ></span>
      </template>
    </sh-menu>
    <prompt-modal
      v-if="delete_checklist"
      :name="checklist.name"
      :heading="checklist.name"
      :iconPath="require('@/assets/icons/prompt/delete.svg')"
      :footer="true"
      :cancelString="'Cancel'"
      :confirmString="'Delete'"
      @cancel="$modal.hide(checklist.name)"
      @confirm="delete_current_checklist(checklist)"
      @closed="$modal.hide(checklist.name)"
    >
      <div class="prompt-modal__content__text" slot="content">
        {{
          localization(
            "app-are-you-sure-you-want-to-delete",
            "Are you sure you want to delete"
          )
        }}
        <span class="is-capitalized"> {{ checklist.name }}? </span>
      </div>
    </prompt-modal>
    <save-template
      v-if="open_template_modal"
      :checklist="checklist"
      :ticket="ticket"
      :service="service"
      @hide-template-modal="load_from_template = false"
      @closed="open_template_modal = false"
      @update_items="$emit('load_items', $event)"
      @update="$eventHub.$emit('refresh_tasks_data')"
      @save="$emit('savetemplate', $event)"
    ></save-template>
    <load-template
      v-if="load_from_template"
      :current_checklist="checklist"
      :items_only="true"
      :ticket="ticket"
      :service="service"
      @load="$emit('loadtemplate', $event)"
      @hide-template-modal="load_from_template = false"
      @closed="load_from_template = false"
      @update_items="$emit('load_items', $event)"
      @update="$eventHub.$emit('refresh_tasks_data')"
    ></load-template>
  </div>
</template>

<script>
  import shMenu from "@/app/old/desktop/shared/components/menu";
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";
  import SaveTemplate from "./save-checklist-template";
  import LoadTemplate from "./load-template";
  import CreateChecklistItems from "@/app/old/desktop/shared/components/create-items.vue";

  export default {
    props: ["ticket", "checklist", "service"],
    data() {
      return {
        active: false,
        list: {
          icons: [
            {
              tooltip: `Add task`,
              action: "create_new",
              icon: "add",
              is_disabled: false
            },
            {
              tooltip: `Edit name`,
              action: "edit_checklist",
              icon: "pen",
              is_disabled: false
            },
            {
              tooltip: `Duplicate checklist`,
              action: "duplicate_checklist",
              icon: "copy",
              is_disabled: this.checklist.isTemplate ? true : false
            },
            {
              tooltip: `Delete`,
              action: () => {
                this.active = false;
                this.delete_checklist = true;
                this.$modal.show(this.checklist.uid);
              },
              icon: "trash",
              is_disabled: false
            }
          ],
          items: [
            {
              icon: "upload",
              text: "Import",
              action: this.import_checklist_items,
              is_disabled: false
            },
            {
              icon: "assignee",
              text: "Assign all to...",
              localization: "app-assign-all-to...",
              action: () => {
                this.assign_all = true;
              },
              is_disabled: false
            },
            {
              icon: "assignee",
              text: "Unassign all",
              localization: "app-unassign-all",
              action: () => {
                this.update_all("assignee", null);
                this.active = false;
              },
              is_disabled: false
            },
            {
              icon: "checkall",
              text: "Check all",
              localization: "app-check-all",
              action: () => {
                this.update_all("status", "resolved");
                this.active = false;
              },
              is_disabled: false
            },
            {
              icon: "save-as-template",
              text: "Save as Template",
              localization: "app-save-as-template",
              action: () => {
                this.open_template_modal = true;
                this.active = false;
                this.$emit("savetemplate", true);
              },
              is_disabled: this.checklist.isTemplate ? true : false
            },
            {
              icon: "magic-load",
              text: "Load from Template",
              localization: "app-load-from-template",
              action: () => {
                this.load_from_template = true;
                this.active = false;
                this.$emit("loadtemplate", true);
              },
              is_disabled: this.checklist.isTemplate ? true : false
            }
          ]
        },
        showDeleteModal: false,
        assign_all: false,
        load_from_template: false,
        open_template_modal: false,
        searchQuery: "",
        delete_checklist: false
      };
    },
    components: {
      PromptModal,
      SaveTemplate,
      LoadTemplate,
      shMenu
    },
    mounted() {
      if (this.$route.meta.screen === "mobile") {
        this.list = {
          icons: [],
          items: [
            {
              icon: "add",
              text: "New Task",
              localization: "app-new-task",
              action: () => {
                this.$emit("create_new");
              },
              is_disabled: false
            },
            {
              icon: "pen",
              text: "Edit",
              localization: "app-edit",
              action: () => {
                this.$emit("edit_checklist");
                this.active = false;
              },
              is_disabled: false
            },
            {
              icon: "checkall",
              text: "Check all",
              localization: "app-check-all",
              action: () => {
                this.update_all("status", "resolved");
                this.active = false;
              },
              is_disabled: false
            },
            {
              icon: "magic-load",
              text: "Load from Template",
              localization: "app-load-from-template",
              action: () => {
                this.load_from_template = true;
                this.active = false;
              },
              is_disabled: this.checklist.isTemplate ? true : false
            },
            {
              icon: "trash",
              text: "Delete",
              action: () => {
                if (this.offline) {
                  this.active = false;
                  this.$toast.show(
                    this.localization(
                      "app-offline-delete-is-unavailable",
                      "Offline - delete is unavailable"
                    ),

                    "",
                    this.$store.state.izitoast_options.appError
                  );
                  return;
                }
                this.active = false;
                this.delete_checklist = true;
                this.$modal.show(this.checklist.uid);
              },
              is_disabled: false
            }
          ]
        };
      }
    },
    watch: {
      active: function(val) {
        this.assign_all = false;
        this.searchQuery = "";
        this.$emit("open_dropdown", this.active);
      }
    },
    computed: {
      menuData() {
        if (this.assign_all) {
          return { items: this.searched_users };
        } else {
          return this.list;
        }
      },
      offline() {
        return this.$store.state.is_offline;
      },
      searched_users() {
        if (this.searchQuery.length) {
          return this.users.filter(user =>
            _.includes(
              (user.first_name
                ? user.first_name + user.last_name + user.email
                : user.email
              ).toLowerCase(),
              this.searchQuery.toLowerCase()
            )
          );
        } else {
          return this.users;
        }
      },
      users() {
        return Object.values(this.$store.getters.state_users).map(d => {
          d.action = () => this.update_all("assignee", d);
          return d;
        });
      }
    },
    methods: {
      listAction(action) {
        action();
      },
      iconAction(action) {
        if (typeof action === "string") this.$emit(action);
        else action();
        this.active = false;
      },
      import_checklist_items() {
        this.$modal.show(
          CreateChecklistItems,
          {
            number_of_rows: 20,
            no_items_message: "Please specify names for the tasks!",
            column_title: this.localization(
              "app-checklist-task-name",
              "Checklist task name"
            ),
            table_settings: {
              rowHeaders: true,
              height: 400
            },
            create_items: items => {
              if (this.ticket.uid) {
                this.checklist.items = this.checklist.items.concat(
                  items.map(item => ({
                    assignee: null,
                    dueDate: null,
                    name: item,
                    resolved: false,
                    status: null
                  }))
                );
                this.$store.dispatch("checklist/update_checklist", {
                  id: this.ticket.uid,
                  checklist_id: this.checklist.uid,
                  body: this.checklist
                });
              }
            }
          },
          { width: "650px", height: "500px" }
        );
      },
      delete_current_checklist(checklist) {
        this.delete_checklist = false;
        this.$modal.hide(checklist.uid);
        this.$store.dispatch("checklist/delete_checklist", {
          id: this.ticket.uid,
          checklist_id: checklist.uid
        });
      },
      update_all(key, value) {
        this.active = false;
        function updateAll(items) {
          if (!items) {
            return;
          }
          items.forEach(task => {
            updateAll(task.children);
            task[key] = value;
          });
        }
        updateAll(this.checklist.items);
        if (this.$route.name == "checklist-details" || !this.ticket.uid) {
          this.$store.dispatch("checklist/update_template_checklist", {
            checklist_id: this.checklist.uid,
            body: this.checklist
          });
        } else {
          this.$store.dispatch("checklist/update_checklist", {
            id: this.ticket.uid,
            checklist_id: this.checklist.uid,
            body: this.checklist
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .is-24x24 {
    height: 2.4rem;
    width: 2.4rem;
  }
</style>
