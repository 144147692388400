var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.meta.screen !== 'mobile')?_c('skeleton-loading',[_c('row',{key:_vm.item,attrs:{"gutter":{ top: '20px' }}},[_c('column',{attrs:{"span":24,"gutter":40}},[_c('square-skeleton',{attrs:{"count":1,"boxProperties":{
            top: '12px',
            bottom: '12px',
            width: '100%',
            height: '13rem'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '10px',
            width: '100%',
            height: '15px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '10px',
            height: '15px'
          }}})],1)],1)],1):_c('skeleton-loading',[_c('row',{attrs:{"gutter":{ top: '10px' }}},[_c('column',{attrs:{"span":24,"gutter":10}},[_c('square-skeleton',{attrs:{"count":1,"boxProperties":{
            top: '5px',
            bottom: '5px',
            width: '100%',
            height: '5rem'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '5px',
            height: '5px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '5px',
            height: '5px'
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }