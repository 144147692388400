var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.existing_files)?_c('div',[_c('div',[_c('drag-select-container',{class:{ 'attachment-list pt-20': _vm.component != 'ticketDetails' }},_vm._l((_vm.existing_files),function(file,index){return _c('div',{key:file.uid,attrs:{"data-file":file.uid}},[_c('attachment',{attrs:{"service":_vm.service,"ticket":_vm.ticket,"file":file,"index":index,"template":_vm.template,"selected_attachments":_vm.selected_attachments.map(function (file) { return file.uid; }),"component":_vm.component,"no_selection":_vm.no_selection,"show_more":_vm.show_more,"attachment_details":_vm.attachment_details,"is_comment":_vm.is_comment},on:{"delete_attachment":function($event){return _vm.$emit('delete_attachment', $event)},"toggle_selection":_vm.TOGGLE_SELECTION,"attachment_card_click":_vm.ATTACHMENT_CARD_CLICK,"refresh_attachments":function($event){return _vm.$emit('refresh_attachments')}}})],1)}),0)],1),(_vm.webViewerfileDelete)?_c('prompt-modal',{attrs:{"name":_vm.webViewerfile.uid,"heading":_vm.webViewerfile.fileName,"iconPath":require('@/assets/icons/prompt/delete.svg'),"footer":true,"cancelString":'Cancel',"confirmString":'Delete'},on:{"cancel":function($event){_vm.webViewerfileDelete = false},"confirm":function($event){_vm.$emit('delete_attachment', _vm.webViewerfile);
      _vm.webViewerfileDelete = false;
      _vm.webViewerfile = null;},"closed":function($event){_vm.webViewerfileDelete = false}}},[_c('div',{staticClass:"prompt-modal__content__text",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.localization( "app-are-you-sure-you-want-to-delete", "Are you sure you want to delete" ))+" "+_vm._s(_vm.webViewerfile.fileName.charAt(0).toUpperCase() + _vm.webViewerfile.fileName.slice(1))+"? ")])]):_vm._e(),(_vm.webViewerfile)?_c('web-viewer',{attrs:{"url":_vm.webViewerfile.url,"name":_vm.webViewerfile.fileName,"bookmark":_vm.webViewerfile.bookmark,"hasNavigation":true,"hasNavIcon":{
      left: _vm.webViewerIndex == 0,
      right: _vm.webViewerIndex == _vm.existing_files.length - 1
    },"navigate":_vm.navigateViewer,"icon":require('@/assets/file-type-icons/' +
        _vm.prettyFileIcons.getIcon(_vm.webViewerfile.fileName) +
        '.svg')},on:{"close":function($event){_vm.webViewerfile = null},"delete":function($event){_vm.webViewerfileDelete = true}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }