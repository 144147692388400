<template>
  <div class="sh-comment-name is-flex is-between">
    <div class="is-medium-16-500-19 sh-comment-name--text is-flex align-center">
      <span
        v-if="$store.getters.state_users[comment.user.id]"
        v-username="$store.getters.state_users[comment.user.id]"
        style="line-height:20px"
      ></span>
      <span v-else class="is-medium-16-500-19">
        {{ localization("app-unknown", "Unknown") }}
      </span>
      <div
        class="is-flex ml-20 align-center sh-comment-pin"
        v-if="comment.pinned"
      >
        <sh-icon :name="'pinned-yellow'" class="is-18x18 mr-5"></sh-icon>
        {{ localization("app-pinned", "Pinned") }}
      </div>
    </div>
    <div
      :class="active ? 'active' : ''"
      class="is-flex align-center sh-comment-option"
    >
      <sh-icon
        v-if="!comment.pinned"
        @click.native="$emit('pinned')"
        :class="option.pinned ? 'sh-comment-pinned' : ''"
        :name="'td-pin'"
        class="is-18x18 is-pointer mr-20"
      ></sh-icon>
      <span @click="$emit('pinned')" v-else>
        <sh-icon
          :class="option.pinned ? 'sh-comment-pinned' : ''"
          :name="'pinned-yellow-active'"
          class="is-24x24 is-pointer mr-20"
        ></sh-icon>
      </span>
      <p
        v-if="this.comment.user.id === this.$store.state.claims.user_id"
        @click="$emit('edit')"
      >
        <sh-icon :name="'edit-2'" class="is-pointer mr-20 is-18x18"></sh-icon>
      </p>
      <p @click="$emit('reply')" class=" align-center mr-20 is-pointer">
        <sh-icon
          :name="'ticket-details-tab-comments'"
          class="is-pointer mr-7 is-16x16"
        ></sh-icon>
        <span class="is-medium-14-500-17 has-accent-text">{{
          localization("app-reply", "Reply")
        }}</span>
      </p>
      <sh-menu
        :items="items"
        @clicked-outside="active = false"
        :active="active"
        :width="'25.6rem'"
        @item-clicked="
          active = false;
          $emit($event);
        "
      >
        <sh-icon
          @click.native="active = !active"
          :name="'light-grey-3dots-menu-horizontal'"
          class="is-24x24 is-pointer"
        />
      </sh-menu>
      <p
        class="is-regular-14-500-17 has-text-theme-black has-opacity-7 sh-created-at"
        :class="active ? 'active' : ''"
      >
        {{ createdByTime(new Date(comment.created_at).toISOString()) }}
      </p>
    </div>
  </div>
</template>
<script>
  import shMenu from "@/app/old/desktop/shared/components/menu";
  import { DateTime } from "luxon";

  export default {
    components: { shMenu },
    props: ["ticket", "comment", "isEditing", "channel", "option"],
    data() {
      return {
        active: false,
        items: [],
        interval: null,
        updateTime: null
      };
    },
    computed: {
      pinned() {
        return this.comment.pinned;
      }
    },
    watch: {
      pinned: function(val) {
        this.items[0] = {
          icon: this.comment.pinned ? "pinned" : "pinned-active",
          text: this.comment.pinned
            ? "Un-pin comment"
            : "Pin to this conversation",
          action: "pinned"
        };
      }
    },
    mounted() {
      this.$emit("mobileEvent", () => {
        this.active = true;
      });
      this.items.push(
        {
          icon: this.comment.pinned ? "pinned" : "pinned-active",
          text: this.comment.pinned
            ? "Un-pin comment"
            : "Pin to this conversation",
          action: "pinned"
        },
        {
          icon: "ticket-tab-comments",
          text: "Reply to this comment",
          action: "reply"
        }
      );
      if (this.comment.user.id === this.$store.state.claims.user_id) {
        this.items.push(
          {
            icon: "edit-2",
            text: "Edit this comment",
            action: "edit"
          },
          {
            icon: "trash",
            text: "Delete this comment",
            action: "delete"
          }
        );
      }
      this.interval = setInterval(() => {
        this.createdByTime();
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    methods: {
      createdByTime(createdTime) {
        let givenDate = new DateTime.fromISO(createdTime);
        let today = new DateTime.local();
        this.updateTime = givenDate.toRelative({ base: today });
        return this.updateTime;
      }
    }
  };
</script>
