var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"truncate is-flex is-vcenter",class:_vm.info.is_block},[_c('div',{staticClass:"info-block__image",class:[_vm.info.image_class, { 'is-hidden': _vm.hideImage }]},[_c('div',{staticClass:"info-block__image__container"},[(_vm.unknown)?[(_vm.info.name == 'Sensehawk')?_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.info.name, html: false }),expression:"{ content: info.name, html: false }"}],staticClass:"info-block__image__container__picture",class:_vm.info.image_class,attrs:{"file":'users_sprite',"name":'sensehawk-created-36px'}}):_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.info.name, html: false }),expression:"{ content: info.name, html: false }"}],staticClass:"info-block__image__container__picture",class:_vm.info.image_class,attrs:{"file":'users_sprite',"name":'unknown-user-36px'}})]:[(_vm.info.image)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.info.name, html: false }),expression:"{ content: info.name, html: false }"}],staticClass:"info-block__image__container__picture",class:[
            [_vm.info.image_class ? _vm.info.image_class : 'is-36x36'],
            _vm.info.is_border
          ],attrs:{"src":_vm.info.image}}):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.info.name),expression:"info.name"}],staticClass:"info-block__image__container__picture info-block__image__container__picture__icon",class:[
            [_vm.info.image_class ? _vm.info.image_class : 'is-36x36'],
            _vm.info.is_border
          ],style:(("background-color: " + (_vm.info.color ? _vm.info.color : _vm.hashed_color(_vm.info.name, _vm.info.type))))},[_vm._v(" "+_vm._s(_vm.info.name.charAt(0))+" ")])]],2)]),_c('div',{staticClass:"info-block__info"},[_c('div',{staticClass:"info-block__info--type is-regular-14-00-17",class:_vm.info.top_class},[_vm._v(" "+_vm._s(_vm.info.top)+" ")]),_c('div',{staticClass:"info-block__info--name-block"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.info.name.length > _vm.truncate_value || _vm.info.name.length > 18
            ? _vm.info.name
            : null
        ),expression:"\n          info.name.length > truncate_value || info.name.length > 18\n            ? info.name\n            : null\n        "}],staticClass:"name",class:[_vm.info.name_class ? _vm.info.name_class : null]},[_vm._v(_vm._s(_vm._f("truncate")(_vm.info.name,_vm.truncate_value || 15)))]),_c('br'),_c('span',{staticClass:"sidetext",class:_vm.info.subtext_class},[_vm._v(_vm._s(_vm._f("sinceDays")(_vm.info.sidetext)))]),(_vm.info.subtext)?_c('span',{staticClass:"subtext",class:_vm.info.subtext_class},[_vm._v(_vm._s(_vm.info.subtext))]):_vm._e()]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.info.bottom),expression:"info.bottom"}],staticClass:"info-block__info--title",class:_vm.info.bottom_class ? _vm.info.bottom_class : 'has-text-theme-black'},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.info.bottom,18))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }