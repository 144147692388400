<template>
  <div>
    <div class="element_actions">
      <div class="is-flex is-vcenter has-space-between ">
        <div
          class="is-regular-12-00-14 pb-7 new-ticket-details__content--title"
        >
          {{ localization("app-assets", "Assets") }}
        </div>
        <div
          class="is-flex is-vcenter "
          v-if="
            $store.getters.modify_task_element &&
              elementData &&
              elementData.type == 'asset'
          "
        >
          <p
            class="is-pointer ml-10 is-medium-12-500-00 element_actions_buttons has-text-info"
            v-if="!edit_asset"
            @click="edit_asset = true"
          >
            {{ localization("app-edit", "Edit") }}
          </p>
          <p
            class="is-pointer ml-10 is-medium-12-500-00 element_actions_buttons"
            v-if="!edit_asset"
            @click="removeAsset"
          >
            {{ localization("app-clear", "Clear") }}
          </p>
          <p
            class="is-pointer ml-10 is-medium-12-500-00 has-text-info"
            v-if="edit_asset"
            @click="updateAsset"
          >
            {{ localization("app-save", "Save") }}
          </p>
        </div>
        <div v-else-if="$store.getters.modify_task_element">
          <p
            class="is-pointer ml-10 is-medium-12-500-00 has-text-info"
            v-if="edit_asset"
            @click="updateAsset"
          >
            {{ localization("app-save", "save") }}
          </p>
          <p
            v-else
            class="is-pointer ml-10 is-medium-12-500-00 element_actions_buttons"
            @click="edit_asset = true"
          >
            {{ localization("app-add-asset", "Add asset") }}
          </p>
        </div>
      </div>
      <div v-if="is_asset_loading" class="pb-20">
        <b-skeleton width="60%" height="2rem" :animated="animated"></b-skeleton>
      </div>
      <div
        v-else-if="edit_asset"
        class="pb-20"
        style="width:25rem"
        v-click-outside="() => (edit_asset = false)"
      >
        <associated-asset-dropdown
          :text="'Select asset'"
          :noTopMargin="true"
          :canDelete="false"
          :auto_open="true"
          :existing="
            elementData && elementData.type == 'asset' ? elementData.uid : null
          "
          @update="selected_asset = $event"
        ></associated-asset-dropdown>
      </div>
      <div v-else>
        <primary-tags
          class="pb-20"
          :tags="
            elementData && elementData.type == 'asset' ? [element_asset] : null
          "
          :type="'asset'"
          :noTopMargin="true"
          :no_access="true"
          v-if="elementData"
        ></primary-tags>

        <div class="is-flex is-vcenter is-pointer pb-20" v-else>
          <sh-icon :name="'add-asset'" class="mr-7 is-30x30" />
          <span
            class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
            >{{
              localization("app-asset-not-associated", "Asset not associated")
            }}</span
          >
        </div>
      </div>
    </div>

    <div>
      <div class="pb-20 element_actions">
        <div class="is-flex is-vcenter has-space-between ">
          <div
            class="is-regular-12-00-14 pb-7 new-ticket-details__content--title"
          >
            {{ localization("app-location", "Location") }}
          </div>
          <div
            class="is-flex is-vcenter "
            v-if="
              $store.getters.modify_task_element &&
                nameArray &&
                nameArray.length
            "
          >
            <p
              class="is-pointer ml-10 is-medium-12-500-00 element_actions_buttons has-text-info"
              v-if="!edit_location"
              @click="editLocation()"
            >
              {{ localization("app-edit", "Edit") }}
            </p>
            <p
              class="is-pointer ml-10 is-medium-12-500-00 element_actions_buttons"
              v-if="!edit_location"
              @click="removeLocation"
            >
              {{ localization("app-clear", "Clear") }}
            </p>
            <p
              class="is-pointer ml-10 is-medium-12-500-00 has-text-info"
              v-if="edit_location"
              @click="updateLocation"
              :class="{ 'is-disabled': !location }"
            >
              {{ localization("app-save", "Save") }}
            </p>
          </div>
          <div v-else-if="$store.getters.modify_task_element">
            <p
              class="is-pointer ml-10 is-medium-12-500-00 has-text-info"
              v-if="edit_location"
              @click="updateLocation"
              :class="{ 'is-disabled': !location }"
            >
              {{ localization("app-save", "Save") }}
            </p>
            <p
              v-else-if="elementData && elementData.uid"
              class="is-pointer ml-10 is-medium-12-500-00 element_actions_buttons"
              @click="editLocation()"
            >
              {{ localization("app-add-location", "Add locatiom") }}
            </p>
          </div>
        </div>
        <div
          v-if="edit_location"
          v-click-outside="() => (edit_location = false)"
        >
          <treeselect
            style="background:white"
            class="is-medium-14-500-17"
            :placeholder="
              localization('app-select-location', 'Select location')
            "
            :always-open="true"
            :options="instances"
            :normalizer="projects_normalizer"
            @input="location = $event"
            :value-format="'object'"
            :value="location"
            :value-consists-of="'LEAF_PRIORITY'"
          ></treeselect>
        </div>
        <div v-else>
          <div v-if="is_location_loading" class="pb-20">
            <b-skeleton
              width="60%"
              height="2rem"
              :animated="animated"
            ></b-skeleton>
          </div>
          <div class="is-flex pb-20" v-else-if="nameArray && nameArray.length">
            <sh-icon
              v-if="
                elementData &&
                  ['file', 'folder', 'vault'].includes(elementData.type)
              "
              :file="'vault_sprite'"
              :name="'add-folder'"
              class="image is-pointer mr-5 is-20x20"
            />
            <div class="is-flex is-flex-wrap">
              <div
                v-for="(name, i) in nameArray"
                :key="i"
                class="is-flex is-vcenter"
              >
                <span
                  class="is-medium-14-500-17 no-whitespace-wrap"
                  v-tooltip="{
                    content: name,
                    html: false
                  }"
                  :class="[
                    name == '*Not found'
                      ? 'has-text-light-grey'
                      : 'has-text-theme-black'
                  ]"
                  >{{ name | truncate(15) }}</span
                >
                <p v-if="i != nameArray.length - 1">
                  <sh-icon
                    :name="'collapse'"
                    class="is-16x16 mx-5"
                    style="transform: rotate(-90deg)"
                  ></sh-icon>
                </p>
              </div>
            </div>
          </div>
          <div class="is-flex is-vcenter is-pointer" v-else>
            <sh-icon :name="'add-location'" class="mr-7 is-30x30" />
            <span
              class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
              >{{
                localization("app-location-not-found", "Location not found")
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { assetService, apiService } from "@/app/old/desktop/shared/services/";
  import Treeselect from "@riophae/vue-treeselect";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  import AssociatedAssetDropdown from "@/app/old/desktop/shared/components/asset-selection-dropdown.vue";
  import elementMixin from "@/app/old/desktop/shared/mixins/element.mixin";

  export default {
    mixins: [elementMixin],

    props: {
      ticket: {
        type: Object,
        required: false
      }
    },
    components: {
      PrimaryTags,
      AssociatedAssetDropdown,
      Treeselect
    },
    data() {
      return {
        nameArray: [],
        elementData: null,
        location: null,
        edit_location: false,
        is_location_loading: false,
        is_asset_loading: false,
        edit_asset: false,
        selected_asset: null,
        instances: []
      };
    },
    created() {
      this.get_element(this.ticket.targetElement);
    },
    computed: {
      ...mapState(["assets"]),

      element_asset() {
        if (this.elementData) {
          if (this.elementData.name) {
            return this.elementData;
          } else {
            return _.find(this.assets, o => {
              return o.uid == this.elementData.uid;
            });
          }
        }
      }
    },

    methods: {
      async get_element(targetElement) {
        this.is_asset_loading = true;
        this.is_location_loading = true;
        this.nameArray = [];
        let res = await apiService
          .get_element_hierarchy({
            id: targetElement.uid,
            stage: targetElement.stage
          })
          .catch(err => {
            console.log(err);
          });
        this.elementData = res;
        this.elementData = Object.assign({}, this.elementData);
        this.is_asset_loading = false;
        if (this.elementData) this.getName(this.elementData);
        this.is_location_loading = false;
      },
      getName(dataObject) {
        if (dataObject[`name`]) {
          this.nameArray.push(dataObject[`name`]);
        }
        if (dataObject[`child`] && dataObject[`child`].uid)
          return this.getName(dataObject[`child`]);
        return null;
      },
      projects_normalizer(node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.children
        };
      },
      async editLocation() {
        this.is_location_loading = true;
        let { data } = await apiService.get_all_instances({
          asset_uid: this.elementData.uid,
          stage: "system_model"
        });
        this.instances = data.instances;

        this.edit_location = true;
        this.is_location_loading = false;
      },
      async updateAsset() {
        this.is_asset_loading = true;

        let asset_details = await assetService
          .get({ id: this.selected_asset })
          .catch(err => {
            this.handleErrors(err);
          });

        let targetElement;
        if (_.isString(asset_details.element)) {
          targetElement = await this.create_or_get_element(
            asset_details.element
          );
        } else {
          targetElement = this.get_target_element(asset_details.element);
        }
        this.update({
          targetElement: targetElement
        });
        this.edit_asset = false;
        this.get_element(targetElement);
        this.is_asset_loading = false;
      },
      async removeAsset() {
        this.is_asset_loading = true;

        let targetElement = await this.get_org_element();
        this.update({
          targetElement: targetElement
        });
        this.get_element(targetElement);
        this.is_asset_loading = false;
      },
      async removeLocation() {
        this.is_location_loading = true;
        let targetElement;
        if (this.$store.state.tickets.selected_asset.length) {
          let asset_details = await assetService
            .get({ id: this.$store.state.tickets.selected_asset })
            .catch(err => {
              this.handleErrors(err);
            });
          if (_.isString(asset_details.element)) {
            targetElement = await this.create_or_get_element(
              asset_details.element
            );
          } else {
            targetElement = this.get_target_element(asset_details.element);
          }
        } else {
          targetElement = await this.get_org_element();
        }
        this.update({
          targetElement: targetElement
        });
        this.edit_asset = false;
        this.get_element(targetElement);

        this.is_location_loading = false;
      },
      async updateLocation() {
        this.is_location_loading = true;
        let { data } = await apiService.get_element_for_instance({
          instance_uid: this.location.uid,
          stage: "system_model"
        });
        this.update({
          targetElement: data.element
        });

        this.get_element(data.element);
        this.edit_location = false;
        this.is_location_loading = false;
      },
      update(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
      }
    }
  };
</script>

<style lang="scss">
  .element_actions {
    &_buttons {
      visibility: hidden;
    }
    &:hover {
      .element_actions_buttons {
        visibility: visible;
      }
    }
  }
</style>
