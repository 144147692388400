<script>
  export default {
    data() {
      return {
        browser_back_modal_close: true
      };
    },
    created() {
      if (this.browser_back_modal_close) {
        const unregisterRouterGuard = this.$router.beforeEach(
          (to, from, next) => {
            if (this.back) this.back();
            this.$emit("close");
            next(false);
          }
        );

        this.$once("hook:destroyed", () => {
          unregisterRouterGuard();
        });
      }
    }
  };
</script>
