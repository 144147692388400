var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-attachment"},[_c('div',{staticClass:"task-list-attachments--top"},[_c('div',{staticClass:"attachments-top-section is-flex align-center has-space-between",class:{ 'my-20': _vm.component == 'ticketDetails' && _vm.selected.length },style:(("position:relative;z-index:1;right: 0;padding: 0;" + (_vm.component != 'ticketDetails' ? 'height:36px;top:15px' : 'top:0')))},[_c('div'),_c('div',{staticClass:"attachments-top-section__right is-flex align-center"},[_c('attachment-bulk-actions',{attrs:{"existing_files":_vm.existing_files,"ticket":_vm.ticket,"checklist":_vm.checklist,"selected":_vm.selected,"deselectUid":_vm.task.uid,"component":_vm.component},on:{"refresh_attachments":_vm.get_attachments}})],1)])]),(_vm.is_loading)?_c('div',[(_vm.$route.meta.screen !== 'mobile')?_c('div',_vm._l((2),function(i){return _c('div',{key:i,staticClass:"columns"},_vm._l((4),function(i){return _c('attachment-skeleton',{key:i,staticClass:"column is-3"})}),1)}),0):_c('div',_vm._l((2),function(i){return _c('div',{key:i,staticClass:"columns is-flex",staticStyle:{"flex-wrap":"none"}},_vm._l((3),function(i){return _c('attachment-skeleton',{key:i,staticClass:"column is-4",staticStyle:{"width":"33.33%","flex":"none"}})}),1)}),0)]):(
      _vm.showUploader ||
        !(
          (_vm.existing_files && Object.keys(_vm.existing_files).length) ||
          (_vm.offlineAttachments && Object.keys(_vm.offlineAttachments).length)
        )
    )?_c('upload-file',{staticClass:"padding-15",attrs:{"service":"tickets","name":_vm.ticket.name,"type":"ticket","offlineData":{ checklist_id: _vm.checklist.uid, item_id: _vm.task.uid },"uploadMethod":_vm.uploadMethod,"uid":_vm.ticket.uid,"id":("ticket_attachments_" + (_vm.task.uid))},on:{"uploaded_success":_vm.upload_completed,"uploaded-files-details":function (attachments) {
        _vm.track_file_uploads(attachments, null, 'checklist_item');
      },"close-uppy":function($event){_vm.getOfflineData();
      _vm.$emit('update_checklist', $event);}}}):_c('div',[(_vm.existing_files && Object.keys(_vm.existing_files).length)?_c('attachment-list',{staticClass:"task-list-tab has-slimscroll-xs",attrs:{"id":"task-tab","service":_vm.service,"component":_vm.component,"deselectUid":_vm.task.uid,"existing_files":_vm.existing_files,"ticket":_vm.ticket,"template":{
        isTemplate: _vm.checklist.isTemplate,
        checklist_id: _vm.checklist.uid,
        task_id: _vm.task.uid
      }},on:{"delete_attachment":function($event){return _vm.delete_attachment($event)},"selection_change":function($event){_vm.selected = $event},"refresh_attachments":_vm.get_attachments}}):_vm._e(),(_vm.offlineAttachments && _vm.offlineAttachments.length)?_c('attachment-offline',{attrs:{"offlineAttachments":_vm.offlineAttachments},on:{"deleteOfflineAttachment":_vm.deleteOfflineAttachment}}):_vm._e()],1),(_vm.selected.length)?_c('attachment-bulk-actions',{staticClass:"attachment-mobile-actions",attrs:{"existing_files":_vm.existing_files,"ticket":_vm.ticket,"checklist":_vm.checklist,"deselectUid":_vm.task.uid,"selected":_vm.selected},on:{"refresh_attachments":_vm.get_attachments}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }