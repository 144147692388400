<template>
  <div v-click-outside="() => (is_dropdown = false)">
    <!-- <div class="ebs-cluster">
      <div class="dropdown is-right is-active">
        <div
          class="dropdown-trigger"
          @click="trigger_dropdown()"
          v-tooltip="{
            content: localization('app-display-tickets', 'Display Tickets'),
            placement: 'top-center',
          }"
        >
          <a aria-haspopup="true" aria-controls="dropdown-menu">
            <img
              v-if="selected.length"
              class="cluster-dd-trigger"
              src="@/assets/icons/cluster-active.svg"
            />
            <img
              v-else
              class="cluster-dd-trigger"
              src="@/assets/icons/cluster-inactive.svg"
            />
          </a>
        </div>
        <div
          v-if="is_dropdown"
          class="dropdown-menu"
          id="dropdown-menu"
          role="menu"
        >
          <div class="options-list" v-if="!active_settings">
            <div class="cluster-dropdown-heading">
              <span class="cluster-dropdown-heading-show"> Show </span>
              <span
                @click="clear_cluster()"
                class="cluster-dropdown-heading-clear-all is-pointer"
                >Clear All</span
              >
            </div>
            <div class="cluster-dropdown-options is-flex mb-10">
              <div class="">
                <input
                  v-model="selected"
                  :value="'tickets'"
                  class="is-checkradio is-info is-small"
                  type="checkbox"
                  id="tickets"
                />
                <label
                  for="tickets"
                  style="font-size: 16px; font-weight: 600; color: #171e48"
                >
                  Tickets
                </label>
              </div>

              <img
                @click="active_settings = 'tickets'"
                class="mr-10 is-pointer is-pulled-right"
                src="@/assets/cluster-setting.svg"
              />
            </div>
            <div
              v-if="$store.getters.forms"
              class="cluster-dropdown-options is-flex mt-10"
            >
              <div class="">
                <input
                  v-model="selected"
                  :value="'forms'"
                  class="is-checkradio is-info is-small"
                  type="checkbox"
                  id="forms"
                />
                <label
                  for="forms"
                  style="font-size: 16px; font-weight: 600; color: #171e48"
                >
                  Forms
                </label>
              </div>

              <img
                  @click="active_settings = 'forms'"
                  class="mr-10 is-pointer"
                  src="@/assets/cluster-setting.svg"
                />
            </div>
            <div class="cluster-dropdown-options is-flex mt-10">
              <b-checkbox
                v-model="selected"
                native-value="vault"
                type="is-info"
                style="font-weight: 600; color: #171e48"
                >Vault</b-checkbox
              >
              <img
                  @click="active_settings = 'vault'"
                  class="mr-10 is-pointer"
                  src="@/assets/cluster-setting.svg"
                />
            </div>
          </div>
          <div
            class="settings"
            style="margin-top: 1rem"
            v-if="active_settings == 'tickets'"
          >
            <div
              class="settings-heading is-flex"
              style="justify-content: space-between; margin-bottom: 1rem"
            >
              <div class="is-flex" style="font-weight: 600; color: #171e48">
                <img
                  class="is-pointer"
                  style="margin-right: 10px"
                  src="@/assets/back-arrow.svg"
                  @click="active_settings = ''"
                />
                Tickets
              </div>
              <img
                @click="active_settings = ''"
                src="@/assets/icons/close.svg"
                class="is-pulled-right is-pointer"
              />
            </div>
            <div class="settings-content">
              <div>
                <p class="settings-filter-item-heading">Group by</p>
                <div class="block">
                  <b-radio
                    v-model="tickets_settings.group_by"
                    native-value="status"
                    type="is-info"
                  >
                    Status
                  </b-radio>
                  <b-radio
                    v-model="tickets_settings.group_by"
                    native-value="priority"
                    type="is-info"
                  >
                    Priority
                  </b-radio>
                </div>
              </div>
            </div>
          </div>
          <div
            class="settings"
            style="margin-top: 1rem"
            v-if="active_settings == 'vault'"
          >
            <div
              class="settings-heading is-flex"
              style="justify-content: space-between; margin-bottom: 1rem"
            >
              <div class="is-flex" style="font-weight: 600; color: #171e48">
                <img
                  class="is-pointer"
                  style="margin-right: 10px"
                  src="@/assets/back-arrow.svg"
                  @click="active_settings = ''"
                />
                Vault
              </div>
              <img
                @click="active_settings = ''"
                src="@/assets/icons/close.svg"
                class="is-pulled-right is-pointer"
              />
            </div>
            <div class="settings-content">
              <div>
                <p class="settings-filter-item-heading">Group by</p>
                <div class="block">
                  <b-radio
                    v-model="vault_settings.group_by"
                    native-value="bookmark"
                    type="is-info"
                  >
                    Bookmark
                  </b-radio>
                  <b-radio
                    v-model="vault_settings.group_by"
                    native-value="archive"
                    type="is-info"
                  >
                    Archive
                  </b-radio>
                </div>
              </div>
            </div>
          </div>
          <div
            class="settings"
            style="margin-top: 1rem"
            v-if="active_settings == 'forms'"
          >
            <div
              class="settings-heading is-flex"
              style="justify-content: space-between; margin-bottom: 1rem"
            >
              <div class="is-flex" style="font-weight: 600; color: #171e48">
                <img
                  class="is-pointer"
                  style="margin-right: 10px"
                  src="@/assets/back-arrow.svg"
                  @click="active_settings = ''"
                />
                Forms
              </div>
              <img
                @click="active_settings = ''"
                src="@/assets/icons/close.svg"
                class="is-pulled-right is-pointer"
              />
            </div>
            <div class="settings-content">
              <div>
                <p class="settings-filter-item-heading">Group by</p>
                <div class="block">
                  <b-radio
                    v-model="forms_settings.group_by"
                    native-value="open"
                    type="is-info"
                  >
                    Open
                  </b-radio>
                  <b-radio
                    v-model="forms_settings.group_by"
                    native-value="draft"
                    type="is-info"
                  >
                    Draft
                  </b-radio>
                </div>
              </div>
            </div>
          </div>
          <br />
          <b-button @click="load_cluster()" type="is-info" outlined
            >Apply</b-button
          >
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { mapGetters, mapState } from "vuex";
  import { DateTime } from "luxon";
  function prettydate(date) {
    if (!date) return "";
    let givenDate = new DateTime.fromISO(date);
    let today = new DateTime.local();
    if (today.diff(givenDate, "days").days < 7)
      return givenDate.toRelative({ base: today });
    return givenDate.toFormat("DDDD");
  }

  export default {
    data() {
      return {
        selected: ["tickets"],
        active_settings: "",
        tickets_settings: {
          group_by: "status"
        },
        vault_settings: {
          group_by: "status"
        },
        forms_settings: {
          group_by: "open"
        },
        tickets: null,
        folders: null,
        forms: null,
        //
        is_dropdown: false,
        priorities: {
          1: "Critical",
          2: "High",
          3: "Medium",
          4: "Low"
        },
        statuses: {
          1: "pending",
          2: "in progress",
          3: "resolved",
          4: "closed",
          5: "rejected"
        }
      };
    },
    created() {
      this.load_cluster();
    },

    computed: {
      ...mapState("thermv2", ["map"]),
      ...mapGetters("thermv2", ["project_defects"])
    },
    watch: {
      map: {
        handler(val) {
          if (this.map)
            this.map.on("style.load", () => {
              this.load_images();

              if (this.map.getSource("clusters_source"))
                this.map.removeSource("clusters_source");
              if (this.active_projects && this.project_defects.length)
                this.load_cluster();
            });

          var popup = new mapboxgl.Popup({
            closeButton: false,
            className: "ticket-cluster-popup",
            offset: 0
          });

          document.addEventListener(
            "click",
            event => {
              // If the clicked element doesn't have the right selector, bail
              if (!event.target.matches(".ticket-cluster-popup-action")) return;

              // Don't follow the link
              event.preventDefault();

              // Log the clicked element in the console
              this.open_ticket_details(
                event.target.getAttribute("data-ticket")
              );
            },
            false
          );
          if (this.map)
            this.map.on("mouseenter", "clusters_label", e => {
              // Change the cursor style as a UI indicator.
              // this.map.getCanvas().style.cursor = "pointer";
              // var coordinates = e.features[0].geometry.coordinates.slice();
              // e.features[0].properties.folder;
              // let description;
              // if (e.features[0].properties.ticket) {
              //   description = this.get_popup_html_ticket(
              //     JSON.parse(e.features[0].properties.ticket)
              //   );
              // } else if (e.features[0].properties.folder) {
              //   description = this.get_popup_html_folder(
              //     JSON.parse(e.features[0].properties.folder)
              //   );
              // } else if (e.features[0].properties.form) {
              //   description = this.get_popup_html_form(
              //     JSON.parse(e.features[0].properties.form)
              //   );
              // }
              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              // //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              // }
              // Populate the popup and set its coordinates
              // based on the feature found.
              // popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
            });
          this.load_images();
        },
        immediate: true
      },
      project_defects() {
        if (this.map.getLayer("clusters_label")) {
          this.map.removeLayer("clusters_label");
          this.map.off("click", "clusters_label");
        }
        if (this.map.getSource("clusters_source"))
          this.map.removeSource("clusters_source");
        if (this.project_defects.length) this.load_cluster();
      }
    },
    methods: {
      load_images() {
        if (!this.map) return;
        // Vault
        this.map.loadImage("/img/icons/vault/folder.png", (error, image) => {
          if (error) throw error;
          this.map.addImage("folder", image);
        });

        // forms images
        this.map.loadImage(
          "/img/icons/forms/drafts-form.png",
          (error, image) => {
            if (error) throw error;
            this.map.addImage("drafts-form", image);
          }
        );
        this.map.loadImage("/img/icons/forms/open-form.png", (error, image) => {
          if (error) throw error;
          this.map.addImage("open-form", image);
        });

        // Tickets
        for (let i = 1; i <= 4; i++) {
          this.map.loadImage(
            "/img/icons/priority/" + i + ".png",
            (error, image) => {
              if (error) throw error;
              this.map.addImage("priority" + i, image);
            }
          );
        }
        for (let i = 1; i <= 5; i++) {
          this.map.loadImage(
            "/img/icons/status/" + i + ".png",
            (error, image) => {
              if (error) throw error;
              this.map.addImage("status" + i, image);
            }
          );
        }
      },
      trigger_dropdown() {
        this.is_dropdown = true;
        if (!this.selected.length) {
          this.selected = ["tickets"];
          if (this.$store.getters.forms) {
            this.selected = ["tickets", "forms"];
          }
        }
        this.load_cluster();
      },
      // On hover popup
      get_popup_html_ticket(ticket) {
        return (
          `<div class="ticket-cluster-popup">` +
          `<div class="top">` +
          `<div class="top-status"><img src=".\/img\/icons\/status\/${ticket.status}.png" >` +
          `<p>${this.statuses[ticket.status]}</p>` +
          `</div>` +
          `<div class="top-priority">` +
          `<img src=".\/img\/icons\/priority\/${ticket.priority}.png">` +
          `<p>${this.priorities[ticket.priority]}</p>` +
          `</div>` +
          `</div>` +
          `<div class="middle">${ticket.name}</div>` +
          `<div class="bottom">` +
          `<div class="bottom-due-date"><img src=".\/img\/icons\/clock.svg"><span> ${prettydate(
            ticket.dueDate || ticket.createdAt
          )} </span> </div>` +
          `<a class="bottom-see-more"><span class="ticket-cluster-popup-action" data-ticket='${JSON.stringify(
            ticket
          )}' >See More</span><img class="ticket-cluster-popup-action" data-ticket="${ticket}" src=".\/img\/icons\/see-more.svg"></a>` +
          `</div>` +
          `</div>`
        );
      },
      get_popup_html_folder(item) {
        return (
          `<div class="ticket-cluster-popup">` +
          `<div class="top">` +
          `<div class="top-status"><img src=".\/img\/icons\/vault\/folder.png" >` +
          `</div>` +
          `<div class="top-priority">` +
          `</div>` +
          `</div>` +
          `<div class="middle">${item.name}</div>` +
          `<div class="bottom">` +
          `</div>` +
          `</div>`
        );
      },
      get_popup_html_form(item) {
        let icon = item.currentSubmission.length ? "open-form" : "drafts-form";
        return (
          `<div class="ticket-cluster-popup">` +
          `<div class="top">` +
          `<div class="top-status"><img src=".\/img\/icons\/forms\/${icon}.png" >` +
          `</div>` +
          `<div class="top-priority">` +
          `</div>` +
          `</div>` +
          `<div class="middle">${item.name}</div>` +
          `<div class="bottom">` +
          `</div>` +
          `</div>`
        );
      },
      //
      open_folder_details(folder) {
        folder = JSON.parse(folder);
        let feature = turf.feature(folder.location);
        feature.properties.element = folder.targetElement;
        feature.properties.is_folder_details = true;
        feature.properties.folder = folder;
        parent.postMessage(
          {
            name: "show_ebs_data",
            element: feature
          },
          "*"
        );
      },
      open_ticket_details(ticket) {
        if (ticket)
          this.$eventHub.$emit("open_ticket_details", JSON.parse(ticket));
      },
      open_form_details(form) {
        if (form)
          parent.postMessage(
            {
              name: "show_form_submission",
              form: JSON.parse(form)
            },
            "*"
          );
      },
      clear_cluster() {
        if (this.map.getLayer("clusters_label")) {
          this.map.removeLayer("clusters_label");
          this.map.off("click", "clusters_label");
        }
        if (this.map.getSource("clusters_source"))
          this.map.removeSource("clusters_source");
        this.is_dropdown = false;
      },
      async get_tickets_features(polygon) {
        try {
          if (!this.project_defects.length) return [];
          // else
          // this.tickets = (
          //   await this.apiService.get_tickets({
          //     query: `stage=terra&pageSize=10000000&polygon=${polygon}`,
          //   })
          // ).data;
          return (this.project_defects || []).map(ticket => {
            if (ticket.location) {
              let feature = turf.feature(ticket.location);
              feature.properties["status"] = ticket.status;
              feature.properties["priority"] = ticket.priority;
              feature.properties["icon"] =
                this.tickets_settings.group_by == "priority"
                  ? "priority" + ticket.priority
                  : "status" + ticket.status;

              feature.properties["ticket"] = ticket;
              return feature;
            }
            return turf.feature({
              type: "Point",
              coordinates: [0, 0],
              properties: {
                icon: "status" + t.status,
                ticket: {}
              }
            });
          });
        } catch (err) {
          console.log(err);
          return [];
        }
      },
      async get_folders_features(polygon) {
        try {
          if (!this.selected.includes("vault")) return [];
          // else
          // this.folders = (
          //   await this.apiService.get_folders({
          //     query: `polygon=${polygon}`,
          //   })
          // ).data;
          return (this.folders || []).map(folder => {
            if (folder.location) {
              let feature = turf.feature(folder.location);
              // TODO Change when icons available
              feature.properties["icon"] =
                this.vault_settings.group_by == "bookmark"
                  ? "folder"
                  : "folder";
              feature.properties["folder"] = folder;
              return feature;
            }
            return turf.feature({
              type: "Point",
              coordinates: [0, 0],
              properties: {
                icon: "folder",
                folder: {}
              }
            });
          });
        } catch (err) {
          console.log(err);
          return [];
        }
      },
      async get_forms_features(polygon) {
        try {
          if (!this.selected.includes("forms")) return [];
          // else
          // this.forms = (
          //   await this.apiService.get_forms({
          //     query: `polygon=${polygon}`,
          //   })
          // ).data.data;

          return (this.forms || []).map(form => {
            if (form.location) {
              let feature = turf.feature(form.location);
              feature.properties["icon"] = form.currentSubmission.length
                ? "open-form"
                : "drafts-form";
              feature.properties["form"] = form;
              return feature;
            }
            return turf.feature({
              type: "Point",
              coordinates: [0, 0],
              properties: {
                icon: "open-form",
                form: {}
              }
            });
          });
        } catch (err) {
          console.log(err);
          return [];
        }
      },
      load_cluster: _.debounce(async function() {
        try {
          // this.draw.delete("load_clusters");
          if (this.map.getLayer("clusters_label")) {
            this.map.removeLayer("clusters_label");
          }
          // let featuresBox = turf.bboxPolygon(turf.bbox(this.draw.getAll()));
          // let map_active_projects = Object.values(this.active_projects).filter(
          //   (project) => project.is_ortho_active == true
          // );

          // let map_bounds = [];
          // for (let project of map_active_projects) {
          //   if (project.meta && project.meta.bounds) {
          //     map_bounds.push(turf.bboxPolygon(project.meta.bounds));
          //   }
          // }

          // let queryBoundary = turf.union(featuresBox, ...map_bounds);

          // if (queryBoundary && this.$store.getters.debug) {
          //   queryBoundary.id = "load_clusters";
          //   this.draw.add(queryBoundary);
          // }

          // let polygon = btoa(JSON.stringify(queryBoundary.geometry));

          let features = await this.get_tickets_features();

          let featuresCollection = {
            type: "FeatureCollection",
            features
          };

          var map = this.map;

          // filters for classifying assets into five categories based on magnitude
          var case1 = [
            "all",
            ["==", ["get", this.tickets_settings.group_by], 1]
          ];
          var case2 = [
            "all",
            ["==", ["get", this.tickets_settings.group_by], 2]
          ];
          var case3 = [
            "all",
            ["==", ["get", this.tickets_settings.group_by], 3]
          ];
          var case4 = [
            "all",
            ["==", ["get", this.tickets_settings.group_by], 4]
          ];
          var case5 = [
            "all",
            ["==", ["get", this.tickets_settings.group_by], 5]
          ];
          // colors to use for the categories
          var colors =
            this.tickets_settings.group_by === "status"
              ? ["#f79c4b", "#08aa4f", "#5240b9", "#b9bbc7", "#f85859"]
              : ["#babbc8", "#5cab51", "#f5ae38", "#e95456"];

          // add a clustered GeoJSON source for a sample set of assets
          if (!map.getSource("clusters_source"))
            map.addSource("clusters_source", {
              type: "geojson",
              data: featuresCollection,
              cluster: this.$store.getters.tickets_clustering,
              clusterRadius: 80,
              clusterProperties: {
                // keep separate counts for each magnitude category in a cluster
                case1: ["+", ["case", case1, 1, 0]],
                case2: ["+", ["case", case2, 1, 0]],
                case3: ["+", ["case", case3, 1, 0]],
                case4: ["+", ["case", case4, 1, 0]],
                case5: ["+", ["case", case5, 1, 0]]
              }
            });
          else map.getSource("clusters_source").setData(featuresCollection);
          // circle and symbol layers for rendering individual assets (unclustered points)

          if (map.getLayer("clusters_label")) {
            map.removeLayer("clusters_label");
          }

          map.addLayer({
            id: "clusters_label",
            type: "symbol",
            source: "clusters_source",
            filter: ["!=", "cluster", true],
            layout: {
              "icon-image": ["get", "icon"],
              "icon-size": 1,
              "icon-allow-overlap": true
            }
          });

          let open_details = e => {
            if (e.features[0].properties.ticket)
              this.open_ticket_details(e.features[0].properties.ticket);
            if (e.features[0].properties.folder)
              this.open_folder_details(e.features[0].properties.folder);
            if (e.features[0].properties.form)
              this.open_form_details(e.features[0].properties.form);
          };
          if (map.getLayer("clusters_label"))
            map.off("click", "clusters_label", open_details);

          map.on("click", "clusters_label", open_details);
          // objects for caching and keeping track of HTML marker objects (for performance)
          var markers = {};
          var markersOnScreen = {};

          function updateMarkers() {
            var newMarkers = {};
            var features = map.querySourceFeatures("clusters_source");

            // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
            // and add it to the map if it's not there already
            for (var i = 0; i < features.length; i++) {
              var coords = features[i].geometry.coordinates;
              var props = features[i].properties;
              if (!props.cluster) continue;
              var id = props.cluster_id;

              var marker = markers[id];
              if (!marker) {
                var el = createDonutChart(props);
                marker = markers[id] = new mapboxgl.Marker({
                  element: el
                }).setLngLat(coords);
                // new mapboxgl.Popup()
                //   .setLngLat(coords)
                //   .setHTML(props.ticket.name)
                //   .addTo(map);
              }
              newMarkers[id] = marker;
              if (!markersOnScreen[id]) marker.addTo(map);
            }
            // for every marker we've added previously, remove those that are no longer visible
            for (id in markersOnScreen) {
              if (!newMarkers[id]) markersOnScreen[id].remove();
            }
            markersOnScreen = newMarkers;
          }
          // after the GeoJSON data is loaded, update markers on the screen and do so on every map move/moveend
          map.on("data", function(e) {
            if (e.sourceId !== "clusters_source" || !e.isSourceLoaded) return;

            map.on("move", updateMarkers);
            map.on("moveend", updateMarkers);
            updateMarkers();
          });

          // code for creating an SVG donut chart from feature properties
          function createDonutChart(props) {
            var offsets = [];
            var counts = [
              props.case1,
              props.case2,
              props.case3,
              props.case4,
              props.case5
            ];
            var total = 0;
            for (var i = 0; i < counts.length; i++) {
              offsets.push(total);
              total += counts[i];
            }
            var fontSize =
              total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
            var r =
              total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
            var r0 = Math.round(r * 0.6);
            var w = r * 2;

            var html =
              '<div><svg width="' +
              w +
              '" height="' +
              w +
              '" viewbox="0 0 ' +
              w +
              " " +
              w +
              '" text-anchor="middle" style="font: ' +
              fontSize +
              'px sans-serif">';

            for (i = 0; i < counts.length; i++) {
              html += donutSegment(
                offsets[i] / total,
                (offsets[i] + counts[i]) / total,
                r,
                r0,
                colors[i]
              );
            }
            html +=
              '<circle cx="' +
              r +
              '" cy="' +
              r +
              '" r="' +
              r0 +
              '" fill="white" /><text dominant-baseline="central" transform="translate(' +
              r +
              ", " +
              r +
              ')">' +
              total.toLocaleString() +
              "</text></svg></div>";
            var el = document.createElement("div");
            el.innerHTML = html;

            return el.firstChild;
          }

          function donutSegment(start, end, r, r0, color) {
            if (end - start === 1) end -= 0.00001;
            var a0 = 2 * Math.PI * (start - 0.25);
            var a1 = 2 * Math.PI * (end - 0.25);
            var x0 = Math.cos(a0),
              y0 = Math.sin(a0);
            var x1 = Math.cos(a1),
              y1 = Math.sin(a1);
            var largeArc = end - start > 0.5 ? 1 : 0;

            return [
              '<path d="M',
              r + r0 * x0,
              r + r0 * y0,
              "L",
              r + r * x0,
              r + r * y0,
              "A",
              r,
              r,
              0,
              largeArc,
              1,
              r + r * x1,
              r + r * y1,
              "L",
              r + r0 * x1,
              r + r0 * y1,
              "A",
              r0,
              r0,
              0,
              largeArc,
              0,
              r + r0 * x0,
              r + r0 * y0,
              '" fill="' + color + '" />'
            ].join(" ");
          }
        } catch (err) {
          console.log(err);
        }
      }, 500)
    }
  };
</script>

<style lang="scss">
  .ticket-cluster-popup {
    padding: 10px;
    &.mapboxgl-popup {
      min-width: 330px !important;
    }
    .top {
      margin-left: -7px;
      display: flex;
      &-status,
      &-priority {
        display: flex;
        justify-content: center;
        font-family: Roboto-SemiBold;
        font-size: 14px;
        color: #171e48;
        font-weight: 600;
        text-transform: capitalize;
      }
      &-status {
        img {
          height: 35px;
        }
        p {
          margin-top: 4px;
        }
      }
      &-priority {
        margin-left: 10px;
        margin-top: -2px;
        img {
          height: 43px;
        }
        p {
          margin-top: 8px;
          margin-left: -3px;
        }
      }
    }
    .middle {
      margin-top: 4px;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: Roboto-SemiBold;
      color: #171e48;
      font-weight: 600;
    }
    .bottom {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      &-due-date {
        display: flex;
        color: #fc5a5a;
        img {
          height: 19px;

          margin-right: 7px;
        }
      }
      &-see-more {
        color: #5c7ef7;
        img {
          height: 12px;
          margin-left: 7px;
        }
      }
    }
  }
  .ebs-cluster {
    position: fixed;
    right: 6.6rem;
    top: 2.4rem;
    z-index: 38;
    .dropdown-menu {
      border-radius: 8px;
      width: 277.7px;
      padding: 0 25px 31px 20px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafcff;
    }

    .is-checkradio label {
      user-select: none;
    }
    .is-checkradio[type="checkbox"].is-small + label::before,
    .is-checkradio[type="checkbox"].is-small + label:before {
      margin-top: 5px;
    }
    .is-checkradio[type="checkbox"].is-small + label::after,
    .is-checkradio[type="checkbox"].is-small + label:after {
      margin-top: 3px;
      top: 0.40375rem;
    }
    .cluster-dropdown-options {
      margin: 10px 0;
      justify-content: space-between;
    }
  }
  .cluster-dropdown-heading {
    margin: 8.5px 10px 30px 1px;
    font-family: Roboto-Regular;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    text-align: le ft;
    color: #171e48;
    &-show {
      opacity: 0.5;
    }
    &-clear-all {
      margin-left: 5px;
      font-weight: 600;
      color: #5c7ef7;
    }
  }
</style>
