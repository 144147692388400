var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-ticket"},[(!_vm.webviewer)?_c('router-view'):_vm._e(),(_vm.selected.length)?_c('bulk-actions',{attrs:{"selected":_vm.selected}}):_vm._e(),_c('div',{staticClass:"m-ticket__search-bar",class:{ 'is-hidden': _vm.hideSearchbar || _vm.isThermTicket }},[_c('search-bar',{attrs:{"placeholder_value":'Search tasks',"filter_option":_vm.filterOption},on:{"search":function($event){_vm.$store.commit('tickets/SET_SEARCH_BY', $event);
        _vm.segment_track_event('tickets_searched');},"filter":function($event){_vm.open_filter = true}}}),(_vm.open_filter)?_c('filter-dropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.open_filter = false); }),expression:"() => (open_filter = false)"}],class:{ 'viewer-ticket-filters': _vm.is_vertical_window },attrs:{"filtersData":_vm.allFiltersData},on:{"apply":function($event){_vm.selected_filters = $event;
        _vm.$emit('filter', $event);},"close":function($event){_vm.open_filter = false}}}):_vm._e()],1),(_vm.is_vertical_window && !_vm.isThermTicket)?_c('div',{staticClass:"viewer-filters is-flex is-vcenter has-space-between margin-20"},[_c('div',{staticClass:"each-filter"},[_c('SortDropdown')],1),_c('div',[_c('p',{staticClass:"is-pointer is-flex is-vcenter",on:{"click":function($event){_vm.open_filter = true}}},[_c('span',{staticClass:"is-medium-14-500-17 has-opacity-5 mr-5"},[_vm._v(" "+_vm._s(_vm.localization("app-filters", "Filters"))+": ")]),_c('span',{staticClass:"is-semiBold-14-600-17 is-pointer is-flex is-vcenter"},[_vm._v(" "+_vm._s(_vm.selected_filters ? "Selected" : "None")+" "),_c('sh-icon',{staticClass:"is-16x16 is-pointer",attrs:{"name":("" + (_vm.open_filter ? 'expand' : 'collapse'))}})],1)])])]):_vm._e(),_c('div',{staticClass:"m-ticket__ticketlist has-slimscroll-xs",class:_vm.isMobile ? 'calc_height' : 'notmobile'},[(_vm.is_vertical_window && !_vm.create_ticket && !_vm.isThermTicket)?_c('p',{staticClass:"is-flex is-start mr-auto",on:{"click":function($event){_vm.create_ticket = true}}},[_c('button',{staticClass:"sh-button sh-button--create sh-button--inverted sh-button--small",staticStyle:{"border":"none","box-shadow":"none"}},[_vm._v(" "+_vm._s(_vm.localization("app-new-ticket", "New Ticket"))+" ")])]):_vm._e(),(_vm.create_ticket)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.create_ticket = false); }),expression:"() => (create_ticket = false)"}],class:[
        { 'viewer-shadow': _vm.is_vertical_window },
        [_vm.customclass ? _vm.customclass : 'create-mobile-ticket']
      ]},[_c('create-new-ticket',{attrs:{"without_status":true,"ticket_created_from":_vm.is_vertical_window ? 'feature_ticket_list' : 'main_ticket_list',"element":_vm.is_vertical_window || _vm.isMobile ? _vm.element : null,"viewer_ticket":true},on:{"hide":function($event){_vm.create_ticket = false}}})],1):_vm._e(),(_vm.is_loading && (!_vm.filtered_tickets || !_vm.filtered_tickets.length))?_c('div',[_c('mobile-list-skeleton')],1):(_vm.filtered_tickets && _vm.filtered_tickets.length)?_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.prevent.left",value:(_vm.swipeLeftHandler),expression:"swipeLeftHandler",arg:"swipe",modifiers:{"prevent":true,"left":true}},{name:"touch",rawName:"v-touch:swipe.prevent.right",value:(_vm.swipeRightHandler),expression:"swipeRightHandler",arg:"swipe",modifiers:{"prevent":true,"right":true}}]},[_vm._l((_vm.filtered_tickets),function(ticket){return _c('div',{key:ticket.uid,staticClass:"m-ticket-list__item is-flex ",style:(_vm.isThermTicket ? { background: '#d8dde2' } : { background: '#fff' })},[_c('div',{staticClass:"margin-auto"},[(_vm.selected.length)?_c('input',{staticClass:"sh-custom-checkbox is-thinner rounded",attrs:{"type":"checkbox","name":"check","id":ticket.uid},domProps:{"checked":_vm.selected.includes(ticket.uid) ? true : false},on:{"change":function($event){return _vm.$store.dispatch('tickets/select_ticket', ticket.uid)}}}):_vm._e()]),_c('list-item',{staticClass:"ml-10",attrs:{"has_dark_theme":_vm.has_dark_theme,"ticket":ticket,"tags":_vm.tags
              ? (ticket.tags || []).map(function (t) { return (_vm.tags[t] ? _vm.tags[t] : null); })
              : null,"isThermTicket":_vm.isThermTicket}})],1)}),(_vm.is_loading)?_c('div',{staticClass:"loading"},[_c('img',{staticClass:"image is-64x64 margin-auto",attrs:{"src":require("@/assets/icons/ellipsis-animation.svg")}})]):(!_vm.is_fetched_all)?_c('div',{staticClass:"has-text-blue is-medium-16-500-19 has-text-centered py-20 is-pointer",on:{"click":_vm.load_more}},[_vm._v(" "+_vm._s(_vm.localization("app-load-more", "Load More"))+" ")]):_vm._e()],2):_c('div',{staticClass:"m-no-ticket"},[_vm._m(0),_c('div',{staticClass:"is-flex is-center mt-20 is-medium-16-500-19 has-primary-text"},[_vm._v(" "+_vm._s(_vm.localization("app-no-tickets", "No Tickets"))+" ")]),_c('div',{staticClass:"is-flex is-center mt-10 is-regular-12-00-18 has-primary-text has-opacity-8"},[_vm._v(" "+_vm._s(_vm.localization("app-no-results-found", "No results found"))+" ")])])]),(!_vm.create_ticket && _vm.isMobile && !_vm.is_vertical_window)?_c('div',{staticClass:"is-flex is-vcenter is-center",class:_vm.uploadbtnclass ? _vm.uploadbtnclass : 'attachment-mobile-uploadbtn',on:{"click":function($event){_vm.create_ticket = true}}},[_c('i',{staticClass:"fa fa-plus"})]):_vm._e(),(_vm.create_ticket)?_c('div',{staticClass:"mobile-background",on:{"click":function($event){$event.stopPropagation();_vm.create_ticket = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-no-ticket--img"},[_c('img',{attrs:{"src":require("@/assets/illustrations/tickets-illustration.svg"),"alt":""}})])}]

export { render, staticRenderFns }