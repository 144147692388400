<template>
  <div>
    <sh-icon
      :name="'left-arrow-slider'"
      :file="'helper_sprite'"
      @click.native="$emit('prev')"
      class="is-24x24 navigate_icon navigate_icon--left"
      :class="{ disabled: hasNavIcon.left }"
    ></sh-icon>
    <sh-icon
      :name="'right-arrow-slider'"
      :file="'helper_sprite'"
      @click.native="$emit('next')"
      class="is-24x24 navigate_icon navigate_icon--right"
      :class="{ disabled: hasNavIcon.right }"
    ></sh-icon>
  </div>
</template>

<script>
  export default {
    props: ['hasNavIcon']
  }
</script>

<style lang="scss" scoped>
  .navigate_icon {
    position: fixed;
    top: 50%;
    cursor: pointer;
    &--left {
      left: 15px;
    }
    &--right {
      right: 15px;
    }
    &.disabled {
      display: none;
    }
  }
</style>
