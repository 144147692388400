<template>
  <div
    class="is-sh-dropdown is-relative is-duedate"
    v-click-outside="() => (active = false)"
  >
    <div
      class=" is-close is-pointer  is-relative"
      v-tooltip="{
        content: tooltip
      }"
      :class="[
        { 'is-danger': is_overdue },
        { 'is-selector': !bulk_selection && !custom_text }
      ]"
      @click="
        active = !active;
        create_cal();
      "
    >
      <div v-if="due && !bulk_selection" class="is-flex is-vcenter">
        <p class="is-flex">
          <sh-icon
            :name="is_overdue ? 'due-date-danger' : 'due-date-dark'"
            class=" is-18x18 mr-5"
          ></sh-icon>
          <span
            :class="{ 'has-text-danger': is_overdue }"
            v-if="!bulk_selection"
            >{{ new Date(due).toISOString() | sinceDays }}</span
          >
        </p>
        <div
          class="is-pointer ml-10 close-mark is-24x24 is-flex align-center is-center"
          @click="change_duedate(null)"
        >
          <sh-icon :name="'ticket-priority-close'" class="is-8x8" />
        </div>
      </div>
      <div class="is-flex is-vcenter is-pointer" v-else>
        <sh-icon
          :name="icon ? icon : 'select-date'"
          :class="[icon_class ? icon_class : 'is-24x24 mr-7']"
        />
        <span
          :class="{ ' has-opacity-5': !custom_text }"
          class=" has-text-theme-black "
          v-if="!bulk_selection"
          >{{
            custom_text
              ? localization(
                  `app-${custom_text
                    .split(" ")
                    .join("-")
                    .toLowerCase()}`,
                  custom_text
                )
              : localization("app-select-date", "Select Date")
          }}</span
        >
      </div>
    </div>
    <div
      class="mobile-background-new"
      v-if="active && isMobile"
      @click="active = !active"
    ></div>
    <div
      :style="[is_bulk ? { left: '0rem', 'z-index': 0 } : null]"
      :class="[
        {
          'is-hidden':
            !active ||
            !`calendar_container${ticket ? ticket.uid : 'new'}${
              in_details ? '-details' : null
            }`
        },
        [isMobile ? 'mobile-cal-container' : 'dueDate-calendar']
      ]"
    >
      <div
        :id="
          `calendar_container${
            bulk_selection
              ? 'bulk_calendar_container'
              : ticket
              ? ticket.uid
              : 'new'
          }${in_details ? '-details' : null}`
        "
      ></div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from "luxon";
  import { mapState } from "vuex";

  export default {
    props: [
      "ticket",
      "due",
      "bulk_selection",
      "icon",
      "icon_class",
      "in_details",
      "is_therm",
      "is_bulk",
      "custom_text"
    ],
    data() {
      return {
        active: false,
        calendar: null
      };
    },
    beforeDestroy() {
      this.calendar = null;
    },

    computed: {
      ...mapState(["language"]),
      tooltip() {
        return this.due
          ? moment(new Date(this.due)).format(" Do MMM YYYY, h:mm:ss a")
          : this.localization("app-set-a-due-date", "Set a duedate");
      },
      is_overdue() {
        let givenDate = new DateTime.fromISO(this.due);
        let today = new DateTime.local();
        let difference = Math.floor(givenDate.diff(today, "days").days);
        return difference < 0;
      }
    },
    methods: {
      create_cal() {
        let es = {
          // short names of months
          monthsShort: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
          ],

          // full names of months
          months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ],
          // short names of days
          daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
          // full names of days
          days: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
          ]
        };

        let ja = {
          // short names of months
          monthsShort: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          // full names of months
          months: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          // short names of days
          daysShort: ["日", "月", "火", "水", "木", "金", "土"],
          // full names of days
          days: [
            "日曜日",
            "月曜日",
            "火曜日",
            "水曜日",
            "木曜日",
            "金曜日",
            "土曜日"
          ]
        };

        if (!this.calendar) {
          if (this.language == "ja") {
            dhx.i18n.setLocale("calendar", ja);
          } else if (this.language == "es") {
            dhx.i18n.setLocale("calendar", es);
          }
          this.calendar = new dhx.Calendar(
            `calendar_container${
              this.bulk_selection
                ? "bulk_calendar_container"
                : this.ticket
                ? this.ticket.uid
                : "new"
            }${this.in_details ? "-details" : null}`,
            {
              css: this.isMobile
                ? "for-mobile"
                : this.is_therm
                ? "therm-cal"
                : this.is_bulk
                ? "bulk-cal"
                : "tickets-cal",
              timePicker: this.isMobile ? false : true,
              value: this.due ? new Date(this.due) : new Date()
            }
          );
        }
        this.calendar.events.on("Change", date => {
          this.change_duedate(date);
        });
      },
      change_duedate(date) {
        this.$emit("change-duedate", date);
        this.active = false;
      }
    }
  };
</script>
<style lang="scss">
  .mobile-cal-container {
    position: fixed;
    z-index: 1000;
  }

  .for-mobile {
    width: 100vw !important;
    position: fixed;
    right: 0;
    bottom: 0;
    top: auto;
  }
  .tickets-cal {
    background-color: $white;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 0.5rem;
    position: relative;
  }
  .therm-cal {
    background-color: $white;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 0.5rem;
    position: relative;
    top: 2rem;
    right: 15rem;
    background: white;
    z-index: 1;
  }

  .bulk-cal {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 5px 1px #ddd;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 0.5rem;
    position: relative;
    top: 0rem;
    right: 27rem;
    background: white;
    z-index: 1;
  }
  .dhx_calendar-day--selected::before {
    background-color: $primary;
    &.dhx_calendar-day--weekend {
      color: #fff;
    }
  }
  .dhx_calendar-day--selected {
    &.dhx_calendar-day--weekend {
      color: #fff !important;
    }
  }
  .dhx_calendar-day--weekend {
    color: $primary;
  }
  .dhx_calendar-day {
    padding: 0.5rem;
  }
  .is-sh-dropdown {
    &.is-duedate {
      width: max-content;
      .close-mark {
        visibility: hidden;
      }
      &:hover {
        .close-mark {
          visibility: visible;
        }
      }
    }
  }
  .duedate-dropdown {
    position: relative;
    left: -1.2rem;
    .for-mobile {
      width: 100vw !important;
      position: fixed;
      right: 0;
      bottom: 0;
      top: auto;
    }
    .tickets-cal {
      position: absolute;

      top: 20px;
    }
    .is-selector {
      padding: 1rem;
      @include for-size(portrait-tablets) {
        padding: 0.5rem;
      }
    }
  }
</style>
