<template>
  <div
    class="is-sh-dropdown is-priority"
    v-click-outside="() => (active = false)"
  >
    <div class="is-pointer" @click="active = !active">
      <div
        v-if="is_multi_select"
        class="is-medium-14-500-17 has-primary-text is-flex is-vcenter"
      >
        <div v-if="priority && priority.length" class="is-flex is-vcenter">
          <sh-icon
            :name="'priority-' + `${priority_value ? priority_value : 1}`"
            class="is-pointer is-18x18 mr-5"
            v-for="priority_value in priority"
            :key="priority_value"
          ></sh-icon>
          <sh-icon
            :file="'terra_sprite'"
            :name="'terra-filter-add-priority'"
            class="is-20x20 ml-5"
          ></sh-icon>
        </div>

        <p v-else class="is-flex is-vcenter">
          <sh-icon
            :file="'terra_sprite'"
            :name="'terra-filter-add-priority'"
            class="is-20x20 mr-5"
          ></sh-icon>
          <span class="no-whitespace-wrap">{{
            localization("app-select-priority", "Select priority")
          }}</span>
        </p>
      </div>

      <sh-icon
        v-else-if="!priority"
        :name="'priority'"
        class="is-20x20"
        v-tooltip="{
          content: localization('app-add-priority', 'Add Priority')
        }"
      ></sh-icon>

      <div
        v-else
        class="is-selector "
        :class="[
          [ticket && !ticket.writeAccess ? 'has-no-access' : null],
          [active ? 'is-open' : 'is-close']
        ]"
      >
        <p class="is-flex align-center">
          <sh-icon
            :name="`priority-${priority ? priority : 4}`"
            class="is-20x20 mr-5"
            v-tooltip="{
              content: priority
                ? localization(
                    `app-${priorities[priority]
                      .split(' ')
                      .join('-')
                      .toLowerCase()}`,
                    priorities[priority]
                  )
                : localization('app-add-priority', 'Add Priority')
            }"
          ></sh-icon>
          <span>{{
            localization(
              `app-${priorities[priority]
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              priorities[priority]
            )
          }}</span>
        </p>
      </div>
    </div>
    <div
      :class="{ 'mobile-background-new': isMobile }"
      v-if="active"
      @click="active = !active"
    ></div>
    <ul
      class="is-options-list"
      :class="{ 'mobile-view-option': isMobile }"
      v-if="active"
    >
      <li class="item mobile-header">
        <p class="is-medium-16-500-19">
          {{ localization("app-priority", "Priority") }}
        </p>
        <p @click="active = false">
          <sh-icon :name="'close'" class="is-12x12" v-if="active"></sh-icon>
        </p>
      </li>
      <li
        class="item is-flex is-between "
        @click.stop="change_priority(i)"
        v-for="(priority_option, i) in priorities"
        :key="priority_option"
      >
        <p class="item--text is-capitalized is-flex">
          <sh-icon
            :name="'priority-' + i"
            class="is-20x20 item--icon"
          ></sh-icon>
          <span>{{
            localization(
              `app-${priority_option
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              priority_option
            )
          }}</span>
        </p>
        <span class="ml-10">
          <sh-icon
            :name="'checkmark'"
            class="is-12x12 item--icon"
            v-if="!is_multi_select ? i == priority : selected.includes(i)"
          ></sh-icon>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ["priority", "is_multi_select"],
    data() {
      return {
        active: false,
        selected: this.priority || [],
        priorities: {
          1: "Critical",
          2: "High",
          3: "Medium",
          4: "Low"
        }
      };
    },

    methods: {
      change_priority(val) {
        if (this.is_multi_select) {
          if (this.selected.includes(val)) {
            _.remove(this.selected, el => el == val);
            this.selected = Object.assign([], this.selected);
          } else {
            this.selected.push(val);
          }
          this.$emit("input", this.selected);
        } else {
          this.$emit("change_ticket_priority", val);
          this.active = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .is-priority {
    .is-options-list {
      width: 14rem;
    }
    .is-open {
      width: 14rem;
    }
  }
</style>
