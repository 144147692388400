<template>
  <div>
    <p class="searchbar control has-icons-left">
      <input
        v-focus
        @keyup="search"
        v-model="search_keyword"
        class="searchbar__input input is-regular-16-00-20"
        :placeholder="localization('app-search', 'Search')"
        autocomplete="off"
      />
      <span class="icon is-large is-left">
        <sh-icon
          :name="'search'"
          :file="'helper_sprite'"
          class="is-20x20"
        ></sh-icon>
      </span>
    </p>
  </div>
</template>
<script>
  import SearchbarMixin from "@/app/old/desktop/core/shared/components/searchbar.mixin.vue";
  export default {
    mixins: [SearchbarMixin],
    props: ["searchText"],
    watch: {
      searchText() {
        if (this.searchText && this.searchText.length) {
          this.search_keyword = this.searchText;
        } else {
          this.search_keyword = "";
        }
      }
    }
  };
</script>
<style lang="scss">
  .searchbar {
    width: 38rem;
    height: 4rem;
    &__input {
      height: 4.4rem;
      border: none;
      border-radius: 4px;
      padding-left: 4rem;
      box-shadow: 0px 1px 4px #0000001a;
      &::placeholder {
        color: #5b607e;
      }
      &:focus {
        box-shadow: 2px 2px 4px 0px rgba(23, 30, 72, 0.164);
      }
    }
  }
</style>
<style lang="scss" scoped>
  .control.has-icons-left .icon.is-left {
    height: 4.4rem;
  }
</style>
