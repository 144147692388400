var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"therm-sidebar has-slimscroll-xs is-paddingless",class:{ 'therm-sidebar--mini': _vm.mini_thermsidebar },style:({ width: (_vm.sidebar_width + "rem") })},[(!_vm.mini_thermsidebar)?_c('div',{staticClass:"therm-sidebar__details"},[_c('div',{staticClass:"therm-sidebar__asset-name is-regular-12-00-18"},[_vm._v(" "+_vm._s(_vm.asset_name)+" ")]),(_vm.container && _vm.container.length)?_c('p',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.container[0].viewName)+" ")]):_vm._e()]):_c('div',{staticClass:"therm-sidebar__details"},[_c('span',{staticClass:"is-regular-12-00-18"},[_vm._v(" "+_vm._s(_vm.asset_name)+" ")]),_c('i',{staticClass:"fas fa-chevron-right",staticStyle:{"font-size":"1rem","color":"grey","padding":"0 10px"}}),(_vm.container && _vm.container.length)?_c('span',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.container[0].viewName)+" ")]):_vm._e()]),(!_vm.mini_thermsidebar)?_c('hr',{staticClass:"divider is-marginless has-opacity-4"}):_vm._e(),_c('projects-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'therm-v2-compare'),expression:"$route.name !== 'therm-v2-compare'"}],attrs:{"custom_styles_for_groups":_vm.$route.name == 'therm-v2-reports' || _vm.$route.name == 'therm-v2-tickets'
        ? "right: 4rem; top: 1rem; bottom: initial"
        : ("left: " + (_vm.sidebar_width + 2) + "rem"),"custom_styles_for_projects":_vm.$route.name == 'therm-v2-reports' || _vm.$route.name == 'therm-v2-tickets'
        ? ("left: " + (_vm.sidebar_width + 2) + "rem;top:-6.1rem;")
        : ("left: " + (_vm.sidebar_width + 2) + "rem;top:-5rem")},on:{"projects-selected":_vm.set_active_projects,"projects-unselected":_vm.set_inactive_projects}}),(!_vm.mini_thermsidebar && false)?_c('img',{staticClass:"mainlogo image",attrs:{"src":require("@/assets/icons/thermv2/logo.svg")}}):(_vm.mini_thermsidebar && false)?_c('div',{staticClass:"image is-32x32 logo-mini image"},[_c('img',{staticClass:"is-marginless",attrs:{"src":require("@/assets/logos/sensehawk-logo-small.svg"),"alt":""}})]):_vm._e(),(false)?_c('hr',{staticClass:"divider is-marginless has-opacity-1"}):_vm._e(),_c('i',{staticClass:"fas is-24x24 toggle-thermsidebar is-pointer",class:[
      _vm.mini_thermsidebar
        ? ['fa-chevron-right', 'chevron-active']
        : 'fa-chevron-left'
    ],style:([_vm.mini_thermsidebar ? { left: '3.2rem' } : { left: '18.6rem' }]),on:{"click":function($event){return _vm.SET_MINI_THERMSIDEBAR(!_vm.mini_thermsidebar)}}}),(!_vm.mini_thermsidebar)?_c('div',{staticClass:"has-slimscroll-xs",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"mt-5"},_vm._l((_vm.navigation_headers),function(nav){return _c('router-link',{key:nav.header,staticClass:"therm-sidebar__item is-flex align-center is-pointer",class:{
          'is-active-sidebar-item': _vm.handle_routes(nav.header),
          'pl-10': !_vm.mini_thermsidebar,
          'is-center justify-center': _vm.mini_thermsidebar
        },attrs:{"tag":"div","to":nav.to},nativeOn:{"click":function($event){_vm.is_intercom_active = false}}},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.mini_thermsidebar ? nav.header : null,
            classes: ['tooltip'],
            placement: 'right-end',
            offset: '12px'
          }),expression:"{\n            content: mini_thermsidebar ? nav.header : null,\n            classes: ['tooltip'],\n            placement: 'right-end',\n            offset: '12px'\n          }"}],staticClass:"therm-sidebar__item--icon"},[_c('sh-icon',{staticClass:"icon is-20x20",attrs:{"name":_vm.handle_routes(nav.header) ? nav.sprite + '-active' : nav.sprite,"file":'thermv2_sprite'}})],1),(!_vm.mini_thermsidebar)?_c('p',{staticClass:"therm-sidebar__item--text is-medium-15-500-18 pl-10",class:{ 'has-text-white': _vm.handle_routes(nav.header) }},[_vm._v(" "+_vm._s(_vm.localization( ("app-" + (nav.header .split(" ") .join("-") .toLowerCase())), nav.header ))+" ")]):_vm._e()])}),1),_c('div',{staticClass:"filters-section mt-10"},[_c('div',{staticClass:"is-relative"},[_c('div',{staticClass:"is-pointer is-medium-15-500-18 therm-sidebar__item--text has-full-width is-flex align-center my-15 pl-5",class:{ 'is-disabled': _vm.$route.name === 'therm-v2-compare' },on:{"click":function($event){_vm.tickets_filter_active = !_vm.tickets_filter_active}}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.mini_thermsidebar ? 'Filters' : null,
              classes: ['tooltip'],
              placement: 'right-end',
              offset: '8px'
            }),expression:"{\n              content: mini_thermsidebar ? 'Filters' : null,\n              classes: ['tooltip'],\n              placement: 'right-end',\n              offset: '8px'\n            }"}]},[_c('sh-icon',{staticClass:"is-20x20 icon mr-7",attrs:{"file":'thermv2_sprite',"name":_vm.active_class_ids.length ? 'filter-active' : 'filter'}})],1),(!_vm.mini_thermsidebar)?_c('div',{staticClass:"filters-section__heading pr-10 has-full-width is-flex has-space-between"},[_c('div',{class:{ 'has-text-blue': _vm.active_class_ids.length }},[_vm._v(" "+_vm._s(_vm.localization("app-filters", "Filters"))+" ")]),_c('div',{staticClass:"is-flex align-center"},[(_vm.active_class_ids.length && _vm.selectedFiltersCount)?_c('div',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.selectedFiltersCount)+" selected ")]):_vm._e(),_c('i',{staticClass:"fas fa-chevron-right",staticStyle:{"font-size":"1rem","color":"grey","padding":"0 10px"}})])]):_vm._e()]),(_vm.tickets_filter_active)?_c('ticket-filters',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
            function () {
              _vm.tickets_filter_active = false;
            }
          ),expression:"\n            () => {\n              tickets_filter_active = false;\n            }\n          "}],staticClass:"thermv2-filters-popup",style:({
            left: _vm.mini_thermsidebar ? '8rem' : '22rem'
          }),attrs:{"visible_parameter":[
            'status',
            'priority',
            'assignee',
            'created_by',
            'duedate',
            'tags'
          ],"has_dark_theme":false,"filtersData":_vm.filtersData},on:{"apply-viewer":function($event){return _vm.onApplyViewer($event)},"close":function($event){_vm.tickets_filter_active = false}},scopedSlots:_vm._u([{key:"custom-content",fn:function(ref){
          var allFiltersData = ref.allFiltersData;
return [_c('div',{staticClass:"filter-dropdown-content__item has-text-black mt-5 is-medium-14-500-17 "},[_vm._v(" "+_vm._s(_vm.localization( "app-temperature-difference", "Temperature Difference" ))+" ")]),_c('temperature-difference',{staticClass:"filter-dropdown-content__item is-block no-padding-bottom",attrs:{"active_range":_vm.filtersData['temperature-difference']},on:{"updated":function($event){allFiltersData['temperature-difference'] = $event}}})]}}],null,false,574338971)}):_vm._e()],1),(!_vm.mini_thermsidebar)?_c('div',{staticClass:"therm-sidebar__filters has-slimscroll-xs"},[_vm._l((_vm.therm_vector_filters),function(vector){return [(vector.count || vector.default)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                vector.description.length > _vm.vector_description_max_length
                  ? vector.description
                  : null,
              classes: ['tooltip'],
              placement: 'top'
            }),expression:"{\n              content:\n                vector.description.length > vector_description_max_length\n                  ? vector.description\n                  : null,\n              classes: ['tooltip'],\n              placement: 'top'\n            }"}],key:vector.class_id,staticClass:"py-10 is-flex align-center therm-sidebar__filters--item is-between px-10 is-pointer"},[_c('div',{staticClass:"is-flex has-full-width",on:{"click":function($event){return _vm.update_active_class_ids(vector.class_id)}}},[_c('div',{staticClass:"therm-sidebar__filters--box is-relative is-flex align-center",style:({ borderColor: vector.color })},[_c('div',{staticClass:"is-inner is-absolute",style:({
                    background: _vm.active_class_ids.includes(vector.class_id)
                      ? vector.color
                      : 'none'
                  })})]),_c('div',{staticClass:"is-medium-14-500-17 therm-sidebar__item--text ml-5"},[_vm._v(" "+_vm._s(_vm._f("truncate")(vector.description,_vm.vector_description_max_length))+" ")])]),_c('div',{staticClass:"is-medium-14-500-17",on:{"click":function($event){_vm.active_class_ids = [];
                _vm.update_active_class_ids(vector.class_id);}}},[_vm._v(" "+_vm._s(vector.count || 0)+" ")])]):_vm._e()]})],2):_vm._e()]),(!_vm.mini_thermsidebar && _vm.$route.name !== 'therm-v2-compare')?_c('export-data',{staticClass:"is-absolute"}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }