<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  export default {
    methods: {
      deselect_all() {
        this.$eventHub.$emit(`deselect-${this.deselectUid}`);
      },
      async bulk_delete_attachments() {
        let req = {
          body: {
            tickets: {}
          }
        };
        req.body.tickets[this.ticket.uid || ""] = { attachments: [] };
        req.body.tickets[this.ticket.uid || ""].attachments = this.selected.map(
          file => {
            if (file.foreignObjectType == "checklist") {
              return { uid: file.uid, checklistItemUid: file.foreignObjectUid };
            } else return { uid: file.uid };
          }
        );
        await ticketService
          .bulk_delete_attachments(req)
          .then(() => {
            this.deselect_all();
            this.$emit("refresh_attachments");
          })
          .catch(err => console.log(err));
        // old
        // req.body.tickets[this.ticket.uid||'']={attachments: []};
        // req.body.tickets[this.ticket.uid||''].attachments = this.selected;
      },
      async favorite_selected() {
        let attachmentUids = this.selected.map(file => file.uid);
        let req = {
          body: {
            tickets: {}
          }
        };
        req.body.tickets[this.ticket.uid || ""] = { attachments: {} };
        attachmentUids.forEach(uid => {
          req.body.tickets[this.ticket.uid || ""].attachments[uid] = {
            pinned: true
          };
        });
        await ticketService
          .bulk_update_attachments(req)
          .then(() => {
            this.deselect_all();
            this.$emit("refresh_attachments");
          })
          .catch(err => console.log(err));
      },
      download_files(type) {
        let all_files = [];
        if (type == "selected") {
          all_files = this.selected.map(file =>
            this.existing_files.find(f => f.uid == file.uid)
          );
        } else if (type == "favorite") {
          all_files = this.existing_files.filter(file => file.pinned);
        } else {
          all_files = this.existing_files;
        }
        all_files.forEach(
          attachment => (attachment["name"] = attachment.fileName)
        );
        let data = {
          attachments: all_files.map(attachment =>
            _.pick(attachment, ["name", "url"])
          ),
          name: this.ticket.name,
          type: "ticket"
        };
        this.$eventHub.$emit("download_zip", data);
      }
    }
  };
</script>

<style></style>
