<template>
  <div
    class="ticket-details-forms-card"
    :class="{ 'has-slimscroll-xs ticket-details-forms-card--mobile': isMobile }"
  >
    <div v-if="!isMobile" class="is-flex foms-title align-center">
      <div
        class="is-medium-16-500-19 has-primary-text is-flex align-center"
        style="min-height: 5rem"
      >
        {{ localization("app-forms", "Forms") }}
      </div>
      <span
        v-if="form_items && form_items.length"
        class="is-medium-16-500-19 has-primary-text ml-5 has-opacity-7"
        >({{ form_items.length }})</span
      >
      <span
        v-if="form_items && form_items.length && !search_form_create"
        @click="
          search_form_create = true;
          search_form_dropdown = true;
        "
      >
        <sh-icon
          class="mr-10 ml-10 is-pointer is-20x20"
          :name="'add-create'"
        ></sh-icon>
      </span>
      <div
        v-if="search_form_create"
        class="is-relative ml-20 search-input"
        v-click-outside="
          () => {
            search_form_create = false;
            selected_forms = ticket.properties.forms;
          }
        "
      >
        <sh-icon
          :name="'search'"
          :file="'helper_sprite'"
          class="is-16x16 search-icon"
        ></sh-icon>
        <!-- @click="search_form_dropdown = !search_form_dropdown" -->
        <input
          v-focus
          type="text"
          v-model="formsearch"
          @click="search_form_dropdown = true"
          @input="search_form_dropdown = true"
          :placeholder="
            localization('app-seach-&-select-forms', 'Seach & Select Forms')
          "
          class="search-form is-medium-14-500-17"
        />
        <div v-if="search_form_dropdown" class="search-form__dropdown">
          <div class="search-form__dropdown--content has-slimscroll-xs">
            <div v-if="filteredForms && filteredForms.length">
              <div v-for="item in filteredForms" :key="item">
                <div
                  class="search-form__dropdown--content-item is-medium-14-500-17"
                >
                  <input
                    :id="item.uid"
                    type="checkbox"
                    class="sh-custom-checkbox"
                    :value="item.uid"
                    v-model="selected_forms"
                  />
                  <label :for="item.uid" class="is-pointer">
                    <div class="is-medium-14-500-24 ml-10">
                      {{ item.name | truncate(25) }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              v-else
              class="search-form__dropdown--content is-flex align-center is-center"
            >
              <no-result :search="formsearch"></no-result>
            </div>
          </div>
          <div
            class="is-flex is-between align-center search-form__dropdown--btn is-medium-14-500-17"
          >
            <div>
              <div
                class="is-flex align-center"
                v-if="selected_forms && selected_forms.length"
              >
                <sh-icon
                  @click.native="selected_forms = []"
                  :name="'field-table-close'"
                  :file="'forms-sprite'"
                  class="is-14x14 is-pointer mr-10"
                ></sh-icon>
                <span>
                  {{ selected_uids.length }}
                  {{ localization("app-selected", "Selected") }}</span
                >
              </div>
            </div>
            <div
              @click="update_ticket({ properties: { forms: selected_forms } })"
              class="btn-add"
            >
              {{ localization("app-add", "Add") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-10" v-else></div>
    <div class="with-data" v-if="form_items && form_items.length">
      <div
        v-for="(form_item, index) in show_more
          ? form_items
          : form_items.slice(0, 4)"
        :key="form_item.uid"
        @mouseenter.stop="hover = true"
        @mouseleave.stop="hover = false"
        class="is-flex align-center is-between form-item"
        @click="
          $eventHub.$emit('open_form_submission', {
            formUid: form_item.uid,
            submissionUid:
              form_item.currentSubmission.length &&
              (form_item.currentSubmission[0].formUid ||
                form_item.currentSubmission[0].submissionUid ||
                form_item.currentSubmission[0].uid),
            ticketUid: ticket.uid
          })
        "
        :class="[
          {
            'has-opacity-6':
              index == 2 && !show_more && !hover && form_items.length > 4
          },
          {
            'has-opacity-4':
              index == 3 && !show_more && !hover && form_items.length > 4
          }
        ]"
      >
        <div class="is-flex align-center">
          <img
            v-if="form_item.preview && form_item.preview.thumbnails"
            :src="form_item.preview.thumbnails.xsmall"
            class="is-40x40 mr-15 has-border-radius-5"
            alt="forms"
          />
          <img
            v-else
            src="@/assets/icons/png/forms-logo.png"
            class="is-40x40 mr-15"
            alt="forms"
          />
          <div>
            <div class="is-medium-14-500-17 pb-5 is-pointer">
              {{ form_item.name }}
            </div>
            <div
              class="is-flex"
              :class="{ 'align-center': !isMobile }"
              :style="isMobile ? 'flex-flow:column' : ''"
              v-if="
                form_item.currentSubmission &&
                  form_item.currentSubmission.length
              "
            >
              <div class="is-regular-12-00-14 has-text-theme-newgrey">
                {{
                  form_item.currentSubmission[0].submittedAt | fullDateFormat
                }}
              </div>
              <div
                v-if="
                  form_item.currentSubmission[0].submittedAt &&
                    form_item.currentSubmission[0].owner &&
                    !isMobile
                "
                class="file-details-divider"
              ></div>
              <div class="is-flex">
                <user-avatar
                  :user="
                    $store.getters.state_users[
                      form_item.currentSubmission[0].owner
                    ]
                  "
                  :pxsize="16"
                  :custom_class="'tiny-user-avatar'"
                ></user-avatar>
                <div
                  class="is-medium-12-500-00 ml-5"
                  v-username="
                    $store.getters.state_users[
                      form_item.currentSubmission[0].owner
                    ]
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="is-flex align-center">
          <div
            v-if="
              form_item.currentSubmission.length > 0 &&
                form_item.currentSubmission[0].status == 'filled'
            "
            class="has-status is-medium-12-500-14 status-submitted"
            :class="[!isMobile ? ' mr-30' : '']"
          >
            {{ localization("app-submitted", "Submitted") }}
          </div>
          <div
            v-else
            class="has-status is-medium-12-500-14"
            :class="[
              form_item.currentSubmission.length > 0
                ? 'status-draft'
                : 'status-open',
              !isMobile ? ' mr-30' : ''
            ]"
          >
            {{
              form_item.currentSubmission.length > 0
                ? localization("app-draft", "Draft")
                : localization("app-open", "Open")
            }}
          </div>
          <div v-if="!isMobile">
            <sh-icon
              @click.native="deletePublicForms(form_item.uid)"
              :name="'delete'"
              :file="'forms-sprite'"
              class="is-18x18 is-pointer"
            ></sh-icon>
          </div>
        </div>
      </div>
      <div class="is-flex is-center" v-if="form_items.length > 4 && !isMobile">
        <div
          class="show-more-btn is-medium-12-500-14 is-pointer"
          :class="{ 'mt-15': show_more }"
          @click="show_more = !show_more"
        >
          Show {{ show_more ? "Less" : "More" }}
          <sh-icon
            :name="`${show_more ? 'expand' : 'collapse'}`"
            class="is-18x18 ml-5 is-pointer"
          ></sh-icon>
        </div>
      </div>
      <div
        v-if="search_form"
        class="is-relative ml-20 search-input"
        v-click-outside="
          () => {
            search_form = false;
            selected_forms = ticket.properties.forms;
          }
        "
      >
        <sh-icon
          :name="'search'"
          :file="'helper_sprite'"
          class="is-16x16 search-icon"
        ></sh-icon>
        <!-- @click="search_form_dropdown = !search_form_dropdown" -->
        <input
          v-focus
          type="text"
          v-model="formsearch"
          @click="search_form_dropdown = true"
          @input="search_form_dropdown = true"
          :placeholder="
            localization('app-seach-&-select-forms', 'Seach & Select Forms')
          "
          class="search-form is-medium-14-500-17"
        />
        <div v-if="search_form_dropdown" class="search-form__dropdown">
          <div class="search-form__dropdown--content has-slimscroll-xs">
            <div v-if="filteredForms && filteredForms.length">
              <div v-for="item in filteredForms" :key="item">
                <div
                  class="search-form__dropdown--content-item is-medium-14-500-17"
                >
                  <input
                    :id="item.uid"
                    type="checkbox"
                    class="sh-custom-checkbox"
                    :value="item.uid"
                    v-model="selected_forms"
                  />
                  <label :for="item.uid" class="is-pointer">
                    <div class="is-medium-14-500-24 ml-10">
                      {{ item.name | truncate(25) }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              v-else
              class="search-form__dropdown--content is-flex align-center is-center"
            >
              <no-result :search="formsearch"></no-result>
            </div>
          </div>
          <div
            class="is-flex is-between align-center search-form__dropdown--btn is-medium-14-500-17"
          >
            <div>
              <div
                class="is-flex align-center"
                v-if="selected_forms && selected_forms.length"
              >
                <sh-icon
                  @click.native="selected_forms = []"
                  :name="'field-table-close'"
                  :file="'forms-sprite'"
                  class="is-14x14 is-pointer mr-10"
                ></sh-icon>
                <span> {{ selected_uids.length }} Selected</span>
              </div>
            </div>
            <div
              @click="update_ticket({ properties: { forms: selected_forms } })"
              class="btn-add"
            >
              Add
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="!isMobile"
        @click="
          search_form = true;
          search_form_dropdown = true;
        "
        class="is-flex align-center ml-20 has-accent-text mt-10 is-pointer"
      >
        <i class="fas fa-plus mr-5"></i>
        <div class="is-medium-14-500-17">
          {{ localization("app-add-Form", "Add Form") }}
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="form_loading">
        <div class="mb-10 is-flex mx-20" v-for="i in 10" :key="i">
          <div class="mr-20">
            <b-skeleton width="40px" height="40px"></b-skeleton>
          </div>
          <div class="has-full-width mt-10">
            <b-skeleton width="70%"></b-skeleton>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <div class="has-text-centered">
          <div class="is-flex is-center">
            <sh-icon
              :name="'forms-icon'"
              :file="'forms-sprite'"
              class="is-24x24"
            ></sh-icon>
          </div>
          <div class="is-medium-18-500-22 pt-15">
            {{ localization("app-no-forms", "No Forms") }}
          </div>
          <div class="is-medium-14-500-17 no-desc pt-10">
            {{
              localization(
                "app-attach-forms-to-gather-data-of-any-sort-to-gain-better-insight.",
                "Attach forms to gather data of any sort to gain better insight."
              )
            }}
          </div>
          <div v-if="search_form" class="is-relative search-input">
            <sh-icon
              :name="'search'"
              :file="'helper_sprite'"
              class="is-16x16 search-icon"
            ></sh-icon>
            <!-- @click="search_form_dropdown = !search_form_dropdown" -->
            <input
              v-focus
              type="text"
              v-model="formsearch"
              @click="search_form_dropdown = true"
              @input="search_form_dropdown = true"
              :placeholder="
                localization('app-seach-&-select-forms', 'Seach & Select Forms')
              "
              class="search-form is-medium-14-500-17"
            />
            <div
              v-click-outside="() => (search_form_dropdown = false)"
              v-if="search_form_dropdown"
              class="search-form__dropdown"
            >
              <div class="search-form__dropdown--content has-slimscroll-xs">
                <div v-for="item in filteredForms" :key="item">
                  <div
                    class="search-form__dropdown--content-item is-medium-14-500-17"
                  >
                    <input
                      :id="item.uid"
                      type="checkbox"
                      class="sh-custom-checkbox"
                      :value="item.uid"
                      v-model="selected_forms"
                    />
                    <label :for="item.uid" class="is-pointer">
                      <div class="is-medium-14-500-24 ml-10">
                        {{ item.name | truncate(25) }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="is-flex is-between align-center search-form__dropdown--btn is-medium-14-500-17"
              >
                <div>
                  <div
                    class="is-flex align-center"
                    v-if="selected_forms && selected_forms.length"
                  >
                    <sh-icon
                      @click.native="selected_forms = []"
                      :name="'field-table-close'"
                      :file="'forms-sprite'"
                      class="is-14x14 is-pointer mr-10"
                    ></sh-icon>
                    <span>
                      {{ selected_uids.length }}
                      {{ localization("app-selected", "Selected") }}</span
                    >
                  </div>
                </div>
                <div
                  @click="
                    update_ticket({ properties: { forms: selected_forms } })
                  "
                  class="btn-add"
                >
                  {{ localization("app-add", "Add") }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="!isMobile"
            @click="
              search_form = true;
              search_form_dropdown = true;
            "
            class="is-flex is-center align-center has-accent-text mt-10 is-pointer"
          >
            <i class="fas fa-plus mr-5"></i>
            <div class="is-medium-14-500-17">
              {{ localization("app-add-form", "Add Form") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formsService } from "@/app/old/desktop/shared/services";
  import NoResult from "@/app/old/desktop/shared/components/add-teams-modal-user-list-invite/no-results.vue";
  import elementMixin from "@/app/old/desktop/shared/mixins/element.mixin";
  import { mapState, mapActions } from "vuex";

  export default {
    props: ["ticket"],
    components: {
      "no-result": NoResult
    },
    mixins: [elementMixin],
    data() {
      return {
        search_form: false,
        search_form_create: false,
        search_form_dropdown: false,
        formsearch: "",
        selected_forms: [],
        form_list: [],
        form_items: [],
        uidList: [],
        selected_uids: [],
        targetElement: null,
        show_more: false,
        hover: false,
        form_loading: false
      };
    },
    beforeDestroy: function() {
      this.$store.commit("forms/SET_TICKET_FORMS", []);
    },
    watch: {
      ticketForms(val) {
        this.form_items = val;
      },
      selected_forms() {
        (this.selected_uids = []),
          this.selected_forms.map(uid => {
            if (_.includes(this.uidList, uid)) {
              this.selected_uids.push(uid);
            }
          });
        return this.selected_uids;
      }
    },
    computed: {
      ...mapState("forms", ["ticketForms"]),
      filteredForms() {
        return this.form_list.filter(form => {
          return form.name
            .toLowerCase()
            .includes(this.formsearch.toLowerCase());
        });
      }
    },
    mounted() {
      this.$eventHub.$on("forms_updates", () => {
        this.getFilteredForms();
      });

      this.show_more = this.isMobile;
      this.selected_forms = this.ticket.properties.forms
        ? this.ticket.properties.forms
        : [];
      this.getPublicForms();
      if (this.ticket.properties.forms && this.ticket.properties.forms.length) {
        this.getFilteredForms();
      }
    },
    methods: {
      ...mapActions("forms", ["get_ticket_forms"]),
      async getPublicForms() {
        this.form_loading = true;
        let res = await formsService.forms.getAll({
          query: "public=true"
        });
        let data = res.data.data;
        data.map(m => {
          this.uidList.push(m.uid);
        });

        this.selected_forms.map(uid => {
          if (_.includes(this.uidList, uid)) {
            this.selected_uids.push(uid);
          }
        });

        this.form_list = data;
        this.form_loading = false;
      },
      async getFilteredForms() {
        let { data } = await this.get_ticket_forms({
          body: {
            formUids: this.selected_forms ? this.selected_forms : [],
            public: true,
            attachedTo: {
              service: "TICKETS",
              object: this.ticket.uid
            }
          }
        });
      },
      deletePublicForms(uid) {
        if (this.selected_forms && _.includes(this.selected_forms, uid)) {
          this.selected_forms.splice(_.indexOf(this.selected_forms, uid), 1);
          this.update_ticket({ properties: { forms: this.selected_forms } });
          this.getFilteredForms();
        }
      },
      update_ticket(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
        this.search_form = false;
        this.search_form_create = false;
        this.getFilteredForms();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ticket-details-forms-card {
    &--mobile {
      height: 100%;
      overflow-y: auto;
    }
    .foms-title {
      margin: 0 2rem;
      padding-top: 4rem;
    }
    .no-data {
      height: 200px;
      margin: 1.2rem 2rem 1.2rem 2rem;
      z-index: 40;
      border-radius: 10px;
      border: solid 1px rgba(91, 96, 126, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-shadow: 0 1px 0 0 rgba(92, 126, 247, 0.3);
    }
    .with-data {
      margin-top: 6px;
      .form-item {
        padding: 1rem 2.3rem;
        &:hover {
          background-color: rgba(37, 133, 211, 0.05);
        }
        .has-status {
          padding: 4px 1rem;
          text-transform: capitalize;
          border-radius: 5rem;
          &.status-open {
            background-color: rgba(247, 156, 75, 0.15);
            color: #f79c4b;
          }
          &.status-draft {
            background-color: rgba(237, 182, 3, 0.15);
            color: #edb603;
          }
          &.status-submitted {
            background-color: rgba(14, 172, 83, 0.1);
            color: #0eac53;
          }
        }
      }
    }
  }
  .search-input {
    input::placeholder {
      opacity: 0.5;
    }
    .search-form {
      width: 30rem;
      padding: 1.9rem 1.3rem 1.3rem 4rem;
      border: 0;
      border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    }
    .search-icon {
      position: absolute;
      bottom: 1.3rem;
      left: 1.3rem;
    }
    .search-form__dropdown {
      position: absolute;
      width: 30rem;
      height: 26rem;
      border-radius: 4px;
      box-shadow: -10px 10px 20px 0 rgba(23, 30, 72, 0.1);
      border: solid 1px rgba(23, 30, 72, 0.15);
      background-color: #ffffff;
      z-index: 1;
      &--content {
        height: 21rem;
        overflow-y: auto;
        text-align: left;
        &-item {
          padding: 1.2rem 1.5rem;
          border-bottom: 1px solid rgba(112, 112, 112, 0.15);
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      &--btn {
        height: 5rem;
        border-top: 1px solid rgba(112, 112, 112, 0.15);
        padding: 0 1.5rem;
        .btn-add {
          background-color: $primary;
          height: 3.2rem;
          color: $white;
          padding: 0 2rem;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
</style>
