<script>
  import { mapState } from "vuex";

  export default {
    computed: {
      ...mapState(["is_offline"])
    },
    methods: {
      segment_track_event(event, properties = {}) {
        // Track events and send them to Segment.com
        analytics.track(event, {
          is_offline: this.is_offline,
          is_mobile: window.is_mobile ? true : false,
          ...properties
        });
      }
    }
  };
</script>
