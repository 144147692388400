<template>
  <div
    class="is-flex is-pointer"
    style="width: 100%"
    v-touch:touchhold.stop="selectTicket"
    v-touch-options="{ touchClass: 'active', touchHoldTolerance: 300 }"
  >
    <template v-if="!$route.name.includes('therm-v2')">
      <status-dropdown
        v-tooltip="{
          content:
            ticket && !ticket.writeAccess ? status_values[ticket.status] : null,
          html: false
        }"
        :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
        :current_status="ticket.status"
        :ticket="ticket"
        class="status-selector mr-5 mt-3"
        :hide_names="true"
        v-if="ticket"
        :size="22"
        @update-ticket-status="
          update_ticket({ status: parseInt($event.value) })
        "
      ></status-dropdown>
    </template>
    <template v-else>
      <div
        class="defect-status-box mr-5 mt-3 "
        :style="{ background: vector_color ? vector_color : 'none' }"
      ></div>
    </template>
    <div
      class="m-ticket-list__item__details"
      @click.stop="openDetailsPage(ticket.uid)"
    >
      <div
        class="m-name is-medium-14-500-00 is-flex is-vcenter"
        style="overflow: hidden; text-overflow: ellipsis"
        :class="{ 'has-text-white': has_dark_theme }"
        :style="[isThermTicket ? { width: '250px' } : { width: '320px' }]"
      >
        {{ ticket.name | truncate(28) }}
        <div class="is-pointer ml-10">
          <img
            v-if="ticket.bookmark"
            src="@/assets/svg/bookmarked.svg"
            alt="bookmarked"
            class="image is-12x12"
          />
        </div>
      </div>
      <div class="is-flex align-center mt-5" v-if="ticket.dueDate">
        <m-icon
          :name="'ticket-list-duedate'"
          class="is-13x13 mr-5"
          :file="'tickets-sprite'"
        ></m-icon>
        <span class="is-regular-12-00-14 duedate-text" v-if="ticket.dueDate">{{
          ticket.dueDate | prettyDate
        }}</span>

        <m-icon
          :name="'ticket-recurring'"
          class="is-13x13 ml-10"
          :file="'tickets-sprite'"
          v-if="ticket.schedule && ticket.schedule.rrule"
        ></m-icon>
      </div>
      <div class="mt-5 is-flex align-center is-between">
        <div>
          <div
            v-if="tags && tags.length"
            class="is-flex is-vcenter no-whitespace-wrap"
          >
            <div
              v-for="(tag, i) in tags.slice(0, isThermTicket ? 1 : 2)"
              :key="i"
            >
              <div
                v-if="tag"
                class="is-medium-14-500-17 no-whitespace-wrap sh-type-tag"
                :class="[{ 'is-not-last': i !== tags.length - 1 }]"
                :style="{ color: tag.color }"
              >
                {{ tag.name | truncate(isThermTicket ? 8 : 12) }}
              </div>
            </div>
            <div
              v-if="tags.length > 2"
              class="more-count is-pointer is-medium-12-500-14 has-shadow-light-grey"
            >
              +{{ isThermTicket ? tags.length - 1 : tags.length - 2 }}
            </div>
          </div>
          <div v-else class="is-flex align-center">
            <m-icon
              :name="'ticket-list-no-tags'"
              class="is-11x11 mr-5"
              :file="'tickets-sprite'"
            ></m-icon>
            <span class="is-regular-12-500-14 has-opacity-5">{{
              localization("app-no-tags", "No tags")
            }}</span>
          </div>
        </div>
        <div
          v-if="
            $store.state.users.length && $store.state.internal_users_uids.length
          "
        >
          <div
            class="is-flex align-center"
            v-if="ticket.takenUp && ticket.takenUp.uid"
          >
            <user-avatar
              v-if="ticket.takenUp"
              :user="ticket.takenUp"
              :pxsize="20"
              class="mr-5"
              :avatarfontsize="12"
              :custom_class="'tiny-user-avatar'"
            ></user-avatar>
            <p
              :class="[
                has_dark_theme
                  ? 'has-text-white has-opacity-7'
                  : 'has-text-blue-grey'
              ]"
              class="is-medium-14-500-17  truncate-text is-width-10"
              v-username="ticket.takenUp"
            ></p>
          </div>
        </div>
        <div v-else class="is-flex">
          <b-skeleton active width="100px"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";

  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";
  import PriorityDropdown from "@/app/old/desktop/tickets/shared/components/priority-dropdown";
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import UserDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";

  export default {
    props: ["ticket", "tags", "has_dark_theme", "isThermTicket"],
    mixins: [TicketDetailsMixin],
    computed: {
      ...mapState("tickets", ["selected"]),
      ...mapState("thermv2", ["vector_types"]),
      vector_color() {
        return this.vector_map[this.ticket.properties.classId].color;
      }
    },
    data() {
      return {
        status_values: {
          1: "pending",
          2: "in progress",
          3: "resolved",
          4: "closed",
          5: "rejected"
        },
        vector_map: {}
      };
    },
    components: {
      PriorityDropdown,
      StatusDropdown,
      UserDropdown
    },
    created() {
      if (this.$route.name.includes("therm-v2")) this.get_vector_map();
    },
    methods: {
      get_vector_map() {
        this.vector_types.forEach(v => {
          this.vector_map[v.class_id] = v;
        });
      },
      update_ticket(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
      },
      selectTicket() {
        if (this.ticket && this.ticket.writeAccess) {
          this.$store.dispatch("tickets/select_ticket", this.ticket.uid);
        }
      },
      openDetailsPage() {
        if (this.isThermTicket) {
          this.$emit("show-defect-details", this.ticket.uid);
        } else {
          this.open_ticket_details(this.ticket.uid);
        }
      }
    }
  };
</script>
<style scoped>
  .defect-status-box {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 2px;
  }
</style>
