<template>
  <div class="tickets-top-filters">
    <div class="left">
      <div class="is-flex header mr-10">
        <h1 class="left-heading" v-if="!isThermTicket">
          {{
            $route.name == "favorites"
              ? localization("app-favorites", "Favorites")
              : localization("app-tickets", "Tickets")
          }}
        </h1>
        <h1 class="left-heading" v-else>
          {{ localization("app-tasks", "Tasks") }}
        </h1>
        <a
          v-if="$store.getters.is_internal_user && !isThermTicket"
          @click="is_internal = !is_internal"
          class="left-internal-filter"
          :class="{ active: is_internal }"
          >{{ localization("app-internal", "Internal") }}</a
        >
      </div>
      <div class="each-filter">
        <asset-dropdown></asset-dropdown>
      </div>
      <div class="each-filter">
        <display-dropdown></display-dropdown>
      </div>
    </div>
    <div class="right">
      <div class="mr-30 mt-5">
        <export-dropdown :allFiltersData="allFiltersData"></export-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import AssetDropdown from "./components/asset-dropdown";
  import DisplayDropdown from "./components/display-dropdown";
  import GroupByDropdown from "./components/group-by-dropdown";
  import ExportDropdown from "./components/export-dropdown";
  export default {
    props: ["allFiltersData", "isThermTicket"],
    components: {
      AssetDropdown,
      GroupByDropdown,
      ExportDropdown,
      DisplayDropdown
    },
    data() {
      return {
        data: [
          { label: "None", key: null, filter_key: null, enable: true },
          {
            label: "Status",
            key: "status",
            filter_key: "status",
            enable: true
          },
          {
            label: "Priority",
            key: "priority",
            filter_key: "priority",
            enable: true
          }
        ]
      };
    },
    computed: {
      is_internal: {
        get() {
          return this.$store.state.tickets.is_internal;
        },
        set(value) {
          this.$store.commit("tickets/SET_IS_INTERNAL", value);
        }
      }
    }
  };
</script>

<style lang="scss">
  .tickets-top-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .header {
      align-items: center;
    }
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .each-filter {
        margin: 0 1rem;
      }
      &-heading {
        font-family: Roboto-Medium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: $primary-text;
      }
      &-internal-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        width: 74px;
        height: 30px;
        border-radius: 6px;
        border: solid 1px rgba(91, 96, 126, 0.3);
        background-color: white;
        opacity: 0.7;
        font-family: Roboto-SemiBold;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.21;
        text-align: left;
        color: #171e48;
        &.active {
          background: #171e48;
          color: white;
        }
      }
    }
    .right {
      display: flex;
    }
    .heading-1 {
      opacity: 0.4;
      font-family: Roboto-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #171e48;
    }
    .heading-2 {
      margin-left: 7px;
      margin-right: 7px;
      font-family: Roboto-SemiBold;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #171e48;
      cursor: pointer;
    }

    .dropdown-content {
      border-radius: 12px;
      box-shadow: 10px 20px 50px 0 rgba(23, 30, 72, 0.2);
      border: solid 1px rgba(23, 30, 72, 0.15);
      background-color: white;
      .dropdown-item {
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        font-family: Roboto-Medium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.13;
        letter-spacing: normal;
        text-align: left;
        color: #171e48;
        &:hover {
          background-color: #f4f6fe;
        }
      }
    }
  }
</style>
