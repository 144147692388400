import { httpClient } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_GIS_API_HOST

class API {
  constructor() {}
  async contour(req = {}) {
    return httpClient({
      url: `contours/dsm/${req.query ? `?${req.query}` : ''}`,
      baseURL: API_HOST,
      method: 'POST',
      data: req.body,
      headers: req.headers
    })
  }
  async xyz(req = {}) {
    return httpClient({
      url: `${API_HOST}/xyz/dsm/${req.query ? `?${req.query}` : ''}`,
      baseURL: API_HOST,
      baseURL: API_HOST,
      method: 'POST',
      data: req.body,
      headers: req.headers
    })
  }
}
export const gisService = new API()
