<template>
  <div
    @mouseover="hover_state = true"
    @mouseout="hover_state = false"
    class="is-sh-dropdown align-center is-inline-flex is-relative"
  >
    <!-- Displaying the list of tags tied to a resource -->
    <assigned-tags
      :truncate_length="truncate_length"
      :write_access="write_access"
      :complete="complete"
      :number_of_tags_to_display="number_of_tags_to_display"
      :resourceId="resourceId"
      :service="service"
      :tags="tags"
      :isForms="isForms"
      :tag_font_size="tag_font_size"
    />

    <div
      v-if="write_access"
      :class="{ 'is-vhidden': display_on_hover && !hover_state }"
      @click="dropdown_active = !dropdown_active"
      class="is-flex is-vcenter is-pointer"
    >
      <!-- content to toggle the dropdown (can be customized using the slot) -->
      <slot name="toggle-dropdown">
        <sh-icon
          v-tooltip="{ content: localization('app-add-tags', 'Add Tags') }"
          v-if="tags && tags.length"
          :name="'add-more-tags'"
          class="is-24x24"
        ></sh-icon>
        <div v-else class="is-inline-flex align-center">
          <sh-icon :name="'add-more-tags'" class="is-24x24"></sh-icon>
          <span
            class="ml-10 is-medium-14-500-17 has-text-theme-black"
            :class="[full_opacity_text ? '' : 'has-opacity-5 ']"
            >{{ localization("app-add-tags", "Add tags") }}</span
          >
        </div>
      </slot>
    </div>
    <div v-if="!write_access && tags && !tags.length">
      <not-assigned :type="'tag'" />
    </div>
    <dropdown-list
      :position="position"
      v-click-outside="() => (dropdown_active = false)"
      :resourceId="resourceId"
      :service="service"
      :complete="complete"
      :tags="tags"
      v-if="dropdown_active"
      @close="dropdown_active = false"
    />
  </div>
</template>

<script>
  import DropdownList from "./components/dropdown-list";
  import AssignedTags from "./components/assigned-tags";
  import NotAssigned from "@/app/old/desktop/shared/components/not-assigned";
  export default {
    data() {
      return {
        dropdown_active: false,
        hover_state: false
      };
    },

    components: {
      DropdownList,
      AssignedTags,
      NotAssigned
    },

    props: {
      position: {
        type: String,
        default: ""
      },
      tags: {
        default: []
      },
      service: {
        default: null
      },
      complete: {
        default: null
      },
      resourceId: {
        default: null
      },
      number_of_tags_to_display: {
        type: Number,
        default: null
      },
      write_access: {
        type: Boolean,
        default: true
      },
      display_on_hover: {
        type: Boolean,
        default: true
      },
      truncate_length: {
        type: Number,
        default: 10
      },
      isForms: {
        type: Boolean,
        default: false
      },
      tag_font_size: {
        type: Number,
        default: 14
      },
      full_opacity_text: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .toggle-dropdown {
    visibility: hidden;
    &:hover .toggle-dropdown {
      visibility: visible;
    }
  }
</style>
