import { DateTime } from 'luxon'

export default function(date) {
  if (!date) return ''
  let givenDate = DateTime.fromISO(date, {
    setZone: true,
    zone: 'utc'
  }).toLocaleString(DateTime.DATETIME_MED)

  return givenDate
}
