<template>
  <section class="popup-modal">
    <b-tabs position="is-centered" v-model="active_tab">
      <b-tab-item :label="localization('app-raw-images', 'Raw Images')">
        <div class="field marker-toggle">
          <b-switch
            @input="toggleMarker()"
            :value="is_marker"
            type="is-info"
            v-if="lang_data"
            >{{ localization("app-markers", "marker") }}</b-switch
          >
        </div>
        <div id="rawImageMap"></div>
        <div class="controls">
          <button
            @click="changeImage(-1)"
            class="button is-white"
            :disabled="raw_image_active <= 0"
          >
            <i class="fa fa-angle-left"></i>
          </button>
          <a class="button is-white"
            >{{ raw_image_active + 1 }} / {{ raw_image_total }}</a
          >
          <button
            @click="changeImage(1)"
            class="button is-white"
            :disabled="raw_image_active + 1 >= raw_image_total"
          >
            <i class="fa fa-angle-right"></i>
          </button>
          <a
            style="margin-top: 5px;"
            @click="forceDownload(raw_images[raw_image_active].src)"
            v-if="raw_images.length"
            class="button is-info is-small is-rounded"
          >
            <i class="fa fa-download"></i>
          </a>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
  import { mapState, mapMutations } from "vuex";

  export default {
    props: ["raw_images", "index"],

    data() {
      return {
        active_tab: 0,
        map: null,
        marker: null,
        raw_image_total: null,
        raw_image_active: 0,
        is_marker: true,
        marker_element: null
      };
    },
    mounted() {
      this.loadMap(true);
      setInterval(() => {
        if (this.raw_images_map) this.raw_images_map.resize();
      }, 3000);
    },
    beforeDestroy() {
      this.SET_RAW_IMAGES_MAP(null);
    },
    computed: {
      ...mapState("thermv2", ["token", "raw_images_map", "mapbox_token"])
    },

    methods: {
      ...mapMutations("thermv2", ["SET_RAW_IMAGES_MAP"]),
      toggleMarker() {
        if (!this.is_marker) this.marker_element.style.display = "block";
        else this.marker_element.style.display = "none";

        this.is_marker = !this.is_marker;
      },
      loadMap(is_feature_changed = false) {
        this.raw_image_total = this.raw_images.length;
        this.raw_image_active = 0;

        if (!this.raw_images_map) {
          try {
            // mapboxgl.accessToken = this.mapbox_token;
            let map = new mapboxgl.Map({
              container: "rawImageMap",
              style: "mapbox://styles/mapbox/satellite-v8",
              center: [-75.789, 41.874],
              zoom: 5,
              maxZoom: 10,
              minZoom: 5,
              attributionControl: false
            });
            this.SET_RAW_IMAGES_MAP(map);
            this.raw_images_map.on("style.load", () => {
              this.raw_images_map.removeLayer("satellite");
              this.raw_images_map.removeLayer("background");
              if (this.raw_images && this.raw_images.length) {
                this.changeImage(0, true);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      },
      changeImage(n, first_time = false) {
        try {
          this.raw_image_active = this.raw_image_active + n;
          if (this.marker) {
            this.marker.remove();
          }
          if (this.raw_images_map.getLayer("overlay"))
            this.raw_images_map.removeLayer("overlay");
          if (this.raw_images_map.getSource("myImageSource"))
            this.raw_images_map.removeSource("myImageSource");

          const image = new Image();
          image.src = this.raw_images[this.raw_image_active].src;
          image.setAttribute("crossorigin", "anonymous");
          image.onload = () => {
            const map = this.raw_images_map;
            let img_width = image.width;
            let img_height = image.height;

            let nw = map.unproject([0, 0]);
            let ne = map.unproject([img_width, 0]);
            let se = map.unproject([img_width, img_height]);
            let sw = map.unproject([0, img_height]);
            map.addSource("myImageSource", {
              type: "image",
              url: image.src,
              coordinates: [
                [nw.lng, nw.lat],
                [ne.lng, ne.lat],
                [se.lng, se.lat],
                [sw.lng, sw.lat]
              ]
            });
            map.addLayer({
              id: "overlay",
              source: "myImageSource",
              type: "raster"
            });

            let el = document.createElement("div");
            this.marker_element = el;
            el.className = "marker fa fa-map-marker fa-2x";
            if (!this.is_marker) this.marker_element.style.display = "none";
            el.addEventListener("click", () => (this.active_tab = 0));

            const location = this.raw_images[this.raw_image_active].location;
            const center = map.unproject([location[0], location[1] + 5]);
            this.marker = new mapboxgl.Marker(el).setLngLat(center).addTo(map);

            let bounds = new mapboxgl.LngLatBounds(
              new mapboxgl.LngLat(sw.lng, sw.lat),
              new mapboxgl.LngLat(ne.lng, ne.lat)
            );
            map.fitBounds(bounds, {
              duration: 0
            });
            map.resize();
          };
        } catch (err) {
          console.log("===================================");
          console.log(err);
        }
      },
      forceDownload(url) {
        try {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function() {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement("a");
            tag.href = imageUrl;
            tag.download = "";
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
          };
          xhr.send();
        } catch (err) {
          window.open(url, "_blank");
        }
      }
    }
  };
</script>
<style lang="scss">
  .popup-modal {
    border-radius: 10px;
    background: white;
    height: 600px;

    .marker-toggle {
      position: absolute;
      z-index: 2;
      top: 25px;
      left: 25px;
      color: white;
      .button {
        margin-right: 5px;
      }
    }
    .controls {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);

      > * {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    #rawImageMap {
      position: relative;
      min-height: 600px;
      width: 100%;
    }
    .marker {
      color: blue;
      cursor: pointer;
    }
    .mapboxgl-ctrl-logo {
      display: none !important;
    }
  }
</style>
