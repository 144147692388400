<template>
  <div @click.stop @mousedown.stop class="webviewer">
    <div>
      <heading
        v-if="documentLoaded"
        :bookmark="bookmark"
        :icon="icon"
        :name="name"
        :id="uid"
        :instance="instance"
        :docViewer="docViewer"
        :isMobile="isMobile"
        :url="url"
        @close="closeWebviewer"
        @delete="$emit('delete')"
      >
      </heading>
      <toolbar
        v-if="documentLoaded && toolsEnabled && !isMobile"
        :instance="instance"
        :docViewer="docViewer"
        :isMobile="isMobile"
        @tool-clicked="toolClicked = $event"
        @tools-genre="toolsGenre = $event"
      ></toolbar>
      <!-- <icon-count ref="comment_icon" :annotPos="annotPos"></icon-count> -->
      <navigation
        :hasNavIcon="hasNavIcon"
        v-if="hasNavigation"
        @next="navigate(1)"
        @prev="navigate(-1)"
      ></navigation>
      <ticket-comment
        :commentsChannel="commentsChannel"
        :commentsQuery="commentsQuery"
        :showComments="showComments"
        :ticketQuery="ticketQuery"
        :tickets="tickets"
        ref="tickets"
        @hide-ticket="tickets = false"
        @hide-comment="showComments = false"
      ></ticket-comment>
      <tooltip
        v-if="hasTooltip"
        :annotOwners="annotOwners"
        :instance="instance"
        :idMap="idMap"
      ></tooltip>
    </div>
    <div class="webviewer-viewer" ref="viewer"></div>
  </div>
</template>

<script>
  /* eslint-disable */
  import WebViewer from "@pdftron/webviewer";
  import Heading from "@/app/old/desktop/shared/components/pdftron/components/heading";
  import Toolbar from "@/app/old/desktop/shared/components/pdftron/components/toolbar";
  import Navigation from "@/app/old/desktop/shared/components/pdftron/components/navigation";
  import IconCount from "@/app/old/desktop/shared/components/pdftron/components/icon-count";
  import tooltip from "@/app/old/desktop/shared/components/pdftron/components/tooltip";
  import TicketComment from "@/app/old/desktop/shared/components/pdftron/components/ticket-comment";
  import AnnotationsMixin from "@/app/old/desktop/shared/components/pdftron/mixins/annotations.mixin.vue";
  export default {
    name: "WebViewer",
    mixins: [AnnotationsMixin],
    components: {
      Heading,
      Toolbar,
      Navigation,
      IconCount,
      tooltip,
      TicketComment
    },
    props: {
      name: String,
      url: String,
      icon: String,
      bookmark: {
        default: false,
        type: Boolean
      },
      targetElement: Object,
      uid: String,
      hasNavigation: {
        type: Boolean,
        default: false
      },
      navigate: {
        type: Function
      },
      hasNavIcon: {
        type: Object,
        default: { left: false, right: false }
      },
      info: {
        type: Object
      },
      showViewer: {
        type: Boolean
      }
    },
    data() {
      return {
        instance: null,
        documentLoaded: false,
        isMobile: false,
        supported: false,
        docViewer: null,
        toolsEnabled: false,
        tickets: false,
        showComments: false,
        state: {},
        ckeditorLoaded: false,
        toolClicked: null,
        annotManager: null,
        idMap: {},
        annotMap: {},
        toolsGenre: {},
        channel: null,
        commentsChannel: null,
        ticketQuery: null,
        ownerMap: {},
        hasTooltip: false,
        annotPos: [],
        opts: {
          licenseKey:
            "SenseHawk Incorporated(sensehawk.com):OEM:SenseHawk::B+:AMS(20211118):09A5342D0447B80AB360B13AC982547860612F9DBF786A559B62358AAD22B3D04AF231F5C7",
          path: `${process.env.BASE_URL}webviewer`,
          css: `${process.env.BASE_URL}pdftron.css`,
          disabledElements: [
            "header",
            "ribbons",
            "toolsHeader",
            "searchButton",
            "menuButton",
            "leftPanelButton",
            "outlinesPanel",
            "bookmarksPanel",
            "thumbnailControl",
            "documentControl",
            "outlinesPanelButton",
            "contextMenuPopup",
            "notesPanel"
          ]
        }
      };
    },
    beforeDestroy() {
      this.destroy();
    },
    computed: {
      annotOwners() {
        return Object.keys(this.ownerMap).map(d => {
          return { uid: d };
        });
      }
    },
    watch: {
      url: function(val) {
        if (this.instance && this.name) {
          let extension = this.name.split(".").pop();
          this.initialize();
          this.checkSupportedFormat();
          this.instance.loadDocument(val, { extension: extension });
        }
      },
      showViewer: function(val) {
        if (val) {
          this.checkSupportedFormat();
          this.documentLoaded = true;
        }
      }
    },
    mounted: function() {
      // document.getElementsByTagName("html")[0].style.overflowY = "hidden";
      WebViewer(this.opts, this.$refs.viewer).then(instance => {
        const {
          docViewer,
          annotManager,
          Annotations,
          Tools,
          CoreControls
        } = instance;

        this.instance = instance;
        this.docViewer = docViewer;
        this.annotManager = annotManager;
        this.instance.setTheme("dark");
        const tool = this.docViewer.getTool("AnnotationCreateFreeText");
        tool.on("annotationAdded", annotation => {
          annotation.Font = "Roboto-Regular";
        });
        this.instance.annotationPopup.add({
          type: "customElement",
          render: this.renderCustomButton()
        });
        docViewer.on("documentLoaded", () => {
          this.toolsEnabled = true;
          if (this.$store.getters.annotation_tools && this.targetElement) {
            this.getAllAnnotations();
            // docViewer.on('zoomUpdated', () => {
            //   this.updateAnnotationPosition()
            // });
            docViewer.on("keyDown", e => {
              if (e.key === "Escape") {
                this.toolClicked();
              }
            });
            this.createCustomAnnotationsAndTools(
              docViewer,
              annotManager,
              Annotations,
              Tools,
              instance
            );
          } else this.toolsEnabled = false;
          this.hidePageNavigation();
          // const scrollElement = this.docViewer.getScrollViewElement()
          // scrollElement.onscroll = e => {
          //   this.$refs.comment_icon.$el.scrollTop = e.srcElement.scrollTop
          // }
          // this.getPageText(docViewer.getDocument())
          // this.mouseEventOnIcons()
          // this.hasTooltip = true
        });
        this.annotationEvents();
      });
    },
    methods: {
      initialize() {
        this.documentLoaded = true;
        this.hidebackground(".ticket-details-popup .animation-content", true);
        this.isMobile = this.$route.meta.screen == "mobile";
        this.commentsChannel = this.uid;
        this.annotMap = {};
        this.idMap = {};
        this.zoomValue = this.docViewer.getZoom();
        this.channel = this.$store.state.PUSHER.subscribe(
          `private-annotations-org_${this.$store.state.current_organization.uid}`
        );
        console.log("initialized");
        this.pusherEvents();
      },
      destroy() {
        this.documentLoaded = false;
        // document.getElementsByTagName('html')[0].style.overflowY = 'auto'
        this.channel = null;
        this.hidebackground(".ticket-details-popup .animation-content", false);
        this.$store.state.PUSHER.unsubscribe(
          `private-annotations-org_${this.$store.state.current_organization.uid}`
        );
        console.log("destroted");
      },
      closeWebviewer() {
        this.destroy();
        this.$emit("close");
      },
      hidePageNavigation() {
        if (this.docViewer.getPageCount() < 2) {
          this.instance.disableElements(["pageNavOverlay"]);
        }
      },
      createCustomAnnotationsAndTools(
        docViewer,
        annotManager,
        Annotations,
        Tools,
        instance
      ) {
        this.createCustomAnnotations(
          docViewer,
          annotManager,
          Annotations,
          Tools,
          instance
        );
      },
      mouseEventOnIcons() {
        const scrollElement = this.docViewer.getScrollViewElement();
        this.$refs.tickets.$el.addEventListener("mouseover", e => {
          let dataUid =
            e.fromElement.getAttribute("dataUid") ||
            e.srcElement.getAttribute("dataUid");
          if (
            dataUid &&
            this.$refs.comment_icon.$refs &&
            this.$refs.comment_icon.$refs[dataUid]
          ) {
            let icon = this.$refs.comment_icon.$refs[dataUid][0];
            icon.scrollIntoView();
            icon.classList.add("mouseon");
            this.$refs.comment_icon.$el.scrollTop =
              this.$refs.comment_icon.$el.scrollTop - 150;
            scrollElement.scrollTop = this.$refs.comment_icon.$el.scrollTop;
          } else {
            Object.values(this.$refs.comment_icon.$refs).forEach(d => {
              if (d[0]) d[0].classList.remove("mouseon");
            });
          }
        });
      },
      checkSupportedFormat() {
        let extension = this.name.split(".").pop();
        this.supported = this.instance.CoreControls.SupportedFileFormats.CLIENT.indexOf(
          extension.toLowerCase()
        );
        if (this.supported === -1) {
          this.$toast.show(
            "File Format not supported",
            "",
            this.$store.state.izitoast_options.appError
          );
          this.closeWebviewer();
          return;
        }
      },
      async getPageText(doc) {
        if (!doc) return;
        const pageIndex = 1;
        const top_x = 0,
          top_y = 0;
        const bottom_x = doc.getPageInfo(pageIndex).width,
          bottom_y = doc.getPageInfo(pageIndex).height;
        const text = await this.getOCRText(
          doc,
          pageIndex,
          top_x,
          top_y,
          bottom_x,
          bottom_y
        );
        console.log(text);
      },
      getOCRText(doc, pageIndex, top_x, top_y, bottom_x, bottom_y) {
        return new Promise(resolve => {
          doc.loadPageText(pageIndex, text => {
            doc.getTextPosition(pageIndex, 0, text.length, arr => {
              var indies = [];
              arr = arr.filter((item, index) => {
                if (
                  item.x4 >= top_x &&
                  item.y4 >= top_y &&
                  item.x2 <= bottom_x &&
                  item.y2 <= bottom_y
                ) {
                  indies.push(index);
                  return true;
                }
                return false;
              });
              let str = "";
              for (let i = 0, len = indies.length; i < len; i++) {
                str += text[indies[i]];
              }
              resolve(str);
            });
          });
        });
      },
      showCommentsBox(annotationID) {
        if (this.idMap[annotationID]) {
          this.showComments = true;
          this.tickets = false;
        }
      },
      showTicketsBox(annotationID) {
        if (this.idMap[annotationID]) {
          this.ticketQuery = {
            dataUid: this.idMap[annotationID].uid,
            ...this.targetElement
          };
          this.tickets = true;
          this.showComments = false;
        }
      },
      updateAnnotationPosition() {
        return;
        this.annotPos = [];
        this.$refs.comment_icon.$refs.container.style.height =
          this.docViewer.getViewerElement().offsetHeight + "px";
        const display = this.docViewer.getDisplayModeManager().getDisplayMode();
        this.annotManager.getAnnotationsList().forEach(f => {
          this.annotPos.push({
            ...display.pageToWindow(
              { x: f.getX(), y: f.getY() },
              f.getPageNumber()
            ),
            id: this.idMap[f.Id] ? this.idMap[f.Id].uid : null,
            page: f.getPageNumber()
          });
        });
      },
      hidebackground(q, b) {
        const div = document.querySelector(q);
        if (!div) return;
        div.style.overflowY = b ? "hidden" : "auto";
      }
    }
  };
</script>

<style lang="scss">
  .webviewer {
    iframe {
      height: 100%;
    }
  }
</style>
<style lang="scss" scoped>
  svg {
    fill: white;
  }
  $size: 18px;

  .webviewer {
    font-size: $size;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 42;
    display: flex;
    flex-flow: column;
    background-color: rgba(0, 0, 0, 0.8);
    &-viewer {
      width: 100%;
      margin: 0px auto;
      margin-bottom: 20px;
      padding: 0px 64px;
      @include for-size(tablet) {
        padding: 0px;
        margin-bottom: 0px;
      }
      flex: 1;
    }
  }
</style>
