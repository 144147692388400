var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sh-type-tag",style:(_vm.tag_background()),on:{"mouseenter":function($event){_vm.display_ellipsis = true},"mouseleave":function($event){_vm.display_ellipsis = false}}},[(_vm.tag_to_display.name && !_vm.tag_rename)?_c('span',{staticClass:"is-pointer"},[_c('span',{staticClass:" no-whitespace-wrap",class:[
        { 'translucent-tag': _vm.display_ellipsis },
        _vm.tag_font_size == 14
          ? 'is-semiBold-14-600-17'
          : 'is-semiBold-12-600-14'
      ]},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.tag_to_display.name,_vm.truncate_length, "..."))+" ")]),(_vm.display_ellipsis)?_c('span',{staticClass:"tag-ellipsis"},[(_vm.tag_in_loading_state)?[_c('i',{staticClass:"fa fa-spinner fa-spin mr-10"})]:[_c('sh-icon',{staticClass:"is-16x16 margin-auto",attrs:{"name":'ellipsis'},nativeOn:{"click":function($event){_vm.isMobile ? null : (_vm.dropdown_active = true)}}})],_c('div',{staticClass:"tag-close",style:(("background-color: " + (_vm.tag_to_display.color))),on:{"click":function($event){return _vm.unassign_tag(_vm.tag)}}},[_c('i',{staticClass:"fa fa-times is-absolute tag-close--icon",attrs:{"aria-hidden":"true"}})])],2):_vm._e()]):(_vm.tag_to_display.name)?_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"click-outside",rawName:"v-click-outside",value:(
      function () {
        _vm.tag_rename = false;
      }
    ),expression:"\n      () => {\n        tag_rename = false;\n      }\n    "},{name:"model",rawName:"v-model",value:(_vm.input_tag_name),expression:"input_tag_name"}],staticClass:"input-rename",attrs:{"type":"text"},domProps:{"value":(_vm.input_tag_name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.rename_tag($event)},"input":function($event){if($event.target.composing){ return; }_vm.input_tag_name=$event.target.value}}}):_vm._e(),(_vm.dropdown_active)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
      function () {
        _vm.dropdown_active = false;
      }
    ),expression:"\n      () => {\n        dropdown_active = false;\n      }\n    "}]},[_c('ul',{staticClass:"edit-tag-dropdown"},[_c('li',{staticClass:"edit-tag-dropdown__row",on:{"click":function($event){_vm.tag_rename = true;
          _vm.dropdown_active = false;
          _vm.input_tag_name = _vm.tag_to_display.name;}}},[_c('img',{staticClass:"edit-tag-dropdown__row__icon",attrs:{"src":require("@/assets/icons/type-tag/edit.svg")}}),_c('span',[_vm._v("Rename")])]),_c('li',{staticClass:"edit-tag-dropdown__row",on:{"click":function($event){return _vm.unassign_tag()}}},[_c('img',{staticClass:"edit-tag-dropdown__row__icon",attrs:{"src":require("@/assets/icons/type-tag/delete.svg")}}),_c('span',[_vm._v(_vm._s(_vm.localization("app-delete", "Delete")))])]),_c('li',{staticClass:"edit-tag-dropdown__row",class:{ 'edit-tag-dropdown__row--selected': _vm.color_dropdown },on:{"click":function($event){_vm.color_dropdown = true}}},[_vm._m(0),(_vm.color_dropdown)?_c('div',{staticClass:"change-color-dropdown"},_vm._l((_vm.colorPalette.tag),function(color,i){return _c('div',{key:i},[_c('div',{staticClass:"change-color-dropdown__color-div",on:{"click":function($event){return _vm.update_color(color)}}},[_c('div',{staticClass:"change-color-dropdown__color-div__color",class:{
                  'change-color-dropdown__color-div__color--selected':
                    _vm.selected_color === color
                },style:(("background-color: " + color))},[(_vm.selected_color === color)?_c('i',{staticClass:"fa fa-check change-color-dropdown__color-div__color__check",attrs:{"aria-hidden":"true"}}):_vm._e()])])])}),0):_vm._e()])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-tag-dropdown__row__change-color"},[_c('img',{staticClass:"edit-tag-dropdown__row__icon",attrs:{"src":require("@/assets/icons/type-tag/change-color.svg")}}),_c('span',[_vm._v("Change color")])])}]

export { render, staticRenderFns }