<template>
  <div
    class="is-sh-dropdown is-status"
    v-click-outside="() => (active = false)"
  >
    <div class @click="active = !active">
      <div v-if="is_multi_select" class=" has-primary-text is-flex is-vcenter">
        <div
          v-if="current_status && current_status.length"
          class="is-flex is-vcenter"
        >
          <sh-icon
            :name="`status-${status ? status : 1}`"
            :class="[`is-${size}x${size}`]"
            class="is-pointer"
            v-for="status in current_status"
            :key="status"
          ></sh-icon>
          <sh-icon
            :file="'terra_sprite'"
            :name="'terra-filter-add-status'"
            class="is-20x20 ml-5"
          ></sh-icon>
        </div>
        <p v-else class="is-flex is-vcenter ">
          <sh-icon
            :file="'terra_sprite'"
            :name="'terra-filter-add-status'"
            class="is-20x20 mr-5"
          ></sh-icon>
          <span>{{ localization("app-select-status", "Select status") }}</span>
        </p>
      </div>

      <div
        v-else-if="hide_names"
        v-tooltip="{
          content: localization('app-update-status', 'Update Status'),
          html: false
        }"
      >
        <div
          class="status-pointer  is-pointer"
          :class="[status_bg_classes[current_status ? current_status : 1]]"
        ></div>
      </div>

      <li
        v-else
        :class="[
          [active ? 'is-open' : 'is-close '],
          [
            has_dark_theme && active
              ? 'black-background'
              : has_dark_theme
              ? 'dark-theme-colors'
              : null
          ]
        ]"
        class="is-selector"
      >
        <div class="is-flex align-center is-pointer">
          <div
            class="status-pointer is-inline-block mr-10"
            :class="[
              [
                current_status
                  ? status_bg_classes[current_status]
                  : status_bg_classes[1]
              ]
            ]"
          ></div>
          <div
            :class="[
              has_dark_theme ? 'has-text-light' : 'has-text-theme-black'
            ]"
            class="   is-capitalized"
          >
            {{
              localization(
                `app-${status_options[current_status - 1].name
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`,
                status_options[current_status - 1].name
              )
            }}
          </div>
        </div>

        <div class="is-pointer">
          <sh-icon
            :name="active ? 'expand' : 'collapse'"
            class="is-16x16 ml-5"
          ></sh-icon>
        </div>
      </li>
    </div>

    <div
      :class="{ 'mobile-background-new': isMobile }"
      v-if="active"
      @click="active = !active"
    ></div>
    <ul
      class="is-options-list is-status"
      :style="[
        $route.name == 'tickets-list'
          ? null
          : hide_names
          ? { left: '-1rem' }
          : null
      ]"
      :class="[
        { 'dark-theme-colors': has_dark_theme },
        ,
        { 'mobile-view-option': isMobile }
      ]"
      v-if="active"
    >
      <li class="item mobile-header">
        <p class="">
          {{ localization("app-status", "Status") }}
        </p>
        <p @click="active = false">
          <sh-icon :name="'close'" class="is-12x12" v-if="active"></sh-icon>
        </p>
      </li>
      <li
        class="item is-flex is-between"
        v-for="state in status_options"
        :key="state.value"
        @click="state.value == 6 ? update_and_close() : change_status(state)"
        :class="[
          { 'dark-theme-colors': has_dark_theme },
          {
            'is-disabled is-not-allowed':
              disabled_status && disabled_status.includes(parseInt(state.value))
          }
        ]"
      >
        <div class="is-flex">
          <div
            class="status-pointer is-inline-block mr-10"
            :class="[status_bg_classes[state.value]]"
          ></div>

          <div class="item--text  is-capitalized">
            {{
              localization(
                `app-${state.name
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`,
                state.name
              )
            }}
          </div>
        </div>
        <p class="is-pulled-right">
          <sh-icon
            :name="'checkmark'"
            class="is-12x12 item--icon"
            v-if="
              !is_multi_select
                ? state.value == current_status
                : selected.includes(state.value)
            "
          />
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
  import TicketsCommonSegmentEvents from "@/app/old/desktop/tickets/components/ticket-details/components/tickets-common-segment-events.mixin.vue";

  export default {
    mixins: [TicketsCommonSegmentEvents],
    props: {
      is_multi_select: {
        type: Boolean,
        default: false
      },
      current_status: {},
      hide_names: {
        type: Boolean,
        default: false
      },
      size: {
        type: Number,
        default: 26
      },
      disabled_status: Array,
      has_dark_theme: {
        type: Boolean,
        default: false
      },
      status_options: {
        type: Array,
        default: [
          {
            name: "pending",
            value: 1,
            key: "pending"
          },
          {
            name: "in progress",
            value: 2,
            key: "inprogress"
          },
          {
            name: "resolved",
            value: 3,
            key: "resolved"
          },
          {
            name: "closed",
            value: 4,
            key: "closed"
          },
          {
            name: "rejected",
            value: 5,
            key: "rejected"
          }
        ]
      }
    },
    data() {
      return {
        active: false,
        selected: this.current_status || [],
        status_bg_classes: {
          "1": "is-bg-yellow",
          "2": "is-bg-green",
          "3": "is-bg-blue",
          "4": "is-bg-light-grey",
          "5": "is-bg-red",
          "6": "is-bg-blue"
        }
      };
    },

    methods: {
      change_status(status) {
        if (this.is_multi_select) {
          if (this.selected.includes(status.value)) {
            _.remove(this.selected, el => el == status.value);
            this.selected = Object.assign([], this.selected);
          } else {
            this.selected.push(status.value);
          }
          this.$emit("input", this.selected);
        } else {
          this.$emit("update-ticket-status", status);
          this.active = false;
        }
        this.ticket_status_changed({ status: status.key });
      },
      update_and_close() {
        this.change_status({
          name: "completed",
          value: "3"
        });
        this.$emit("close");
      }
    }
  };
</script>
<style lang="scss">
  .is-26x26 {
    height: 2.6rem;
    width: 2.6rem;
  }
  .is-status {
    .is-selector {
      &.is-close {
        display: flex;
        border-radius: 6px;
        &.pending-status {
          border: 1px solid $pending-status;
        }
        &.inprogress-status {
          border: 1px solid $completed-status;
        }
        &.resolved-status {
          border: 1px solid $completed-status;
          border: 1px solid $resolved-status;
        }
        &.closed-status {
          border: 1px solid $closed-status;
        }
        &.rejected-status {
          border: 1px solid $rejected-status;
        }
        @include for-size(portrait-tablets) {
          width: min-content;
          border: none !important;
        }
      }
      &.is-open {
        display: flex;
        width: 18rem;
        @include for-size(portrait-tablets) {
          width: min-content;
        }
      }
    }
    .is-options-list {
      width: 18rem;
      // left: -1rem;
      @include for-size(portrait-tablets) {
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .top-border-radius {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16) !important;
  }

  .td-status-open {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
    border: solid 1px rgba(23, 30, 72, 0.1) !important;
    background: #fff;
    width: 18rem;
    padding: 1rem;
  }
  .status-hover:hover {
    cursor: pointer;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
    border: solid 1px rgba(23, 30, 72, 0.1) !important;
    background: #fff;
    width: 18rem;
    padding: 1rem;
  }
  .td-status-close {
    border: solid 1px rgba(23, 30, 72, 0) !important;
    background: #fff;
    width: 18rem;
    padding: 1rem;
  }

  .td-list {
    padding: 0.7rem 1rem !important;
  }
</style>
