<template>
  <div class="template-dropdown__content--template">
    <div class="is-flex align-center">
      <span class="icon">
        <sh-icon :name="'search'" class="is-16x16"></sh-icon>
      </span>

      <input
        @click.prevent.stop="show_templates = true"
        class="input"
        :placeholder="
          localization(
            'app-select-or-create-template',
            'Select Template or Create new... '
          )
        "
        v-model="search_keyword"
        @input="selected_template = null"
      />
      <span
        @click="show_templates = !show_templates"
        class="image is-22x22 mt-5 pl-5"
      >
        <sh-icon
          class="icon"
          :name="show_templates ? 'expand' : 'collapse'"
        ></sh-icon>
      </span>
    </div>
    <div
      v-if="
        show_templates &&
          grouped_templates &&
          Object.keys(grouped_templates).length &&
          (!selected_template || selected_template.name !== search_keyword)
      "
      :class="{
        'is-hidden': !searched_templates || !searched_templates.length
      }"
    >
      <div
        v-click-outside="() => close_templates()"
        class="results has-slimscroll-xs"
        style="width:32rem;"
      >
        <div
          v-for="(list, index) in grouped_templates"
          :key="list"
          class="margin-10"
          :class="{ 'is-hidden': !list || !list.length }"
        >
          <div class=" is-semiBold-14-600-17" v-if="index !== 'no_asset'">
            <p
              v-if="asset && index == 'existing'"
              class="is-flex is-vcenter padding-10 bb-1 bt-1 "
            >
              <img
                :src="require(`@/assets/icons/primary-tag/asset.svg`)"
                class="image is-16x16 mr-10"
              />

              <span class="is-capitalized">
                {{ keyed_assets[asset] ? keyed_assets[asset].name : "Unknown" }}
              </span>
            </p>
            <p
              v-else-if="index !== 'existing'"
              class="is-flex is-vcenter padding-10 bb-1 bt-1"
            >
              <img
                :src="require(`@/assets/icons/primary-tag/asset.svg`)"
                class="image is-16x16 mr-10"
              />
              <span>
                {{ index }}
              </span>
            </p>
          </div>
          <p
            class="padding-10 is-semiBold-16-500-19 bb-1 bt-1 is-flex is-vcenter"
            v-else
          >
            <sh-icon
              :name="`organizations`"
              :file="'admin_sprite'"
              class="is-20x20 mr-7"
            ></sh-icon>
            <span class="is-semiBold-14-600-17">
              {{ $store.state.current_organization.name }}
            </span>
          </p>
          <div
            v-for="item in list"
            :key="item"
            class="item is-medium-14-500-17"
            @click.stop="select_template(item)"
            :class="[
              selected_template && selected_template.name == list.name
                ? 'selected_template'
                : null
            ]"
          >
            <p>
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  const queryString = require("query-string");
  import { mapState } from "vuex";

  export default {
    props: ["asset"],
    data() {
      return {
        show_templates: false,
        search_keyword: null,
        all_templates: null,
        selected_template: null
      };
    },
    mounted() {
      this.initial_data();
    },
    computed: {
      ...mapState(["assets"]),
      keyed_assets() {
        return _.keyBy(this.assets, "uid");
      },
      grouped_templates() {
        let grouped = _.groupBy(this.searched_templates, "templateAsset");
        let existing = this.asset ? grouped[this.asset] : [];
        let no_asset = grouped["null"] || [];

        delete grouped[this.asset];
        delete grouped["null"];
        let mapped = _.mapKeys(grouped, (value, key) => {
          return this.keyed_assets[key] ? this.keyed_assets[key].name : null;
        });
        const sortObject = obj =>
          Object.keys(obj)
            .sort()
            .reduce((res, key) => ((res[key] = obj[key]), res), {});
        let sorted = sortObject(mapped);

        return { existing, ...sorted, no_asset };
      },
      searched_templates() {
        if (this.search_keyword) {
          return this.all_templates.filter(f =>
            f.name.toLowerCase().includes(this.search_keyword.toLowerCase())
          );
        } else {
          return this.all_templates;
        }
      }
    },
    methods: {
      async initial_data() {
        let { data } = await ticketService
          .getAll({
            query: "isTemplate=true&pageSize=10000"
          })
          .catch(err => this.handleErrors(err));
        this.all_templates = data;
      },

      select_template(list) {
        this.selected_template = list;
        this.search_keyword = this.selected_template.name;
        this.$emit("select-template", this.selected_template);
      },
      close_templates() {
        this.show_templates = false;
      }
    }
  };
</script>

<style></style>
