<template>
  <div class="defect-columns-dropdown" v-click-outside="() => (active = false)">
    <div class="is-flex align-center" @click="active = !active">
      <span class="is-medium-14-500-17 option-title">
        <sh-icon
          :file="'thermv2_sprite'"
          :name="'add-icon'"
          class="is-24x24 mr-10"
      /></span>
    </div>
    <div
      class="dropdown"
      v-if="active"
      v-click-outside="() => (active = false)"
    >
      <div class="dropdown-header is-flex is-between align-center px-15 py-10">
        <span class="is-medium-16-500-19 mr-15">
          {{ localization("app-show", "Show") }}
        </span>
        <sh-icon
          @click.native="active = false"
          :name="'ticket-close-filter'"
          class="is-10x10 remove-icon is-pointer"
        />
      </div>

      <div class="defect-columns-dropdown-content has-slimscroll-xs mb-5">
        <div
          class="defect-columns-dropdown-content__item is-flex is-between align-center px-15 py-10 is-pointer"
          v-for="(column, id, i) in columns"
          :key="i"
          @click="
            column.hidden = !column.hidden;
            columns = [...columns];
          "
        >
          <span class="is-medium-14-500-24 cardfilter-item-name">{{
            localization(
              `app-${column.name
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              column.name
            )
          }}</span>
          <span>
            <sh-icon
              :name="
                !column.hidden
                  ? 'ticket-show-tick'
                  : 'ticket-show-tick-inactive'
              "
              class="is-14x14"
            />
          </span>
        </div>
      </div>
      <div
        @click="$emit('column-change', columns)"
        class="apply-columns-button mx-10 my-10 is-semiBold-14-600-17"
      >
        {{ localization("app-apply", "Apply") }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        active: false
      };
    },
    computed: {
      columns: {
        get() {
          return this.$store.state.thermv2.columns;
        },
        set(value) {
          this.$store.commit("thermv2/SET_COLUMNS", value);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .defect-columns-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    color: $primary-text;
  }
  .dropdown {
    box-shadow: 0px 5px 10px #00000029;
    border: solid 1px rgba(23, 30, 72, 0.1);
    background-color: #ffffff;
    position: absolute;
    width: 17.8rem;
    z-index: 15;
    border-radius: 0.4rem;
    top: 25px;
    right: 0;
    display: block;
  }

  .defect-columns-dropdown-content {
    border-top: 1px solid rgba(23, 30, 72, 0.1);
    border-bottom: 1px solid rgba(23, 30, 72, 0.1);
    height: 22rem;
    overflow: auto;
    &__item {
      height: 3.6rem;
      &:hover {
        background-color: #f7f8ff;
      }
      &:last-child:hover {
        border-radius: 0 0 0.4rem 0.4rem;
        background-color: #f7f8ff;
      }
    }
    .cardfilter-item-name {
      color: #171e48;
    }
  }
  .apply-columns-button {
    height: 3.6rem;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(92, 126, 247, 0.2);
    border: solid 1px $primary;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    cursor: pointer;
    &:hover {
      background-color: $primary;
      color: $white;
      box-shadow: 0 2px 5px 0 rgba(92, 126, 247, 0.2);
    }
  }
</style>
