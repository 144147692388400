<template>
  <div class="padding-20 create-items" @click.stop="">
    <hot-table
      ref="specified-items"
      :settings="{ ...hot_settings, ...table_settings }"
      height="350"
      :startRows="number_of_rows"
      class="is-medium-14-500-17 has-slimscroll-xs"
    >
      <hot-column class="has-no-access" :title="column_title"> </hot-column>
    </hot-table>
    <div class="mt-20 is-flex is-end">
      <button
        @click="bulk_create_items"
        class="sh-button is-semiBold-14-600-17 mr-15"
      >
        {{
          localization(
            `app-${button_text
              .split(" ")
              .join("-")
              .toLowerCase()}`,
            button_text
          )
        }}
      </button>
      <button
        @click="$emit('close')"
        class="sh-button sh-button--inverted is-semiBold-14-600-17"
      >
        {{ localization("app-cancel", "Cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
  import { HotTable, HotColumn } from "@handsontable/vue";

  export default {
    components: {
      HotTable,
      HotColumn
    },
    props: {
      create_items: {
        type: Function,
        required: true
      },
      number_of_rows: {
        type: Number,
        required: true,
        default: 14
      },
      no_items_message: {
        type: String,
        required: true,
        default: "No data specified!"
      },
      column_title: {
        type: String,
        required: true
      },
      table_settings: {
        type: Object,
        required: false,
        default: {}
      },
      button_text: {
        type: String,
        default: "Add"
      }
    },
    data() {
      return {
        hot_settings: {
          allowInsertRow: true,
          licenseKey: "non-commercial-and-evaluation",
          contextMenu: ["row_above", "row_below", "remove_row"],
          minSpareRows: 1,
          stretchH: "all"
        }
      };
    },
    methods: {
      async bulk_create_items() {
        const grid_data = _.flatten(
          this.$refs["specified-items"].hotInstance.getData()
        );
        const specified_items = grid_data.filter(item => item && item.length);
        if (specified_items.length) {
          try {
            await this.create_items(specified_items);
            this.$emit("close");
          } catch (err) {
            console.error(err);
            this.handleErrors(err, true);
          }
        } else {
          this.$toast.show(
            this.no_items_message,
            "",
            this.$store.state.izitoast_options.appError
          );
        }
      }
    },
    watch: {
      "$route.name": {
        handler() {
          this.$emit("close");
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "~handsontable/dist/handsontable.full.css";
  .create-items {
    .sh-button {
      padding: 1.1rem 2.8rem;
      user-select: none;
      &--inverted {
        padding: 1rem 2rem;
      }
    }
    .ht_master .wtHolder {
      &::-webkit-scrollbar {
        width: 0.5rem !important;
        height: 0.5rem !important;
        background-color: rgba(250, 247, 247, 0.8);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem !important;
        width: 0.5rem !important;
        background: rgba(184, 183, 183, 0.63) !important;
      }
    }
  }
</style>
