<template>
  <div class="px-20 py-15">
    <div
      class="is-inline-flex align-center is-pointer"
      @click="
        repeat_forever = true;
        until = '';
      "
    >
      <sh-icon
        :name="`radio-btn${repeat_forever ? '-selected' : ''}`"
        class="is-20x20 mr-10"
      ></sh-icon>
      <p class="is-regular-14-00-00" for="until">
        {{ localization("app-repeat-forever", "Repeat forever") }}
      </p>
    </div>
    <div>
      <div class="pt-10 is-flex">
        <div class="is-flex is-vcenter">
          <div
            @click="
              repeat_forever = false;
              until = 'occurrence';
            "
            class="is-inline-flex is-pointer"
          >
            <sh-icon
              :name="
                `radio-btn${
                  !repeat_forever && until == 'occurrence' ? '-selected' : ''
                }`
              "
              class="is-20x20 mr-10"
            ></sh-icon>
            <span class="is-regular-14-00-00">
              {{ localization("app-repeat", "Repeat") }}
            </span>
          </div>
        </div>
        <div class="is-flex">
          <input
            :class="[until != 'occurrence' ? 'is-disabled' : null]"
            type="number"
            min="1"
            name="internal"
            id="internal"
            class="small-input is-semiBold-16-600-19 has-text-centered"
            v-model="count"
          />
          <p
            :class="{ 'has-text-lightgrey': until != 'occurrence' }"
            class="is-medium-16-500-19"
          >
            {{ localization("app-times", "Times") }}
          </p>
        </div>
      </div>
      <div class="pt-10 is-flex">
        <div class="is-flex is-vcenter mr-30">
          <div
            @click="
              repeat_forever = false;
              until = 'date';
            "
            class="is-inline-flex is-pointer"
          >
            <sh-icon
              :name="
                `radio-btn${
                  !repeat_forever && until == 'date' ? '-selected' : ''
                }`
              "
              class="is-20x20 mr-10"
            ></sh-icon>
            <span class="is-regular-14-00-00">
              {{ localization("app-repeat-until", "Repeat until") }}</span
            >
          </div>
        </div>
        <repeat-date-calendar
          :is_disabled="until != 'date'"
          :date="date"
          @set_date="date = $event"
        ></repeat-date-calendar>
      </div>
    </div>
  </div>
</template>

<script>
  import RepeatDateCalendar from "./repeat-date.vue";

  export default {
    props: ["existing_rule"],
    components: {
      RepeatDateCalendar
    },
    data() {
      return {
        until: "occurrence",
        repeat_forever: true,
        count: 1,
        date: new Date()
      };
    },
    created() {
      if (this.existing_rule) {
        if (this.existing_rule.count) {
          this.repeat_forever = false;
          this.until = "occurrence";
          this.count = this.existing_rule.count;
        } else if (this.existing_rule.date) {
          this.repeat_forever = false;
          this.until = "date";
          this.date = new Date(this.existing_rule.date);
        } else {
          this.repeat_forever = true;
        }
      }
    }
  };
</script>

<style lang="scss">
  .px-25 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .ml-35 {
    margin-left: 3.5rem;
  }
</style>
