<template>
  <div v-click-outside="() => ((save_template_popup = false), (tags = null))">
    <p class="bulk_action_bar" v-if="selected.length || select_all_tickets">
      <span>
        <status-dropdown
          :hide_names="true"
          @update-ticket-status="
            bulk_update_tickets({ status: parseInt($event.value) })
          "
        ></status-dropdown>
      </span>
      <span>
        <priority-dropdown
          @change_ticket_priority="
            bulk_update_tickets({ priority: parseInt($event) })
          "
        ></priority-dropdown>
      </span>
      <span>
        <duedate-dropdown
          :bulk_selection="true"
          :icon="'due-date'"
          :icon_class="'is-22x22 '"
          @change-duedate="bulk_update_tickets({ dueDate: $event })"
        >
          ></duedate-dropdown
        >
      </span>
      <span>
        <user-dropdown
          :is_name_hidden="true"
          :pxsize="20"
          :icon="'assignee'"
          @update-user="bulk_update_tickets({ takenUp: $event })"
          :can_unassign="false"
        ></user-dropdown>
      </span>
      <span>
        <sh-icon
          @click.native="save_template_popup = !save_template_popup"
          :name="'load-from-template'"
          :file="'tickets-2'"
          class=" is-18x18 is-pointer"
          v-tooltip="{
            content: localization(
              'app-load-from-template',
              'Load from Template'
            )
          }"
        ></sh-icon>
      </span>
      <span class="is-flex align-center">
        <type-tag
          :display_on_hover="false"
          :complete="
            tag => {
              bulk_update_tickets({ tags: { add: [tag] } });
            }
          "
        >
          <template #toggle-dropdown>
            <sh-icon
              :name="'tags'"
              :file="'tickets-2'"
              class="is-16x16 is-pointer"
              v-tooltip="{
                content: localization('app-add-tags', 'Add tags'),
                html: false
              }"
            />
          </template>
        </type-tag>
      </span>
      <span @click="bulk_update_tickets({ watch: true })">
        <sh-icon
          :name="'watch'"
          :file="'tickets-2'"
          class=" is-16x16 is-pointer"
          v-tooltip="{
            content: localization('app-follow-tickets', 'Follow tickets'),
            html: false
          }"
        />
      </span>
      <span @click="add_users">
        <sh-icon
          :name="'users'"
          :file="'tickets-2'"
          class=" is-18x18 is-pointer"
          v-tooltip="{ content: localization('app-add-users', 'Add users') }"
        ></sh-icon>
      </span>
      <span @click="add_teams">
        <sh-icon
          :name="'teams'"
          :file="'tickets-2'"
          class=" is-18x18 is-pointer"
          v-tooltip="{ content: localization('app-add-teams', 'Add teams') }"
        ></sh-icon>
      </span>
      <span @click="bulk_update_tickets({ bookmark: true })">
        <sh-icon
          :name="'bookmark'"
          class=" is-16x16 is-pointer"
          v-tooltip="{
            content: localization('app-add-bookmark', 'Add bookmark'),
            html: false
          }"
        ></sh-icon>
      </span>
      <span @click="toggle_multiple_archive">
        <sh-icon
          :name="'archive'"
          class=" is-20x20 is-pointer"
          v-tooltip="{
            content: localization('app-archive-selected', 'Archive Selected'),
            html: false
          }"
        ></sh-icon>
      </span>
      <span
        @click="show_multi_delete = true"
        :class="{ 'is-disabled': select_all_tickets }"
      >
        <sh-icon
          :name="'delete-ticket'"
          class=" is-20x20 is-pointer"
          v-tooltip="{
            content: localization('app-delete-selected', 'Delete selected'),
            html: false
          }"
        ></sh-icon>
      </span>
    </p>
    <div class="is-absolute has-background-white">
      <!-- TODOvinita  pass asset(uid) -->
      <save-template
        :type="'load'"
        @close-load-template="save_template_popup = false"
        :tickets="selected"
        v-if="save_template_popup"
      ></save-template>
    </div>
    <prompt-modal
      :name="'delete-multiple'"
      :heading="'Delete'"
      :iconPath="require('@/assets/icons/prompt/delete.svg')"
      :footer="true"
      :cancelString="'Cancel'"
      :confirmString="'Delete'"
      v-if="show_multi_delete"
      @cancel="show_multi_delete = false"
      @confirm="delete_tickets"
      @closed="show_multi_delete = false"
    >
      <div class="prompt-modal__content__text" slot="content">
        <p>
          {{
            localization(
              "app-text-delete-multple-ticket-confirmation",
              "Are you sure you want to delete multiple tickets?"
            )
          }}
        </p>
        <p>
          {{
            localization(
              "app-this-action-cannot-be-undone",
              "This action can not be undone"
            )
          }}
        </p>
      </div>
    </prompt-modal>
  </div>
</template>
<script>
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-dropdown";
  import UserDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";
  import PriorityDropdown from "@/app/old/desktop/tickets/shared/components/priority-dropdown";
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import SaveTemplate from "@/app/old/desktop/tickets/shared/components/save-template";
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import AddUsers from "@/app/old/desktop/shared/components/add-users-modal";
  import { mapState, mapActions } from "vuex";
  import TypeTag from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";

  import { ticketService } from "@/app/old/desktop/shared/services/";
  export default {
    props: ["extra_filters"],
    components: {
      DuedateDropdown,
      UserDropdown,
      PriorityDropdown,
      StatusDropdown,
      PromptModal,
      SaveTemplate,
      TypeTag
    },
    data() {
      return {
        show_multi_delete: false,
        save_template_popup: false,
        tags: null
      };
    },
    computed: {
      ...mapState("tickets", [
        "selected",
        "tickets",
        "allFiltersData",
        "is_filters_selected",
        "select_all_tickets"
      ])
    },
    methods: {
      ...mapActions("tickets", [
        "update_multiple_tickets",
        "remove_tickets",
        "select_ticket",
        "delete_multiple_tickets"
      ]),
      async bulk_update_tickets(value) {
        if (this.select_all_tickets) {
          this.update_all_tickets(value);
        } else {
          this.update_multiple_tickets({
            tickets: this.selected,
            value: value
          });
        }
      },

      async update_all_tickets(value) {
        let attributes = value;
        let filters = {
          organization: this.$store.state.current_organization.uid
        };
        filters = { ...filters, ...this.extra_filters };
        if (this.is_filters_selected) {
          let filtersData = {};
          filtersData["takenUp"] = this.allFiltersData.assignee.map(o => o.uid);

          filtersData["priority"] = this.allFiltersData.priority;
          filtersData["status"] = this.allFiltersData.status;
          filtersData["tags"] = this.allFiltersData.tags;

          if (
            this.allFiltersData &&
            this.allFiltersData.dueDate &&
            this.allFiltersData.dueDate.length
          ) {
            filtersData["dueDateStart"] = this.allFiltersData.dueDate[0];
            filtersData["dueDateEnd"] = this.allFiltersData.dueDate[1];
          }
          if (
            this.allFiltersData &&
            this.allFiltersData.options &&
            this.allFiltersData.options.length
          ) {
            filtersData["bookmarked"] = this.allFiltersData.options.includes(
              "bookmarked"
            );
            filtersData["archived"] = this.allFiltersData.options.includes(
              "archived"
            );
          }
          filters = { ...filters, ...filtersData };
        }
        if (this.$route.name == "favorites") {
          filters = { ...filters, ...{ bookmarked: true } };
        }

        await ticketService.bulk_update_all({
          body: {
            // "strict": true,
            filters: filters,
            attributes
          }
        });
      },
      async toggle_multiple_archive() {
        if (this.show_archived) {
          this.bulk_update_tickets({ archive: false });
        } else {
          this.bulk_update_tickets({ archive: true });
        }
        this.remove_tickets(this.selected);
        this.select_ticket();
      },

      async delete_tickets() {
        this.delete_multiple_tickets(this.selected);
        this.show_multi_delete = false;
        // this.$emit("refresh-tickets");
      },

      add_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [],
            complete: async e => {
              this.bulk_update_tickets({ labels: { add: e } });
            }
          },
          { height: "80%" }
        );
      },
      add_users() {
        this.$emit("overlay_popup", true);
        this.$modal.show(
          AddUsers,
          {
            users: [],

            complete: async e => {
              this.bulk_update_tickets({ users: { add: e } });
            }
          },
          { height: "80%" },
          { name: "add-ticket-users" }
        );
      }
    }
  };
</script>

<style lang="scss">
  .bulk_action_bar {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    position: relative;
    height: 4rem;
    width: auto;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(92, 126, 247, 0.3);
    border: solid 1px $primary;
    justify-content: space-around;
    span {
      width: 3rem;
    }
  }
</style>
