<template>
  <div @click.stop class="save-template" v-click-outside="() => close_modal()">
    <div class="template-dropdown is-paddingless is-static has-slimscroll-xs">
      <div
        class="template-dropdown__content padding-30 no-padding-bottom pt-15"
      >
        <search-template
          ref="search"
          @select-template="selected_template = $event"
          :asset="asset"
        ></search-template>

        <div class="mt-15" v-if="type == 'save'">
          <p class="is-medium-14-500-17">
            {{ localization("app-asset", "Asset") }}
          </p>
          <div class="my-5 is-inline-flex no-whitespace-wrap align-center">
            <asset-dropdown
              :text="'Select Asset'"
              :existing="templateAsset"
              @update="templateAsset = $event"
              @remove="templateAsset = null"
            ></asset-dropdown>
          </div>
        </div>
        <div class="mt-15" v-if="type == 'save'">
          <p class="is-medium-14-500-17">
            {{ localization("app-share-with", "Share with") }}
          </p>
          <div class="my-15 is-inline-flex no-whitespace-wrap align-center">
            <b-radio
              class="radio"
              type="is-info"
              v-model="members"
              native-value="me"
            >
              <span
                class="is-regular-14-00-17 mr-5"
                :class="{ 'is-medium-14-500-17': members == 'me' }"
                >{{ localization("app-only-me", "Only Me") }}</span
              >
            </b-radio>
            <b-radio
              class="radio"
              type="is-info"
              v-model="members"
              native-value="all"
            >
              <span
                class="is-regular-14-00-17"
                :class="{ 'is-medium-14-500-17': members == 'all' }"
                >{{ localization("app-all-members", "All Members") }}</span
              >
            </b-radio>
            <sh-icon class="icon mr-5 mt-3" :name="'info'"></sh-icon>

            <b-radio
              class="radio no-margin-right ml-5"
              type="is-info"
              v-model="members"
              native-value="selected"
            >
              <span
                class="is-regular-14-00-17"
                :class="{ 'is-medium-14-500-17': members == 'selected' }"
                >{{ localization("app-select-people", "Select People") }}</span
              >
            </b-radio>
          </div>
          <p class="is-flex is-vcenter" v-if="members == 'selected'">
            <watcher-dropdown
              :custom_icon_size="30"
              :custom_icon="'add-user-assignee'"
              :ticket_watchers="shared_with"
              @update-watchers="shared_with = $event"
              :is_duplicate="true"
            ></watcher-dropdown>
          </p>
        </div>
        <div @change="fill_inclusions()">
          <p class="is-medium-14-500-17 mb-10 mt-20">
            {{ localization("app-include-in-template", "Include in Template") }}
          </p>

          <b-radio
            class="radio mr-30"
            type="is-info"
            v-model="inclusions"
            native-value="all"
          >
            <span
              class="is-regular-14-00-17 mr-30"
              :class="{ 'is-medium-14-500-17': inclusions == 'all' }"
              >{{ localization("app-everything", "Everything") }}</span
            >
          </b-radio>
          <b-radio
            class="radio mr-30"
            type="is-info"
            v-model="inclusions"
            native-value="custom"
          >
            <span
              class="is-regular-14-00-17"
              :class="{ 'is-medium-14-500-17': inclusions == 'custom' }"
              >{{ localization("app-custom", "Custom") }}</span
            >
          </b-radio>
          <div class="mt-10">
            <div class="divider has-full-width"></div>
          </div>
          <div class="is-flex is-between mt-15">
            <div class="left">
              <p class="is-flex is-vcenter padding-10 no-padding-left">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="assignee"
                  value="assignee"
                />
                <label for="assignee" class="ml-5 is-regular-14-00-17">
                  {{ localization("app-assignee", "Assignee") }}</label
                >
              </p>

              <p class="is-flex is-vcenter padding-10 no-padding-left">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="priority"
                  value="priority"
                />
                <label
                  for="priority"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-priority", "Priority") }}</label
                >
              </p>

              <p class="is-flex is-vcenter padding-10 no-padding-left">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="users"
                  value="users"
                />
                <label
                  for="users"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-users", "Users") }}</label
                >
              </p>
              <p class="is-flex is-vcenter padding-10 no-padding-left">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="status"
                  value="status"
                />
                <label
                  for="status"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-status", "Status") }}</label
                >
              </p>
            </div>
            <div class="middle">
              <p class="is-flex is-vcenter padding-10">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="attachments"
                  value="attachments"
                />
                <label
                  for="attachments"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-attachments", "Attachments") }}</label
                >
              </p>
              <p class="is-flex is-vcenter padding-10">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="watchers"
                  value="watchers"
                />
                <label
                  for="watchers"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-watchers", "Watchers") }}</label
                >
              </p>
              <p class="is-flex is-vcenter padding-10">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="teams"
                  value="teams"
                />
                <label
                  for="teams"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-teams", "Teams") }}</label
                >
              </p>
            </div>
            <div class="right">
              <p class="is-flex is-vcenter padding-10">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="checklists"
                  value="checklists"
                />
                <label
                  for="checklists"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-checklists", "Checklists") }}</label
                >
              </p>
              <p class="is-flex is-vcenter padding-10">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="tags"
                  value="tags"
                />
                <label
                  for="tags"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-tags", "Tags") }}</label
                >
              </p>
              <p class="is-flex is-vcenter padding-10">
                <input
                  type="checkbox"
                  name="create"
                  class="sh-custom-checkbox is-thinner fill"
                  v-model="template_options"
                  :disabled="inclusions == 'all'"
                  :class="{ 'is-disabled': inclusions == 'all' }"
                  id="schedule"
                  value="schedule"
                />
                <label
                  for="schedule"
                  class="ml-5 is-regular-14-00-17 has-text-theme-black"
                  >{{ localization("app-schedule", "Schedule") }}</label
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="type == 'save'"
        class="is-pointer padding-30 no-padding-top is-flex is-end align-center"
      >
        <div
          @click.stop="$emit('close-save-template')"
          class="mr-5 button padding-20 is-white is-medium-16-500-19 has-shadow-light-grey has-opacity-5"
        >
          {{ localization("app-cancel", "Cancel") }}
        </div>
        <div
          v-if="selected_template"
          @click="update_save_template"
          class="sh-button sh-button--inverted"
          :class="{ 'is-disabled': !selected_template }"
        >
          {{ localization("app-update-template", "Update Template") }}
        </div>
        <div
          @click="save_as_template"
          class="is-medium-16-500-19 sh-button sh-button--inverted"
          :class="{ 'is-disabled': selected_template }"
          v-else
        >
          {{ localization("app-save-as-template", "Save as Template") }}
        </div>
      </div>
      <div
        v-else
        class="is-pointer padding-30 no-padding-top is-flex is-end align-center"
      >
        <div
          @click.stop="$emit('close-load-template')"
          class="mr-5 button padding-20 is-white is-medium-16-500-19 has-shadow-light-grey has-opacity-5"
        >
          {{ localization("app-cancel", "Cancel") }}
        </div>
        <div
          @click="load_from_template"
          class="is-medium-16-500-19 sh-button sh-button--inverted"
          :class="{ 'is-disabled': !selected_template }"
        >
          {{ localization("app-load-template", "Load Template") }}
          <i v-if="loading_from_template" class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import AssetDropdown from "@/app/old/desktop/shared/components/asset-selection-dropdown.vue";
  import SearchTemplate from "./search-template";
  import WatcherDropdown from "@/app/old/desktop/tickets/shared/components/watchers-dropdown";
  import SegmentAnalytics from "@/app/old/desktop/shared/mixins/segment-analytics.mixin.vue";

  export default {
    props: ["tickets", "type", "callback", "loading_from_template", "asset"],
    components: {
      AssetDropdown,
      SearchTemplate,
      WatcherDropdown
    },
    mixins: [SegmentAnalytics],
    data() {
      return {
        shared_with: [],
        selected_template: null,

        inclusions: "all",
        template_options: [
          "assignee",
          "attachments",
          "checklists",
          "priority",
          "watchers",
          "users",
          "teams",
          "schedule",
          "tags",
          "status"
        ],
        members: "me",

        templateAsset: this.asset
      };
    },

    methods: {
      close_modal() {
        this.$emit("close-save-template");
      },

      fill_inclusions() {
        if (this.inclusions == "all") {
          let defaultInclusions = [
            "assignee",
            "attachments",
            "checklists",
            "priority",
            "watchers",
            "users",
            "teams",
            "schedule",
            "tags",
            "status"
          ];

          this.template_options = [...defaultInclusions];
        }
      },

      get_write_users() {
        if (this.members == "selected") {
          return this.shared_with;
        } else if (this.members == "me") {
          return [
            {
              uid: this.$store.state.claims.user_id,
              email: this.$store.state.claims.email
            }
          ];
        } else {
          return null;
        }
      },

      async save_as_template() {
        await this.update_tickets({
          templateUsers: this.get_write_users(),
          templatePublic: this.members == "all" ? true : false,
          action: "saveTemplate"
        }).then(() => {
          this.$emit("close-save-template");
          this.$toast.show(
            "Template created successfully",
            "",
            this.$store.state.izitoast_options.appSuccess
          );
        });
      },
      async update_save_template() {
        await this.update_tickets({
          templateUsers: this.get_write_users(),
          templatePublic: this.members == "all" ? true : false,
          action: "saveTemplate",
          templateUid: this.selected_template.uid
        }).then(() => {
          this.$emit("close-save-template");
          this.$toast.show(
            "Template updated successfully",
            "",
            this.$store.state.izitoast_options.appSuccess
          );
        });
      },
      async load_from_template() {
        await this.update_tickets({
          templateUid: this.selected_template.uid
        }).then(() => {
          this.$emit("close-load-template");
          this.segment_track_event("ticket_loaded_from_template");
          this.$toast.show(
            "Template loaded successfully",
            "",
            this.$store.state.izitoast_options.appSuccess
          );
        });
      },
      async update_tickets(extraProps) {
        let updateObject = {
          tickets: [],
          strict: true
        };
        let template = {
          action: "loadTemplate",
          name: this.$refs.search.search_keyword,
          include: {
            assignee: this.template_options.includes("assignee"),
            attachments: this.template_options.includes("attachments"),
            checklists: this.template_options.includes("checklists")
              ? {
                  asignee: true,
                  dueDate: true,
                  attachments: true,
                  resolved: true
                }
              : false,
            priority: this.template_options.includes("priority"),
            watchers: this.template_options.includes("watchers"),
            users: this.template_options.includes("users"),
            labels: this.template_options.includes("teams"),
            schedule: this.template_options.includes("schedule"),
            tags: this.template_options.includes("tags"),
            status: this.template_options.includes("status"),
            properties: false
          },
          templateAsset: this.templateAsset
        };
        template = { ...template, ...extraProps };

        if (this.callback) {
          this.callback(template);
          return;
        }

        updateObject.tickets = this.tickets;
        updateObject.tickets = this.tickets.map(uid => {
          return { ...template, ticketUid: uid };
        });

        return await ticketService
          .bulk_template({ body: updateObject })
          .catch(err => this.handleErrors(err));
      }
    }
  };
</script>
<style lang="scss">
  .save-template {
    .template-dropdown {
      position: relative;
      z-index: 100;
      border-radius: 10px;
      width: 383px;
      background-color: #fff;
      box-shadow: -10px 20px 50px 0 rgba(23, 30, 72, 0.2);

      &__content {
        &--template {
          position: relative;
        }
        &__text {
          text-align: center;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.75;
          color: rgba(23, 30, 72, 0.7);
        }

        .input {
          width: 95%;
          border: none;
          background-color: transparent;
          box-shadow: none;
          color: #171e48;
          font-size: 1.6rem;
          line-height: 2rem;
          border-radius: 0;
          border-bottom: 1px solid rgba(23, 30, 72, 0.1);

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: rgba(23, 30, 72, 0.4);
          }
        }

        .results {
          background-color: white;
          border: 1px solid #eee;
          z-index: 100;
          border-radius: 1rem;
          position: absolute;
          top: 4rem;
          padding: 1rem 0rem;
          box-shadow: -10px 20px 50px 0 rgba(23, 30, 72, 0.2);
          max-height: 200px;
          overflow: auto;
          .item {
            cursor: pointer;
            padding: 1.3rem 2rem;
            &:hover {
              background-color: rgba(92, 126, 247, 0.2);
            }
          }
        }
      }
    }

    .hover-background {
      &:hover {
        background-color: $primary-background;
      }
    }
  }

  @include for-size(portrait-tablets) {
    .save-template {
      .template-dropdown {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        left: 0;
        border-radius: 0 !important;
        .template-dropdown__content--template {
          padding-bottom: 100px;

          & + div {
            display: none;
          }
        }
        .results {
          height: 165px;
          border-radius: 0;
          border: 1px solid #ccc;
          border-top: 0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          position: fixed !important;
          top: unset !important;
          bottom: -7px !important;
          left: 0;
          background: #fff;
          z-index: 1000;
          width: 100vw !important;
        }
      }
    }
  }
</style>
