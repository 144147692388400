<template>
  <div class="therm-v2__compare-projects">
    <!-- Emits projects that were selected/unselected.
    Also, passing the group from the other component
    to this one -->
    <projects-list
      class="therm-v2__compare-projects__list-of-projects"
      :custom_styles_for_groups="`left: ${sidebar_width + 6}rem`"
      :custom_styles_for_projects="
        `left: 2rem !important; top: 2rem !important;`
      "
      :set_groups="set_groups"
      @group-selected="
        map_before_old_group = map_before_new_group;
        map_before_new_group = $event;
        active_map = 'before';
      "
      @projects-selected="
        (projects, groups) => {
          projects.forEach(project => {
            set_features_and_layers_for_map(project, groups);
          });
        }
      "
      @projects-unselected="
        (projects, groups) => {
          projects.forEach(project => {
            unset_features_and_layers_for_map(project, groups);
          });
        }
      "
    />
    <!-- The selected group is passed to the component above -->
    <projects-list
      class="therm-v2__compare-projects__list-of-projects"
      :custom_styles_for_groups="`right: 2rem`"
      custom_styles_for_projects="display: none"
      @group-selected="
        map_after_old_group = map_after_new_group;
        map_after_new_group = $event;
        active_map = 'after';
      "
    />
    <div class="thermv2-map-container__map__layer-select">
      <div
        v-tooltip="{
          content: localization(`app-${layer}`, layer),
          placement: 'left-center',
          classes: ['tooltip']
        }"
        :key="layer"
        v-for="layer in supported_layers"
        @click="update_active_layers(layer.toLowerCase())"
        class="is-pointer  is-semiBold-14-600-17 image my-10 mr-20 has-br-3"
        :class="[
          active_layers.includes(layer.toLowerCase())
            ? 'has-blue-border'
            : 'has-white-border'
        ]"
      >
        <img
          class="is-28x28"
          :src="require(`@/assets/icons/thermv2/${layer.toLowerCase()}.svg`)"
          alt=""
        />
        <div
          class="layer-check icon"
          v-if="active_layers.includes(layer.toLowerCase())"
        >
          <i class="fa fa-check has-text-white" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="thermv2-map-container__map" id="before"></div>
    <div class="thermv2-map-container__map" id="after"></div>
    <div class="therm-sidebar--filters is-fixed has-slimscroll-xs">
      <div
        @click="update_map_after_active_classes(vector.class_id)"
        v-tooltip="{
          content:
            vector.description.length > vector_description_max_length
              ? vector.description
              : null,
          classes: ['tooltip'],
          placement: 'top'
        }"
        v-for="vector in after_vector_filters"
        :key="vector.class_id"
        class="py-10 px-10 is-flex align-center pl-10 is-pointer"
      >
        <div
          class="therm-sidebar--filters--box is-relative"
          :style="{
            borderColor: vector.color,
            borderWidth: '1px',
            borderStyle: 'solid',
            width: '15px',
            height: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }"
        >
          <span
            class="is-inner is-absolute"
            :style="{
              background: map_after_active_class_ids.includes(vector.class_id)
                ? vector.color
                : 'none',
              width: '7px',
              height: '7px'
            }"
          ></span>
        </div>
        <p class="is-regular-14-500-17 therm-sidebar__item--text pl-10">
          {{ vector.description | truncate(vector_description_max_length) }}
          ({{ vector.count || 0 }})
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import ProjectsList from "./therm-v2-projects-list.vue";
  import MapsMixin from "../mixins/therm-v2.maps.mixin.vue";

  import { mapMutations, mapState } from "vuex";

  export default {
    components: {
      ProjectsList
    },
    mixins: [MapsMixin],
    data() {
      return {
        map_before: null,
        map_after: null,
        metas: {},
        active_map: "before",
        active_projects: [],
        map_after_new_group: [],
        map_before_new_group: [],
        map_after_old_group: [],
        map_before_old_group: [],
        active_layers: ["thermal"],
        supported_layers: ["CAD", "Visual", "Thermal"],
        after_vector_filters: [],
        map_after_active_class_ids: [],
        vector_description_max_length: 17
      };
    },
    mounted() {
      this.map_before = new mapboxgl.Map({
        container: "before",
        style: "mapbox://styles/mapbox/satellite-v8",
        center: [0, 0],
        zoom: 0
      });
      this.map_after = new mapboxgl.Map({
        container: "after",
        style: "mapbox://styles/mapbox/satellite-v8",
        center: [0, 0],
        zoom: 0
      });
      new mapboxgl.Compare(this.map_before, this.map_after, {
        // Set this to enable comparing two maps by mouse movement:
        //   mousemove: true
      });
      this.initialize_map(this.map_before);
      this.initialize_map(this.map_after);
      // Unset counts for issues in the left sidebar
      if (this.vector_types && this.vector_types.length) {
        const vector_types_with_count = this.vector_types.map(vector => {
          return {
            ...vector,
            count: 0
          };
        });
        this.SET_VECTOR_TYPES(vector_types_with_count);
        this.after_vector_filters = vector_types_with_count;
        this.map_after_active_class_ids = this.vector_types.map(
          vector => vector.class_id
        );
      }
    },
    computed: {
      ...mapState("thermv2", ["mini_thermsidebar"]),
      set_groups() {
        return [this.map_after_new_group];
      },
      sidebar_width() {
        return this.mini_thermsidebar ? 6 : 16;
      }
    },
    watch: {
      /* Applicable only to the map on the left (map_before)
      Update the map on the left whenever classes are enabled/disabled */
      active_class_ids: {
        deep: true,
        handler() {
          const before_features_source = this.map_before.getSource(
            "project-features"
          );
          if (!before_features_source) return;
          const before_project_features = [];
          for (let active_project of this.active_projects) {
            if (
              this.projects[active_project].group_uid ===
              this.map_before_new_group
            ) {
              before_project_features.push(
                ...Object.values(this.projects[active_project].features || [])
              );
            }
          }
          const before_active_vectors = before_project_features.filter(
            vector => {
              return this.active_class_ids.includes(vector.properties.class_id);
            }
          );
          before_features_source.setData({
            type: "FeatureCollection",
            features: before_active_vectors
          });
        }
      },
      /* Applicable only to the map on the right (map_after)
      Update the map on the right whenever classes are enabled/disabled */
      map_after_active_class_ids: {
        deep: true,
        handler() {
          const after_features_source = this.map_after.getSource(
            "project-features"
          );
          if (!after_features_source) return;
          const after_project_features = [];
          for (let active_project of this.active_projects) {
            if (
              this.projects[active_project].group_uid ===
              this.map_after_new_group
            ) {
              after_project_features.push(
                ...Object.values(this.projects[active_project].features || [])
              );
            }
          }
          const after_active_vectors = after_project_features.filter(vector => {
            return this.map_after_active_class_ids.includes(
              vector.properties.class_id
            );
          });
          after_features_source.setData({
            type: "FeatureCollection",
            features: after_active_vectors
          });
        }
      },
      vector_types(current_types, previous_types) {
        if (!previous_types) {
          this.after_vector_filters = this.vector_types;
          this.map_after_active_class_ids = this.vector_types.map(
            vector => vector.class_id
          );
        }
      }
    },
    methods: {
      ...mapMutations("thermv2", ["SET_VECTOR_TYPES"]),
      initialize_map(map) {
        if (map.loaded()) {
          this.setup_map_with_features(map);
        } else {
          map.on("load", () => {
            this.setup_map_with_features(map);
          });
        }
      },
      update_map_after_active_classes(class_id) {
        const class_id_index = this.map_after_active_class_ids.findIndex(
          active_class_id => active_class_id === class_id
        );
        if (class_id_index > -1) {
          this.map_after_active_class_ids.splice(class_id_index, 1);
        } else {
          this.map_after_active_class_ids = [
            ...this.map_after_active_class_ids,
            class_id
          ];
        }
      },
      // Updates counts for issues displayed in the filters
      set_vectors_count() {
        let before_vector_filters = [];
        let after_vector_filters = [];
        if (this.vector_types) {
          const class_counts_before = {};
          const class_counts_after = {};
          for (let vector_type of this.vector_types) {
            class_counts_before[vector_type.class_id] = 0;
            class_counts_after[vector_type.class_id] = 0;
          }
          for (let active_project of this.active_projects) {
            const project_group = this.projects[active_project].group_uid;
            const project_features = Object.values(
              this.projects[active_project].features || []
            );
            if (project_group === this.map_before_new_group) {
              project_features.forEach(feature => {
                class_counts_before[feature.properties.class_id] += 1;
              });
            }
            if (project_group === this.map_after_new_group) {
              project_features.forEach(feature => {
                class_counts_after[feature.properties.class_id] += 1;
              });
            }
          }
          before_vector_filters = this.vector_types.map(vector => {
            return {
              ...vector,
              count: class_counts_before[vector.class_id]
            };
          });
          after_vector_filters = this.vector_types.map(vector => {
            return {
              ...vector,
              count: class_counts_after[vector.class_id]
            };
          });
        }
        this.after_vector_filters = after_vector_filters;
        // Updates counts for the issues displayed in the left sidebar
        this.SET_VECTOR_TYPES(before_vector_filters);
      },
      update_active_layers(selected_layer) {
        const layer_index = this.active_layers.findIndex(
          active_layer => active_layer === selected_layer
        );
        if (layer_index > -1) {
          this.active_layers.splice(layer_index, 1);
        } else {
          this.active_layers.push(selected_layer);
        }
        this.active_projects.forEach(project => {
          // Enables the same layer for both the maps
          if (this.map_before_new_group === this.map_after_new_group) {
            this.set_active_layers(
              project,
              true,
              this.map_before,
              this.active_layers
            );
            this.set_active_layers(
              project,
              true,
              this.map_after,
              this.active_layers
            );
          } else {
            // Figure out the map the project belongs to and update the map accordingly
            const active_map = this.map_from_project(project);
            this.set_active_layers(
              project,
              true,
              active_map,
              this.active_layers
            );
          }
        });
      },
      set_active_vectors(map, project_id = null) {
        const features_source = map.getSource("project-features");
        if (!features_source) return;
        let active_project_features = [];
        const project_features = Object.values(
          this.projects[project_id].features
        );
        active_project_features = [
          ...(features_source._data.features || []),
          ...(project_features || [])
        ];
        const active_vectors = active_project_features.filter(vector => {
          return map._container.id === "before"
            ? this.active_class_ids.includes(vector.properties.class_id)
            : this.map_after_active_class_ids.includes(
                vector.properties.class_id
              );
        });
        features_source.setData({
          type: "FeatureCollection",
          features: active_vectors
        });
      },
      unset_active_vectors(map, project_id) {
        const features_source = map.getSource("project-features");
        if (!features_source) return;
        const active_vectors = features_source._data.features.filter(
          feature => feature.properties.projectUid !== project_id
        );
        features_source.setData({
          type: "FeatureCollection",
          features: active_vectors
        });
      },
      async set_features_and_layers_for_map(project_id, groups) {
        console.log("Selected a project: ", project_id);
        if (!this.active_projects.includes(project_id))
          this.active_projects.push(project_id);
        const project_details = await this.get_project_details(project_id);
        if (groups || this.map_before_new_group !== this.map_after_new_group) {
          // This block gets executed when we update groups for the views
          const active_map = this.map_from_project(project_id);
          this.set_features_and_layers_for_project(
            active_map,
            project_id,
            project_details
          );
        } else {
          // This block gets executed when we're updating projects
          // for views with the same groups/scan dates
          this.set_features_and_layers_for_project(
            this.map_before,
            project_id,
            project_details
          );
          this.set_features_and_layers_for_project(
            this.map_after,
            project_id,
            project_details
          );
        }
        console.log("active projects : ", this.active_projects);
        this.set_vectors_count();
      },
      set_features_and_layers_for_project(map, project_id, project_details) {
        this.set_active_vectors(map, project_id);
        this.set_active_layers(project_id, true, map, this.active_layers);
        this.fly_to_project(project_details, map);
      },
      unset_features_and_layers_for_map(project_id, groups = null) {
        console.log("Unselected a project: ", project_id);
        // features belonging to projects that were a part of the previously
        // active groups have to be hidden
        if (groups) {
          const previously_active_map = this.map_from_previous_group(
            project_id
          );
          this.unset_features_and_layers_for_project(
            previously_active_map,
            project_id
          );
          const map_associated_with_project = this.map_from_project(project_id);
          if (!map_associated_with_project) {
            this.active_projects = this.active_projects.filter(
              active_project => active_project !== project_id
            );
          }
        } else if (this.map_before_new_group === this.map_after_new_group) {
          // Update both the maps since they are associated with the same group
          this.unset_features_and_layers_for_project(
            this.map_before,
            project_id
          );
          this.unset_features_and_layers_for_project(
            this.map_after,
            project_id
          );
          this.active_projects = this.active_projects.filter(
            active_project => active_project !== project_id
          );
        } else {
          // Figure out the map the project belongs to and update the map accordingly
          console.log(
            "deactivating projects in currently active group : ",
            project_id
          );
          this.unset_features_and_layers_for_project(
            this.map_from_project(project_id),
            project_id
          );
          this.active_projects = this.active_projects.filter(
            active_project => active_project !== project_id
          );
        }
        console.log("active projects : ", this.active_projects);
        this.set_vectors_count();
      },
      unset_features_and_layers_for_project(map, project_id) {
        this.unset_active_vectors(map, project_id);
        this.set_active_layers(project_id, false, map, this.active_layers);
      },
      map_from_previous_group(project_id) {
        // Figure out the map to update using the project ID
        // Find the group/scan date the project belongs to and return
        // the map instance using the group/scan date
        let previously_active_map = null;
        const group_for_project = this.container.find(
          container_item => container_item.projectUid === project_id
        ).groupUid;
        const same_groups =
          this.map_after_old_group === this.map_before_old_group;
        if (
          (same_groups ? this.active_map === "after" : true) &&
          group_for_project === this.map_after_old_group
        ) {
          previously_active_map = this.map_after;
        } else if (
          (same_groups ? this.active_map === "before" : true) &&
          group_for_project === this.map_before_old_group
        ) {
          previously_active_map = this.map_before;
        }
        return previously_active_map;
      },
      // Figure out the map the project belongs to using the group
      map_from_project(project_id) {
        const group_for_project = this.container.find(
          container_item => container_item.projectUid === project_id
        ).groupUid;
        const same_groups =
          this.map_after_new_group === this.map_before_new_group;
        if (
          (same_groups ? this.active_map === "after" : true) &&
          group_for_project === this.map_after_new_group
        ) {
          return this.map_after;
        } else if (
          (same_groups ? this.active_map === "before" : true) &&
          group_for_project === this.map_before_new_group
        ) {
          return this.map_before;
        } else {
          return null;
        }
      },
      /* See if details for the project are present in the store
      If not, fetch the details for the project and push the
      the details to the store */
      async get_project_details(project_id) {
        let project_details = this.projects[project_id];
        if (!project_details) {
          project_details = await this.fetch_project_details(project_id);
        }
        return project_details;
      }
    }
  };
</script>

<style lang="scss">
  .therm-v2__compare-projects {
    &__list-of-projects {
      position: fixed;
      z-index: 15;
    }
    .thermv2-map-container__map__layer-select {
      right: 2rem;
      top: 2rem;
      z-index: 30;
    }
    .therm-sidebar--filters {
      height: 34.5rem;
      right: 2rem;
      bottom: 8rem;
      background: white;
      padding: 1rem;
      overflow: scroll;
      box-shadow: 0px 2px 5px #00000033;
    }
    .has-slimscroll-xs {
      &::-webkit-scrollbar {
        width: 0.3rem !important;
        height: 0.3rem !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1rem !important;
        background: rgba(250, 247, 247, 0.7) !important;
      }

      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
  }
</style>
