var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-dropdown__content--template"},[_c('div',{staticClass:"is-flex align-center"},[_c('span',{staticClass:"icon"},[_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'search'}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_keyword),expression:"search_keyword"}],staticClass:"input",attrs:{"placeholder":_vm.localization(
          'app-select-or-create-template',
          'Select Template or Create new... '
        )},domProps:{"value":(_vm.search_keyword)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.show_templates = true},"input":[function($event){if($event.target.composing){ return; }_vm.search_keyword=$event.target.value},function($event){_vm.selected_template = null}]}}),_c('span',{staticClass:"image is-22x22 mt-5 pl-5",on:{"click":function($event){_vm.show_templates = !_vm.show_templates}}},[_c('sh-icon',{staticClass:"icon",attrs:{"name":_vm.show_templates ? 'expand' : 'collapse'}})],1)]),(
      _vm.show_templates &&
        _vm.grouped_templates &&
        Object.keys(_vm.grouped_templates).length &&
        (!_vm.selected_template || _vm.selected_template.name !== _vm.search_keyword)
    )?_c('div',{class:{
      'is-hidden': !_vm.searched_templates || !_vm.searched_templates.length
    }},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.close_templates(); }),expression:"() => close_templates()"}],staticClass:"results has-slimscroll-xs",staticStyle:{"width":"32rem"}},_vm._l((_vm.grouped_templates),function(list,index){return _c('div',{key:list,staticClass:"margin-10",class:{ 'is-hidden': !list || !list.length }},[(index !== 'no_asset')?_c('div',{staticClass:" is-semiBold-14-600-17"},[(_vm.asset && index == 'existing')?_c('p',{staticClass:"is-flex is-vcenter padding-10 bb-1 bt-1 "},[_c('img',{staticClass:"image is-16x16 mr-10",attrs:{"src":require("@/assets/icons/primary-tag/asset.svg")}}),_c('span',{staticClass:"is-capitalized"},[_vm._v(" "+_vm._s(_vm.keyed_assets[_vm.asset] ? _vm.keyed_assets[_vm.asset].name : "Unknown")+" ")])]):(index !== 'existing')?_c('p',{staticClass:"is-flex is-vcenter padding-10 bb-1 bt-1"},[_c('img',{staticClass:"image is-16x16 mr-10",attrs:{"src":require("@/assets/icons/primary-tag/asset.svg")}}),_c('span',[_vm._v(" "+_vm._s(index)+" ")])]):_vm._e()]):_c('p',{staticClass:"padding-10 is-semiBold-16-500-19 bb-1 bt-1 is-flex is-vcenter"},[_c('sh-icon',{staticClass:"is-20x20 mr-7",attrs:{"name":"organizations","file":'admin_sprite'}}),_c('span',{staticClass:"is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.$store.state.current_organization.name)+" ")])],1),_vm._l((list),function(item){return _c('div',{key:item,staticClass:"item is-medium-14-500-17",class:[
            _vm.selected_template && _vm.selected_template.name == list.name
              ? 'selected_template'
              : null
          ],on:{"click":function($event){$event.stopPropagation();return _vm.select_template(item)}}},[_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])])})],2)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }