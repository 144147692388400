<template>
  <div
    class="therm-v2__sidebar__export-to-csv has-width-100 is-flex has-direction-column is-medium-12-500-14"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
    v-tooltip="{
      content: all_filtered_defects.length
        ? null
        : localization('app-no-data-to-export', 'No data to export'),
      classes: ['tooltip']
    }"
  >
    <template v-if="is_exporting">
      <template v-if="total_files_to_export > 1">
        <div
          class="therm-v2__sidebar__export-to-csv__progress is-absolute"
          :style="
            `width: ${Math.floor(
              (current_export_file_index / total_files_to_export) * 100
            )}%`
          "
        />
        <div class="is-flex is-vcenter has-space-between px-10">
          <div class="therm-v2__sidebar__export-to-csv__loading my-15">
            <div class="is-flex has-direction-column ml-5">
              <span class="is-medium-14-500-17">
                {{ localization("app-exporting-pdfs", "Exporting PDFs") }}
              </span>
              <span class="is-medium-11-00-12 text-inactive mt-3">
                {{ localization("app-processing-file", "Processing file") }}
                {{ current_export_file_index }}/{{ total_files_to_export }}
              </span>
            </div>
          </div>
          <div @click="cancelExport" class="close_button">
            <sh-icon :name="'close-blue'" class="is-10x10"></sh-icon>
          </div>
        </div>
      </template>
      <div
        v-else
        class="therm-v2__sidebar__export-to-csv__loading is-flex is-vcenter has-space-between px-10 my-15"
      >
        <div class="is-flex is-vcenter">
          <i class="fa fa-circle-notch fa-spin mr-7" style="color: #2585d3" />
          {{
            is_exporting === "pdf"
              ? localization("app-exporting-pdf", "Exporting PDF")
              : localization("app-exporting-csv", "Exporting CSV")
          }}...
        </div>
        <div @click="cancelExport" class="close_button">
          <sh-icon :name="'close-blue'" class="is-10x10"></sh-icon>
        </div>
      </div>
    </template>
    <div
      v-else
      class="is-flex align-center pl-10 my-15"
      :class="{
        'pl-17': !$store.getters.export_therm_pdf,
        'is-disabled': !all_filtered_defects.length
      }"
    >
      <sh-icon
        name="export-to-csv"
        class="is-14x14 mr-10"
        file="thermv2_sprite"
      />
      <span class="is-regular-12-00-14">
        {{ localization("app-export-to", "Export to") }} :
      </span>
      <span
        class="is-pointer is-semiBold-12-600-17 ml-5 mr-3"
        role="button"
        @click="csvExportHandler"
      >
        {{ localization("app-csv", "CSV").toUpperCase() }}
      </span>
      <div v-if="$store.getters.export_therm_pdf">
        <div class="vert-separator"></div>
        <span
          @click="pdfExportHandler"
          class="is-pointer is-semiBold-12-600-17 ml-3"
          role="button"
        >
          {{ localization("app-pdf", "PDF").toUpperCase() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ticketService,
    assetService
  } from "@/app/old/desktop/shared/services/";
  import { mapGetters, mapState } from "vuex";
  const ChartJsImage = require("chartjs-to-image");
  import * as Comlink from "comlink";

  export default {
    data() {
      return {
        max_issues_pdf_export: 100,
        current_export_file_index: 0,
        total_files_to_export: 0,
        is_exporting: "",
        chunked_defects_array: [],
        pdf_generator_worker: null,
        pdf_generator: null
      };
    },
    created() {
      window.addEventListener("beforeunload", this.confirmLeaving);
    },
    computed: {
      ...mapState("thermv2", [
        "active_filters",
        "active_projects",
        "active_class_ids",
        "projects",
        "vector_types",
        "container",
        "defects"
      ]),
      ...mapGetters("thermv2", ["filtered_defects", "project_defects"]),
      all_filtered_defects() {
        const defects = this.filtered_defects.concat(this.project_defects);
        return _.orderBy(
          defects,
          ["properties.tableRow", "properties.tableColumn"],
          ["asc", "asc"]
        );
      },
      class_ids() {
        const class_ids = {};
        this.vector_types.forEach(vector => {
          class_ids[vector.class_id] = vector.description;
        });
        return class_ids;
      },
      block_names_list() {
        let blocks = this.active_projects.reduce((acc, uid) => {
          if (this.projects[uid]) acc.push(this.projects[uid].name);
          return acc;
        }, []);
        return blocks.join(", ");
      },
      asset_name() {
        try {
          const asset_id = this.$route.query.asset;
          return this.$store.state.assets.find(a => a.uid == asset_id).name;
        } catch {
          return "Unnamed Asset";
        }
      }
    },
    methods: {
      cleanupAfterExport() {
        this.is_exporting = "";
        this.total_files_to_export = 0;
        this.current_export_file_index = 0;
      },
      cancelExport() {
        this.is_exporting = "";
        this.pdf_generator_worker.terminate();
      },
      confirmLeaving(event) {
        if (this.is_exporting) {
          const unsaved_changes_warning =
            "Some files are still exporting. Are you sure you wish to leave?";
          event.returnValue = unsaved_changes_warning;
          return unsaved_changes_warning;
        }
      },
      generateFileName() {
        if (this.total_files_to_export > 1) {
          return `${this.$store.state.current_organization.name} - ${this.asset_name} - ${this.block_names_list} - Part ${this.current_export_file_index} of ${this.total_files_to_export}`;
        }
        return `${this.$store.state.current_organization.name} - ${this.asset_name} - ${this.block_names_list}`;
      },
      downloadFile(blob, filename) {
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
      },
      async createSummaryData() {
        const class_names = [];
        const group_defects = {};
        const unordered_histogram_data = {};
        const histogram = new ChartJsImage();
        const pie_chart = new ChartJsImage();
        const severity = {
          "Severity 1": 0,
          "Severity 2": 0,
          "Severity 3": 0
        };

        for (const proj of this.active_projects) {
          group_defects[this.projects[proj].name] = {};
        }

        for (const defect of this.all_filtered_defects) {
          const class_name = this.class_ids[defect.properties.classId];
          const project_name = this.projects[defect.properties.projectUid].name;
          const diff = defect.properties.temperatureDifference;

          if (!class_names.includes(class_name)) class_names.push(class_name);

          if (!group_defects?.[project_name]?.[class_name]) {
            group_defects[project_name][class_name] = 1;
          } else {
            group_defects[project_name][class_name] =
              group_defects[project_name][class_name] + 1;
          }

          if (diff) {
            const ceil = Math.ceil(diff);
            const floor = Math.floor(diff);
            const key =
              floor === ceil ? `${floor}-${floor + 1}` : `${floor}-${ceil}`;
            if (!unordered_histogram_data[key]) {
              unordered_histogram_data[key] = 1;
            } else {
              unordered_histogram_data[key] += 1;
            }
          }

          // How defects can be categorized based on severity
          // Severity 3: Temperature Difference is greater than 40 degrees
          // Severity 1: Anomaly type is Dirt, Vegetation, Suspected Soiling or Shadow
          // Severity 2: All other defects (electrical faults - hotspots, bypass diode, string failures, tracker malfunction etc.)

          if (diff && diff > 40) {
            severity["Severity 3"]++;
          } else if ([108, 109, 303, 315].includes(defect.properties.classId)) {
            severity["Severity 1"]++;
          } else {
            severity["Severity 2"]++;
          }
        }

        const ordered_histogram_data = Object.keys(unordered_histogram_data)
          .sort()
          .reduce((obj, key) => {
            obj[key] = unordered_histogram_data[key];
            return obj;
          }, {});

        const length_of_keys = Object.keys(ordered_histogram_data).length;
        histogram.setConfig({
          type: "bar",
          options: {
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Temperature Range (°C)",
                    font: {
                      size: 20
                    }
                  }
                }
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Number of Anomalies",
                    font: {
                      size: 20
                    }
                  }
                }
              ]
            },
            legend: {
              display: false
            },
            title: {
              display: false
            }
          },
          data: {
            labels: Object.keys(ordered_histogram_data),
            legend: {
              display: false
            },
            datasets: [
              {
                label: "Temperature",
                backgroundColor: Array(length_of_keys).fill("#0d84d5"),
                data: Object.values(ordered_histogram_data)
              }
            ]
          }
        });
        histogram
          .setWidth(854)
          .setHeight(480)
          .setBackgroundColor("transparent");

        pie_chart.setConfig({
          type: "pie",
          data: {
            labels: Object.keys(severity),
            datasets: [
              {
                label: "Severity",
                backgroundColor: ["#ffbb43", "#ff8652", "#c13525"],
                data: Object.values(severity)
              }
            ]
          }
        });
        pie_chart.setWidth(350).setHeight(350);

        return {
          group_defects,
          class_names,
          severity,
          severity_pie: await pie_chart.toDataUrl(),
          temperature_histogram: await histogram.toDataUrl()
        };
      },
      async exportToPdf(index, defects, links_by_defect) {
        if (!this.asset) {
          const asset_details = await assetService.get({
            id: this.all_filtered_defects[0].targetElement.asset
          });
          this.asset = asset_details.name;
          if (!this.is_exporting) {
            return this.cleanupAfterExport();
          }
        }

        const payload = {
          defects,
          block_names: this.block_names_list,
          group_name: this.projects[this.active_projects[0]].group_name,
          projects: this.projects,
          class_ids: this.class_ids,
          org_name: this.$store.state.current_organization.name,
          asset: this.asset,
          links_by_defect,
          current_doc_index: this.current_export_file_index,
          total_docs: this.total_files_to_export,
          summary: null
        };

        if (this.current_export_file_index === 1) {
          payload["summary"] = await this.createSummaryData();
        }

        const generated_blob = await this.pdf_generator.generate_document_definition(
          payload
        );
        if (!this.is_exporting) {
          return this.cleanupAfterExport();
        }

        this.downloadFile(generated_blob, `${this.generateFileName()}.pdf`);

        if (
          !this.is_exporting ||
          this.chunked_defects_array.length === index + 1
        ) {
          return this.cleanupAfterExport();
        }
      },
      async pdfExportHandler() {
        this.pdf_generator_worker = new Worker(
          "../utils/therm-v2-pdf-generator.js",
          { type: "module" }
        );
        this.pdf_generator = Comlink.wrap(this.pdf_generator_worker);
        this.is_exporting = "pdf";

        const console_timer_name = "Time taken for PDF export";
        console.timeEnd(console_timer_name);
        console.time(console_timer_name);

        this.chunked_defects_array = _.chunk(
          this.all_filtered_defects,
          this.max_issues_pdf_export
        );
        this.total_files_to_export = this.chunked_defects_array.length;

        for (let [index, defects] of this.chunked_defects_array.entries()) {
          if (!this.is_exporting) {
            this.cleanupAfterExport();
            break;
          }
          this.current_export_file_index = index + 1;
          let attachments_for_defects = await ticketService.get_attachments_for_tickets(
            { body: { tickets: defects.map(defect => defect.uid) } }
          );
          attachments_for_defects = attachments_for_defects.filter(attachment =>
            /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(attachment.fileName)
          );
          if (!attachments_for_defects.length) continue;

          const links_by_defect = _.groupBy(
            attachments_for_defects,
            "foreignObjectUid"
          );

          try {
            await this.exportToPdf(index, defects, links_by_defect);
          } catch (error) {
            if (this.is_exporting) alert("PDF generation failed");
            console.error(`%cerror`, "font-weight: bold;", error);
            this.cleanupAfterExport();
            break;
          }
        }
        console.timeEnd(console_timer_name);
      },
      exportToCsv(doc) {
        let csv;
        const Papa = require("papaparse");
        Papa.parse(doc, {
          delimiter: "", // auto-detect
          newline: "", // auto-detect
          quoteChar: '"',
          escapeChar: '"',
          error: undefined,
          download: false,
          header: true,
          skipEmptyLines: true,
          comments: true,
          delimitersToGuess: [
            ",",
            "\t",
            "|",
            ";",
            Papa.RECORD_SEP,
            Papa.UNIT_SEP
          ],
          complete: results => {
            csv = results.data;
          }
        });
        let final = Papa.unparse(csv);
        const blob = new Blob([final], { type: "text/csv;charset=utf-8;" });

        this.downloadFile(blob, `${this.generateFileName()}.csv`);
      },
      async csvExportHandler() {
        let filtersData = {};
        let properties = null;
        this.is_exporting = "csv";
        try {
          if (this.active_filters) {
            filtersData = _.pickBy(
              this.active_filters,
              v => v !== null && v !== undefined
            );
            if (
              this.active_filters.dueDate &&
              this.active_filters.dueDate.length
            ) {
              filtersData["dueDateStart"] = this.active_filters.dueDate[0];
              filtersData["dueDateEnd"] = this.active_filters.dueDate[0];
              delete filtersData.dueDate;
            }
            if (this.active_filters.tags && this.active_filters.tags.length) {
              filtersData["tag"] = this.active_filters.tags;
              delete filtersData.tags;
            }
            if (
              this.active_filters.assignee &&
              this.active_filters.assignee.length
            ) {
              filtersData["assignee"] = this.active_filters.assignee.map(
                a => a.uid
              );
            }
          }
          filtersData[
            "organization"
          ] = this.$store.state.current_organization.uid;
          if (this.active_projects && this.active_projects.length) {
            let projectUid = this.active_projects.join(",");
            const temperature_range = this.active_filters
              ? this.active_filters["temperature-difference"]
              : null;
            properties = btoa(
              `projectUid=${projectUid}
              ${
                this.active_class_ids && this.active_class_ids.length
                  ? `&classId=${this.active_class_ids.join(",")},null`
                  : ""
              }
              ${
                temperature_range && temperature_range.length
                  ? `&temperatureDifference>=${temperature_range[0]}&temperatureDifference<=${temperature_range[1]}`
                  : ""
              }`
            );
          }
          let projectMap = {};
          let groupMap = {};

          (this.container || []).forEach(item => {
            if (item.published) {
              projectMap[item.projectUid] = item.projectName;
              if (item.groupProperties && item.groupProperties.date) {
                groupMap[item.groupUid] = moment(
                  new Date(item.groupProperties.date)
                ).format("MMM DD, YYYY");
              } else if (!groupMap[item.groupUid]) {
                groupMap[item.groupUid] = item.groupName;
              }
            }
          });

          const res = await ticketService.generate_tickets_csv_therm({
            body: {
              filters: { ...filtersData, ...{ properties } },
              classIdMap: this.class_ids,
              projectMap,
              groupMap
            }
          });
          this.exportToCsv(res);
        } catch (error) {
          console.error(error);
        } finally {
          this.is_exporting = "";
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .therm-v2__sidebar__export-to-csv {
    background-color: $ghost-white-border;
    border-top: #949494;
    box-shadow: 0px -2px 4px #00000031;
    bottom: 0;

    &__progress {
      height: 4px;
      background: $primary;
      transition: width 300ms;
    }

    &__loading {
      display: flex;
      align-items: center;
    }
  }

  .vert-separator {
    display: inline-block;
    height: 11px;
    width: 1px;
    margin-left: 3px;
    background: $grey-text;
  }

  .semi-disabled {
    opacity: 0.4;
    cursor: default !important;
  }

  .close_button {
    background: rgb(200, 229, 255);
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: rgb(255, 200, 200);
      padding: 5px;
      border-radius: 50%;
    }

    &:active {
      background: rgb(255, 104, 104);
      padding: 5px;
      border-radius: 50%;
    }
  }
</style>
