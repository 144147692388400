/* 
Filter : for Currency to be displayed along with the symbol and the comma separator
1st param - "USD", "INR", "EUR" ...
2nd Param - By default it is US Style, Can pass "en-IN", "de-DE", "ja-JP" ...
Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
*/
export default function(currency, format, seperator_format) {
  // seperator_format : It is the representation/style of displaying comma in currency
  seperator_format = seperator_format ? seperator_format : 'en-US'
  return new Intl.NumberFormat(seperator_format, {
    style: 'currency',
    currency: format
  }).format(currency)
}
