<template>
  <div
    class="pagination-box"
    v-if="pageCount > 1 || $route.name == 'therm-v2-tickets'"
  >
    <!-- <pre>
      visiblePagesCount :: {{visiblePagesCount}}
      currentPage :: {{currentPage}}
      pageCount :: {{pageCount}} 
      totalRecords :: {{totalRecords}}
    </pre> -->

    <section v-if="page_sizes && page_sizes.length">
      <div class="pagination-text is-flex align-center">
        <span>{{ localization("app-show", "Show") }}</span>
        <div
          v-click-outside="() => (is_active = false)"
          class="page-size-dropdown"
        >
          <div class="dropdown" :class="{ 'is-active': is_active }">
            <div class="dropdown-trigger b-1">
              <button
                class="button is-medium-14-500-17 mx-5 is-pointer no-box-shadow"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="is_active = !is_active"
              >
                <span>{{ default_page_size }}</span>
                <span class="icon is-small">
                  <i
                    class="fas"
                    :class="{
                      ' fa-angle-down': is_active,
                      ' fa-angle-up': !is_active
                    }"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content" style="width:6rem;">
                <div
                  :class="{ 'bb-1': i != page_sizes.length - 1 }"
                  v-for="(s, i) in page_sizes"
                  :key="s"
                  @click="
                    default_page_size = s;
                    $emit('default-page-size', default_page_size);
                    is_active = false;
                  "
                  class="hover-background"
                >
                  <p class="dropdown-item py-5 is-pointer ">
                    <span class="is-medium-16-500-19">
                      {{ s }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span>{{ localization("app-per-page", "per page") }}</span>
      </div>
    </section>
    <section v-else>
      <p class="pagination-text">
        Showing
        <span
          v-if="
            currentPage * default_page_size - default_page_size + 1 ==
              totalRecords
          "
          >last</span
        >
        <span v-else
          >{{ currentPage * default_page_size - default_page_size + 1 }} to
          {{
            currentPage * default_page_size >= totalRecords
              ? totalRecords
              : currentPage * default_page_size
          }}</span
        >
        of {{ totalRecords }} entries
      </p>
    </section>
    <nav class="pagination" role="navigation" aria-label="pagination">
      <a
        class="pagination-previous"
        :disabled="isPreviousButtonDisabled"
        @click="previousPage"
      >
        <i class="fas fa-chevron-left"></i>
      </a>

      <ul class="pagination-list">
        <li v-for="(paginationTrigger, i) of paginationTriggers" :key="i">
          <a
            v-if="!paginationTrigger"
            @click="onLoadPage(paginationTriggers[i - 1] + 1)"
          >
            <span style="width:0;height:0" class="pagination-ellipsis"
              >&hellip;</span
            >
          </a>
          <a
            @click="onLoadPage(paginationTrigger)"
            class="pagination-link"
            :class="{ 'is-current': paginationTrigger == currentPage }"
            v-else
            >{{ paginationTrigger }}</a
          >
        </li>
      </ul>
      <a
        class="pagination-next"
        :disabled="isNextButtonDisabled"
        @click="nextPage"
      >
        <i class="fas fa-chevron-right"></i>
      </a>
    </nav>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        is_active: false
      };
    },
    props: [
      "visiblePagesCount",
      "currentPage",
      "pageCount",
      "totalRecords",
      "default_page_size",
      "page_sizes"
    ],
    computed: {
      paginationTriggers() {
        let currentPage = this.currentPage;
        let currentPageIndex = this.currentPage - 1;
        let edgeFillingLimit = this.visiblePagesCount - 2;
        let midFillingLimit = this.visiblePagesCount - 4;
        let paginationArray = [];
        let arrayFillingCount = 0;
        let count = 0;

        if (this.pageCount <= this.visiblePagesCount) {
          for (let i = 1; i <= this.pageCount; i++) paginationArray[i - 1] = i;
          return paginationArray;
        } else {
          paginationArray = [];
        }

        if (currentPage <= Math.floor(this.visiblePagesCount / 2)) {
          //Left Edge Case
          while (arrayFillingCount < edgeFillingLimit) {
            if (count && currentPageIndex - count >= 0) {
              paginationArray[currentPageIndex - count] = currentPage - count;
              arrayFillingCount++;
            }
            if (
              currentPageIndex + count < this.pageCount &&
              arrayFillingCount < edgeFillingLimit
            ) {
              paginationArray[currentPageIndex + count] = currentPage + count;
              arrayFillingCount++;
            }
            count++;
          }
          paginationArray.push(0, this.pageCount);
        } else if (
          currentPage >=
          this.pageCount - Math.ceil(this.visiblePagesCount / 2)
        ) {
          //Right Edge Case
          while (arrayFillingCount < edgeFillingLimit) {
            if (
              count &&
              this.pageCount - currentPageIndex + count <= edgeFillingLimit
            ) {
              paginationArray[
                this.visiblePagesCount -
                  this.pageCount +
                  currentPageIndex -
                  count
              ] = currentPage - count;
              arrayFillingCount++;
            }
            if (
              currentPageIndex + count < this.pageCount &&
              arrayFillingCount < edgeFillingLimit
            ) {
              paginationArray[
                this.visiblePagesCount -
                  this.pageCount +
                  currentPageIndex +
                  count
              ] = currentPage + count;
              arrayFillingCount++;
            }
            count++;
          }
          paginationArray[0] = 1;
        } else {
          //Mid Case
          while (arrayFillingCount < midFillingLimit) {
            if (count) {
              paginationArray[Math.floor(midFillingLimit / 2) - count] =
                currentPage - count;
              arrayFillingCount++;
            }
            paginationArray[Math.floor(midFillingLimit / 2) + count] =
              currentPage + count;
            arrayFillingCount++;
            count++;
          }
          paginationArray.unshift(1, 0);
          paginationArray.push(0, this.pageCount);
        }
        return paginationArray;
      },

      isPreviousButtonDisabled() {
        return this.currentPage === 1;
      },
      isNextButtonDisabled() {
        return this.currentPage === this.pageCount;
      }
    },
    methods: {
      nextPage() {
        this.$emit("nextPage");
        this.$emit("pagination-clicked");
      },
      previousPage() {
        this.$emit("previousPage");
        this.$emit("pagination-clicked");
      },
      onLoadPage(value) {
        this.$emit("loadPage", value);
        this.$emit("pagination-clicked");
      }
    }
  };
</script>
<style lang="scss">
  .page-size-dropdown {
    .dropdown-menu {
      bottom: 3.3rem;
      top: unset;
    }
  }

  .pagination {
    margin-bottom: 8rem;
    &-box {
      margin: 1rem 0;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }
    &-text {
      font-weight: 500;
      color: $light-black;
      font-size: 1.8rem;
    }

    &-list {
      order: 2 !important;
      flex-grow: unset;

      .is-current {
        background-color: $primary !important;
        border-color: $primary !important;
        color: #fff;
      }
    }

    &-link,
    &-previous,
    &-next {
      border-radius: 5px;
      background: $white;
      font-weight: 600;
      color: $light-black;
      border: 1px solid $white !important;
      box-shadow: 0px 0px 1px 1px #eee !important;
    }
  }
</style>
