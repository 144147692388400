import { render, staticRenderFns } from "./assigned-tags.vue?vue&type=template&id=4de571c1&scoped=true&"
import script from "./assigned-tags.vue?vue&type=script&lang=js&"
export * from "./assigned-tags.vue?vue&type=script&lang=js&"
import style0 from "./assigned-tags.vue?vue&type=style&index=0&id=4de571c1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de571c1",
  null
  
)

export default component.exports