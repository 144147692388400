var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active = false); }),expression:"() => (active = false)"}],staticClass:"is-sh-dropdown columns is-marginless is-relative is-duedate"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization(
        'app-select-due-date-range',
        'Select duedate range'
      ),
      html: false
    }),expression:"{\n      content: localization(\n        'app-select-due-date-range',\n        'Select duedate range'\n      ),\n      html: false\n    }"}],staticClass:"is-close is-pointer column is-narrow is-relative padding-10",class:[_vm.$route.name == 'reports' ? 'is-paddingless' : 'is-selector'],on:{"click":function($event){_vm.active = !_vm.active;
      _vm.create_cal();}}},[_c('p',{staticClass:"is-flex align-center"},[_c('span',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.change_duedate([])}}},[_c('sh-icon',{class:[
            _vm.dueText == 'Select Date' || _vm.$route.name == 'reports'
              ? 'is-24x24 mr-7'
              : 'is-12x12 mr-5'
          ],attrs:{"name":_vm.dueText == 'Select Date' || _vm.$route.name == 'reports'
              ? 'select-date'
              : 'ticket-priority-close'}})],1),_c('span',[_vm._v(_vm._s(_vm.localization( ("app-" + (_vm.dueText .split(" ") .join("-") .toLowerCase())), _vm.dueText )))])])]),(_vm.active)?_c('div',{staticClass:"mobile-background",on:{"click":function($event){_vm.active = !_vm.active}}}):_vm._e(),_c('div',{staticClass:"dueDate-calendar",class:{
      'is-hidden': !_vm.active,
      'report-duedate': _vm.$route.name == 'reports'
    }},[_c('div',{attrs:{"id":"filter_calendar_container"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }