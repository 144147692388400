import { ticketService } from "@/app/old/desktop/shared/services/";

const thermv2 = {
  strict: true,
  namespaced: true,
  state: {
    map: null,
    container: null,
    vector_types: null,
    active_filters: null,
    projects: {},
    active_layer_types: ["thermal"],
    mini_thermsidebar: false,
    mini_ticket_sidebar: false,
    raw_images_map: null,
    // ONLY FOR TICKET TESTING
    active_class_ids: [],
    defects: [],
    selected_feature_id: null,
    selected_defects: [],
    select_all_defects: false,
    active_projects: [],
    columns: [
      {
        id: "name",
        name: "Name",
        hidden: false
      },
      {
        id: "inverter",
        name: "Inverter",
        hidden: false
      },
      {
        id: "defect_type",
        name: "Anomaly Type",
        hidden: false
      },
      {
        id: "string_name",
        name: "String",
        hidden: false
      },
      {
        id: "assignee_name",
        name: "Assignees",
        hidden: false
      },
      {
        id: "status_name",
        name: "Status",
        hidden: false
      },
      {
        id: "priority_name",
        name: "Priority",
        hidden: false
      },
      {
        id: "dueDate",
        name: "End Date",
        hidden: false
      },
      {
        id: "tags",
        name: "Tags",
        hidden: false
      },
      {
        id: "timestamp",
        name: "Timestamp",
        hidden: true
      },
      {
        id: "temperature",
        name: "Δ T",
        hidden: true
      }
    ]
  },
  mutations: {
    SET_COLUMNS(state, val) {
      state.columns = [...val];
    },
    RESET_STATE(state, data) {
      state.projects = {};
      state.defects = [];
      state.map = null;
      state.active_projects = [];
      state.active_filters = null;
      state.selected_feature_id = null;
      state.container = null;
    },
    SET_ACTIVE_PROJECTS(state, active_projects) {
      state.active_projects = active_projects;
    },
    SET_SELECTED_FEATURE_ID(state, selected_feature_id) {
      const features_source = state.map.getSource("project-features");
      state.selected_feature_id = selected_feature_id;

      features_source.setData({
        type: "FeatureCollection",
        features: features_source._data.features.map(feature => {
          if (selected_feature_id === feature.properties.uid)
            feature.properties.selected_feature = 1;
          else feature.properties.selected_feature = 0;

          return feature;
        })
      });
    },
    SET_MAP(state, map) {
      state.map = map;
    },
    SET_ACTIVE_FILTERS(state, active_filters) {
      state.active_filters = Object.assign({}, active_filters);
    },
    SET_DEFECTS(state, defects) {
      state.defects = defects;
    },
    SET_ACTIVE_CLASSES(state, classIds) {
      state.active_class_ids = classIds;
    },
    SET_ACTIVE_LAYER_TYPES(state, layer_type) {
      const layer_index = state.active_layer_types.findIndex(
        active_layer => active_layer === layer_type
      );
      if (layer_index > -1) {
        state.active_layer_types.splice(layer_index, 1);
      } else {
        state.active_layer_types.push(layer_type);
      }
    },
    SET_MINI_THERMSIDEBAR(state, mini_thermsidebar) {
      state.mini_thermsidebar = mini_thermsidebar;
    },
    SET_TICKET_SIDEBAR(state, mini_ticket_sidebar) {
      state.mini_ticket_sidebar = mini_ticket_sidebar;
    },
    SET_CONTAINER(state, container) {
      state.container = container;
    },
    SET_PROJECT_DETAILS(state, project_details) {
      state.projects[project_details.uid] = {
        ...(state.projects[project_details.uid] || {}),
        ...project_details
      };
      state.projects = Object.assign({}, state.projects);
    },
    SET_VECTOR_TYPES(state, vector_types) {
      state.vector_types = vector_types;
    },
    UPDATE_TICKET(state, payload) {
      state.defects[payload.index] = {
        ...state.defects[payload.index],
        ...payload.value
      };
      state.defects = Object.assign([], state.defects);
    },
    ADD_TICKET_DETAILS(state, payload) {
      let ticketIndex = _.findIndex(
        state.defects,
        ticket => ticket.uid == payload.uid
      );
      if (ticketIndex == -1) {
        state.defects.push(payload);
      } else {
        state.defects[ticketIndex] = {
          ...state.defects[ticketIndex],
          ...payload
        };
      }
      state.defects = Object.assign([], state.defects);
    },
    SELECT_TICKET(state, uid) {
      state.selected_defects.includes(uid)
        ? (state.selected_defects = _.pull(state.selected_defects, uid))
        : state.selected_defects.push(uid);
      state.selected_defects = Object.assign([], state.selected_defects);
    },
    CLEAR_SELECTED_TICKETS(state) {
      state.selected_defects = [];
      state.select_all_defects = false;
    },
    SELECT_ALL_TICKETS(state, val) {
      state.select_all_defects = val;
    },
    SELECT_MULTIPLE_TICKETS(state, data) {
      state.selected_defects = [...(data || [])];
    },
    UPDATE_MULTIPLE_TICKETS(state, payload = { uids: [], value: {} }) {
      // alert("update_multiple_tickets");
      let defects = _.keyBy(state.defects, "uid");
      payload.uids.forEach(uid => {
        if (defects[uid]) defects[uid] = { ...defects[uid], ...payload.value };
      });
      state.defects = Object.values(defects);
    },
    REMOVE_TICKETS(state, uids = []) {
      _.remove(state.defects, ticket => uids.includes(ticket.uid));
      state.defects = Object.assign([], state.defects);
    },
    SET_RAW_IMAGES_MAP(state, val) {
      state.raw_images_map = val;
    }
  },
  getters: {
    filtered_defects(state) {
      return state.defects.filter(t =>
        state.active_class_ids.includes(t.properties.classId)
      );
    },
    project_defects(state) {
      return state.defects.filter(t => !t.properties.issueUid);
    }
  },
  actions: {
    async update_ticket_value({ commit, state }, payload) {
      let index = state.defects.findIndex(ticket => ticket.uid === payload.id);
      var old_details = _.cloneDeep(state.defects[index]);
      commit("UPDATE_TICKET", { index: index, value: payload.value });
      await ticketService
        .patch({
          id: payload.id,
          body: payload.value
        })
        .catch(err => {
          commit("UPDATE_TICKET", { index: index, value: old_details });
        });
    },
    select_ticket({ commit }, uid) {
      if (uid) {
        commit("SELECT_TICKET", uid);
      } else {
        commit("CLEAR_SELECTED_TICKETS");
      }
    },

    async update_multiple_defects({ commit }, payload) {
      if (payload.value.labels) {
        let e = payload.value.labels.add;
        let readLabels = e.filter(u => u.access === "read").map(u => u.uid);
        let writeLabels = e.filter(u => u.access === "write").map(u => u.uid);
        let teamsPayload = {
          uids: payload.tickets,
          value: {
            readLabels,
            writeLabels
          }
        };
        commit("UPDATE_MULTIPLE_TICKETS", teamsPayload);
      } else if (payload.value.users) {
        let e = payload.value.users.add;
        let readUsers = e.filter(u => u.access === "read");
        let writeUsers = e.filter(u => u.access === "write");
        let usersPayload = {
          uids: payload.tickets,
          value: {
            readUsers,
            writeUsers
          }
        };
        commit("UPDATE_MULTIPLE_TICKETS", usersPayload);
      } else if (payload.value.watchers) {
        let watchersPayload = {
          uids: payload.tickets,
          value: { watchers: payload.value.watchers.add }
        };
        commit("UPDATE_MULTIPLE_TICKETS", watchersPayload);
      } else if (payload.value.tags) {
        let tagsPayload = {
          uids: payload.tickets,
          value: { tags: payload.value.tags.add }
        };
        commit("UPDATE_MULTIPLE_TICKETS", tagsPayload);
      } else {
        commit("UPDATE_MULTIPLE_TICKETS", {
          uids: payload.tickets,
          value: payload.value
        });
      }

      let updateObject = {};
      payload.tickets.forEach(uid => {
        updateObject[uid] = payload.value;
      });

      return await ticketService.bulk_update({
        body: { tickets: updateObject }
      });
    },
    async delete_multiple_tickets({ commit }, payload) {
      await ticketService
        .bulk_delete({
          body: { tickets: payload }
        })
        .catch(this.handleErrors);
      commit("REMOVE_TICKETS", payload);
      commit("CLEAR_SELECTED_TICKETS", payload);
    },
    remove_tickets({ commit }, payload) {
      commit("REMOVE_TICKETS", payload);
    }
  }
};

export default thermv2;
