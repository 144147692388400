<script>
  import SegmentAnalytics from "@/app/old/desktop/shared/mixins/segment-analytics.mixin.vue";

  export default {
    mixins: [SegmentAnalytics],
    methods: {
      track_file_uploads(attachments, location, attached_to) {
        attachments.forEach(attachment => {
          this.segment_track_event("ticket_attachments_added", {
            size: attachment.size,
            filetype: attachment.type,
            location,
            attached_to
          });
        });
      },
      ticket_status_changed(data) {
        this.segment_track_event("ticket_status_changed", data);
      },
      track_checklist_creation(is_loaded_from_template) {
        this.segment_track_event("checklist_created", {
          is_loaded_from_template
        });
      },
      track_description_views(ticket_description) {
        this.segment_track_event("ticket_description_viewed", {
          is_content: ticket_description ? true : false
        });
      },
      track_start_date(start_date) {
        this.segment_track_event("ticket_start_date_updated", {
          is_content: start_date ? true : false
        });
      },
      track_due_date(due_date) {
        this.segment_track_event("ticket_due_date_updated", {
          is_content: due_date ? true : false
        });
      }
    }
  };
</script>
