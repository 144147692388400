<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  export default {
    methods: {
      async create_properties_string(loading) {
        if (this.active_projects && this.active_projects.length) {
          let projectUid = this.active_projects.join(",");
          // therm_query["projectUid"] = projectUid;
          const temperature_range = this.active_filters
            ? this.active_filters["temperature-difference"]
            : null;
          this.properties = btoa(
            `projectUid=${projectUid}${
              temperature_range && temperature_range.length
                ? `&temperatureDifference>=${temperature_range[0]}&temperatureDifference<=${temperature_range[1]}`
                : ""
            }`
          );
        }
        if (this.$route.name == "therm-v2-tickets")
          this.pageChangeHandle(1, "");
        this.get_data(loading);
      },
      async get_data(loading = true) {
        try {
          if (!this.active_projects || !this.active_projects.length) {
            this.SET_DEFECTS([]);
            return;
          }

          this.loading = loading;
          let query = {
            pageSize: Number.MAX_SAFE_INTEGER,
            properties: this.properties,
            organization: this.$store.state.current_organization.uid
          };
          let filters = { ...this.active_filters };
          if (this.active_filters && this.active_filters.assignee) {
            query["assignee"] = this.active_filters.assignee.map(a => a.uid);
            delete filters["assignee"];
          }
          if (filters?.owner?.length && filters?.owner[0]?.uid) {
            filters.owner = filters.owner.map(item => item.uid);
          }
          filters["tag"] = [...(filters["tags"] || [])];
          delete filters["tags"];
          query = { ...query, ...filters };

          if (this.search_by) query["q"] = this.search_by;

          let { data } = await ticketService
            .get_therm_tickets({
              body: {
                filters: query
              }
            })
            .catch(err => {
              this.handleErrors(err);
            });
          this.SET_DEFECTS(_.orderBy(data, "properties.stringName"));
          // if (this.$route.name == "therm-v2-tickets") {
          //   this.updatePagination(this.filtered_defects.length);
          //   if (this.grid) {
          //     this.grid.data.parse(this.page_defects);
          //     this.grid.paint();
          //   } else {
          //     this.initGrid(this.page_defects);
          //     this.handleColumn(this.$store.state.thermv2.columns);
          //     this.grid.paint();
          //   }
          // }
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
        }
      }
    }
  };
</script>

<style></style>
