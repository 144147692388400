<template>
  <div class="add-item" @click.stop="">
    <div class="add-item__header">
      <span
        class="add-item__header__title is-semiBold-20-600-000"
        :class="{ 'is-medium-16-500-19': isMobile }"
        >{{ localization("app-add-users", "Add Users") }}</span
      >
      <div
        @click="$emit('close')"
        class="is-24x24 is-flex align-center is-center"
      >
        <i class="fa fa-times add-item__header__close" aria-hidden="true" />
      </div>
    </div>
    <div
      class="add-item__content__select"
      :class="{ forms__content__select: isForms }"
    >
      <img
        :src="require('@/assets/icons/form-search-icon.svg')"
        class="mr-10"
      />
      <input
        v-model="search"
        class="add-item__content__select__text is-medium-16-500-19"
        type="text"
        :placeholder="localization('app-search-user-name', 'Search User Name')"
      />
    </div>
    <div
      class="add-item__content__heading"
      :class="{ forms__content__heading: isForms }"
    >
      <div
        class="mr-auto is-regular-14-00-17"
        :class="[isMobile ? 'ml-40' : 'ml-50']"
      >
        {{ localization("app-users", "Users") }}
      </div>
      <template v-if="!remove_access && !isForms">
        <div class="is-regular-14-00-17">
          {{ localization("app-read", "Read") }}
        </div>
        <div class="is-regular-14-00-17">
          {{ localization("app-write", "Write") }}
        </div>
      </template>
      <template v-if="isForms">
        <div class="is-regular-14-00-17">
          {{ localization("app-access", "Access") }}
        </div>
      </template>
    </div>
    <div
      class=" has-slimscroll"
      :class="[{ forms__content: isForms }, { 'add-item__content': !isForms }]"
    >
      <template>
        <div
          :class="[{ 'add-item__content__top': !isMobile }]"
          style="padding-top: 0.7rem"
        >
          <div
            v-for="(user, i) in existing"
            :key="i"
            class="add-item__content__top__row"
          >
            <i
              @click="
                include_users.push(existing.splice(i, 1)[0].uid);
                if (isForms) update();
              "
              class="fa fa-times add-item__content__top__row__cancel"
            />

            <div class="add-item__content__top__row__main">
              <user-avatar
                :user="user"
                :custom_class="'tiny-user-avatar'"
                :pxsize="24"
                class="mr-10"
              ></user-avatar>
              <span
                class="add-item__content__top__row__main__name is-medium-16-500-19"
                v-username="user"
              ></span>
            </div>
            <template v-if="!remove_access && !isForms">
              <span
                class="sh-access-tag"
                style="cursor: pointer"
                :class="{
                  'sh-access-tag--read':
                    user.access === 'read' || user.access === 'write'
                }"
                @click="
                  user.access === 'read'
                    ? include_users.push(existing.splice(i, 1)[0].uid)
                    : (user.access = 'read')
                "
              >
                <i class="fa fa-check" aria-hidden="true" />
              </span>
              <span
                class="sh-access-tag"
                style="cursor: pointer"
                :class="{ 'sh-access-tag--write': user.access === 'write' }"
                @click="
                  user.access === 'write'
                    ? (user.access = 'read')
                    : (user.access = 'write')
                "
              >
                <i class="fa fa-check" aria-hidden="true" />
              </span>
            </template>
            <access-forms-dropdown
              :data="user"
              :selected="selected"
              @update-access="
                change_access(user, $event);
                update();
              "
              v-if="isForms"
            ></access-forms-dropdown>
          </div>
        </div>
      </template>
      <div
        :class="[
          { 'add-item__content__bottom': !isMobile },
          { forms__content__bottom: isForms }
        ]"
      >
        <div v-if="filtered.length">
          <div
            v-for="(user, i) in filtered"
            :key="i"
            class="add-item__content__bottom__row"
          >
            <div class="add-item__content__bottom__row__b-checkbox">
              <sh-icon
                :name="
                  selected[user.uid]
                    ? 'share-checkbox-selected'
                    : 'share-checkbox'
                "
                @click.native="
                  toggle(user);
                  if (isForms) update();
                "
                :file="'forms-sprite'"
                class="is-20x20 is-pointer"
              ></sh-icon>
            </div>
            <div class="add-item__content__bottom__row__main">
              <div
                v-if="user.display_picture"
                class="image is-24x24 mr-10"
                style="flex-shrink:0;"
              >
                <img
                  :src="user.display_picture"
                  class="image is-24x24 is-rounded"
                />
              </div>
              <div
                v-else
                class="add-item__content__bottom__row__main__icon"
                style="flex-shrink:0;"
                :style="
                  `background-color: ${
                    user.first_name
                      ? hashed_color(
                          user.first_name + ' ' + user.last_name,
                          'userManagement'
                        )
                      : hashed_color(user.email, 'userManagement')
                  }`
                "
              >
                {{
                  user.first_name
                    ? user.first_name.charAt(0).toUpperCase()
                    : user.email.charAt(0).toUpperCase()
                }}
              </div>
              <span
                class="add-item__content__bottom__row__main__name is-medium-16-500-19"
                style="word-break: break-word"
                >{{
                  user.first_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.email
                }}</span
              >
              <span
                class="add-item__content__bottom__row__main__name ml-5 has-text-grey"
                >{{
                  user.is_guest
                    ? `(${localization("app-guest", "Guest")})`
                    : user.is_internal
                    ? "(Internal)"
                    : ""
                }}</span
              >
            </div>
            <template v-if="!remove_access && !isForms">
              <span
                style="cursor: pointer"
                :class="{
                  'sh-access-tag--read':
                    selected[user.uid] &&
                    (selected[user.uid].access === 'read' ||
                      selected[user.uid].access === 'write')
                }"
                @click="
                  selected[user.uid] && selected[user.uid].access === 'read'
                    ? toggle(user)
                    : change_access(user, 'read')
                "
                class="sh-access-tag"
              >
                <i class="fa fa-check" aria-hidden="true" />
              </span>
              <span
                style="cursor: pointer"
                @click="
                  selected[user.uid] && selected[user.uid].access === 'write'
                    ? change_access(user, 'read')
                    : change_access(user, 'write')
                "
                class="sh-access-tag"
                :class="{
                  'sh-access-tag--write':
                    selected[user.uid] && selected[user.uid].access === 'write'
                }"
              >
                <i class="fa fa-check" aria-hidden="true" />
              </span>
            </template>
            <access-forms-dropdown
              :data="user"
              :selected="selected"
              @update-access="
                change_access(user, $event);
                update();
              "
              v-if="isForms"
            ></access-forms-dropdown>
          </div>
        </div>
        <invite-user
          :search="search"
          @invited="invited_user($event)"
          v-else
        ></invite-user>
      </div>
    </div>
    <div v-if="isMobile" class="add-item__footer mobile-footer">
      <div
        v-if="is_saving"
        class="add-item__footer__full-button button is-pulled-right"
      >
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
      <div
        v-else
        @click="update"
        class="add-item__footer__full-button button has-text-white is-pulled-right is-medium-16-500-19"
      >
        {{ localization("app-add-users", "Add Users") }}
      </div>
    </div>
    <div v-else class="add-item__footer">
      <div
        v-if="is_saving"
        class="add-item__footer__button button is-pulled-right"
      >
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
      <div
        v-else
        @click="update"
        class="add-item__footer__button button has-text-white is-pulled-right is-medium-16-500-19"
      >
        {{ localization("app-save", "Save") }}
      </div>
      <div
        @click="$emit('close')"
        class="add-item__footer__button__cancel button is-pulled-right is-medium-16-500-19 mr-10"
      >
        {{ localization("app-cancel", "Cancel") }}
      </div>
    </div>
  </div>
</template>

<script>
  import { userService } from "@/app/old/desktop/shared/services/";
  import InviteUser from "./add-teams-modal-user-list-invite/add-teams-modal-user-list-invite.vue";

  import Mixin from "@/app/old/desktop/shared/mixins/add-team-user.mixin.vue";
  import HashColor from "@/app/old/desktop/shared/mixins/hash-color.mixin.vue";
  import accessFormsDropdown from "./access-forms-dropdown.vue";

  export default {
    mixins: [Mixin, HashColor],
    components: {
      InviteUser,
      accessFormsDropdown
    },
    props: [
      "users",
      "complete",
      "service",
      "item_uid",
      "team",
      "role",
      "hide_access",
      "isForms"
    ],
    data() {
      return {
        items: this.users,
        search: "",
        type: "users",
        existing: [],
        selected: {},
        include_users: []
      };
    },

    computed: {
      filtered() {
        if (this.remove_access) {
          return _.filter(
            this.$store.state.users || [],
            u =>
              !this.existing_uids.includes(u.uid) &&
              this.include_users.includes(u.uid) &&
              _.includes(
                (u.email + u.first_name).toLowerCase(),
                this.search.toLowerCase()
              )
          );
        } else
          return _.filter(
            this.$store.state.users || [],
            u =>
              !this.existing_uids.includes(u.uid) &&
              _.includes(
                (u.email + u.first_name).toLowerCase(),
                this.search.toLowerCase()
              )
          );
      },
      remove_access() {
        return this.team || this.role || this.hide_access;
      }
    },
    async created() {
      this.include_users = Object.assign(
        [],
        this.$store.state.users.map(u => u.uid)
      );
      try {
        if (this.team) {
          let data = await userService.getAll({
            from_server: true,
            query: "active=true&exclude_label=" + this.item_uid
          });
          if (data.results) this.include_users = data.results.map(u => u.uid);
        }
        if (this.role) {
          let data = await userService.getAll({
            from_server: true,
            query: "active=true&team=null"
          });
          if (data.results) this.include_users = data.results.map(u => u.uid);
        }
      } catch (err) {
        console.log(err);
      }
      this.existing = Object.assign([], this.items);
    },
    methods: {
      invited_user(user) {
        user.access = "read";
        this.selected[user.uid] = user;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .add-item__content {
    height: calc(100% - 250px) !important;
    &__bottom:last-of-type {
      padding-bottom: 0;
    }
  }
</style>
<style lang="scss">
  .mobile-footer {
    position: fixed !important;
  }
  .forms__content__select {
    margin: 0 0 0 20px !important;
    padding-top: 15px !important;
  }
  .forms__content__heading {
    margin: 0 0 0 30px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .forms__content__bottom {
    padding-top: 0rem !important;
    padding-bottom: 0px !important;
    border: none !important;
  }
  .forms__content {
    height: 70%;
    padding: 0 3rem;
    overflow: auto;
  }
</style>
