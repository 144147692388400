<template>
  <div>
    <div class="padding-20 new-schedule">
      <div class="new-schedule__header is-semiBold-18-600-22">
        {{ localization("app-new-schedule", "New Schedule") }}
      </div>
      <div class="new-schedule__status pb-15" v-if="schedule && schedule.rrule">
        <div>
          <p class="is-medium-14-500-17 rrule_string mb-5">
            {{ recurrence_text }}
          </p>
          <p class="is-regular-14-500-17">
            {{ localization("app-next-occurrence", "Next occurrence") }}:
            <span class="is-semiBold-14-600-17">{{
              next_occurrence
                ? next_occurrence
                : localization("app-not-available", "*Not available")
            }}</span>
          </p>
        </div>
        <div v-if="schedule.recurWhen" class="is-flex align-center">
          <span
            class="is-medium-12-500-00 is-capitalized mr-10 new-schedule__status-tag"
            :class="'is-' + schedule.recurWhen"
            >{{ status[schedule.recurWhen] }}</span
          >
          <sh-icon
            :name="'delete-2'"
            class="is-18x18 is-pointer is-hidden"
            :class="{ 'is-disabled': !has_access }"
            @click.native="remove_recurrence"
          ></sh-icon>
        </div>
      </div>
      <div v-if="schedule && schedule.rrule" class="new-schedule-divider"></div>

      <div>
        <div class="schedule-action is-hidden">
          <div class="is-hidden">
            <p class="is-semiBold-16-600-19 is-vcenter is-flex">
              <sh-icon
                :name="'recurrence-recurs'"
                class="is-20x20 mr-10"
              ></sh-icon>
              <span class="has-text-blue">{{
                localization("app-set-recurrence", "Set Recurrence")
              }}</span>
            </p>
          </div>
          <div>
            <div
              class="frequency-dropdown"
              v-click-outside="() => (status_dropdown = false)"
            >
              <div class="columns align-baseline">
                <p class="is-semiBold-16-500-19 ml-10 column">
                  {{ localization("app-on", "On") }}:
                </p>
                <p class="column">
                  <status-dropdown
                    :disabled_status="[1, 2]"
                    @update-ticket-status="ticket_status = $event.value"
                    :current_status="ticket_status ? ticket_status : 4"
                    class="is-26x26 mr-10"
                  ></status-dropdown>
                </p>
              </div>
            </div>
          </div>
        </div>
        <recurrence
          :is_bulk="is_bulk"
          :schedule="schedule"
          :uid="uid"
          :access="has_access"
          :isForms="isForms"
          :selectedForm="selectedForm"
          style="height: 100%"
          :ticket_status="ticket_status"
          :component="component"
          @remove-recurrence="remove_recurrence"
          @close-schedule="$emit('close-schedule')"
        ></recurrence>
      </div>
    </div>
  </div>
</template>
<script>
  import { RRule } from "rrule";

  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import StatusTag from "@/app/old/desktop/shared/components/tags/status-tag";
  import Recurrence from "./components/schedule-body";

  export default {
    props: [
      "schedule",
      "uid",
      "has_access",
      "component",
      "isForms",
      "is_bulk",
      "selectedForm"
    ],
    data() {
      return {
        status_dropdown: false,
        ticket_status: null,
        create_schedule: false,
        status: {
          1: "pending",
          2: "in progress",
          3: "resolved",
          4: "closed",
          5: "rejected"
        }
      };
    },
    components: {
      StatusDropdown,
      StatusTag,
      Recurrence
    },
    computed: {
      recurrence_text() {
        if (this.schedule && this.schedule.rrule != null) {
          return new RRule.fromString(this.schedule.rrule).toText();
        } else {
          return null;
        }
      },
      next_occurrence() {
        if (this.schedule && this.schedule.rrule != null) {
          let after = new RRule(RRule.parseString(this.schedule.rrule)).after(
            new Date(),
            true
          );
          let { options } = new RRule.fromString(this.schedule.rrule);
          let difference = options.freq;

          if (after) {
            if (difference == 4) {
              let a = moment(after);
              let b = moment(new Date());
              let number_of_hours = a.diff(b, "minutes", true);
              let hm = _.toString((number_of_hours / 60).toFixed(2)).split(".");

              return `in ${hm[0] != 0 ? hm[0] + " hours" : ""} ${
                hm[0] != 0 && hm[1] != 0 ? "and " : ""
              } ${hm[1] != 0 ? parseInt((hm[1] / 100) * 60) + " minutes" : ""}`;
            } else {
              let a = moment(after);
              let b = moment(new Date());
              let number_of_days = a.diff(b, "days");

              return `in ${number_of_days} days`;
            }
          } else {
            return null;
          }
        } else {
          return "Not available";
        }
      }
    },
    created() {
      if (
        this.$route.name == "schedules" ||
        this.$route.name == "schedule-details"
      ) {
        this.create_schedule = true;
      }
      this.ticket_status = this.schedule ? this.schedule.recurWhen : null;
    },
    methods: {
      async remove_recurrence() {
        if (this.isForms) {
          if (this.is_bulk) {
            this.$emit("complete", {
              recurring: false,
              schedule: null
            });
            return;
          }
          this.$store.dispatch("forms/update_form", {
            body: {
              recurring: false,
              schedule: null
            },
            id: this.uid
          });
          this.$emit("remove-recurrence");
        } else {
          this.$store.dispatch("tickets/update_ticket_value", {
            value: {
              schedule: null
            },
            id: this.uid,
            router: this.$router
          });
        }
      }
    }
  };
</script>
<style lang="scss">
  .schedule {
    &-status {
      justify-content: space-between;
      margin: auto;
      margin-bottom: 3rem;
      display: flex;
      padding: 2.2rem 3.5rem;
      width: 63rem;
      height: 10rem;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 0 2px 7px 0 rgba(23, 30, 72, 0.1);
      border: solid 1px rgba(23, 30, 72, 0.15);
      background-color: #ffffff;
    }
    &-action {
      width: 63rem;
      margin: auto;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      padding-right: 10rem;
      align-items: center;
    }
    &-divider {
      width: 63rem;
      margin: 0 auto;
      background-color: #eee;
      position: relative;
      height: 1px;
    }
  }

  .ticket-status-tag {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0.5rem 2rem;
    border-radius: 20px;
    width: max-content;
    display: flex;
    justify-content: center;
    height: 3rem;
    &.is-1 {
      color: $pending-status;
      background: transparentize($pending-status, 0.8);
    }
    &.is-2 {
      color: $completed-status;
      background: transparentize($completed-status, 0.8);
    }
    &.is-3 {
      color: $resolved-status;
      background: transparentize($resolved-status, 0.8);
    }
    &.is-4 {
      color: $closed-status;
      background: transparentize($closed-status, 0.8);
    }
    &.is-5 {
      color: $rejected-status;
      background: transparentize($rejected-status, 0.8);
    }
  }

  .rrule_string::first-letter {
    text-transform: capitalize;
  }

  .new-schedule {
    &__header {
      padding-bottom: 2rem;
    }
    &-divider {
      background-color: rgba(23, 30, 72, 0.1);
      position: relative;
      height: 1px;
    }
    &__status {
      display: flex;
      justify-content: space-between;
      &-tag {
        padding: 0 1rem;
        border-radius: 20px;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.4rem;
        &.is-1 {
          color: $pending-status;
          background: transparentize($pending-status, 0.8);
        }
        &.is-2 {
          color: $completed-status;
          background: transparentize($completed-status, 0.8);
        }
        &.is-3 {
          color: $resolved-status;
          background: transparentize($resolved-status, 0.8);
        }
        &.is-4 {
          color: $closed-status;
          background: transparentize($closed-status, 0.8);
        }
        &.is-5 {
          color: $rejected-status;
          background: transparentize($rejected-status, 0.8);
        }
      }
    }
  }
</style>
