var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
    function () { return ((_vm.tags = null), _vm.selected_action == '' ? (_vm.active = false) : null); }
  ),expression:"\n    () => ((tags = null), selected_action == '' ? (active = false) : null)\n  "}]},[_c('div',{staticClass:"defect-bulk-dropdown"},[(_vm.selected_defects.length || _vm.select_all_defects)?_c('div',{staticClass:"is-flex align-center",on:{"click":function($event){_vm.active = !_vm.active}}},[_c('p',{staticClass:"is-flex is-vcenter sh-button sh-button--small"},[_c('span',{staticClass:"heading-2 has-text-white pr-5"},[_vm._v(_vm._s(_vm.localization("app-actions", "Actions")))]),_c('span',{staticClass:"icon is-small pr-10"},[(!_vm.active)?_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fas fa-angle-up",attrs:{"aria-hidden":"true"}})])])]):_vm._e(),(_vm.active)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active = false); }),expression:"() => (active = false)"}],staticClass:"therm-bulk-dropdown"},[_c('div',{staticClass:"defect-bulk-dropdown-content has-slimscroll mb-5"},[_c('div',{staticClass:"defect-bulk-dropdown-content__item is-flex is-relative is-medium-14-500-17 align-center px-15 py-10 is-pointer",on:{"click":function($event){_vm.selected_action = _vm.selected_action == 'Status' ? '' : 'Status'}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"file":'thermv2_sprite',"name":'change-status'}}),_c('p',[_vm._v(" "+_vm._s(_vm.localization("app-status", "Status"))+" ")]),_c('div',{staticClass:"is-sh-dropdown is-absolute ",staticStyle:{"top":"-3.5rem","left":"-15rem"}},[(_vm.selected_action == 'Status')?_c('ul',{staticClass:"is-options-list is-status has-background-white",staticStyle:{"width":"15rem"}},_vm._l((_vm.status_options),function(state){return _c('li',{key:state.value,staticClass:"item is-flex is-between align-center",on:{"click":function($event){$event.stopPropagation();_vm.bulk_update_tickets({ status: parseInt(state.value) });
                  _vm.reset();}}},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"status-pointer is-inline-block mr-10",class:[
                      _vm.status_bg_classes[state.value],
                      { invert_colors: _vm.has_dark_theme }
                    ]}),_c('div',{staticClass:"item--text  is-capitalized"},[_vm._v(" "+_vm._s(_vm.localization( ("app-" + (state.name .split(" ") .join("-") .toLowerCase())), state.name ))+" ")])])])}),0):_vm._e()])],1),_c('div',{staticClass:"defect-bulk-dropdown-content__item is-flex  is-relative  align-center px-15 py-10 is-pointer",on:{"click":function($event){_vm.selected_action = _vm.selected_action == 'Priority' ? '' : 'Priority'}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"file":'thermv2_sprite',"name":'add-priority'}}),_c('p',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization("app-priority", "Priority"))+" ")]),(_vm.selected_action == 'Priority')?_c('div',{staticClass:"is-sh-dropdown is-absolute ",staticStyle:{"top":"-3.8rem","left":"-14rem"}},[(_vm.selected_action == 'Priority')?_c('ul',{staticClass:"is-options-list",staticStyle:{"width":"14rem"}},_vm._l((_vm.priorities),function(priority_option,i){return _c('li',{key:priority_option,staticClass:"item is-flex is-between align-center",on:{"click":function($event){$event.stopPropagation();_vm.bulk_update_tickets({ priority: parseInt(i) });
                  _vm.reset();}}},[_c('p',{staticClass:"item--text is-capitalized is-flex"},[_c('sh-icon',{staticClass:"is-20x20 item--icon",attrs:{"name":'priority-' + i}}),_c('span',[_vm._v(_vm._s(_vm.localization( ("app-" + (priority_option .split(" ") .join("-") .toLowerCase())), priority_option )))])],1)])}),0):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"defect-bulk-dropdown-content__item  px-15 py-10 is-pointer"},[_c('div',{staticClass:"is-medium-14-500-17 is-flex align-center",on:{"click":function($event){_vm.member_team_bulk_dropdown_active = true}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.localization('app-add-assignees', 'Add Assignees')
              }),expression:"{\n                content: localization('app-add-assignees', 'Add Assignees')\n              }"}],staticClass:" is-24x24 is-pointer mr-10",attrs:{"name":'vault-add-users'}}),_vm._v(" "+_vm._s(_vm.localization("app-add-assignees", "Add Assignees"))+" ")],1),_c('div',{staticClass:"is-absolute right-0"},[(_vm.member_team_bulk_dropdown_active)?_c('member-team-bulk-dropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                function () { return (_vm.member_team_bulk_dropdown_active = false); }
              ),expression:"\n                () => (member_team_bulk_dropdown_active = false)\n              "}],on:{"close":function($event){_vm.member_team_bulk_dropdown_active = false},"save":function($event){_vm.bulk_update_assignees($event.teams.concat( $event.members));
                _vm.member_team_bulk_dropdown_active = false;
                _vm.active = false;}}}):_vm._e()],1)]),_c('div',{staticClass:"defect-bulk-dropdown-content__item is-flex is-relative  align-center px-15 py-10 is-pointer",on:{"click":function($event){_vm.selected_action = _vm.selected_action == 'dueDate' ? '' : 'dueDate'}}},[_c('p',{staticClass:"is-medium-14-500-17"},[_c('duedate-dropdown',{staticClass:"has-text-dark",attrs:{"icon_class":'is-24x24 mr-10',"custom_text":'End Date',"is_bulk":true},on:{"change-duedate":function($event){_vm.bulk_update_tickets({ dueDate: $event });
                _vm.reset();}}},[_vm._v(" >")])],1)]),_c('div',{staticClass:"defect-bulk-dropdown-content__item is-flex is-between is-relative  align-center px-15 py-10 is-pointer"},[_c('p',{staticClass:"is-medium-14-500-17"},[_c('span',{staticClass:"is-flex align-center"},[_c('type-tag',{attrs:{"display_on_hover":false,"full_opacity_text":true,"complete":function (tag) {
                    _vm.bulk_update_tickets({ tags: { add: [tag] } });
                    _vm.reset();
                  }}},[[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: _vm.localization('app-add-tags', 'Add tags'),
                      html: false
                    }),expression:"{\n                      content: localization('app-add-tags', 'Add tags'),\n                      html: false\n                    }"}],staticClass:"is-24x24 is-pointer mr-10",attrs:{"name":'add-tags',"file":'thermv2_sprite'}})]],2)],1)])]),_c('div',{staticClass:"defect-bulk-dropdown-content__item is-flex is-between is-relative  align-center px-15 py-10 is-pointer"},[_c('p',{staticClass:"is-medium-14-500-17"},[_c('span',{staticClass:"is-flex align-center"},[_c('type-tag',{attrs:{"display_on_hover":false,"full_opacity_text":true,"complete":function (tag) {
                    _vm.bulk_update_tickets({ tags: { remove: [tag] } });
                    _vm.reset();
                  }},scopedSlots:_vm._u([{key:"toggle-dropdown",fn:function(){return [_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: _vm.localization('app-remove-tags', 'remove tags'),
                      html: false
                    }),expression:"{\n                      content: localization('app-remove-tags', 'remove tags'),\n                      html: false\n                    }"}],staticClass:"is-24x24 is-pointer mr-10",attrs:{"name":'add-tags',"file":'thermv2_sprite'}}),_c('p',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization("app-remove-tags", "Remove Tags"))+" ")])]},proxy:true}],null,false,796047156)})],1)])]),_c('div',{staticClass:"defect-bulk-dropdown-content__item is-flex  is-relative  align-center px-15 py-10 is-pointer",on:{"click":function($event){_vm.selected_action =
              _vm.selected_action == 'save_template' ? '' : 'save_template'}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"file":'thermv2_sprite',"name":'add-task-templates'}}),_c('p',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization("app-task-templates", "Task Templates"))+" ")]),_c('div',{staticClass:"is-absolute has-background-white",staticStyle:{"left":"-28rem","top":"4rem"}},[(_vm.selected_action == 'save_template')?_c('save-template',{attrs:{"type":'load',"tickets":_vm.selected_defects},on:{"close-load-template":function($event){return _vm.reset()}}}):_vm._e()],1)],1),_c('div',{staticClass:"defect-bulk-dropdown-content__item  px-15 py-10 is-pointer"},[_c('p',{staticClass:"is-medium-14-500-17 is-flex align-center",on:{"click":function($event){return _vm.bulk_update_tickets({ watch: true })}}},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.localization('app-follow-tickets', 'Follow tickets'),
                html: false
              }),expression:"{\n                content: localization('app-follow-tickets', 'Follow tickets'),\n                html: false\n              }"}],staticClass:" is-20x20 is-pointer mr-12 ml-3",attrs:{"name":'watch',"file":'tickets-2'}}),_vm._v(" "+_vm._s(_vm.localization("app-follow-tickets", "Follow tickets"))+" ")],1)]),(_vm.$store.getters.is_internal_user)?_c('div',{staticClass:"defect-bulk-dropdown-content__item  px-15 py-10 is-pointer"},[_c('p',{staticClass:"is-medium-14-500-17 is-flex align-center",on:{"click":_vm.delete_tickets}},[_c('sh-icon',{staticClass:"is-20x20 mr-12 ml-3",attrs:{"name":'delete-ticket'}}),_vm._v(" "+_vm._s(_vm.localization("app-delete", "Delete"))+" ")],1)]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }