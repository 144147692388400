<template>
  <div class="thermv2-map-container">
    <div id="therm-v2-map" class="thermv2-map-container__map"></div>
    <!-- TODO: Add the ability to compare projects across groups
    <comparision-container :active_classes="active_classes"/> -->
    <div
      class="thermv2-map-container__map__layer-select"
      :style="toolbar_position"
    >
      <div
        v-tooltip="{
          content: localization(`app-${layer}`, layer),
          placement: 'left-center',
          classes: ['tooltip']
        }"
        @click="SET_ACTIVE_LAYER_TYPES(layer.toLowerCase())"
        :key="layer"
        v-for="layer in filter_supported_layers"
        class="is-pointer  is-semiBold-14-600-17 image my-10 mr-20 has-br-3"
        :class="[
          active_layer_types.includes(layer.toLowerCase())
            ? 'has-blue-border'
            : 'has-white-border'
        ]"
      >
        <img
          class="is-28x28"
          :src="require(`@/assets/icons/thermv2/${layer.toLowerCase()}.svg`)"
          alt=""
        />
        <div
          class="layer-check icon"
          v-if="active_layer_types.includes(layer.toLowerCase())"
        >
          <i class="fa fa-check has-text-white" aria-hidden="true"></i>
        </div>
      </div>
      <div
        :class="{ 'is-disabled': !active_projects.length }"
        @click="toggle_marker()"
        class="map-marker is-pointer"
        v-tooltip="{
          content: localization('app-add-task', 'Add task'),
          html: false,
          placement: 'left-center',
          classes: ['tooltip']
        }"
      >
        <img
          v-if="map_marker_active"
          src="@/assets/icons/add-task-selected.png"
          alt=""
        />
        <img v-else src="@/assets/icons/add-task.png" alt="" />
        <!-- <i class="fa fa-map" :class="{ 'has-text-info': map_marker_active }"></i> -->
      </div>
    </div>

    <router-link
      tag="div"
      class="thermv2-map-container__map__compare-projects is-pointer"
      :style="toolbar_position"
      v-tooltip="{
        content: localization('app-compare', 'Compare'),
        classes: ['tooltip']
      }"
      :to="{
        name: 'therm-v2-compare'
      }"
    >
      <sh-icon name="compare-projects" file="thermv2_sprite" class="is-32x32" />
    </router-link>
    <create-ticket
      class="has-background-white"
      style="position:absolute;left:30rem;z-index:99;top:20rem;width:45rem;border-radius:1rem;"
      v-if="element && open_ticket_create"
      @close="
        open_ticket_create = false;
        remover_marker();
      "
      @ticket-created="
        open_ticket_create = false;
        remover_marker();
      "
      :open_on_create="true"
      :element="{ ...element, centroid }"
      :project_details="active_project_details"
      :viewer_create_ticket="true"
      :viewer_ticket="false"
    ></create-ticket>
    <Cluster v-if="map" />
  </div>
</template>

<script>
  import Cluster from "./therm-v2-cluster";
  import MapsMixin from "../mixins/therm-v2.maps.mixin.vue";
  import CreateTicket from "@/app/old/desktop/tickets/components/tickets-list/components/create-new-ticket";

  import { mapMutations, mapState } from "vuex";

  export default {
    name: "therm-v2-map",
    mixins: [MapsMixin],
    data() {
      return {
        supported_layers: ["CAD", "Visual", "Thermal"],
        map_marker_active: false,
        toast: null,
        open_ticket_create: false,
        centroid: {}
        // element: null,
      };
    },
    components: {
      Cluster,
      CreateTicket
    },
    mounted() {
      if (!this.map) this.initMap();
    },
    activated() {
      if (this.map) this.map.resize();
    },
    computed: {
      ...mapState("thermv2", [
        "active_layer_types",
        "mini_ticket_sidebar",
        "map",
        "container",
        "projects",
        "active_projects"
      ]),
      filter_supported_layers() {
        let layers = ["Thermal"];
        if (this.container) {
          this.container.map(item => {
            if (this.active_projects.includes(item.projectUid)) {
              if (item.orthoTilesVisual && !layers.includes("Visual"))
                layers.unshift("Visual");
              if (item.orthoTilesCAD && !layers.includes("CAD"))
                layers.unshift("CAD");
            }
          });
        }
        return layers;
      },
      element() {
        let active = this.active_projects[this.active_projects.length - 1];
        return active
          ? this.projects[active] && this.projects[active].element
          : null;
      },
      active_project_details() {
        return this.projects[
          this.active_projects[this.active_projects.length - 1]
        ];
      },
      toolbar_position() {
        return `right: ${this.mini_ticket_sidebar ? "5rem" : "30rem"}`;
      }
    },
    methods: {
      ...mapMutations("thermv2", ["SET_ACTIVE_LAYER_TYPES"]),
      remover_marker() {
        this.map_marker_active = false;
        if (this.map.getLayer("mapMarker")) this.map.removeLayer("mapMarker");
        if (this.map.getSource("mapMarker")) this.map.removeSource("mapMarker");
      },
      async add_marker_layer() {
        if (!this.map.hasImage("mapMarker")) {
          this.map.loadImage(
            "/img/icons/map-marker.png",
            async (error, image) => {
              if (error) {
                console.log("Imaage exists");
                //   throw error
              } else {
                this.map.addImage("mapMarker", image);
              }
            }
          );
        }
        if (!this.map.getSource("mapMarker")) {
          await this.map.addSource("mapMarker", {
            type: "geojson",
            data: { type: "FeatureCollection", features: [] }
          });
        }
        if (!this.map.getLayer("mapMarker")) {
          await this.map.addLayer({
            id: "mapMarker",
            type: "symbol",
            source: "mapMarker",
            layout: {
              "icon-image": "mapMarker",
              "icon-size": 1
            }
          });
        }
      },
      add_marker(e) {
        console.log("add marker");
        this.add_marker_layer();
        if (this.map_marker_active && e) {
          if (this.toast) this.toast.close();
          if (this.map.getLayer("mapMarker"))
            this.map.setLayoutProperty("mapMarker", "visibility", "visible");
          console.log("open ticket");
          this.centroid = e.lngLat;
          this.open_ticket_create = true;
          // get ticket popup
          // parent.postMessage(
          //   {
          //     open_on_create: true,
          //     name: "create-project-ticket",
          //     element: {
          //       ...this.active_layers[this.cluster_active_layer.projectUid].project_details
          //         .element,
          //       centroid: e.lngLat,
          //     },
          //   },
          //   "*"
          // );

          this.map.getSource("mapMarker").setData({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: Object.values(e.lngLat)
                }
              }
            ]
          });
        } else {
          if (this.map.getLayer("mapMarker"))
            this.map.setLayoutProperty("mapMarker", "visibility", "none");
        }
      },
      toggle_marker() {
        this.map_marker_active = !this.map_marker_active;
        if (this.map_marker_active) {
          this.toast = this.$buefy.toast.open({
            indefinite: true,
            message: this.localization(
              "app-tap-anywhere-on-the-map-to-add-a-task",
              "Tap anywhere on the map to add a task."
            ),
            position: "is-top"
          });
        } else {
          this.toast.close();
          this.map.setLayoutProperty("mapMarker", "visibility", "none");

          // open ticket popup
          // parent.postMessage(
          //   {
          //     name: "create-project-ticket",
          //     element: null
          //   },
          //   "*"
          // );
        }
      },
      initMap() {
        let map = new mapboxgl.Map({
          container: "therm-v2-map",
          style:
            localStorage.getItem("mapstyle") !== "street"
              ? "mapbox://styles/mapbox/streets-v11"
              : "mapbox://styles/mapbox/satellite-v8",
          center: [-122.0350127, 37.2805218],
          zoom: 15,
          maxZoom: 24,
          attributionControl: false
        });

        this.$store.commit("thermv2/SET_MAP", map);

        map.on("style.load", () => {
          map.on("click", e => {
            this.$store.commit("thermv2/SET_SELECTED_FEATURE_ID", null);
            e.preventDefault();
            this.add_marker(e);
          });
          for (let project_index in this.active_projects) {
            const project = this.active_projects[project_index];
            if (project_index == this.active_projects.length - 1)
              this.fly_to_project(this.projects[project], map);
            this.setup_map_with_active_layers_for_projects(project);
            this.set_active_vectors();
          }
        });
      }
    }
  };
</script>

<style lang="scss">
  .is-28x28 {
    height: 2.8rem;
    width: 2.8rem;
    box-shadow: -3px 0px 14px 2px rgba(0, 0, 0, 0.2);
  }
  .has-br-3 {
    border-radius: 0.3rem;
  }
  .has-blue-border {
    border: 2px solid #4385d3;
  }
  .has-white-border {
    border: 2px solid white;
  }
  .layer-check {
    background: rgba(0, 0, 0, 1);
    opacity: 0.7;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    position: absolute;
    transform: translate(20%, -120%);
  }
  .thermv2-map-container {
    &__map {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      .mapboxgl-canvas {
        width: 100%;
        height: 100%;
      }
      &__topbar {
        width: 39rem;
        height: 3.6rem;
        position: fixed;
        background: #1e2328;
        transform: translateX(90%);
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color: white;
        transition: all 0.2s;
      }
      &__layer-select {
        position: fixed;
        top: 20px;
        // z-index: 10;
        &__item {
          background: white;
          margin: 2rem;
          padding: 1rem;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
      }
      &__compare-projects {
        position: fixed;
        z-index: 10;
        bottom: 2rem;
        margin-right: 2rem;
      }
    }
  }
</style>
