let update = (el, binding, vnode) => {
  if (!binding.value || !binding.value.uid) return ''

  const modifiers = binding.modifiers
  let text = ''
  let user = vnode.context.$store.getters.state_users[binding.value.uid]
  if (user && user.first_name) text = user.first_name + ' ' + user.last_name
  else if (binding.value && binding.value.first_name)
    text = binding.value.first_name + ' ' + binding.value.last_name
  else if (user) text = user.email
  else
    text =
      (binding.value.email && binding.value.email.includes('@sensehawk')) ||
      vnode.context.$store.state.internal_users_uids.includes(binding.value.uid)
        ? 'Sensehawk'
        : 'Unknown'

  el.innerText = modifiers.truncate ? Truncate(text, binding.arg) : text
}
function Truncate(str, length, ellipses) {
  length = length || 20
  if (!str) return ''
  if (str && str.length <= length) {
    // let spaces = '';
    // for (let i = 0; i < length - str.length; i++) {
    //   spaces += ".";
    // }
    // spaces += ""
    return str
  }

  if (ellipses === undefined) {
    ellipses = '...'
  }
  ellipses = '' + ellipses
  str = str.slice(0, length)

  return str + ellipses
}
export default {
  bind: update,
  update
}
