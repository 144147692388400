<template>
  <div
    v-click-outside="() => (is_active = false)"
    class="language-dropdown"
    :class="{ 'mobile-language-dropdown': isMobile }"
  >
    <div
      class="dropdown"
      :class="{ 'is-active': is_active, 'is-up': isMobile }"
    >
      <div class="dropdown-trigger">
        <button
          class="button is-large is-borderless is-pointer no-box-shadow"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          @click="is_active = !is_active"
        >
          <i class="fas fa-globe mr-5"></i>
          <span class="is-uppercase">{{ language }}</span>
          <span class="icon is-small">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <div
            :class="{ 'bb-1': i != languages.length - 1 }"
            v-for="(l, i) in languages"
            :key="l.value"
            @click.stop="select_language(l.value)"
            class="hover-background"
          >
            <p class="dropdown-item py-5 is-pointer ">
              <span class="is-medium-16-500-19">
                {{ localization(`app-${l.key}`, l.label) }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    data() {
      return {
        is_active: false,
        languages: [
          {
            label: "English (EN)",
            value: "en",
            key: "english"
          },
          {
            label: "Español (ES)",
            value: "es",
            key: "spanish"
          },
          {
            label: "Japanese (JA)",
            value: "ja",
            key: "japanese"
          }
        ]
      };
    },
    computed: {
      ...mapState(["language"])
    },
    methods: {
      select_language(language) {
        localStorage.setItem("language", language);
        this.$router.go();
      }
    }
  };
</script>

<style lang="scss">
  .mobile-language-dropdown {
    // position: absolute;
    // bottom: 65px;
    // left: 13px;
    .button {
      margin: 1rem 0;
      padding: 0;
    }
    .dropdown__content {
      left: 0;
      bottom: 4.4rem;
      top: unset;
    }
  }
  .language-dropdown .dropdown__content--squared {
    min-width: 12rem;
  }
</style>
