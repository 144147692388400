<template>
  <div
    class="sh-user-status-tag"
    :class="[
      status == 'active'
        ? 'sh-user-status-tag--active'
        : 'sh-user-status-tag--inactive'
    ]"
  >
    {{
      localization(
        `app-${status
          .toLowerCase()
          .split("_")
          .join("-")}`,
        status
      )
    }}
  </div>
</template>

<script>
  export default {
    props: ["status"]
  };
</script>

<style lang="scss" scoped>
  div {
    text-transform: capitalize;
  }
</style>
