<template>
  <div>
    <div id="dhtmlx-slider"></div>
    <div class="is-flex">
      <span class="mr-5">Min</span>
      <input
        @input="onchange"
        style="width:40%"
        v-model="min"
        type="number"
        step="0.00000000000001"
        min="-100"
        max="100"
      />
      <span class="ml-10 mr-5">Max</span>
      <input
        @input="onchange"
        min="-100"
        max="100"
        style="width:40%"
        v-model="max"
        type="number"
        step="0.00000000000001"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      active_range: {
        required: false,
        default: [0, 100],
        type: Array
      }
    },
    data() {
      return {
        slider: null,
        min: this.active_range[0],
        max: this.active_range[1]
      };
    },
    mounted() {
      this.min = this.active_range[0];
      this.max = this.active_range[1];

      const vm = this;
      const slider = new dhx.Slider("dhtmlx-slider", {
        range: true,
        min: -100,
        max: 100,
        step: 0.00000000000001,
        value: this.active_range
      });
      this.slider = slider;
      slider.events.on("Change", function(newValue, oldValue, isRange) {
        vm.$emit("updated", slider.getValue());
        if (isRange) vm.max = newValue;
        else vm.min = newValue;
      });
    },
    methods: {
      onchange() {
        this.slider.setValue([this.min, this.max]);
      }
    }
  };
</script>

<style lang="scss"></style>
