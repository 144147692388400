import { render, staticRenderFns } from "./toolbar.vue?vue&type=template&id=3d355af6&scoped=true&"
import script from "./toolbar.vue?vue&type=script&lang=js&"
export * from "./toolbar.vue?vue&type=script&lang=js&"
import style0 from "./toolbar.vue?vue&type=style&index=0&id=3d355af6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d355af6",
  null
  
)

export default component.exports