<template>
  <div :class="{ 'is-inline-block': isButton }" class="sh-dropdown">
    <span
      v-if="isButton"
      class="is-medium-16-500-19  is-pointer has-opacity-8 has-text-link-blue"
      @click="
        active = !active;
        show_result = false;
        search_templates();
      "
    >
      <sh-icon name="magic-load" class="is-16x16 mr-5 is-inline" />
      {{ localization("app-save-as-template", "Save as Template") }}
    </span>
    <div
      v-click-outside="
        () => {
          active = false;
          $emit('save', active);
        }
      "
      style="top:-25rem"
      class="sh-dd-content"
      v-if="active"
    >
      <div class="search-field">
        <p class="control has-icons-left" @click="show_result = !show_result">
          <input
            v-model="search_keyword"
            @input="selected_template = null"
            class="input"
            :placeholder="
              localization('app-select-template', 'Select Template')
            "
          />
          <span class="icon is-small is-left">
            <sh-icon :name="'search'" class="is-16x16" />
          </span>
        </p>
      </div>
      <div class="mt-20 mb-20">
        <div class="sh-dd-content-heading is-medium-14-500-17">
          {{ localization("app-include", "Include") }}
        </div>
        <div class="mt-15 is-regular-14-00-17 is-flex">
          <label for="assignee" class="is-pointer is-flex align-center">
            <input
              class="sleek-checkbox mr-10"
              id="assignee"
              v-model="assignee"
              type="checkbox"
            />
            <span> {{ localization("app-assignee", "Assignee") }}</span>
          </label>
          <label
            for="attachments"
            class="is-pointer ml-30 is-flex align-center"
          >
            <input
              id="attachments"
              class="sleek-checkbox mr-10"
              v-model="attachment"
              type="checkbox"
            />
            <span>{{ localization("app-attachments", "Attachments") }}</span>
          </label>
        </div>
      </div>
      <div class="sh-dropdown-buttons">
        <div
          @click="
            active = false;
            $emit('save', active);
          "
          class="cancel-button button"
        >
          {{ localization("app-cancel", "Cancel") }}
        </div>
        <div
          v-if="!selected_template"
          @click="save_as_template"
          class="load-button button"
          :class="{ 'is-disabled has-no-access': !search_keyword }"
        >
          {{ localization("app-save-as-template", "Save as Template") }}
        </div>
        <div v-else @click="update_template" class="load-button button">
          {{ localization("app-update-template", "Update template") }}
        </div>
      </div>
    </div>
    <div
      class="results has-slimscroll-xs"
      v-click-outside="() => (show_result = false)"
      v-if="active && show_result && search_results && search_results.length"
      :class="[
        selected_template && selected_template.name == search_keyword
          ? 'is-hidden'
          : null
      ]"
    >
      <div class="result_item_list">
        <div
          v-for="list in search_results"
          :key="list.uid"
          class="results_items sh-dropdown-content__list--item"
          @click.stop="select_template(list)"
          :class="[
            selected_template && selected_template.uid == list.uid
              ? 'selected_template'
              : null
          ]"
        >
          <p class="is-medium-14-500-17">{{ list.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["ticket", "checklist", "isButton", "service"],
    data() {
      return {
        search_keyword: "",
        all_templates: null,
        selected_template: null,
        show_result: false,
        active: false,
        assignee: false,
        attachment: false
      };
    },
    watch: {
      active: function(val) {
        if (!val) {
          this.$emit("closed");
        }
      }
    },
    mounted() {
      this.isButton = !!this.isButton;
      if (!this.isButton) {
        this.active = true;
      }
      this.$modal.show(`load-template-modal`);
      this.search_templates();
    },
    computed: {
      search_results() {
        if (this.all_templates) {
          return this.all_templates.filter(checklist =>
            _.includes(
              checklist.name.toLowerCase(),
              this.search_keyword.toLowerCase()
            )
          );
        }
      }
    },
    methods: {
      async search_templates() {
        let { data } = await this.service
          .get_checklist_template({
            id: this.ticket.uid,
            query: `isTemplate=true`
          })
          .catch(err => console.log(err));
        this.all_templates = data;
      },
      select_template(template) {
        this.selected_template = template;
        this.search_keyword = this.selected_template.name;
      },
      async save_as_template() {
        let { data } = await this.service
          .checklist_template({
            id: this.ticket.uid,
            body: {
              checklists: [
                {
                  action: "saveTemplate",
                  include: {
                    assignee: this.assignee,
                    attachments: this.attachment
                  },
                  name: this.search_keyword,
                  checklistUid: this.checklist.uid
                }
              ]
            }
          })
          .catch(err => this.handleErrors(err));
        this.active = false;
        this.$emit("save", this.active);
        this.$modal.hide(`template-${this.checklist.uid}`);
      },
      async update_template() {
        let { data } = await this.service
          .update_checklist({
            id: this.ticket.uid,
            checklist_id: this.selected_template.uid,
            body: {
              items: this.checklist.items
            }
          })
          .catch(err => this.handleErrors(err));
        this.active = false;
        this.$emit("save", this.active);
        this.$modal.hide(`template-${this.checklist.uid}`);
        this.$toast.show(
          "Checklist template updated successfully",
          "",
          this.$store.state.izitoast_options.appSuccess
        );
      },
      async add_template() {
        this.show_result = false;
        this.active = false;
        this.$emit("save", this.active);
        if (this.items_only) {
          let { data } = await this.service
            .load_template_items({
              id: this.ticket.uid,
              checklist_id: this.current_checklist.uid,
              body: {
                templateUid: this.selected_template.uid
              }
            })
            .catch(err => console.log(err));
          this.$modal.hide(`load-template-modal`);
          this.$store.dispatch("checklist/add_checklists", {
            id: this.ticket.uid
          });
          this.$toast.show(
            "Tasks added to the checklist",
            "Success: ",
            this.$store.state.izitoast_options.appSuccess
          );
        } else {
          let checklist = this.selected_template;
          checklist.isTemplate = false;
          let { data } = await this.service
            .checklist_template({
              id: this.ticket.uid,
              body: {
                action: "loadTemplate",
                include: {
                  assignee: true,
                  attachments: false
                },
                name: this.search_keyword,
                templateUid: this.selected_template.uid
              }
            })
            .catch(err => this.handleErrors(err));
          this.$modal.hide(`load-template-modal`);
          this.$emit("update");
          this.$toast.show(
            "Checklist added",
            "",
            this.$store.state.izitoast_options.appSuccess
          );
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .sh-dd-content,
  .results {
    width: 378px;
    left: 2rem;
    max-height: 235px;
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    padding: 25px;
    background: white;
    border-radius: 10px;
    box-shadow: -10px 20px 50px 0 rgba(23, 30, 72, 0.2);
    z-index: 1000;
  }

  .sh-dd-content-heading {
    // opacity: 0.7;
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #171e48;
  }

  .sh-dropdown-buttons {
    text-align: right;
    margin-top: 30px;
  }

  input {
    border: 0;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
  }

  .load-button {
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #2585d3;
    color: #2585d3;
    line-height: 1.19;
    background-color: var(--white);
  }

  .cancel-button {
    font-size: 16px;
    font-weight: 500;
    border: 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #747891;
  }

  .results {
    left: 4rem;
    top: -18rem;
  }

  .result_item_list {
    overflow: hidden;
  }

  .sh-dropdown-content__list {
    margin: 0rem 2rem;
    &--item {
      padding: 1rem;
      border-radius: 2rem;

      &:hover {
        background-color: $primary-background;
        cursor: pointer;
      }
    }
  }
</style>
