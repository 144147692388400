<template>
  <div>
    <div
      v-if="comment && comment.verb && comment.verb.length"
      class="td-activities"
    >
      <div class="td-activities__block">
        <div
          class="td-activities__block--content"
          :class="{ 'td-activities__block--content-act': comment.verb }"
        >
          <div class="is-flex align-center is-between is-full-width mb-8">
            <div class="is-flex is-align-baseline">
              <p
                class="is-medium-16-500-19 mr-7"
                v-username="$store.getters.state_users[comment.actor.id]"
              ></p>
              <div class="is-medium-14-500-17 has-opacity-7 has-primary-text">
                {{
                  localization(
                    `app-${activity_text[comment.verb]
                      .toLowerCase()
                      .split(" ")
                      .join("-")}`,
                    activity_text[comment.verb]
                  )
                }}
              </div>
            </div>
            <div class="is-regular-14-500-17 has-opacity-7 has-primary-text">
              {{ createdByTime(comment.time + "Z") }}
            </div>
          </div>
          <div><activity-details :activity="comment"></activity-details></div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="sh-comment is-flex px-20"
      v-click-outside="() => (updating = false)"
      @contextmenu.prevent
      v-touch:touchhold="touchhold"
      @click.stop
    >
      <div class="mr-10">
        <user-like
          v-if="userIcon"
          :ticket="ticket"
          :channel="channel"
          :isEditing="showEditor || (comment && comment.type === 'deleted')"
          :comment="comment"
          :reaction="reaction"
          @send="sendReaction"
          @delete="deleteReaction"
        ></user-like>
      </div>

      <div
        class="sh-comment-deleted"
        style="flex:1;border:2px dashed #ccc"
        v-if="comment && comment.type === 'deleted'"
      >
        <div class="sh-comment-box">
          <p class="sh-comment-text  is-regular-14-00-22" role="textbox">
            {{
              localization(
                "app-this-comment-was-deleted",
                "This comment was deleted"
              )
            }}
          </p>
        </div>
      </div>
      <div v-else-if="!showEditor" style="flex:1">
        <div
          class="sh-comment-box is-relative"
          :pinned="comment.pinned"
          :class="[
            this.$store.state.claims.user_id === comment.user.id
              ? 'comment-owner'
              : 'comment-not-owner',
            { 'sh-comment-box-pinned': comment.pinned }
          ]"
        >
          <div v-if="comment.pinned" class="pinned-border"></div>
          <options
            :ticket="ticket"
            :channel="channel"
            :isEditing="showEditor"
            :comment="comment"
            :option="option"
            @mobileEvent="mobileEvents = $event"
            @reply="
              $emit('reply', comment);
              openreply = true;
            "
            @edit="
              description = comment.text;
              updating = true;
            "
            @delete="deleteMessage"
            @pinned="
              option.pinned = true;
              updateComment({ pinned: !comment.pinned });
            "
          ></options>
          <p
            class="sh-comment-text ck ck-content ck-editor__editable ck-rounded-corners ck-blurred is-regular-14-00-22"
            role="textbox"
            v-html="dataHtml"
          ></p>
          <div
            v-if="
              attachment_files.length === 0 &&
                dataHtml === '' &&
                loadingAttachment
            "
            style="height:38px;"
          ></div>
          <div
            style="color:#aaa;border:2px dashed #ccc;padding:5px;"
            v-else-if="
              attachment_files.length === 0 &&
                dataHtml === '' &&
                !loadingAttachment
            "
          >
            {{ localization("app-attachments-deleted", "Attachments deleted") }}
          </div>
          <div v-if="comment.id" v-touch:start.stop>
            <attachment-list
              :service="service"
              class="task-list-tab"
              v-if="
                comment.id && attachment_files && attachment_files.length > 0
              "
              id="task-tab"
              @delete_attachment="delete_attachment($event)"
              :existing_files="attachment_files"
              @refresh_attachments="getFiles"
              :deselect="false"
              :ticket="ticket"
              :is_comment="true"
              :show_more="true"
              :component="'ticketDetails'"
              :no_selection="true"
            ></attachment-list>
          </div>
          <div
            v-else-if="
              comment.attachment_upload && comment.attachment_upload.length > 0
            "
          >
            {{ comment.attachment_upload.length }}
            {{ localization("app-files-uploaded", "Files Uploaded") }}
          </div>
          <attachment
            v-if="comment.attachments"
            :comment="comment"
          ></attachment>
        </div>
        <reply-count
          v-if="comment.reply_count"
          :comment="comment"
          @clicked="
            $emit('reply', comment);
            openreply = !openreply;
            scrollToReplyEditor();
          "
        ></reply-count>
        <div v-if="comment.id == parent.id && openreply" class="mt-10">
          <reply
            v-for="replycomment in replyData"
            :key="replycomment.id"
            :comment="replycomment"
            :ticket="ticket"
            :presence_members="presence_members"
            :parent="parent"
            :zombie="false"
            @reply-added="$emit('reply-added', $event)"
            :channel="channel"
          ></reply>
          <slot></slot>
          <div class="is-flex is-center">
            <div
              class="mb-10 has-accent-text is-medium-14-500-17 is-pointer is-flex align-center"
              @click="openreply = false"
            >
              {{ localization("app-collapse", "Collapse") }}
              <sh-icon
                :name="'expand-active'"
                class="is-18x18 ml-5 is-pointer"
              ></sh-icon>
            </div>
          </div>
        </div>
      </div>
      <editor
        @send="sendComment"
        ref="inlineEditor"
        v-model="description"
        :ticket="ticket"
        :attachment_files="attachment_files"
        :channel="channel"
        :attachments="attachments"
        :comment="comment"
        :showAttachments="true"
        :isMobile="isMobile"
        :action="editorAction"
        :service="service"
        :presence_members="presence_members"
        :autofocus="true"
        @attachment_upload="
          (attachments = $event.attachments), (custom_id = $event.id)
        "
        v-else
      ></editor>
      <div
        @click="sendZombieComment"
        v-if="showRetry"
        v-tooltip="{
          content: localization(
            'app-tooltip-retry-message',
            'Retry sending this message'
          ),
          html: false
        }"
        class="sh-comments-comment--retry"
      >
        <i
          class="fa fa-redo mr-5"
          style="font-size:12px;"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
  import shMenu from "@/app/old/desktop/shared/components/menu";
  import Editor from "./components/editor";
  import UserLike from "./components/user-like";
  import Options from "./components/options";
  import ReplyCount from "./components/reply-count";
  import Attachment from "./components/attachment";
  import AttachmentList from "@/app/old/desktop/tickets/components/ticket-details/components/attachments/components/attachment-list";
  import SegmentAnalytics from "@/app/old/desktop/shared/mixins/segment-analytics.mixin.vue";
  import { v4 as uuidv4 } from "uuid";
  import ActivityDetails from "@/app/old/desktop/tickets/components/ticket-details/components/activities/components/activity-details.vue";
  import Reply from "@/app/old/desktop/tickets/components/ticket-details/new-ticket-details/comments/components/comment/reply.vue";
  import { DateTime } from "luxon";

  export default {
    components: {
      shMenu,
      Editor,
      UserLike,
      Options,
      ReplyCount,
      Attachment,
      AttachmentList,
      ActivityDetails,
      Reply
    },
    mixins: [SegmentAnalytics],
    props: [
      "ticket",
      "comment",
      "isEditing",
      "channel",
      "files",
      "presence_members",
      "zombie",
      "service",
      "small",
      "dataUid",
      "userIcon",
      "parent",
      "replyData",
      "threads"
    ],
    data() {
      return {
        addingComments: false,
        description: "",
        updating: false,
        editorAction: false,
        attachments: [],
        attachment_files: [],
        loadingAttachment: true,
        reaction: { sent: false, delete: false },
        option: { pinned: false },
        custom_id: undefined,
        showRetry: false,
        showRetryTimeout: null,
        isMobile: false,
        openreply: false,
        mobileEvents: () => {},
        interval: null,
        updateTime: null,
        activity_text: {
          TICKET_ASSIGNMENT_CHANGED: "Changed assignment",
          TICKET_UNASSIGNED: "Unassigned task from",
          TICKET_ASSIGNED: "Assigned task to",
          TICKET_PRIORITY_CHANGED: "Changed priority",
          ATTACHMENT_UPLOADED: "Added attachment",
          DUE_DATE_CHANGED: "Changed duedate",
          ATTACHMENT_DELETED: "Deleted attachment",
          ATTACHMENT_UPLOADED: "Added attachment",
          DUE_DATE_CHANGED: "Changed duedate",
          MULTIPLE_ATTACHMENT_UPLOADED: "Added multiple attachments",
          TICKET_STATUS_CHANGED: "Changed status"
        }
      };
    },
    watch: {
      attachmentUploads: {
        handler: "getFiles",
        immediate: true
      }
    },
    computed: {
      attachmentUploads() {
        return this.comment ? this.comment.attachment_upload : undefined;
      },
      dataHtml() {
        let str = this.comment.text;
        if (str) {
          str = str.replace(/<p>&nbsp;<\/p>/g, "");
        }
        return str;
      },
      showEditor() {
        return this.isEditing || this.updating;
      }
    },
    beforeDestroy() {
      clearTimeout(this.showRetryTimeout);
      clearInterval(this.interval);
    },
    mounted() {
      this.isMobile = this.$route.meta.screen === "mobile" || this.small;
      if (this.zombie) {
        this.showRetryTimeout = setTimeout(() => (this.showRetry = true), 5000);
      }
      this.interval = setInterval(() => {
        this.createdByTime();
      }, 1000);
    },
    methods: {
      scrollToReplyEditor() {
        if (this.replyData && this.replyData.length) {
          setTimeout(() => {
            let element = document.getElementById("replyeditor");
            element.scrollIntoView({ block: "end" });
          }, 100);
        }
      },
      createdByTime(createdTime) {
        let givenDate = new DateTime.fromISO(createdTime);
        let today = new DateTime.local();
        this.updateTime = givenDate.toRelative({ base: today });
        return this.updateTime;
      },
      async delete_attachment(file) {
        let res = await this.service
          .delete_comment_attachment({
            id: this.ticket.uid,
            comment_id: this.comment.custom_id,
            file_id: file.uid
          })
          .then(() => this.getFiles())
          .catch(err => this.handleErrors(err));
      },
      touchhold() {
        this.mobileEvents();
      },
      async getFiles() {
        if (this.comment && this.comment.custom_id !== undefined) {
          this.attachment_files = await this.getAttachments(
            this.comment.custom_id
          );
          this.loadingAttachment = false;
        }
      },
      getPresenceMembers() {
        try {
          return this.presence_members.map(d => {
            return { email: d.email, uid: d.uid };
          });
        } catch (e) {
          return [];
        }
      },
      async sendZombieComment() {
        try {
          this.showRetry = false;
          await this.channel.sendMessage(this.comment.unprocessed);
        } catch (e) {
          this.showRetry = true;
        }
      },
      async sendComment(comment) {
        try {
          if (
            (this.description === "" && !this.custom_id) ||
            this.addingComments
          ) {
            return;
          }
          this.addingComments = true;
          setTimeout(() => {
            this.addingComments = false;
          }, 1000);
          let mentions = Array.prototype.map.call(
            this.$refs.inlineEditor.$refs.mentions.querySelectorAll(
              "[data-mention]"
            ),
            e => {
              return {
                uid: e.getAttribute("ref")
              };
            }
          );
          let data = {
            text: this.description,
            mentioned_user_list: mentions,
            attachment_upload: this.attachments,
            owner: this.$store.state.claims.user_id,
            custom_id: this.custom_id,
            presence_members: this.getPresenceMembers(),
            uuid: uuidv4()
          };
          this.attachments = [];
          this.custom_id = undefined;
          if (this.dataUid) {
            data.dataUid = this.dataUid;
          }
          if (this.updating) {
            this.updateComment(data);
            return;
          }
          let load = Object.assign({}, data);
          this.$emit("sendMessage", load);
          this.description = "";
          await this.channel.sendMessage(data);
          this.segment_track_event("comment_added", {
            mentions_count: data.mentioned_user_list
              ? data.mentioned_user_list.length
              : 0,
            attachments_count: data.attachment_upload
              ? data.attachment_upload.length
              : 0
          });
        } catch (e) {
          this.description = "";
          this.attachments = [];
          this.custom_id = undefined;
        }
      },
      async getAttachments(g_id) {
        return await this.service.get_file_download_comments({
          id: this.ticket.uid,
          comment_id: g_id
        });
      },
      async updateComment(prop) {
        try {
          const message = { ...this.comment, ...prop };
          this.editorAction = true;
          delete message.channel;
          const update = await this.$store.state.chat.client.updateMessage(
            message
          );
          this.editorAction = false;
          this.updating = false;
          this.option.pinned = false;
        } catch (e) {
          this.editorAction = false;
          this.updating = false;
          this.option.pinned = false;
        }
      },
      async deleteMessage() {
        await this.$store.state.chat.client.deleteMessage(this.comment.id);
      },
      async sendReaction() {
        try {
          this.reaction.sent = true;
          await this.channel.sendReaction(this.comment.id, {
            type: "like",
            presence_members: this.getPresenceMembers()
          });
          this.reaction.sent = false;
        } catch (e) {
          this.reaction.sent = false;
        }
      },
      async deleteReaction() {
        try {
          if (this.comment.own_reactions.length === 0) {
            this.sendReaction();
            return;
          }
          this.reaction.delete = true;
          await this.channel.deleteReaction(this.comment.id, "like");
          this.reaction.delete = false;
        } catch (e) {
          this.reaction.delete = false;
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  // .container {
  //   height: 10rem !important;
  //   width: 30rem;
  //   overflow: visible;
  //   .activities {
  //     display: flex;
  //     justify-content: center;
  //     flex-direction: column;
  //     align-items: center;
  //     .activity {
  //       margin-bottom: 5rem;
  //       h1 {
  //         @include for-size(portrait-tablets) {
  //           padding: 0.5rem;
  //         }
  //       }
  //       &-card {
  //         width: 45rem;
  //       }
  //     }
  //   }
  // }

  .td-activities {
    // padding: 2rem 3.5rem 2rem 2rem;
    padding: 1.1rem 3.5rem 1.1rem 2rem;
    &__block {
      display: flex;
      // background-color: red;
      &--content {
        margin-left: 1rem;
        width: 100%;
      }
    }
  }
  .pinned-border {
    border-left: 4px solid #fd9c0f;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .td-activities__block--content-act {
    margin-left: 5rem !important;
  }
  .sh-comment-box {
    border-radius: 0 10px 10px 10px;
    &-pinned {
      border-radius: 0 10px 10px 0;
    }
  }
</style>
