import ThermV2 from "@/app/old/desktop/therm-v2/therm-v2.vue";
import Thermv2Map from "@/app/old/desktop/therm-v2/components/therm-v2-map.vue";
import Tickets from "@/app/old/desktop/therm-v2/components/therm-v2-ticket-list.vue";
import Defects from "@/app/old/desktop/therm-v2/components/therm-v2-defects-list.vue";
import Reports from "@/app/old/desktop/therm-v2/components/therm-v2-reports.vue";
import ComparisionContainer from "@/app/old/desktop/therm-v2/components/therm-v2-compare-maps.vue";

const Routes = [
  {
    path: "/",
    component: ThermV2,
    meta: {
      requiresAuth: true,
      requiresThermPermissions: true
    },
    children: [
      {
        path: ":id",
        name: "therm-v2",
        component: Thermv2Map
      },
      {
        path: ":id/tickets",
        name: "therm-v2-tickets",
        component: Defects
      },
      {
        path: ":id/compare",
        name: "therm-v2-compare",
        component: ComparisionContainer
      },
      {
        path: ":id/reports",
        name: "therm-v2-reports",
        component: Reports
      }
    ]
  }
];

export default Routes;
