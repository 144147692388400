export default {
  inserted(el, binding) {
    const position = el.getBoundingClientRect()
    const default_offset = 0
    const vertical_offset = binding.value
      ? binding.value.vertical_offset || default_offset
      : default_offset
    const horizontal_offset = binding.value
      ? binding.value.horizontal_offset || default_offset
      : default_offset
    const inner_height = window.innerHeight + vertical_offset
    const inner_width = window.innerWidth + horizontal_offset
    let element_position = {}
    if (position.y > inner_height || position.bottom > inner_height) {
      element_position.top =
        binding.value && binding.value.top ? binding.value.top : 'unset'
      element_position.bottom =
        binding.value && binding.value.bottom ? binding.value.bottom : 'unset'
    }
    if (position.x > inner_width || position.right > inner_width) {
      element_position.left =
        binding.value && binding.value.left ? binding.value.left : 'unset'
      element_position.right =
        binding.value && binding.value.right ? binding.value.right : 'unset'
    }
    for (let property in element_position) {
      el.style[property] = element_position[property]
    }
  }
}
