<template>
  <div class="px-20">
    <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
      {{ localization("app-status", "Status") }}
    </div>
    <status-dropdown
      class="is-medium-16-500-19 pb-20"
      style="width:14rem;left: -1.1rem;"
      :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
      @close="$emit('close-popup')"
      :current_status="ticket.status"
      :ticket="ticket"
      v-if="ticket"
      @update-ticket-status="update_ticket({ status: parseInt($event.value) })"
    ></status-dropdown>
    <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
      {{ localization("app-assignee", "Assignee") }}
    </div>
    <user-assignee-dropdown
      style="position: relative; left: -10px"
      :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
      :existing_user="ticket.takenUp"
      @update-user="update_ticket({ takenUp: $event })"
      :pxsize="24"
      class="is-medium-16-500-19 is-paddingless mb-20"
    ></user-assignee-dropdown>
    <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
      {{ localization("app-due-date", "Due Date") }}
    </div>
    <div class="duedate-value pb-20">
      <div class="is-flex align-center">
        <template v-if="ticket.isRecurring">
          <div class="is-flex align-center">
            <sh-icon
              :name="`due-date-${is_overdue ? 'danger' : 'dark'}`"
              class="is-18x18 mr-5"
            ></sh-icon>
            <span
              :class="[is_overdue ? 'has-text-danger' : null]"
              class="is-medium-14-500-17 mr-5"
            >
              {{ ticket.dueDate | sinceDays }}
            </span>
            <!-- <p
              class="is-flex is-center ml-5"
              v-tooltip="{ content: rrule_string }"
            >
              <sh-icon :name="'recurrence-recurs'" class="is-18x18 is-pointer" />
            </p> -->
          </div>
        </template>
        <template v-else>
          <duedate-dropdown
            :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
            class="is-medium-16-500-19"
            style="left:-1rem"
            :ticket="ticket"
            v-if="ticket"
            :due="ticket.dueDate || null"
            :in_details="true"
            @change-duedate="
              update_ticket({
                dueDate: $event ? new Date($event).toISOString() : null
              })
            "
          ></duedate-dropdown>
        </template>
        <div class="is-relative">
          <div
            v-if="ticket.schedule"
            class="recurrence-icon"
            :class="[ticket.isRecurring ? 'mt-3 ml-5' : '']"
          >
            <sh-icon
              @click.native="open_schedule = !open_schedule"
              :name="'recurrence-recurs-accent'"
              class="is-20x20 mr-10 is-pointer"
            ></sh-icon>
            <div class="recurrence-card">
              <div class="is-regular-14-500-17 recurrence-card_title">
                Scheduled on:
              </div>
              <div class="is-medium-14-500-17 is-capitalized pb-5">
                {{ recurrence_text }}
              </div>
              <div
                class="is-medium-12-500-00 is-capitalized mr-10 new-schedule__status-tag"
                :class="'is-' + ticket.schedule.recurWhen"
              >
                {{ status[ticket.schedule.recurWhen] }}
              </div>
            </div>
          </div>
          <div
            v-else
            @click="open_schedule = !open_schedule"
            class="is-flex align-center has-accent-text is-pointer"
            :class="{
              'is-hidden': ticket && !ticket.writeAccess,
              'td-set-schedule': !open_schedule
            }"
          >
            <sh-icon
              class="mr-5 ml-10 is-20x20"
              :name="'add-create-accent'"
            ></sh-icon>
            <span class="is-semiBold-14-600-17">{{
              localization("app-new-schedule", "New Schedule")
            }}</span>
          </div>

          <div
            class="new-schedule-card"
            v-click-outside="
              () =>
                ($route.name == 'schedules' ||
                  $route.name == 'schedule-details') &&
                !ticket.schedule
                  ? null
                  : (open_schedule = false)
            "
            v-if="open_schedule"
          >
            <schedule
              :has_access="ticket.writeAccess ? true : false"
              :schedule="ticket.schedule"
              :uid="ticket.uid"
              @close-schedule="
                $route.name == 'schedules' ||
                ($route.name == 'schedule-details' && !ticket.schedule)
                  ? null
                  : (open_schedule = false)
              "
            ></schedule>
          </div>
        </div>
      </div>
    </div>

    <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
      {{ localization("app-priority", "Priority") }}
    </div>
    <priority-dropdown
      :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
      class="is-medium-16-500-19  pb-20"
      @change_ticket_priority="update_ticket({ priority: parseInt($event) })"
      :priority="ticket.priority"
      v-if="ticket"
      style="width:14rem;left: -1.1rem;"
    ></priority-dropdown>

    <div class="pb-20">
      <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
        {{ localization("app-users", "Users") }}
      </div>
      <div
        class="is-flex is-vcenter"
        v-if="
          (ticket.readUsers && ticket.readUsers.length) ||
            (ticket.writeUsers && ticket.writeUsers.length)
        "
      >
        <user-tag
          :custom_class="'tiny-user-avatar is-pointer'"
          :custom_size="24"
          :users="[...(ticket.readUsers || []), ...(ticket.writeUsers || [])]"
          :tagsCountToShow="5"
          :no_access="true"
          :is_precense_component="true"
          @handle_click="ticket.writeAccess ? add_users() : null"
        ></user-tag>
        <sh-icon
          v-if="ticket.writeAccess"
          :name="'add-users-teams'"
          class="is-22x22 ml-5 is-pointer add-user-assignee"
          v-tooltip="{
            content: localization(
              'app-add-users-to-this-ticket',
              `Add users to this ticket`
            ),
            html: false
          }"
          @click.native="add_users"
          :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
        />
      </div>
      <div
        v-else
        class="is-flex is-vcenter is-pointer"
        @click="add_users"
        :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
      >
        <sh-icon :name="'add-users-2'" class="mr-7 is-24x24" />
        <span
          class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
          >{{ localization("app-add-users", "Add Users") }}</span
        >
      </div>
    </div>

    <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
      {{ localization("app-teams", "Teams") }}
    </div>
    <div class="is-flex is-vcenter pb-20" v-if="get_teams(ticket).length">
      <primary-tags
        :tags="get_teams(ticket)"
        @clicked_tag="ticket.writeAccess ? add_teams() : null"
        :toShowCount="2"
        :no_access="true"
        :type="'team'"
        :no_wrap="true"
        :truncate_length="10"
      />
      <sh-icon
        v-if="ticket.writeAccess"
        :name="'add-users-teams'"
        class="show-on-hover is-22x22 ml-5 is-pointer add-user-assignee"
        @click.native="add_teams"
      />
    </div>
    <div
      v-else
      class="is-flex is-vcenter is-pointer pb-20"
      @click="add_teams"
      :class="[ticket && !ticket.writeAccess ? 'has-no-access' : null]"
    >
      <sh-icon :name="'add-teams'" class="mr-7 is-24x24" />
      <span
        class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
        >{{ localization("app-add-teams", "Add Teams") }}</span
      >
    </div>

    <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
      {{ localization("app-created-by", "Created by") }}
    </div>
    <div v-if="ticket.owner" class="is-flex is-vcenter pb-20">
      <user-avatar
        :user="ticket.owner"
        :pxsize="24"
        :custom_class="'tiny-user-avatar'"
      ></user-avatar>
      <div>
        <p class="is-medium-14-500-17 ml-8" v-username="ticket.owner"></p>
        <div class="is-medium-12-500-14 ml-8 ticket-created-time pt-3">
          {{ ticket.createdAt | prettyDate }}
        </div>
      </div>
    </div>
    <p v-else class="is-regular-14-00-17 has-text-grey mt-15 pb-20">
      {{ localization("app-not-found", "*Not found") }}
    </p>
    <!-- <div v-if="$store.getters.modify_task_element"> -->
    <!-- <div v-if="true"> -->
    <modify-element-component :ticket="ticket"></modify-element-component>
    <!-- </div> -->
    <!-- <div v-else>
      <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
        {{ localization("app-assets", "Assets") }}
      </div>
      <primary-tags
        class="pb-20"
        @click.native="$emit('close')"
        :tags="elementData ? [elementData] : null"
        :type="'asset'"
        :noTopMargin="true"
        v-if="elementData && elementData.type == 'asset'"
      ></primary-tags>
      <div class="is-flex is-vcenter is-pointer pb-20" v-else>
        <sh-icon :name="'add-asset'" class="mr-7 is-30x30" />
        <span
          class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
          >Asset not associated</span
        >
      </div>

      <div class="pb-20">
        <div
          class="is-regular-12-00-14 pb-7 new-ticket-details__content--title"
        >
          {{ localization("app-location", "Location") }}
        </div>
        <div class="is-flex pb-20" v-if="nameArray && nameArray.length">
          <sh-icon
            v-if="
              elementData &&
                ['file', 'folder', 'vault'].includes(elementData.type)
            "
            :file="'vault_sprite'"
            :name="'add-folder'"
            class="image is-pointer mr-5 is-20x20"
          />
          <div class="is-flex is-flex-wrap">
            <div
              v-for="(name, i) in nameArray"
              :key="i"
              class="is-flex is-vcenter"
            >
              <span
                class="is-medium-14-500-17 no-whitespace-wrap"
                v-tooltip="{
                  content: name,
                  html: false
                }"
                :class="[
                  name == '*Not found'
                    ? 'has-text-light-grey'
                    : 'has-text-theme-black'
                ]"
                >{{ name | truncate(15) }}</span
              >
              <p v-if="i != nameArray.length - 1">
                <sh-icon
                  :name="'collapse'"
                  class="is-16x16 mx-5"
                  style="transform: rotate(-90deg)"
                ></sh-icon>
              </p>
            </div>
          </div>
        </div>
        <div class="is-flex is-vcenter is-pointer" v-else>
          <sh-icon :name="'add-location'" class="mr-7 is-30x30" />
          <span
            class="mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"
            >{{
              localization("app-location-not-found", "Location not found")
            }}</span
          >
        </div>
      </div>
    </div> -->
    <div v-if="ticket && ticket.properties && ticket.properties.alarm">
      <div class="is-regular-12-00-14 pb-7 new-ticket-details__content--title">
        {{ localization("app-alarm", "Alarm") }}
      </div>
      <div v-if="ticket.owner" class="is-flex is-vcenter pb-20">
        <a @click="Strategix4()">
          <i class="fa fa-bell mr-2"></i>
          Alarm
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import PrimaryTags from "@/app/old/desktop/shared/components/tags/primary-tag";
  import PriorityDropdown from "@/app/old/desktop/tickets/shared/components/priority-dropdown";
  import UserAssigneeDropdown from "@/app/old/desktop/tickets/shared/components/user-assignee-dropdown";
  import StatusDropdown from "@/app/old/desktop/tickets/shared/components/status-dropdown";
  import AddUsers from "@/app/old/desktop/shared/components/add-users-modal";
  import AddTeams from "@/app/old/desktop/shared/components/add-teams-modal";
  import DuedateDropdown from "@/app/old/desktop/tickets/shared/components/duedate-dropdown";
  import TicketsCommonSegmentEvents from "@/app/old/desktop/tickets/components/ticket-details/components/tickets-common-segment-events.mixin.vue";
  import {
    ticketService,
    apiService
  } from "@/app/old/desktop/shared/services/";
  import { RRule } from "rrule";
  import MobileTopSection from "@/app/old/mobile/modules/tickets/components/ticket-details/mobile-ticket-details";
  import Schedule from "@/app/old/desktop/tickets/components/ticket-details/components/schedule/schedule.vue";
  import { DateTime } from "luxon";
  import ModifyElementComponent from "@/app/old/desktop/tickets/shared/components/modify-element-component.vue";

  export default {
    mixins: [TicketsCommonSegmentEvents],
    props: ["ticket"],
    data() {
      return {
        ticketService: ticketService,
        nameArray: [],
        elementData: null,
        open_schedule: false,
        status: {
          1: "pending",
          2: "in progress",
          3: "resolved",
          4: "closed",
          5: "rejected"
        }
      };
    },
    components: {
      UserTag,
      PrimaryTags,
      PriorityDropdown,
      UserAssigneeDropdown,
      StatusDropdown,
      DuedateDropdown,
      MobileTopSection,
      Schedule,
      ModifyElementComponent
    },
    computed: {
      rrule_string() {
        if (this.ticket.rrule) {
          return new RRule.fromString(this.ticket.rrule).toText();
        }
      },
      is_overdue() {
        let givenDate = new DateTime.fromISO(this.ticket.dueDate);
        let today = new DateTime.local();
        let difference = Math.floor(givenDate.diff(today, "days").days);
        return difference < 0;
      },
      recurrence_text() {
        if (this.ticket.schedule && this.ticket.schedule.rrule != null) {
          return new RRule.fromString(this.ticket.schedule.rrule).toText();
        } else {
          return null;
        }
      }
    },
    created() {
      this.get_element();
      if (
        this.$route.name == "schedules" ||
        this.$route.name == "schedule-details"
      ) {
        this.open_schedule = true;
      }
    },
    methods: {
      async Strategix4() {
        let body = {
          email:
            localStorage.getItem("strategix4_email") || "vijaym@sensehawk.com",
          password:
            localStorage.getItem("strategix4_password") || "Sensehawk@123"
        };

        let response = await apiService.get_strategix4_token({
          body
        });

        let token = response?.data?.token;
        token =
          token ||
          localStorage.getItem("strategix4_token") ||
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lck51bWJlciI6Ijk5OTA1NjMiLCJlbWFpbCI6InZpamF5bUBzZW5zZWhhd2suY29tIiwiZXhwIjoxNjI1MDQ3MjkxLCJyb2xlIjoiIiwiYXBpX3ZlcnNpb24iOiIwLjAuMSIsImlhdCI6MTYyNDk2MDg5MX0.u_gDznzypbbN9l2KOGynOVlhiuy3vUw4U3E3K4DVxFk";
        let url = `https://sensehawk.strategix4.com/#alarms-detail?token=${token}&alarmid=${this.ticket.properties.alarm.id}`;

        window.open(url, "_blank").focus();
      },
      add_teams() {
        this.$modal.show(
          AddTeams,
          {
            teams: [
              ...(this.ticket.readLabels || []).map(u => ({
                uid: u,
                access: "read"
              })),
              ...(this.ticket.writeLabels || []).map(u => ({
                uid: u,
                access: "write"
              }))
            ],
            asset_id:
              this.ticket && this.ticket.targetElement
                ? this.ticket.targetElement.asset
                : null,
            item_uid: this.ticket.uid,
            complete: async e => {
              let readLabels = e
                .filter(u => u.access === "read")
                .map(u => u.uid);
              let writeLabels = e
                .filter(u => u.access === "write")
                .map(u => u.uid);
              this.update_ticket({ readLabels, writeLabels });
            }
          },
          { height: "80%" }
        );
      },
      add_users() {
        this.$emit("overlay_popup", true);
        this.$modal.show(
          AddUsers,
          {
            users: [
              ...(this.ticket.readUsers || []).map(u => ({
                ...u,
                access: "read"
              })),
              ...(this.ticket.writeUsers || []).map(u => ({
                ...u,
                access: "write"
              }))
            ],
            item_uid: this.ticket.uid,

            complete: async e => {
              let readUsers = e.filter(u => u.access === "read");
              let writeUsers = e.filter(u => u.access === "write");
              this.update_ticket({ readUsers, writeUsers });
            }
          },
          { height: "80%" },
          { name: "add-ticket-users" }
        );
      },

      update_ticket(value) {
        this.$store.dispatch("tickets/update_ticket_value", {
          id: this.ticket.uid,
          value: value,
          router: this.$router
        });
      },

      async get_element() {
        this.nameArray = [];
        let res = await apiService
          .get_element_hierarchy({
            id: this.ticket.targetElement.uid,
            stage: this.ticket.targetElement.stage
          })
          .catch(err => {
            console.log(err);
            this.nameArray = [this.localization("app-not-found", "*Not found")];
          });
        this.elementData = res;
        if (this.elementData) this.getName(this.elementData);
      },
      getName(dataObject) {
        if (dataObject[`name`]) {
          this.nameArray.push(dataObject[`name`]);
        }
        if (dataObject[`child`] && dataObject[`child`].uid)
          return this.getName(dataObject[`child`]);
        return null;
      }
    }
  };
</script>

<style lang="scss">
  .new-schedule-card {
    position: absolute;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(91, 96, 126, 0.1);
    background-color: #ffffff;
    width: 40rem;
    top: 2rem;
    right: -3rem;
    z-index: 1;
  }
  .recurrence-icon {
    position: relative;
    &:hover {
      .recurrence-card {
        visibility: visible;
      }
    }
    .recurrence-card {
      position: absolute;
      visibility: hidden;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px rgba(91, 96, 126, 0.1);
      background-color: #ffffff;
      padding: 1.6rem;
      right: -2rem;
      top: 2rem;
      z-index: 1;
      width: 25rem;
      &_title {
        padding-bottom: 5px;
        color: $ticket-heading;
      }
    }
  }
  .duedate-value {
    :hover {
      .td-set-schedule {
        visibility: visible;
      }
    }
    .td-set-schedule {
      visibility: hidden;
    }
  }
  .ticket-created-time {
    color: $ticket-heading;
  }
</style>
