import { httpClient } from "@/app/old/desktop/shared/services/";
import { BaseService } from "@/app/old/desktop/shared/services/";

const API_HOST = process.env.VUE_APP_SENSEHAWK_HOST;
const URL = "categories";

class API extends BaseService {
  constructor() {
    super(API_HOST, URL);
  }

  async getAll(req = {}) {
    return httpClient({
      url: `${this.url}`,
      baseURL: `${this.baseURL}/categories/`,
      headers: req.headers
    });
  }
}

export const categoryService = new API();
