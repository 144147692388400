export function loadScript(url, onload = () => {}, id = null) {
  if (id && document.getElementById(id)) {
    return onload();
  }
  let scriptTag = document.createElement("script");
  scriptTag.setAttribute("src", url);
  scriptTag.id = id;
  document.head.appendChild(scriptTag);
  scriptTag.onload = () => {
    onload();
  };
}
