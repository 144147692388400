import { httpClient } from '@/app/old/desktop/shared/services/'
const API_HOST = process.env.VUE_APP_DRS_API_HOST
class API {
  constructor() {
    this.baseURL = API_HOST
  }
  async get_users(req = {}) {
    return httpClient({
      url: `organization/filter-users/${req.query ? `?${req.query}` : ''}`,
      baseURL: this.baseURL.replace('dc', req.dc),
      headers: req.headers,
      data: req.body,
      method: 'POST'
    }).then(res => res.data)
  }
}
export const drsService = new API()
// export { projectService };
