<template>
  <div
    class="therm-sidebar has-slimscroll-xs is-paddingless"
    :class="{ 'therm-sidebar--mini': mini_thermsidebar }"
    :style="{ width: `${sidebar_width}rem` }"
  >
    <div class="therm-sidebar__details" v-if="!mini_thermsidebar">
      <div class="therm-sidebar__asset-name is-regular-12-00-18">
        {{ asset_name }}
      </div>
      <p class="is-medium-14-500-17" v-if="container && container.length">
        {{ container[0].viewName }}
      </p>
    </div>
    <div class="therm-sidebar__details" v-else>
      <span class="is-regular-12-00-18">
        {{ asset_name }}
      </span>
      <i
        class="fas fa-chevron-right"
        style="font-size: 1rem; color: grey; padding: 0 10px;"
      ></i>
      <span class="is-medium-14-500-17" v-if="container && container.length">
        {{ container[0].viewName }}
      </span>
    </div>
    <hr class="divider is-marginless has-opacity-4" v-if="!mini_thermsidebar" />
    <!-- Emits projects that were selected/unselected -->
    <projects-list
      v-show="$route.name !== 'therm-v2-compare'"
      :custom_styles_for_groups="
        $route.name == 'therm-v2-reports' || $route.name == 'therm-v2-tickets'
          ? `right: 4rem; top: 1rem; bottom: initial`
          : `left: ${sidebar_width + 2}rem`
      "
      :custom_styles_for_projects="
        $route.name == 'therm-v2-reports' || $route.name == 'therm-v2-tickets'
          ? `left: ${sidebar_width + 2}rem;top:-6.1rem;`
          : `left: ${sidebar_width + 2}rem;top:-5rem`
      "
      @projects-selected="set_active_projects"
      @projects-unselected="set_inactive_projects"
    />
    <img
      src="@/assets/icons/thermv2/logo.svg"
      class="mainlogo image"
      v-if="!mini_thermsidebar && false"
    />
    <div
      class="image is-32x32 logo-mini image"
      v-else-if="mini_thermsidebar && false"
    >
      <img
        src="@/assets/logos/sensehawk-logo-small.svg"
        alt
        class="is-marginless"
      />
    </div>
    <hr class="divider is-marginless has-opacity-1" v-if="false" />
    <!-- <sh-icon
      @click.native="SET_MINI_THERMSIDEBAR(!mini_thermsidebar)"
      file="thermv2_sprite"
      name="expand-collapse-left"
      :style="{ transform: mini_thermsidebar ? 'rotate(180deg)' : null }"
      class="fas fa-chevron-left is-32x32 is-pointer toggle-thermsidebar"
    ></sh-icon> -->

    <i
      class="fas is-24x24 toggle-thermsidebar is-pointer"
      :class="[
        mini_thermsidebar
          ? ['fa-chevron-right', 'chevron-active']
          : 'fa-chevron-left'
      ]"
      @click="SET_MINI_THERMSIDEBAR(!mini_thermsidebar)"
      :style="[mini_thermsidebar ? { left: '3.2rem' } : { left: '18.6rem' }]"
    ></i>

    <div
      class="has-slimscroll-xs"
      style="height: 100%"
      v-if="!mini_thermsidebar"
    >
      <div class="mt-5">
        <router-link
          tag="div"
          :to="nav.to"
          v-for="nav in navigation_headers"
          :key="nav.header"
          class="therm-sidebar__item is-flex align-center is-pointer"
          :class="{
            'is-active-sidebar-item': handle_routes(nav.header),
            'pl-10': !mini_thermsidebar,
            'is-center justify-center': mini_thermsidebar
          }"
          @click.native="is_intercom_active = false"
        >
          <p
            class="therm-sidebar__item--icon"
            v-tooltip="{
              content: mini_thermsidebar ? nav.header : null,
              classes: ['tooltip'],
              placement: 'right-end',
              offset: '12px'
            }"
          >
            <sh-icon
              :name="
                handle_routes(nav.header) ? nav.sprite + '-active' : nav.sprite
              "
              :file="'thermv2_sprite'"
              class="icon is-20x20"
            ></sh-icon>
          </p>

          <p
            v-if="!mini_thermsidebar"
            :class="{ 'has-text-white': handle_routes(nav.header) }"
            class="therm-sidebar__item--text is-medium-15-500-18 pl-10"
          >
            {{
              localization(
                `app-${nav.header
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`,
                nav.header
              )
            }}
          </p>
        </router-link>
      </div>
      <div class="filters-section mt-10">
        <div class="is-relative">
          <div
            :class="{ 'is-disabled': $route.name === 'therm-v2-compare' }"
            @click="tickets_filter_active = !tickets_filter_active"
            class="is-pointer is-medium-15-500-18 therm-sidebar__item--text has-full-width is-flex align-center my-15 pl-5"
          >
            <div
              v-tooltip="{
                content: mini_thermsidebar ? 'Filters' : null,
                classes: ['tooltip'],
                placement: 'right-end',
                offset: '8px'
              }"
            >
              <sh-icon
                :file="'thermv2_sprite'"
                class="is-20x20 icon mr-7"
                :name="active_class_ids.length ? 'filter-active' : 'filter'"
              ></sh-icon>
            </div>
            <div
              v-if="!mini_thermsidebar"
              class="filters-section__heading pr-10 has-full-width is-flex has-space-between"
            >
              <div :class="{ 'has-text-blue': active_class_ids.length }">
                {{ localization("app-filters", "Filters") }}
              </div>
              <div class="is-flex align-center">
                <div
                  class="is-medium-14-500-17"
                  v-if="active_class_ids.length && selectedFiltersCount"
                >
                  {{ selectedFiltersCount }} selected
                </div>
                <i
                  class="fas fa-chevron-right"
                  style="font-size: 1rem; color: grey; padding: 0 10px;"
                ></i>
              </div>
            </div>
          </div>
          <ticket-filters
            class="thermv2-filters-popup"
            v-if="tickets_filter_active"
            :visible_parameter="[
              'status',
              'priority',
              'assignee',
              'created_by',
              'duedate',
              'tags'
            ]"
            :has_dark_theme="false"
            :filtersData="filtersData"
            :style="{
              left: mini_thermsidebar ? '8rem' : '22rem'
            }"
            @apply-viewer="onApplyViewer($event)"
            @close="tickets_filter_active = false"
            v-click-outside="
              () => {
                tickets_filter_active = false;
              }
            "
          >
            <template v-slot:custom-content="{ allFiltersData }">
              <div
                class="filter-dropdown-content__item has-text-black mt-5 is-medium-14-500-17 "
              >
                {{
                  localization(
                    "app-temperature-difference",
                    "Temperature Difference"
                  )
                }}
              </div>
              <temperature-difference
                class="filter-dropdown-content__item is-block no-padding-bottom"
                :active_range="filtersData['temperature-difference']"
                @updated="allFiltersData['temperature-difference'] = $event"
              />
            </template>
          </ticket-filters>
        </div>
        <div
          class="therm-sidebar__filters has-slimscroll-xs"
          v-if="!mini_thermsidebar"
        >
          <template v-for="vector in therm_vector_filters">
            <div
              v-if="vector.count || vector.default"
              v-tooltip="{
                content:
                  vector.description.length > vector_description_max_length
                    ? vector.description
                    : null,
                classes: ['tooltip'],
                placement: 'top'
              }"
              :key="vector.class_id"
              class="py-10 is-flex align-center therm-sidebar__filters--item is-between px-10 is-pointer"
            >
              <div
                @click="update_active_class_ids(vector.class_id)"
                class="is-flex has-full-width"
              >
                <div
                  class="therm-sidebar__filters--box is-relative is-flex align-center"
                  :style="{ borderColor: vector.color }"
                >
                  <div
                    class="is-inner is-absolute"
                    :style="{
                      background: active_class_ids.includes(vector.class_id)
                        ? vector.color
                        : 'none'
                    }"
                  />
                </div>
                <div
                  class="is-medium-14-500-17 therm-sidebar__item--text ml-5"
                >
                  {{
                    vector.description | truncate(vector_description_max_length)
                  }}
                </div>
              </div>
              <div
                @click="
                  active_class_ids = [];
                  update_active_class_ids(vector.class_id);
                "
                class="is-medium-14-500-17"
              >
                {{ vector.count || 0 }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <export-data
        class="is-absolute"
        v-if="!mini_thermsidebar && $route.name !== 'therm-v2-compare'"
      />
    </div>
  </div>
</template>
<script>
  import { mapMutations, mapState } from "vuex";
  import ProjectsList from "./therm-v2-projects-list.vue";
  import TicketFilters from "@/app/old/desktop/tickets/shared/components/filter-card/filter-dropdown.vue";
  import TemperatureDifference from "./slider.vue";
  import ExportData from "./therm-v2-export-data.vue";
  import MapsMixin from "../mixins/therm-v2.maps.mixin.vue";

  export default {
    mixins: [MapsMixin],
    props: {
      vector_description_max_length: {
        type: Number,
        default: 17,
        required: false
      }
    },
    data() {
      return {
        is_intercom_active: false,
        filtersData: {},
        selectedFilters: [],
        tickets_filter_active: false,
        active_projects: [],
        icon: require("@/assets/icons/sprite.svg"),
        navigation_headers: [
          // {
          //   header: "Home",
          //   to: { name: "home" },
          //   sprite: "home",
          //   access_key: ""
          // },
          {
            header: "Map",
            to: {
              name: "therm-v2",
              id: this.$route.params.id
            },
            sprite: "map",
            access_key: ""
          },
          {
            header: "Anomalies",
            to: {
              name: "therm-v2-tickets",
              id: this.$route.params.id
            },
            sprite: "tickets",
            access_key: "tickets"
          },
          {
            header: "Reports",
            to: {
              name: "therm-v2-reports",
              id: this.$route.params.id
            },
            sprite: "reports",
            access_key: "reports"
          }
        ]
      };
    },
    components: {
      ProjectsList,
      TicketFilters,
      TemperatureDifference,
      ExportData
    },
    computed: {
      ...mapState("thermv2", [
        "vector_types",
        "projects",
        "map",
        "container",
        "mini_thermsidebar",
        "active_layer_types",
        "defects",
        "active_filters"
      ]),
      ...mapState(["claims"]),

      asset_name() {
        try {
          const asset_id = this.$route.query.asset;
          return this.$store.state.assets.find(a => a.uid == asset_id).name;
        } catch {
          return "Unnamed Asset";
        }
      },

      // Update counts for issues displayed in the left sidebar
      therm_vector_filters() {
        if (this.$route.name === "therm-v2-compare") {
          return this.vector_types || [];
        }
        return this.vector_types
          ? this.vector_types.map(vector => {
              const vectors_with_class_id = this.defects.filter(
                defect => defect.properties.classId === vector.class_id
              ).length;
              return {
                ...vector,
                count: vectors_with_class_id
              };
            })
          : [];
      },
      sidebar_width() {
        return this.mini_thermsidebar ? 4.5 : 23;
      },
      is_filter_active() {
        if (!this.active_filters) return false;
        for (let filter in this.active_filters) {
          if (
            this.active_filters[filter] &&
            this.active_filters[filter].length
          ) {
            return true;
          }
        }
        return false;
      },
      selectedFiltersCount() {
        let count = 0;
        for (let v of Object.values(this.selectedFilters)) {
          if (v.length) count++;
        }
        return count;
      }
    },
    watch: {
      defects: {
        deep: true,
        immediate: true,
        handler() {
          // Update features on the map whenever defects are fetched
          this.set_active_vectors();
        }
      },
      active_layer_types: {
        deep: true,
        handler() {
          // Update layers for the active projects whenever layers are enabled/disabled
          this.active_projects.forEach(project => {
            this.set_active_layers(
              project,
              true,
              this.map,
              this.active_layer_types
            );
          });
        }
      },
      is_intercom_active: {
        handler() {
          if (this.is_intercom_active == false) {
            window.Intercom("hide");
          } else {
            window.Intercom("show");
          }
        }
      }
    },
    methods: {
      ...mapMutations("thermv2", [
        "SET_MINI_THERMSIDEBAR",
        "SET_ACTIVE_PROJECTS"
      ]),
      onApplyViewer(filtersData) {
        this.selectedFilters = filtersData;
      },
      set_active_projects(selected_projects) {
        this.active_projects = [...this.active_projects, ...selected_projects];
        this.SET_ACTIVE_PROJECTS(this.active_projects);
        for (let project_index in selected_projects) {
          // When multiple projects are selected at once, fly to the first project in the list
          this.set_project_layers(
            selected_projects[project_index],
            project_index == 0
          );
        }
      },
      set_inactive_projects(unselected_projects) {
        this.active_projects = this.active_projects.filter(
          project => !unselected_projects.includes(project)
        );
        this.SET_ACTIVE_PROJECTS(this.active_projects);
        unselected_projects.forEach(project => {
          // Hide the layers for projects that were unselected
          this.unset_project_layers(project);
        });
      },
      update_active_class_ids(class_id) {
        if (this.active_class_ids.includes(class_id)) {
          const class_id_index = this.active_class_ids.findIndex(
            active_class_id => active_class_id === class_id
          );
          this.active_class_ids.splice(class_id_index, 1);
        } else {
          this.active_class_ids = [...this.active_class_ids, class_id];
        }
        this.set_active_vectors();
      },
      async set_project_layers(project_id, fly = true) {
        let project_details = null;

        if (this.projects[project_id]) {
          project_details = this.projects[project_id];
        } else {
          project_details = await this.fetch_project_details(project_id);
        }

        if (!this.map) return;
        if (fly) this.fly_to_project(project_details, this.map);
        // Refer the mixin
        this.setup_map_with_active_layers_for_projects(project_id);
        this.set_active_vectors();
      },
      unset_project_layers(project_id) {
        if (!this.map) return;
        this.set_active_layers(
          project_id,
          false,
          this.map,
          this.active_layer_types
        );
      },
      handle_routes(nav) {
        if (nav == "Home") return this.$route.name == "home";
        if (nav == "Anomalies") return this.$route.name == "therm-v2-tickets";
        if (nav == "Reports") return this.$route.name == "therm-v2-reports";
        if (nav == "Map")
          return (
            this.$route.name === "therm-v2-compare" ||
            this.$route.name === "therm-v2"
          );
      }
    }
  };
</script>
<style lang="scss">
  .therm-sidebar {
    z-index: 30;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    background-color: $white;
    width: 20rem;
    transition: width 0.2s linear;

    &__details {
      padding: 1rem;
    }

    &__asset-name {
      color: $light-black;
      padding-bottom: 5px;
    }

    .has-slimscroll-xs {
      &::-webkit-scrollbar {
        width: 0.3rem !important;
        height: 0.3rem !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1rem !important;
        background: rgba(134, 134, 134, 0.7) !important;
      }

      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
    .mainlogo {
      width: 13rem;
      height: 5.8rem;
      margin: 0rem auto;
    }
    &__item {
      padding-top: 5px;
      padding-bottom: 5px;
      margin: 5px;
      border-radius: 2px;
    }

    .filters-section {
      height: 100%;
      border-top: 1px solid #bbbdbe21;

      &__heading {
        display: flex;
        align-items: center;
      }

      .dueDate-calendar {
        transform: translate(110%, -75%);
      }
      #filters-dropdown-tags {
        transform: translateY(-100%);
      }
    }
    .toggle-thermsidebar {
      position: absolute;
      color: #5b607e;
      top: 2rem;
      right: -1.5rem;
      background-color: white;
      border-radius: 50%;
      height: 2.7rem;
      width: 2.7rem;
      z-index: 100;
      box-shadow: 1px 2px 15px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
    }
    &__filters {
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 33rem;
      border-top: 1px solid #bbbdbe21;

      &--box {
        height: 1.5rem;
        width: 1.5rem;
        min-height: 1.5rem;
        min-width: 1.5rem;
        max-height: 1.5rem;
        max-width: 1.5rem;
        border: 1px solid red;
        border-radius: 2px;
        .is-inner {
          height: 7px;
          width: 7px;
          transform: translate(40%, 2%);
          border-radius: 1px;
        }
      }
      &--item {
        .selector {
          height: 1.5rem;
          width: 1.5rem;
          border: 1px solid white;
          color: white;
          border-radius: 0.2rem;
          visibility: hidden;
        }
        &:hover {
          .selector {
            visibility: visible;
          }
        }
      }
    }

    &__item {
      &--text {
        color: $light-black;
        white-space: nowrap;
      }
    }
  }
  .logo-mini {
    margin: 1.5rem auto;
  }

  .thermv2-filters-popup {
    // transform: scale(0.9);
    top: 8.6rem;
    position: fixed;

    .dropdown {
      position: relative;
    }
  }

  .therm-sidebar__item.is-active-sidebar-item {
    background-color: $ghost-white-border;

    .therm-sidebar__item--text {
      color: $primary !important;
    }
  }

  .therm-sidebar.therm-sidebar--mini {
    display: flex;
    justify-content: center;

    .therm-sidebar__details {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      margin: auto;
      white-space: nowrap;
    }
  }

  .chevron-active {
    background-color: $primary !important;
    color: white !important;
  }

  .therm-sidebar--mini {
    .therm-popup {
      top: 3rem !important;
    }
  }
</style>
