<script>
  import NewTicketDetails from "@/app/old/desktop/tickets/components/ticket-details/ticket-details";
  import DefectDetails from "@/app/old/desktop/therm-v2/components/defect-details-popup/therm-v2-defect-details-popup.vue";

  export default {
    data() {
      return {
        is_ticket_details_popup_open: false,
        is_submission_popup_open: false,
        can_close: true
      };
    },
    methods: {
      open_form_submission() {
        let res;

        if (res) this.is_submission_popup_open = true;
      },

      open_ticket_details(id) {
        let res = null;
        if (!this.is_ticket_details_popup_open) {
          res = this.$buefy.modal.open({
            props: {
              ticket_id: id
            },
            parent: this,
            component: this.$route.name.includes("therm-v2")
              ? DefectDetails
              : NewTicketDetails,
            destroyOnHide: true,
            hasModalCard: true,
            trapFocus: true,
            customClass: "ticket-details-popup",
            width: "100%",
            height: "100%",
            canCancel: ["escape"],
            onCancel: () => this.outputMessage(), // note the arrow
            events: {
              close: e => {
                (this.is_ticket_details_popup_open = false),
                  this.$store.commit(
                    "tickets/SET_CURRENT_OPEN_TICKET_ID",
                    null
                  );
              }
            }
          });
          if (res) {
            this.is_ticket_details_popup_open = true;
          }
        }
      },

      outputMessage(e) {
        if (this.$route.name == "ticket-details") {
          this.$router.go(-1);
          this.is_ticket_details_popup_open = false;
          this.$store.commit("tickets/SET_CURRENT_OPEN_TICKET_ID", null);
        } else {
          this.$emit("close");
          this.is_ticket_details_popup_open = false;
          this.$store.commit("tickets/SET_CURRENT_OPEN_TICKET_ID", null);
        }
      }
    }
  };
</script>
