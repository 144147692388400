<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import { mapState, mapActions, mapGetters } from "vuex";
  const queryString = require("query-string");

  export default {
    data() {
      return {
        realTimeQuery: null
      };
    },
    computed: {
      ...mapState("tickets", [
        "tickets",
        "is_filters_selected",
        "current_ticket"
      ]),
      ...mapState("thermv2", ["defects"]),
      ...mapState(["PUSHER", "current_organization"])
    },

    methods: {
      async post_message_to_viewer(updates) {
        if (this.$route.name.includes("viewer")) {
          let el = document.getElementById(this.$route.name);
          if (el) {
            let wn = el.contentWindow;
            wn.postMessage(
              {
                name: "tickets_updates",
                data: updates.uids
              },
              this.$route.name === "terra-viewer"
                ? process.env.VUE_APP_TERRA_VIEWER
                : process.env.VUE_APP_THERM_VIEWER
            );
          }
        }
      },
      set_org_tickets_event() {
        let orgChannel = this.$store.state.PUSHER.subscribe(
          `private-tickets-org_${this.current_organization.uid}`
        );
        orgChannel.bind("TICKETS_UPDATED", data => {
          let updatedTickets = data.uids || [];
          this.post_message_to_viewer(data);
          this.updateTicketsRealtime(updatedTickets);
          if (updatedTickets.includes(this.current_ticket)) {
            this.$store.dispatch("checklist/add_checklists", {
              id: this.current_ticket
            });
            this.$eventHub.$emit("pusher_attachments_update");
          }
        });
        orgChannel.bind("TICKETS_ADDED", data => {
          this.post_message_to_viewer(data);
          if (!["dashboard"].includes(this.$route.name)) {
            this.addRealtimeTicket(data);
          }
        });

        orgChannel.bind("TICKETS_REMOVED", data => {
          if (data && data.uids) {
            this.$store.dispatch("tickets/remove_tickets", data.uids);
            this.post_message_to_viewer(data);
          }
        });
      },
      addRealtimeTicket(data) {
        setTimeout(async () => {
          if (data && data.uids) {
            let existingTicketUids = _.map(
              _.filter(this.tickets || []),
              ticket => ticket.uid
            );
            let queryUids = _.difference(data.uids, existingTicketUids);
            queryUids = queryUids.length > 0 ? queryUids : [null];
            let uidsQuery = queryUids
              .map(newticketUid => "&ticketUid=" + newticketUid)
              .join("");

            if (this.is_filters_selected) {
              return;
            } else {
              let query = {
                internal: this.is_internal
              };
              if (this.element && this.element.uid != data.elementUid) {
                return;
              } else {
                query["element"] = data.elementUid;
              }
              let res = await this.fetch_tickets(
                queryString.stringify(query) + `${uidsQuery}`
              );
              res.data.map(ticket => {
                this.$store.dispatch("tickets/add_realtime_ticket", ticket);
              });
            }
          }
        }, 500);
      },

      async updateTicketsRealtime(ticketUids) {
        if (this.$route.name.includes("therm-v2") && ticketUids.length > 25)
          return;

        let new_data = this.$route.name.includes("therm-v2")
          ? this.defects
          : this.tickets;
        let existingTicketUids = _.intersection(
          ticketUids,
          _.map(_.flatMap(new_data || []), ticket => ticket.uid)
        );
        this.realTimeQuery = existingTicketUids
          .map(newticketUid => "&ticketUid=" + newticketUid)
          .join("");
        if (this.realTimeQuery && this.realTimeQuery.length) {
          let { data } = await this.fetch_tickets(
            "tags=true" + this.realTimeQuery
          );
          let updatedTickets = data;
          updatedTickets.forEach(async ticket => {
            let updated = _.omit(ticket, [
              "organization",
              "owner",
              "elmentUid",
              "elementType",
              "element",
              "stage"
            ]);
            this.$store.dispatch("tickets/add_ticket_details", {
              ticket: updated,
              router: this.$router
            });
          });
        }
      },
      async fetch_tickets(query) {
        return await ticketService
          .getAll({
            query: query
          })
          .catch(err => {
            this.handleErrors(err);
          });
      }
    }
  };
</script>
